import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IS_FILTERED_BY_PHARMACY } from '@troyai/pharmacy-context/data-access';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import {
  Appointment,
  AppointmentsResponse,
  AssignAppointmentToPatientPayload,
  ScheduleAppointmentToPatientAsPharmacyPayload,
  TimeslotDistanceDifference,
  UnassignAppointmentToPatientPayload as UnscheduleAppointmentToPatientPayload,
} from './models/appointment.interface';
import { MemberHra, MemberHraAddendum } from './models/hra.interface';
import { NursePractitioner } from './models/nurse-practitioner.interface';
import { HraAction, PatientForAppointment } from './models/scheduled-care-actions.interface';

@Injectable({
  providedIn: 'root',
})
export class HraCareApiService {
  constructor(private restApiService: RestApiClientService) {}

  getCareActions() {
    return this.restApiService.get<HraAction[]>('care-team/hra/actions');
  }

  getAppointmentsData(startDate: string, endDate: string) {
    return this.restApiService.get<AppointmentsResponse>(
      `care-team/hra/appointments?start_date=${startDate}&end_date=${endDate}`
    );
  }

  getAllAppointmentsDataAsPharmacy(startDate: string, endDate: string) {
    return this.restApiService.get<Appointment[]>(
      `pharmacies/hra/appointments?start_date=${startDate}&end_date=${endDate}`,
      {
        context: new HttpContext().set(IS_FILTERED_BY_PHARMACY, true),
      }
    );
  }

  getAppointmentsTimeslotsAsPharmacy(
    actionGlobalId: number,
    addressHash: string,
    startDate: string,
    endDate: string,
    scheduleTelehealth: boolean,
    NPGlobalId?: number
  ) {
    return this.restApiService.get<Appointment[]>(
      `actions/${actionGlobalId}/timeslots?address_hash=${addressHash}&start_date=${startDate}&end_date=${endDate}&np_id=${NPGlobalId}&schedule_telehealth=${
        scheduleTelehealth ? '1' : '0'
      }`
    );
  }

  scheduleAppointmentForPatientAsPharmacy(
    actionGlobalId: number,
    payload: ScheduleAppointmentToPatientAsPharmacyPayload
  ) {
    const body = new HttpParams({
      fromObject: {
        timeslot_id: payload.timeslotId.toString(),
        address_hash: payload.addressHash,
        np_id: payload.NPId.toString(),
        // TODO: remove this when the API is updated, now it requires a '1' instead of boolean
        is_telehealth: payload.isTelehealth === true ? '1' : '0',
      },
    });

    return this.restApiService.post<GenericRestApiOKResponse>(
      `actions/${actionGlobalId}/schedule-appointment`,
      body
    );
  }

  unscheduleAppointmentForPatientAsPharmacy(
    globalId: number,
    payload: UnscheduleAppointmentToPatientPayload
  ) {
    const body = new HttpParams().set('reason', payload.reason);
    return this.restApiService.post<GenericRestApiOKResponse>(
      `actions/${globalId}/unschedule-appointment`,
      body
    );
  }

  checkSlotDrivingDistance(timeslotId: number, addressHash: string, actionId: number) {
    return this.restApiService.get<TimeslotDistanceDifference>(
      `timeslots/${timeslotId}/check-slot-driving-distance?address_hash=${addressHash}&action_id=${actionId}`
    );
  }

  getNursePractitionersList() {
    return this.restApiService.get<NursePractitioner[]>('care-team/nps');
  }

  getPatientsToSchedule() {
    return this.restApiService.get<PatientForAppointment[]>('care-team/hra/appointments/members');
  }

  removeSlotAvailability(timeslotsIds: number[]) {
    // TODO: extract this to a generic function in the rest-api-client
    let body = new HttpParams();
    for (const id of timeslotsIds) {
      body = body.append('timeslots_ids', id);
    }

    return this.restApiService.patch<GenericRestApiOKResponse>(
      'care-team/hra/appointments/remove-availability',
      body
    );
  }

  restoreSlotAvailability(timeslotsIds: number[]) {
    // TODO: extract this to a generic function in the rest-api-client
    let body = new HttpParams();
    for (const id of timeslotsIds) {
      body = body.append('timeslots_ids', id);
    }

    return this.restApiService.patch<GenericRestApiOKResponse>(
      'care-team/hra/appointments/restore-availability',
      body
    );
  }

  assignAppointmentToPatient(timeslotId: number, payload: AssignAppointmentToPatientPayload) {
    const body = new HttpParams({
      fromObject: {
        action_id: payload.actionId.toString(),
        address_hash: payload.addressHash,
      },
    });

    return this.restApiService.post<GenericRestApiOKResponse>(
      `care-team/hra/appointments/${timeslotId}/assign-action`,
      body
    );
  }

  unassignAppointmentToPatient(timeslotId: number, payload: UnscheduleAppointmentToPatientPayload) {
    const body = new HttpParams().set('reason', payload.reason);
    return this.restApiService.patch<GenericRestApiOKResponse>(
      `care-team/hra/appointments/${timeslotId}/unassign-action`,
      body
    );
  }

  getMemberHraItems(memberGlobalId: number) {
    return this.restApiService.get<MemberHra[]>(`members/${memberGlobalId}/assessments`);
  }

  addMemberHraAddendum(memberGlobalId: number, hraId: number, content: string) {
    return this.restApiService.post<MemberHraAddendum>(
      `members/${memberGlobalId}/assessments/${hraId}`,
      {
        content,
      }
    );
  }
}
