import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadingState, getLoadingStatus } from '@troyai/shared/state';
import { tap } from 'rxjs';
import { MedicationApiService } from '../medication-api.service';
import { MedicationListItem } from '../models/medication.interface';
import { MedicationStateModel } from './medication-state.model';
import {
  GetPatientMedicationsList,
  UpdateMedicationEditModeStatus,
  UpdateMedicationNote,
  UpdateMedicationStatus,
} from './medication.actions';

@State<MedicationStateModel>({
  name: 'medicationState',
  defaults: {
    medicationList: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
  },
})
@Injectable()
export class MedicationState {
  constructor(private medicationApiService: MedicationApiService) {}

  @Selector()
  static medicationList(state: MedicationStateModel) {
    return state.medicationList.result;
  }

  @Selector()
  static medicationListLoadingStatus(state: MedicationStateModel) {
    return getLoadingStatus(state.medicationList.loadingStatus);
  }

  @Selector()
  static lastUpdateMedicationItem(state: MedicationStateModel) {
    if (!state.medicationList) {
      return null;
    }

    const medicationList = state.medicationList.result;
    if (!medicationList) {
      return null;
    }

    return medicationList.reduce((acc: MedicationListItem | null, medication) => {
      if (!acc || medication.created_on > acc.created_on) {
        return medication;
      }
      return acc;
    }, null);
  }

  @Action(GetPatientMedicationsList)
  getPatientMedicationsList(
    { patchState }: StateContext<MedicationStateModel>,
    action: GetPatientMedicationsList
  ) {
    patchState({
      medicationList: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.medicationApiService.getMedications(action.memberGlobalId).pipe(
      tap((medicationList) => {
        const medicationListItems = medicationList.map((medication) => {
          return {
            ...medication,
            noteEditMode: false,
          } as MedicationListItem;
        });

        patchState({
          medicationList: {
            result: medicationListItems,
            loadingStatus: LoadingState.LOADED,
          },
        });
      })
    );
  }

  @Action(UpdateMedicationStatus)
  updateMedicationStatus(
    { getState, patchState }: StateContext<MedicationStateModel>,
    action: UpdateMedicationStatus
  ) {
    const { medicationList } = getState();
    if (!medicationList.result) {
      return;
    }

    if (action.persist) {
      const updatedMedications = medicationList.result.map((medication) => {
        if (medication.id === action.payload.medicationId) {
          return {
            ...medication,
            active: action.payload.active,
            noteEditMode: !action.payload.active,
            isLoading: true,
          };
        }
        return {
          ...medication,
          noteEditMode: false,
          isLoading: false,
        };
      });

      patchState({
        medicationList: {
          result: updatedMedications,
          loadingStatus: LoadingState.LOADED,
        },
      });

      return this.medicationApiService
        .updateMedicationStatus(
          action.payload.memberGlobalId,
          action.payload.medicationId,
          action.payload.note,
          action.payload.active
        )
        .pipe(
          tap(() => {
            const { medicationList } = getState();

            if (!medicationList.result) {
              return;
            }

            // TODO: Optimize item update using more efficient state operators
            const updatedMedications = medicationList.result.map((medication) => {
              if (medication.id === action.payload.medicationId) {
                return {
                  ...medication,
                  isLoading: false,
                };
              }
              return {
                ...medication,
              };
            });

            patchState({
              medicationList: {
                result: updatedMedications,
                loadingStatus: LoadingState.LOADED,
              },
            });
          })
        );
    } else {
      const updatedMedications = medicationList.result.map((medication) => {
        if (medication.id === action.payload.medicationId) {
          return {
            ...medication,
            active: action.payload.active,
            noteEditMode: !action.payload.active,
            isLoading: false,
          };
        }
        return {
          ...medication,
          noteEditMode: false,
          isLoading: false,
        };
      });

      patchState({
        medicationList: {
          result: updatedMedications,
          loadingStatus: LoadingState.LOADED,
        },
      });

      return patchState({
        medicationList: {
          result: updatedMedications,
          loadingStatus: LoadingState.LOADED,
        },
      });
    }
  }

  @Action(UpdateMedicationEditModeStatus)
  enterNoteEditMode(
    { getState, patchState }: StateContext<MedicationStateModel>,
    action: UpdateMedicationEditModeStatus
  ) {
    const { medicationList } = getState();
    if (!medicationList.result) {
      return;
    }

    const updatedMedications = medicationList.result.map((medication) => {
      if (medication.id === action.medicationId) {
        return {
          ...medication,
          noteEditMode: action.editModeStatus,
        };
      }
      return medication;
    });

    patchState({
      medicationList: {
        result: updatedMedications,
        loadingStatus: LoadingState.LOADED,
      },
    });
  }

  @Action(UpdateMedicationNote)
  updateMedicationNote(
    { getState, patchState }: StateContext<MedicationStateModel>,
    action: UpdateMedicationNote
  ) {
    const { medicationList } = getState();
    if (!medicationList.result) {
      return;
    }

    const updatedMedications = medicationList.result.map((medication) => {
      if (medication.id === action.payload.medicationId) {
        return {
          ...medication,
          notes: action.payload.medicationListItem.notes || '',
          isLoading: true,
          noteEditMode: false,
        };
      }
      return medication;
    });

    patchState({
      medicationList: {
        result: updatedMedications,
        loadingStatus: LoadingState.LOADED,
      },
    });

    return this.medicationApiService
      .updateMedicationStatus(
        action.payload.memberGlobalId,
        action.payload.medicationId,
        action.payload.medicationListItem.notes || '',
        action.payload.medicationListItem.active || false
      )
      .pipe(
        tap(() => {
          const { medicationList } = getState();
          if (!medicationList.result) {
            return;
          }

          const updatedMedications = medicationList.result.map((medication) => {
            if (medication.id === action.payload.medicationId) {
              return {
                ...medication,
                isLoading: false,
              };
            }
            return medication;
          });

          return patchState({
            medicationList: {
              result: updatedMedications,
              loadingStatus: LoadingState.LOADED,
            },
          });
        })
      );
  }
}
