import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientEncounter } from '@troyai/patients/data-access';
import { IconsModule } from '@troyai/ui-kit';
import { SvgIcons } from '@ngneat/svg-icon';

type Variants = 'default' | 'compact';

@Component({
  selector: 't-encounter-card',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './encounter-card.component.html',
  styleUrls: ['./encounter-card.component.css'],
})
export class EncounterCardComponent {
  @Input() encounter?: PatientEncounter;
  @Input() variant: Variants = 'default';

  iconsMap: Record<string, SvgIcons> = {
    HOS: 'local-hospital',
    HHA: 'home-health',
    SNF: 'home-health',
    HOSPICE: 'home-health',
  };
}
