<div class="py-3">
  <div *ngIf="!isLast" class="border-grey-20 absolute left-3 top-3 h-full border-l"></div>
  <div class="relative flex items-center space-x-1 lg:space-x-4">
    <div class="flex items-center justify-center bg-white p-2">
      <span
        class="border-grey-50 block h-2 w-2 rounded-full border-2"
        [ngClass]="{
          '!border-amber-70': isHighlighted
        }"></span>
    </div>

    <div class="text-grey-50 text-sm font-semibold">
      <ng-content></ng-content>
    </div>
  </div>
</div>
