<div class="relative">
  <ngx-file-drop
    contentClassName="t-upload-file"
    dropZoneLabel="Drop files here"
    [accept]="allowedExtensions"
    (onFileDrop)="dropped($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      @if (isLoading) {
        <div
          class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-90">
          <div class="flex items-center gap-x-2">
            <t-loader size="small" />
            <span class="text-grey-80 text-xs">Uploading...</span>
          </div>
        </div>
      } @else {
        <span>Drag and Drop file here or</span>
        <t-link variant="accent" [isButton]="true" (click)="openFileSelector()">Upload</t-link>
      }
    </ng-template>
  </ngx-file-drop>
</div>
