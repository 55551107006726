<div
  *ngIf="isAuthLoading$ | async; else authLoaded"
  class="flex h-screen w-screen items-center justify-center">
  <t-loader />
</div>

<ng-template #authLoaded>
  <ng-container [ngSwitch]="layoutsService.layout$ | async">
    <t-minimal-layout *ngSwitchCase="Layouts.Minimal">
      <router-outlet />
    </t-minimal-layout>

    <t-sidebar-layout
      *ngSwitchDefault
      (closeSidebarEvent)="toggleSidebar()"
      [sidebarVisible]="sidebarVisible">
      <div top class="bg-sidebar-bg flex h-[56px] items-center justify-between px-5 lg:h-[72px]">
        <a routerLink="/">
          <t-branding [logoUrl]="(tenantLogoUrl$ | async) || ''" />
        </a>

        <div class="flex items-center gap-x-6">
          <ng-container *tIfFeatureFlag="'in-app-notifications'">
            @if (isMOCCompliant$ | async) {
              <a
                *tIfHasRole="[userRoles.PharmacyUser]"
                routerLink="/notifications"
                class="guided-tour-notifications-button"
                id="tour-menu-notification-item">
                <t-notifications-count-badge [count]="notificationsCount$ | async" />
              </a>
            }
          </ng-container>

          <div class="lg:hidden">
            <button
              class="rounded p-1 text-white hover:bg-black hover:bg-opacity-10"
              (click)="toggleSidebar()">
              <t-icon key="menu" color="inherit" size="small" />
            </button>
          </div>
        </div>
      </div>

      <router-outlet />

      <ng-container nav *ngIf="!mobileMode">
        <div class="bg-sidebar-bg h-full pt-[72px] lg:h-[calc(100vh-72px)] lg:pt-0">
          <t-main-menu (menuItemSelected)="toggleSidebar()" />
        </div>
      </ng-container>

      <ng-container mobileNav *ngIf="mobileMode">
        <div class="bg-sidebar-bg h-full pt-[72px] lg:h-[calc(100vh-72px)] lg:pt-0">
          <t-main-menu (menuItemSelected)="toggleSidebar()" />
        </div>
      </ng-container>
    </t-sidebar-layout>
  </ng-container>
</ng-template>
