import { Injectable } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';

import { AvailableFeatureFlags, Environment, FeatureFlags } from '@troyai/envs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(private env: Environment) {}
  private featureFlagsConfig: FeatureFlags = this.env.featureFlags;

  loadConfig() {
    // TODO: uncomment this code to load the feature flags from the server
    // return firstValueFrom(
    //   this.http.get<FeatureFlag>('feature-flags').pipe(tap((data) => (this.config = data)))
    // );

    return firstValueFrom(of(this.featureFlagsConfig));
  }

  isFeatureEnabled(key: AvailableFeatureFlags) {
    return this.featureFlagsConfig[key] && this.featureFlagsConfig[key] === true;
  }

  toggleFeature(key?: AvailableFeatureFlags) {
    if (!key) return;
    this.featureFlagsConfig[key] = !this.featureFlagsConfig[key];
  }

  updateFeature(key: AvailableFeatureFlags, value?: boolean) {
    if (!key || !value) return;

    this.featureFlagsConfig[key] = value;
  }

  getSnapshot() {
    return this.featureFlagsConfig;
  }
}
