import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';

import { ActionsState, ComputedActionValues, GetSingleAction } from '@troyai/actions/data-access';
import {
  ActionEstimatedCompletionTimeComponent,
  ActionResourcesListComponent,
  ActionRichTextDescriptionComponent,
  RelatedActionsListComponent,
} from '@troyai/actions/ui';
import { FullNamePipe } from '@troyai/patients/util';
import { CustomMediumDatePipe } from '@troyai/portal/common/pipes';
import { TrackDirective, TrackService } from '@troyai/tracking';
import { ButtonComponent, CardComponent, IconsModule, ModalModule } from '@troyai/ui-kit';
import { RelatedActionsPipe } from '../../../../pipes/related-actions.pipe';
import { ChatWindowComponent } from '../../../action-chat/chat-window/chat-window.component';
import { ActionCtaCardComponent } from '../../../action-cta-card/action-cta-card.component';
import { ActionIframeEmbedComponent } from '../../../action-iframe-embed/action-iframe-embed.component';
import { ActionLayoutRegularComponent } from '../../../action-layout-regular/action-layout-regular.component';
import { ActionSingleHeaderComponent } from '../../../action-single-header/action-single-header.component';
import { OutreachAttemptsListComponent } from '../../../outreach-attempts/outreach-attempts-list/outreach-attempts-list.component';

@Component({
  selector: 't-action-default-template',
  standalone: true,
  imports: [
    CommonModule,
    ActionLayoutRegularComponent,
    IconsModule,
    ActionIframeEmbedComponent,
    ActionResourcesListComponent,
    ChatWindowComponent,
    ActionCtaCardComponent,
    ActionSingleHeaderComponent,
    CardComponent,
    ActionEstimatedCompletionTimeComponent,
    ButtonComponent,
    ModalModule,
    FullNamePipe,
    CustomMediumDatePipe,
    TrackDirective,
    ActionCtaCardComponent,
    ActionRichTextDescriptionComponent,
    RelatedActionsListComponent,
    OutreachAttemptsListComponent,
    RelatedActionsPipe,
  ],
  templateUrl: './action-default-template.component.html',
})
export class ActionDefaultTemplateComponent {
  constructor(
    private store: Store,
    private trackService: TrackService
  ) {}

  action$ = this.store.select(ActionsState.selectedAction);
  @Input({ required: true }) computedActionValues!: ComputedActionValues;

  handlePostSubmitData() {
    this.action$
      .pipe(
        take(1),
        tap((action) => {
          if (action) {
            this.trackService.trackEvent({
              eventName: 'Submitted action form',
              eventProps: {
                member_id: action.member_assigned_to,
                action_id: action.global_id,
              },
            });
          }

          this.store.dispatch(new GetSingleAction({ globalId: action?.global_id }));
        })
      )
      .subscribe();
  }
}
