<ng-container *ngIf="isTourActive$ | async">
  <ng-container [ngSwitch]="tourName">
    <t-dashboard-tour *ngSwitchCase="'dashboard'" />
    <t-patients-tour *ngSwitchCase="'patients'" />
    <t-actions-tour *ngSwitchCase="'actions'" />
    <t-single-patient-tour *ngSwitchCase="'single-patient'" />
    <t-single-action-tour *ngSwitchCase="'single-action'" />
    <t-adherence-tour *ngSwitchCase="'adherence'" />
  </ng-container>
</ng-container>
