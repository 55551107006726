import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@troyai/ui-kit';

@Component({
  selector: 't-notifications-count-badge',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './notifications-count-badge.component.html',
})
export class NotificationsCountBadgeComponent {
  @Input() count: number | null = 0;
}
