import { Inject, Injectable } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { UserPharmacy } from '@troyai/auth/data-access';

const GLOBAL_SELECTED_PHARMACY_KEY = 'global-selected-pharmacy';

@Injectable({
  providedIn: 'root',
})
export class SelectedPharmacyLocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.retrieveStoredPharmacy();
  }

  private _selectedPharmacy = new BehaviorSubject<UserPharmacy | null>(null);

  localStorageSelectedPharmacy$() {
    return this._selectedPharmacy.asObservable();
  }

  retrieveStoredPharmacy() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const storedPharmacyValue = localStorage.getItem(GLOBAL_SELECTED_PHARMACY_KEY);
    const storedPharmacy: UserPharmacy | null = storedPharmacyValue
      ? JSON.parse(storedPharmacyValue)
      : null;

    if (storedPharmacy) {
      this._selectedPharmacy.next(storedPharmacy);
    }
  }

  storeSelectedPharmacy(pharmacy: UserPharmacy) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const storedPharmacyValue = JSON.stringify(pharmacy);
    localStorage.setItem(GLOBAL_SELECTED_PHARMACY_KEY, storedPharmacyValue);
    this._selectedPharmacy.next(pharmacy);
  }
}
