import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { NgIf } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { IconComponent } from '../../icon/icon.component';
import { GenericPromptData } from '../modal-meta-data.interface';

@Component({
  templateUrl: './generic-prompt.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [PortalModule, NgIf, IconComponent, ButtonComponent],
})
export class GenericPromptComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: GenericPromptData
  ) {}

  dismissModal() {
    this.dialogRef.close(false);
  }

  confirmAction() {
    if (this.data.onPromptConfirm) {
      this.data.onPromptConfirm();
    }
    this.dialogRef.close(true);
  }
}
