import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ongoingCareActionTitle',
  standalone: true,
})
export class OngoingCareActionTitlePipe implements PipeTransform {
  private titleMapping: Record<string, string> = {
    CDPS01: 'HRA',
    HRA01: 'NP Assessment',
    SCFE01: 'Colonoscopy / FIT test',
    LOC01: 'Real-time Level of Care Triage',
    PDFU01: 'Complete Post-Discharge Follow-Up',
    A1C02: 'A1c Home Test',
  };

  transform(value: string) {
    return this.titleMapping[value] || '';
  }
}
