import { AdherencePercentageBracketsConfig } from '@troyai/adherence/util';

// The minimum adherence percentage for a member to be considered adherent
export const MEMBER_MIN_ADHERENCE_PERCENTAGE = 0.8;

// The minimum percentage of all the eligible patients that are at least MEMBER_MIN_ADHERENCE_PERCENTAGE adherent, across all categories
export const ADHERENCE_BONUS_MIN_PERCENTAGE_ACROSS_MEMBERS = 0.9;

// The config for the adherence percentage brackets - mostly used for displaying colors based on the adherence percentage
export const adherencePercentagesConfig: AdherencePercentageBracketsConfig[] = [
  {
    percentageRange: [0, 0.9],
    level: 'low',
  },
  {
    percentageRange: [0.9, 1],
    level: 'high',
  },
];
