<a
  *ngIf="!isButton; else button"
  [routerLink]="path"
  [queryParams]="params"
  [target]="isExternal ? '_blank' : '_self'">
  <ng-container *ngTemplateOutlet="linkContent"></ng-container>
</a>

<ng-template #button>
  <button
    (click)="$event.preventDefault()"
    class="block appearance-none"
    [ngClass]="{
      'text-sm': size === 'large',
      'text-xs': size === 'small',
      'cursor-not-allowed !opacity-20': isDisabled
    }">
    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
  </button>
</ng-template>

<ng-template #linkContent>
  <span
    [ngClass]="{
      'text-grey-70 hover:text-grey-100': variant === 'dark',
      'text-grey-60 hover:text-grey-70': variant === 'light',
      'text-red-90 hover:text-red-100': variant === 'accent',
      'text-sm': size === 'large',
      'text-xs': size === 'small'
    }"
    class="underline">
    <ng-content></ng-content>
  </span>
</ng-template>
