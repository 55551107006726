<div class="flex w-full items-center justify-between">
  <t-button variant="neutral" size="tiny" (onClick)="changeWeek('current')"> THIS WEEK </t-button>
  <div class="item-center flex gap-x-5" *ngIf="timePeriod">
    <span class="text-grey-100 self-center text-sm">
      <strong class="font-semibold"> Week {{ timePeriod.startDate | date: 'ww' }}: </strong>
      {{ timePeriod.startDate | date: 'd MMM' }} —
      {{ timePeriod.endDate | date: 'd MMM, yyyy' }}
    </span>

    <div class="flex gap-x-1">
      <t-button
        (onClick)="changeWeek('previous')"
        variant="neutral"
        size="tiny"
        icon="keyboard-arrow-left"></t-button>
      <t-button
        (onClick)="changeWeek('next')"
        variant="neutral"
        size="tiny"
        icon="keyboard-arrow-right"></t-button>
    </div>
  </div>
</div>
