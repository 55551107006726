import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PluralizePipe } from '@troyai/portal/common/pipes';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { LabelComponent, LinkComponent } from '@troyai/ui-kit';
import { ActionGroup } from '../actions-set-carousel.component';

@Component({
  selector: 't-actions-set-carousel-item',
  standalone: true,
  imports: [CommonModule, LinkComponent, LabelComponent, PluralizePipe, ImageFallbackDirective],
  templateUrl: './actions-set-carousel-item.component.html',
})
export class ActionsSetCarouselItemComponent {
  @Input({ required: true }) actionGroup!: ActionGroup;
  @Input() isFeatured = false;
}
