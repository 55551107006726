<div
  class="shadow-small relative max-h-screen min-w-[350px] max-w-xl overflow-hidden rounded-lg bg-white">
  <div class="px-8 py-6">
    <ng-template cdkPortalOutlet></ng-template>
  </div>

  <div
    *ngIf="!data.hideCloseButton"
    (click)="closeDialog()"
    class="bg-grey-20 hover:bg-grey-25 absolute right-8 top-6 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full p-2">
    <t-icon key="close" size="tiniest"></t-icon>
  </div>
</div>
