import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, forkJoin, from, map, mergeMap, of, switchMap, tap, throwError } from 'rxjs';

import { PatientsFilesApiService } from '@troyai/patients/data-access';
import { LoadingState, getLoadingStatus } from '@troyai/shared/state';
import { sort } from 'fast-sort';
import { CaseNotesApiService } from '../case-notes-api.service';
import { CaseNotesStateModel } from './case-notes-state.model';
import {
  AssociateMemberFilesToCaseNote,
  CreateMemberCaseNote,
  DeleteMemberCaseNote,
  GetAllCaseNotesTemplates,
  GetAllMemberCaseNotes,
  GetSingleCaseNote,
  PublishMemberCaseNote,
  ResetWorkingDraftCaseNote,
  SelectCaseNoteTemplate,
  SetCaseNoteForEditing,
  UpdateMemberCaseNote,
} from './case-notes.actions';

const stateDefaults: CaseNotesStateModel = {
  memberCaseNotes: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
  caseNotesTemplates: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
  selectedCaseNote: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
  workingDraftCaseNote: null,
  selectedCaseNoteTemplate: null,
};

@State<CaseNotesStateModel>({
  name: 'caseNotesState',
  defaults: stateDefaults,
})
@Injectable()
export class CaseNotesState {
  constructor(
    private caseNotesApiService: CaseNotesApiService,
    private patientsFileApiService: PatientsFilesApiService
  ) {}

  @Selector([])
  static allMemberCaseNotes(state: CaseNotesStateModel) {
    return state.memberCaseNotes.result?.filter((caseNote) => !caseNote.deleted_at);
  }

  @Selector()
  static selectedCaseNote(state: CaseNotesStateModel) {
    return state.selectedCaseNote.result;
  }

  @Selector()
  static selectedCaseNoteLoadingStatus(state: CaseNotesStateModel) {
    return getLoadingStatus(state.selectedCaseNote.loadingStatus);
  }

  @Selector()
  static allMemberCaseNotesLoadingStatus(state: CaseNotesStateModel) {
    return getLoadingStatus(state.memberCaseNotes.loadingStatus);
  }

  @Selector()
  static caseNotesTemplates(state: CaseNotesStateModel) {
    if (!state.caseNotesTemplates.result) {
      return [];
    }

    return sort(state.caseNotesTemplates.result).asc((u) => u.name);
  }

  @Selector()
  static selectedCaseNoteTemplate(state: CaseNotesStateModel) {
    return state.selectedCaseNoteTemplate;
  }

  @Selector()
  static caseNotesTemplatesLoadingStatus(state: CaseNotesStateModel) {
    return getLoadingStatus(state.caseNotesTemplates.loadingStatus);
  }

  @Selector()
  static workingDraftCaseNote(state: CaseNotesStateModel) {
    return state.workingDraftCaseNote;
  }

  @Action(GetAllMemberCaseNotes)
  getAllMemberCaseNotes(
    { patchState }: StateContext<CaseNotesStateModel>,
    action: GetAllMemberCaseNotes
  ) {
    patchState({
      memberCaseNotes: {
        result: null,
        loadingStatus: action.payload.silently ? LoadingState.LOADED : LoadingState.LOADING,
      },
    });

    return this.caseNotesApiService.getAllMemberCaseNotes(action.payload.memberGlobalId).pipe(
      tap((allMemberCaseNotes) => {
        patchState({
          memberCaseNotes: {
            result: allMemberCaseNotes,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((err) => {
        patchState({
          memberCaseNotes: {
            result: [],
            loadingStatus: LoadingState.ERRORED,
          },
        });

        return throwError(() => err);
      })
    );
  }

  @Action(GetAllCaseNotesTemplates)
  getAllCaseNotesTemplates({ patchState, getState }: StateContext<CaseNotesStateModel>) {
    const { caseNotesTemplates } = getState();

    if (caseNotesTemplates.result) {
      return of(caseNotesTemplates.result);
    }

    patchState({
      caseNotesTemplates: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.caseNotesApiService.getCaseNotesTemplates().pipe(
      tap((allCaseNotesTemplates) =>
        patchState({
          caseNotesTemplates: {
            result: allCaseNotesTemplates,
            loadingStatus: LoadingState.LOADED,
          },
        })
      ),
      catchError((err) => {
        patchState({
          caseNotesTemplates: {
            result: [],
            loadingStatus: LoadingState.ERRORED,
          },
        });

        return throwError(() => err);
      })
    );
  }

  @Action(CreateMemberCaseNote)
  createMemberCaseNote(
    { patchState }: StateContext<CaseNotesStateModel>,
    action: CreateMemberCaseNote
  ) {
    return this.caseNotesApiService
      .createMemberCaseNote(action.payload.memberGlobalId, action.payload.data)
      .pipe(
        switchMap((response) =>
          this.caseNotesApiService.getMemberCaseNote(action.payload.memberGlobalId, response.id)
        ),
        tap((caseNote) => {
          patchState({
            selectedCaseNote: {
              result: caseNote,
              loadingStatus: LoadingState.LOADED,
            },
            workingDraftCaseNote: {
              id: caseNote.id,
              last_modified: new Date().toString(),
            },
          });
        })
      );
  }

  @Action(UpdateMemberCaseNote)
  updateMemberCaseNote(
    { getState, patchState }: StateContext<CaseNotesStateModel>,
    action: UpdateMemberCaseNote
  ) {
    const { workingDraftCaseNote } = getState();

    if (workingDraftCaseNote) {
      patchState({
        workingDraftCaseNote: {
          ...workingDraftCaseNote,
          last_modified: new Date().toString(),
        },
      });
    }

    return this.caseNotesApiService.editMemberCaseNote(
      action.payload.memberGlobalId,
      action.payload.caseNoteId,
      action.payload.data
    );
  }

  @Action(PublishMemberCaseNote)
  publishMemberCaseNote(
    { getState, patchState }: StateContext<CaseNotesStateModel>,
    action: PublishMemberCaseNote
  ) {
    const { workingDraftCaseNote } = getState();

    return of(workingDraftCaseNote).pipe(
      mergeMap((workingDraft) => {
        if (!action.payload.data.template_id) {
          return throwError(() => 'Template id is required');
        }

        if (workingDraft?.id) {
          return this.caseNotesApiService
            .editMemberCaseNote(action.payload.memberGlobalId, workingDraft.id, {
              content: action.payload.data.content || '',
              published: true,
            })
            .pipe(map(() => workingDraft.id));
        } else {
          return this.caseNotesApiService
            .createMemberCaseNote(action.payload.memberGlobalId, {
              content: action.payload.data.content || '',
              type: action.payload.data.type || '',
              template_id: action.payload.data.template_id,
            })
            .pipe(
              switchMap((caseNote) =>
                this.caseNotesApiService
                  .editMemberCaseNote(action.payload.memberGlobalId, caseNote.id, {
                    content: action.payload.data.content || '',
                    published: true,
                  })
                  .pipe(map(() => caseNote.id))
              )
            );
        }
      }),
      mergeMap((carePlanId) =>
        this.caseNotesApiService.getMemberCaseNote(action.payload.memberGlobalId, carePlanId)
      ),
      tap((carePlan) => {
        patchState({
          selectedCaseNote: {
            result: carePlan,
            loadingStatus: LoadingState.LOADED,
          },
          workingDraftCaseNote: null,
        });
      })
    );
  }

  @Action(GetSingleCaseNote)
  getSingleCaseNote({ patchState }: StateContext<CaseNotesStateModel>, action: GetSingleCaseNote) {
    patchState({
      selectedCaseNote: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.caseNotesApiService
      .getMemberCaseNote(action.payload.memberGlobalId, action.payload.caseNoteId)
      .pipe(
        tap((caseNote) =>
          patchState({
            selectedCaseNote: {
              result: caseNote,
              loadingStatus: LoadingState.LOADED,
            },
          })
        ),
        mergeMap((caseNote) =>
          // Retrieve all the signed urls for the files in parallel
          forkJoin(
            caseNote.files.map((file) =>
              this.patientsFileApiService.getFileSignedURL(action.payload.memberGlobalId, file.id)
            )
          ).pipe(
            map((signedUrls) => {
              return {
                ...caseNote,
                files: caseNote.files.map((file, index) => ({
                  ...file,
                  signed_url: signedUrls[index],
                })),
              };
            })
          )
        ),
        tap((caseNote) =>
          patchState({
            selectedCaseNote: {
              result: caseNote,
              loadingStatus: LoadingState.LOADED,
            },
          })
        )
      );
  }

  @Action(AssociateMemberFilesToCaseNote)
  associateMemberFileToCaseNote(
    { patchState }: StateContext<CaseNotesStateModel>,
    action: AssociateMemberFilesToCaseNote
  ) {
    return from(action.payload.fileIds).pipe(
      mergeMap((fileId: number) => {
        return this.patientsFileApiService.associateFile(
          action.payload.memberGlobalId,
          fileId,
          action.payload.data
        );
      }),
      switchMap(() =>
        this.caseNotesApiService.getMemberCaseNote(
          action.payload.memberGlobalId,
          action.payload.data.id
        )
      ),
      tap((caseNote) => {
        patchState({
          selectedCaseNote: {
            result: caseNote,
            loadingStatus: LoadingState.LOADED,
          },
        });
      })
    );
  }

  @Action(DeleteMemberCaseNote)
  deleteMemberCaseNote(
    { patchState }: StateContext<CaseNotesStateModel>,
    action: DeleteMemberCaseNote
  ) {
    return this.caseNotesApiService
      .deleteMemberCaseNote(action.payload.memberGlobalId, action.payload.caseNoteId)
      .pipe(
        tap(() =>
          patchState({
            selectedCaseNote: {
              result: null,
              loadingStatus: LoadingState.INITIAL,
            },
            workingDraftCaseNote: null,
          })
        )
      );
  }

  @Action(SetCaseNoteForEditing)
  setCaseNoteForEditing(
    { patchState }: StateContext<CaseNotesStateModel>,
    action: SetCaseNoteForEditing
  ) {
    patchState({
      selectedCaseNote: {
        loadingStatus: LoadingState.LOADED,
        result: action.payload,
      },
      workingDraftCaseNote: action.payload,
    });
  }

  @Action(ResetWorkingDraftCaseNote)
  resetWorkingDraftCaseNote({ patchState }: StateContext<CaseNotesStateModel>) {
    patchState({
      workingDraftCaseNote: null,
      selectedCaseNote: {
        result: null,
        loadingStatus: LoadingState.INITIAL,
      },
    });
  }

  @Action(SelectCaseNoteTemplate)
  selectCaseNoteTemplate(
    { patchState }: StateContext<CaseNotesStateModel>,
    action: SelectCaseNoteTemplate
  ) {
    patchState({
      selectedCaseNoteTemplate: action.payload.template,
    });
  }
}
