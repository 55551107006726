import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_OPTIONS, CacheOptions, RestApiClientService } from '@troyai/rest-api-client';
import { PharmacyStats, StatsTimeInterval } from './models/pharmacy-stats.interface';

@Injectable({
  providedIn: 'root',
})
export class ActivityApiService {
  constructor(private restApiService: RestApiClientService) {}

  getAllAssociatedPharmaciesStats(time_interval: StatsTimeInterval, cacheOptions?: CacheOptions) {
    const queryParams = time_interval
      ? new URLSearchParams({
          time_interval,
        })
      : null;

    const context = new HttpContext();
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<PharmacyStats>(`pharmacy-stats?${queryParams?.toString()}`, {
      context,
    });
  }

  getPharmacyStatsByPharmacyId(
    pharmacyId: number,
    time_interval: StatsTimeInterval,
    cacheOptions?: CacheOptions
  ) {
    const queryParams = time_interval
      ? new URLSearchParams({
          time_interval,
        })
      : null;

    const context = new HttpContext();
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<PharmacyStats>(
      `pharmacy-stats/${pharmacyId}?${queryParams?.toString()}`,
      {
        context,
      }
    );
  }
}
