<div class="shadow-large relative overflow-hidden rounded bg-white">
  <ng-container *ngIf="!selectedFilterCategory && !sortMode">
    <div *ngIf="sortingOptions.length > 0" class="border-grey-20 border-b px-5 py-4">
      <span class="text-grey-50 block text-xs">Sort by:</span>
      <div
        (click)="toggleSortSelectionMode()"
        class="text-grey-70 bg-grey-15 hover:bg-grey-20 mt-2 flex cursor-pointer justify-between rounded px-2.5 py-2">
        <span class="text-xs">{{ activeSortOptionLabel }}</span>
        <t-icon key="sort-by-alpha" color="inherit" size="tiny"></t-icon>
      </div>
    </div>

    <ng-container *ngIf="filters.length > 0">
      <span class="text-grey-50 block px-5 py-4 text-xs">Filter by:</span>
      <div class="px-2 pb-2">
        <t-dropdown-menu-item
          *ngFor="let filter of filters"
          (click)="toggleFilterOptionsSelectionMode(filter)"
          [iconKey]="filter.config.iconKey ?? 'info-circle'"
          [hasNestedMenu]="true">
          {{ filter.config.label }}
        </t-dropdown-menu-item>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="selectedFilterCategory">
    <div class="border-grey-20 flex items-center justify-between border-b px-5 py-4">
      <span class="text-grey-50 text-xs">
        Filter by {{ selectedFilterCategory.config.label }}
      </span>
      <t-link
        *ngIf="filters.length > 1"
        [isButton]="true"
        (click)="toggleFilterOptionsSelectionMode()">
        Back
      </t-link>
    </div>
    <div class="max-h-[50vh] overflow-y-auto p-2">
      <div
        class="hover:bg-grey-20 text-grey-70 group flex cursor-pointer items-center justify-between px-3.5 py-2.5"
        *ngFor="let filterOption of selectedFilterCategory?.values">
        <div
          class="flex flex-1 items-center"
          (click)="updateFilterSelection(selectedFilterCategory.config.id, filterOption.id)">
          <t-checkbox [checked]="filterOption.isActive"></t-checkbox>
          <span class="text-grey-70 block text-sm">{{ filterOption.id }}</span>
        </div>

        <div class="hover:bg-grey-20 p-1">
          <t-icon
            key="add"
            size="tiny"
            color="inherit"
            (click)="selectFilterAndClose(selectedFilterCategory.config.id, filterOption.id)"
            class="opacity-0 group-hover:opacity-100"></t-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="sortMode">
    <div class="border-grey-20 flex items-center justify-between border-b px-5 py-4">
      <span class="text-grey-50 block text-xs">Sort by:</span>
      <t-link [isButton]="true" (click)="toggleSortSelectionMode()">Back</t-link>
    </div>

    <div class="p-2">
      <div
        class="hover:bg-grey-20 text-grey-70 group flex cursor-pointer items-center justify-between px-3.5 py-2.5"
        *ngFor="let sortOption of sortingOptions"
        (click)="updateSortSelection(sortOption)">
        <div class="flex flex-1 items-center">
          <!-- TODO: Use checkbox from UI Kit -->
          <input
            [checked]="sortOption.isActive"
            class="form-radio checked:bg-red-90 checked:hover:bg-red-90 checked:active:bg-red-90 checked:focus:bg-red-90 checked:disabled:hover:bg-grey-20 accent-red-90 border-grey-50 focus:border-red-90 focus:ring-red-30 disabled:border-grey-40 disabled:bg-green-20 mr-2.5 cursor-pointer rounded-full bg-white focus:ring focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed"
            type="radio" />
          <span class="text-grey-70 block text-sm">{{ sortOption.config.label }}</span>
        </div>

        <div class="hover:bg-grey-20 p-1">
          <t-icon
            key="add"
            size="tiny"
            color="inherit"
            (click)="selectSortAndClose(sortOption)"
            class="opacity-0 group-hover:opacity-100"></t-icon>
        </div>
      </div>
    </div>
  </div>
</div>
