export const getTenantRef = (domain?: string) => {
  const loc = domain ? domain : window.location.hostname;
  const subdomain = loc.split('.')[0];

  // TODO:MULTI-TENANT: Running locally, find a better way to pass this information and simulate tenancy on local development
  if (subdomain === 'localhost') {
    return 'troy';
  }

  // Troy is running at app.troy.ai, the rest of the tenants are having own subdomain name
  if (subdomain === 'app') {
    return 'troy';
  }

  return subdomain;
};
