import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PatientsFilesApiService } from '@troyai/patients/data-access';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import { catchError, forkJoin, from, map, mergeMap, switchMap, tap, throwError } from 'rxjs';
import { ClinicalObservationsApiService } from '../clinical-observations-api.service';
import { ClinicalObservation } from '../models/clinical-observation.interface';
import { ClinicalObservationsStateModel } from './clinical-observations-state.model';
import {
  AddNewClinicalObservation,
  AssociateMemberFilesToClinicalObservation,
  EditClinicalObservation,
  EmptySelectedClinicalObservation,
  GetPatientClinicalObservations,
  GetSinglePatientClinicalObservation,
} from './clinical-observations.actions';

@State<ClinicalObservationsStateModel>({
  name: 'clinicalObservationsState',
  defaults: {
    clinicalObservationsList: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
    selectedClinicalObservation: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
  },
})
@Injectable()
export class ClinicalObservationsState {
  constructor(
    private clinicalObservationsApiService: ClinicalObservationsApiService,
    private patientsFileApiService: PatientsFilesApiService
  ) {}

  @Selector()
  static clinicalObservationsList(state: ClinicalObservationsStateModel) {
    return state.clinicalObservationsList.result;
  }

  @Selector()
  static clinicalObservationsListLoadingStatus(state: ClinicalObservationsStateModel) {
    return getLoadingStatus(state.clinicalObservationsList.loadingStatus);
  }

  @Selector()
  static selectedClinicalObservation(state: ClinicalObservationsStateModel) {
    return state.selectedClinicalObservation.result;
  }

  @Selector()
  static selectedClinicalObservationLoadingStatus(state: ClinicalObservationsStateModel) {
    return getLoadingStatus(state.selectedClinicalObservation.loadingStatus);
  }

  @Selector()
  static lastUpdateClinicalObservationsList(state: ClinicalObservationsStateModel) {
    if (!state.clinicalObservationsList) {
      return null;
    }

    const clinicalObservationsList = state.clinicalObservationsList.result;
    if (!clinicalObservationsList) {
      return null;
    }

    return clinicalObservationsList.reduce((acc: ClinicalObservation | null, clinicalObs) => {
      if (!acc || clinicalObs.recorded_at > acc.recorded_at) {
        return clinicalObs;
      }
      return acc;
    }, null);
  }

  @Action(GetPatientClinicalObservations)
  getPatientClinicalObservations(
    { patchState }: StateContext<ClinicalObservationsStateModel>,
    action: GetPatientClinicalObservations
  ) {
    patchState({
      clinicalObservationsList: {
        result: [],
        loadingStatus: LoadingState.LOADING,
      },
    });

    if (action.payload.globalId) {
      return this.clinicalObservationsApiService
        .getClinicalObservations(action.payload.globalId)
        .pipe(
          tap((clinicalObs) =>
            patchState({
              clinicalObservationsList: {
                result: clinicalObs,
                loadingStatus: LoadingState.LOADED,
              },
            })
          )
        );
    } else {
      return;
    }
  }

  @Action(AddNewClinicalObservation)
  addNewClinicalObservation(
    { patchState, getState }: StateContext<ClinicalObservationsStateModel>,
    action: AddNewClinicalObservation
  ) {
    patchState({
      selectedClinicalObservation: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    const { clinicalObservationsList: clinicalObservationsList } = getState();

    return this.clinicalObservationsApiService
      .addNewClinicalObservation(action.memberGlobalId, action.payload)
      .pipe(
        tap((clinicalObs) => {
          if (!clinicalObservationsList.result) return;

          const updatedClinicalObservationsList = [clinicalObs, ...clinicalObservationsList.result];

          patchState({
            selectedClinicalObservation: {
              result: clinicalObs,
              loadingStatus: LoadingState.LOADED,
            },
            clinicalObservationsList: {
              result: updatedClinicalObservationsList,
              loadingStatus: LoadingState.LOADED,
            },
          });
        })
      );
  }

  @Action(EditClinicalObservation)
  editClinicalObservation(
    { patchState, getState }: StateContext<ClinicalObservationsStateModel>,
    action: EditClinicalObservation
  ) {
    const {
      clinicalObservationsList: clinicalObservationsList,
      selectedClinicalObservation: selectedClinicalObservation,
    } = getState();
    const currentClinicalObservation = selectedClinicalObservation.result;

    // Nothing to edit as no selected clinical observation exists in state
    if (!currentClinicalObservation) {
      return;
    }

    patchState({
      selectedClinicalObservation: {
        result: currentClinicalObservation,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.clinicalObservationsApiService
      .editClinicalObservation(action.memberGlobalId, currentClinicalObservation.id, action.payload)
      .pipe(
        switchMap(() => {
          return this.clinicalObservationsApiService
            .getSingleClinicalObservation(action.memberGlobalId, currentClinicalObservation.id)
            .pipe(
              tap((updatedClinicalObs) => {
                if (!clinicalObservationsList.result) return;

                const updatedClinicalObservationsList = clinicalObservationsList.result.map(
                  (item) => {
                    if (item.id === currentClinicalObservation.id) {
                      return updatedClinicalObs;
                    }

                    return item;
                  }
                );

                patchState({
                  selectedClinicalObservation: {
                    result: updatedClinicalObs,
                    loadingStatus: LoadingState.LOADED,
                  },
                  clinicalObservationsList: {
                    result: updatedClinicalObservationsList,
                    loadingStatus: LoadingState.LOADED,
                  },
                });
              })
            );
        })
      );
  }

  @Action(GetSinglePatientClinicalObservation)
  getSinglePatientClinicalObservation(
    { patchState }: StateContext<ClinicalObservationsStateModel>,
    action: GetSinglePatientClinicalObservation
  ) {
    patchState({
      selectedClinicalObservation: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.clinicalObservationsApiService
      .getSingleClinicalObservation(
        action.payload.memberGlobalId,
        action.payload.clinicalObservationId
      )
      .pipe(
        tap((clinicalObs) => {
          patchState({
            selectedClinicalObservation: {
              result: clinicalObs,
              loadingStatus: LoadingState.LOADED,
            },
          });
        }),
        mergeMap((clinicalObs) =>
          // Retrieve all the signed urls for the files in parallel
          forkJoin(
            clinicalObs.files.map((file) =>
              this.patientsFileApiService.getFileSignedURL(action.payload.memberGlobalId, file.id)
            )
          ).pipe(
            map((signedUrls) => {
              return {
                ...clinicalObs,
                files: clinicalObs.files.map((file, index) => ({
                  ...file,
                  signed_url: signedUrls[index],
                })),
              };
            })
          )
        ),
        tap((clinicalObs) => {
          patchState({
            selectedClinicalObservation: {
              result: clinicalObs,
              loadingStatus: LoadingState.LOADED,
            },
          });
        }),
        catchError((err) => {
          patchState({
            selectedClinicalObservation: {
              result: null,
              loadingStatus: LoadingState.ERRORED,
            },
          });
          return throwError(() => err);
        })
      );
  }

  @Action(AssociateMemberFilesToClinicalObservation)
  associateMemberFileToClinicalObservation(
    _: StateContext<ClinicalObservationsStateModel>,
    action: AssociateMemberFilesToClinicalObservation
  ) {
    return from(action.payload.fileIds).pipe(
      mergeMap((fileId: number) => {
        return this.patientsFileApiService.associateFile(
          action.payload.memberGlobalId,
          fileId,
          action.payload.data
        );
      }, 3) // Maximum 3 file uploads at once
    );
  }

  @Action(EmptySelectedClinicalObservation)
  emptyNewClinicalObservationAdding({ patchState }: StateContext<ClinicalObservationsStateModel>) {
    patchState({
      selectedClinicalObservation: {
        result: null,
        loadingStatus: LoadingState.INITIAL,
      },
    });
  }
}
