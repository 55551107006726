/* eslint-disable @typescript-eslint/no-empty-interface */
export interface PharmacyStats {
  action_stats: StatItem[];
  completed_actions_dates: CompletedActionsDate[] | null;
}

export interface StatItem {
  pharmacy_id: number;
  pharmacy_npi: string;
  pharmacy_name: string;
  members_joining: number;
  members_registered: number;
  members_leaving: number;
  action_completion_rate: number;
  canceled_actions: number;
  new_actions: number;
  completed_actions: number;
  total_assigned_actions: number;
  potential_fees: number;
  earned_fees: number;
  approved_fees: number;
  missed_fees: number;
  time_interval: string;
  expired_actions: number;
  completed_of_assigned_rate_total: number;
  engagement_rate_total: number;
  maximum_completed_of_assigned_rate_total: number;
  engagement_rate: QuarterlyStats;
  completed_of_assigned_rate: QuarterlyStats;
  maximum_completed_of_assigned_rate: QuarterlyStats;
}

export interface ActionsStat
  extends Pick<
    StatItem,
    'completed_actions' | 'new_actions' | 'action_completion_rate' | 'total_assigned_actions'
  > {}

export interface CompletedActionsDate {
  pharmacy_id: number;
  dates: string[] | null;
}

export enum StatsTimeInterval {
  ALL = 'ALL',
  MTD = 'MTD',
  YTD = 'YTD',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  LAST_YEAR = 'LAST_YEAR',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export interface QuarterlyStats {
  q_1: number;
  q_2: number;
  q_3: number;
  q_4: number;
}
