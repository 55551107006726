import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SanitizeHtmlPipe } from '@troyai/portal/common/pipes';

@Component({
  selector: 't-action-rich-text-description',
  standalone: true,
  imports: [CommonModule, SanitizeHtmlPipe],
  templateUrl: './action-rich-text-description.component.html',
})
export class ActionRichTextDescriptionComponent {
  @Input() description = '';
}
