import { NgIf } from '@angular/common';
import { Directive, Input, OnInit, inject } from '@angular/core';
import { AvailableFeatureFlags } from '@troyai/envs';
import { FeatureFlagsService } from './feature-flags.service';

@Directive({
  selector: '[tIfFeatureFlag]',
  standalone: true,
  hostDirectives: [NgIf],
})
export class FeatureFlagDirective implements OnInit {
  constructor(private featureFlagsService: FeatureFlagsService) {}

  @Input() tIfFeatureFlag?: AvailableFeatureFlags;
  private readonly ngIfDirective = inject(NgIf);

  ngOnInit() {
    if (this.tIfFeatureFlag) {
      const isEnabled = this.featureFlagsService.isFeatureEnabled(this.tIfFeatureFlag);
      this.ngIfDirective.ngIf = isEnabled;
    }
  }
}
