@if (selectedActionFormSettingsLoading$ | async; as formSettingsLoadingStatus) {
  @if (
    !computedActionValues.hasCompletedState && computedActionValues.formDisplayType !== 'inline'
  ) {
    <t-card [isInteractive]="false" id="tour-action-cta" paddingSize="none" class="mb-3 block">
      <div class="space-y-4 p-4">
        @switch (computedActionValues.formDisplayType) {
          @case ('modal') {
            <t-button
              (click)="openActionModal()"
              [isFullWidth]="true"
              [loading]="formSettingsLoadingStatus.loading"
              [tTrack]="{
                eventName: 'Opened action form',
                eventProps: {
                  member_id: action.member_assigned_to,
                  pharmacy_id: action.pharmacy_id,
                  action_id: action.global_id,
                  action_type: action.reference_id,
                  pharmacy_name: action.pharmacy_name
                }
              }">
              {{
                formSettingsLoadingStatus.loading
                  ? 'LOADING...'
                  : computedActionValues.hasDraft
                    ? 'CONTINUE DRAFT'
                    : 'OPEN FORM'
              }}
            </t-button>
          }

          @case ('new_tab') {
            <t-button
              *ngSwitchCase="'new_tab'"
              (click)="openNewTab()"
              [isFullWidth]="true"
              [loading]="formSettingsLoadingStatus.loading"
              [tTrack]="{
                eventName: 'Opened action form',
                eventProps: {
                  member_id: action.member_assigned_to,
                  pharmacy_id: action.pharmacy_id,
                  action_id: action.global_id,
                  action_type: action.reference_id,
                  pharmacy_name: action.pharmacy_name
                }
              }">
              {{ formSettingsLoadingStatus.loading ? 'LOADING...' : 'OPEN FORM' }}
            </t-button>
          }
        }

        @if (description) {
          <span class="text-grey-70 block text-xs">
            {{ description }}
          </span>
        }

        <t-action-estimated-completion-time
          *ngIf="action.completion_time_max || action.completion_time_min"
          [completionTimeMax]="action.completion_time_max"
          [completionTimeMin]="action.completion_time_min" />
      </div>
    </t-card>
  }
}
