import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import {
  GetCurrentUserDetails,
  UserState,
  UsersApiService,
  isMOCCompliantForCurrentYear,
} from '@troyai/auth/data-access';
import { filter, interval, last, map, switchMap, take, takeWhile, tap } from 'rxjs';
import { OnboardingStep } from '../models/onboarding-step.interface';
import { OnboardingApiService } from '../onboarding-api.service';
import { OnboardingStateModel } from './onboarding-state.model';
import {
  CheckDetailsAndAgreement,
  CheckMOCTrainingStatus,
  CompleteOnboarding,
  CompleteOnboardingStep,
  GetOnboardingAgreementData,
  InitiateOnboardingState,
  InitiatePlaidLink,
  SetOnboardingStepStatus,
  SetOnboardingSteps,
  SetPreferredPaymentAccount,
  SubmitPlaidPublicToken,
} from './onboarding.actions';

const stateDefaults: OnboardingStateModel = {
  onboardingSteps: [],
  currentActiveStep: null,
  onboardingStepsLoading: true,
  onboardingStepsError: false,
  onboardingCompleted: false,
  plaidLinkToken: null,
  pharmacyToOnboard: null,
  onboardingAgreementData: null,
};

@State<OnboardingStateModel>({
  name: 'onboardingState',
  defaults: stateDefaults,
})
@Injectable()
export class OnboardingState {
  constructor(
    private onboardingApiService: OnboardingApiService,
    private userApiService: UsersApiService,
    private store: Store
  ) {}

  @Selector()
  static onboardingSteps(state: OnboardingStateModel) {
    return state.onboardingSteps;
  }

  @Selector()
  static onboardingCompleted(state: OnboardingStateModel) {
    return state.onboardingCompleted;
  }

  @Selector()
  static pharmacyToOnboard(state: OnboardingStateModel) {
    return state.pharmacyToOnboard;
  }

  @Selector()
  static currentActiveStep(state: OnboardingStateModel) {
    return state.currentActiveStep;
  }

  @Selector()
  static plaidLinkToken(state: OnboardingStateModel) {
    return state.plaidLinkToken;
  }

  @Selector()
  static onboardingAgreementData(state: OnboardingStateModel) {
    return state.onboardingAgreementData;
  }

  @Action(InitiatePlaidLink)
  initiatePlaidLink(
    { patchState }: StateContext<OnboardingStateModel>,
    { pharmacyId }: InitiatePlaidLink
  ) {
    return this.onboardingApiService.getPlaidLinkToken(pharmacyId).pipe(
      tap((response) => {
        patchState({
          plaidLinkToken: response.access_token,
        });
      })
    );
  }

  @Action(SubmitPlaidPublicToken)
  submitPlaidPublicToken(
    _ctx: StateContext<OnboardingStateModel>,
    { pharmacyId, token }: SubmitPlaidPublicToken
  ) {
    return this.onboardingApiService.submitPlaidPublicToken(token, pharmacyId);
  }

  @Action(SetOnboardingStepStatus)
  setOnboardingStepStatus(
    { patchState, getState }: StateContext<OnboardingStateModel>,
    action: SetOnboardingStepStatus
  ) {
    const { stepId, status } = action;
    const state = getState();
    patchState({
      onboardingSteps: state.onboardingSteps.map((step) => {
        if (step.id === stepId) {
          return {
            ...step,
            ...status,
          };
        }
        return step;
      }),
    });
  }

  @Action(CheckDetailsAndAgreement)
  checkDetailsAndAgreement({ getState, dispatch }: StateContext<OnboardingStateModel>) {
    const { pharmacyToOnboard } = getState();

    if (!pharmacyToOnboard) {
      return;
    }

    return interval(2000).pipe(
      switchMap(() => this.userApiService.getUserDetails(true)),
      map((response) => {
        const userPharmacyToOnboard = response.pharmacies.find(
          (pharmacy) => pharmacy.global_id === pharmacyToOnboard.global_id
        );

        if (!userPharmacyToOnboard) {
          return null;
        }

        return userPharmacyToOnboard.completed_baa_onboarding;
      }),
      filter((completedOnboardingStep) => completedOnboardingStep !== null),
      takeWhile((completedOnboardingStep) => !completedOnboardingStep, true),
      take(5),
      last(),
      tap((completedOnboardingStep) => {
        if (completedOnboardingStep) {
          dispatch(new CompleteOnboardingStep());
        } else {
          dispatch(
            new SetOnboardingStepStatus('details_and_agreement', {
              loading: false,
              completed: false,
            })
          );
        }
      })
    );
  }

  @Action(CheckMOCTrainingStatus)
  checkMOCTrainingStatus({ dispatch }: StateContext<OnboardingStateModel>) {
    return interval(3000).pipe(
      switchMap(() => this.userApiService.getUserDetails(true)),
      map((response) => {
        return isMOCCompliantForCurrentYear(response);
      }),
      takeWhile((isCompliant) => !isCompliant, true),
      take(10),
      last(),
      tap((isCompliant) => {
        if (isCompliant) {
          dispatch(new CompleteOnboardingStep());
        } else {
          dispatch(
            new SetOnboardingStepStatus('model_of_care_training', {
              loading: false,
              completed: false,
            })
          );
        }
      })
    );
  }

  @Action(CompleteOnboardingStep)
  completeOnboardingStep({ patchState, getState, dispatch }: StateContext<OnboardingStateModel>) {
    const state = getState();
    const currentActiveStep = state.currentActiveStep;
    if (!currentActiveStep) return;

    const onboardingSteps = state.onboardingSteps;
    const currentActiveStepIndex = currentActiveStep.index;
    const nextStep = onboardingSteps.find((step) => step.index === currentActiveStepIndex + 1);

    const updatedOnboardingSteps = onboardingSteps.map((step) => {
      if (step.id === currentActiveStep.id) {
        return {
          ...step,
          completed: true,
          loading: false,
        };
      }
      return step;
    });

    const onboardingCompleted = updatedOnboardingSteps.every((step) => step.completed);
    if (!nextStep || onboardingCompleted) {
      patchState({
        onboardingSteps: updatedOnboardingSteps,
        currentActiveStep: null,
      });

      return dispatch([new CompleteOnboarding()]);
    }

    return patchState({
      currentActiveStep: nextStep,
      onboardingSteps: updatedOnboardingSteps,
    });
  }

  @Action(InitiateOnboardingState)
  setPharmacyToOnboard(
    { patchState, getState }: StateContext<OnboardingStateModel>,
    action: InitiateOnboardingState
  ) {
    const pharmacy = action.pharmacy;
    const onboardingSteps = getState().onboardingSteps;
    const isMOCCompliant = this.store.selectSnapshot(UserState.isMOCCompliant);

    let onboardingStepsWithUpdatedState: OnboardingStep[] = [];

    // Setting up onboarding for users that live in a pharmacy context (ex: Pharmacy User role)
    if (pharmacy) {
      const detailsAndAgreementCompleted = pharmacy.completed_baa_onboarding || false;
      const bankAccountConnected = pharmacy.completed_financial_onboarding || false;

      onboardingStepsWithUpdatedState = onboardingSteps.map((step) => {
        if (step.id === 'details_and_agreement') {
          return {
            ...step,
            completed: detailsAndAgreementCompleted,
          };
        }
        if (step.id === 'bank_account_connection') {
          return {
            ...step,
            completed: bankAccountConnected,
          };
        }
        if (step.id === 'model_of_care_training') {
          return {
            ...step,
            completed: isMOCCompliant,
          };
        }
        return step;
      });
    } else {
      // Setting up onboarding for users that are not bound to a pharmacy context (ex: CM, Care Team)
      onboardingStepsWithUpdatedState = onboardingSteps.map((step) => {
        if (step.id === 'model_of_care_training') {
          return {
            ...step,
            completed: isMOCCompliant,
          };
        }
        return step;
      });
    }

    const onboardingCompleted = onboardingStepsWithUpdatedState.every((step) => step.completed);

    if (onboardingCompleted) {
      return patchState({
        pharmacyToOnboard: action.pharmacy,
        onboardingSteps: onboardingStepsWithUpdatedState,
        currentActiveStep: null,
        onboardingCompleted: true,
      });
    } else {
      // get the first incomplete step, that will become the active step
      const currentActiveStep = onboardingStepsWithUpdatedState.find((step) => !step.completed);

      return patchState({
        pharmacyToOnboard: action.pharmacy,
        onboardingSteps: onboardingStepsWithUpdatedState,
        currentActiveStep,
      });
    }
  }

  @Action(CompleteOnboarding)
  completeOnboarding({ patchState, dispatch }: StateContext<OnboardingStateModel>) {
    patchState({
      onboardingCompleted: true,
    });
    dispatch(new GetCurrentUserDetails());

    return;
  }

  @Action(SetPreferredPaymentAccount)
  setPreferredPaymentAccount(
    { getState }: StateContext<OnboardingStateModel>,
    action: SetPreferredPaymentAccount
  ) {
    const { pharmacyToOnboard } = getState();
    if (!pharmacyToOnboard?.global_id) return;

    return this.onboardingApiService.setPreferredPaymentAccount(
      action.plaidAccountId,
      pharmacyToOnboard.global_id
    );
  }

  @Action(SetOnboardingSteps)
  setOnboardingSteps(
    { patchState }: StateContext<OnboardingStateModel>,
    action: SetOnboardingSteps
  ) {
    const onboardingSteps = action.steps;
    const onboardingCompleted = onboardingSteps.every((step) => step.completed);

    if (onboardingCompleted) {
      return patchState({
        onboardingSteps,
        currentActiveStep: null,
        onboardingCompleted: true,
      });
    } else {
      // get the first incomplete step, that will become the active step
      const currentActiveStep = onboardingSteps.find((step) => !step.completed);

      return patchState({
        onboardingSteps,
        currentActiveStep,
        onboardingCompleted: false,
      });
    }
  }

  @Action(GetOnboardingAgreementData)
  getOnboardingAgreementData(
    { patchState }: StateContext<OnboardingStateModel>,
    action: GetOnboardingAgreementData
  ) {
    return this.onboardingApiService.getOnboardingAgreementData(action.tenant).pipe(
      tap((agreementData) => {
        patchState({
          onboardingAgreementData: agreementData,
        });
      })
    );
  }
}
