import { DIALOG_DATA, Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { GenericPromptComponent } from './generic-prompt/generic-prompt.component';
import { ModalComponent } from './modal-component/modal.component';
import { GenericPromptData, ModalMetaData, PromptModalMetaData } from './modal-meta-data.interface';
import { PromptModalComponent } from './prompt-modal-component/prompt-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    public dialog: Dialog,
    private overlay: Overlay
  ) {}

  /**
   * Opens a regular modal dialog containing the given component.
   * @param {Component} component - Component reference to be included inside the modal
   * @param {object} modalData - Data to be passed to the modal, that will be injected and available in the provided component, via the @Inject(DIALOG_DATA) decorator
   * @param {ModalMetaData} modalMetaData - Data to be passed to the modal component, limited to the scope of the modal component. Used for modal-specific configuration.
   */
  openDialog<C>(component: ComponentType<C>, modalData?: unknown, modalMetaData?: ModalMetaData) {
    return this.dialog.open(component, {
      container: {
        type: ModalComponent,
        providers: () => [
          {
            provide: DIALOG_DATA,
            useValue: modalMetaData || {
              allowOverflow: false,
              disablePadding: false,
              isFullscreen: false,
            },
          },
        ],
      },
      autoFocus: false,
      disableClose: modalMetaData?.preventCloseOnPromptConfirm ? true : false,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .top(`${modalMetaData?.offsetTop || 50}px`),
      data: modalData || {},
    });
  }

  /**
   * Opens a lightweight prompt dialog containing the given component.
   * @param {Component} component - Component reference to be included inside the prompt
   * @param {object} modalData - Data to be passed to the modal, that will be injected and available in the provided component, via the @Inject(DIALOG_DATA) decorator
   * @param {ModalMetaData} modalMetaData - Data to be passed to the prompt component, limited to the scope of the prompt component. Used for prompt-specific configuration.
   */
  openPromptDialog<C>(
    component: ComponentType<C>,
    modalData?: unknown,
    modalMetaData?: PromptModalMetaData
  ) {
    return this.dialog.open(component, {
      container: {
        type: PromptModalComponent,
        providers: () => [{ provide: DIALOG_DATA, useValue: modalMetaData || {} }],
      },
      disableClose: modalMetaData?.disableClose ? true : false,
      data: modalData || {},
    });
  }

  /**
   * Opens a predefined type of prompt dialog, with a generic prompt component
   * @param {GenericPromptData} modalData - Data to be passed to the modal, that will be injected and available in the GenericPromptComponent component, via the @Inject(DIALOG_DATA) decorator
   * @param {ModalMetaData} modalMetaData - Data to be passed to the prompt component, limited to the scope of the prompt component. Used for prompt-specific configuration.
   */
  openGenericPromptDialog(modalData?: GenericPromptData, modalMetaData?: PromptModalMetaData) {
    return this.openPromptDialog(GenericPromptComponent, modalData, modalMetaData);
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
