import { FeatureFlags } from '@troyai/envs';

export const commonFeatureFlags: Record<string, FeatureFlags> = {
  united: {
    'critical-comments': true,
    'guided-tour': true,
    'plan-review-for-non-troy-members-actions': false,
    'activity-view': false,
    'case-notes': false,
    'moc-training-requirement': false,
    'patient-medication-records': false,
    'demo-mode': false,
    'dashboard-view': false,
    'adherence-view': true,
    'adherence-bonus': true,
    'patients-view': true,
    'actions-view': false,
    'prior-authorization-view': false,
    'np-appointments-view': false,
    'resources-view': false,
    'baa-onboarding-required': true,
    'financial-onboarding-required': false,
    'np-patients-view': false,
    'enrollment-plan-details': false,
    'in-app-notifications': false,
    'patient-diagnoses': false,
    'patient-clinical-observations': false,
    'patient-encounters': false,
    'ongoing-care-actions': false,
    'hra-listing': false,
    'hra-listing-addendum': false,
  },
  troy: {
    'critical-comments': true,
    'guided-tour': true,
    'plan-review-for-non-troy-members-actions': false,
    'activity-view': true,
    'case-notes': true,
    'moc-training-requirement': true,
    'patient-medication-records': true,
    'demo-mode': false,
    'dashboard-view': true,
    'adherence-view': true,
    'adherence-bonus': true,
    'patients-view': true,
    'actions-view': true,
    'prior-authorization-view': true,
    'np-appointments-view': true,
    'resources-view': true,
    'baa-onboarding-required': true,
    'financial-onboarding-required': true,
    'np-patients-view': true,
    'enrollment-plan-details': true,
    'in-app-notifications': true,
    'patient-diagnoses': true,
    'patient-clinical-observations': true,
    'patient-encounters': true,
    'ongoing-care-actions': true,
    'hra-listing': true,
    'hra-listing-addendum': true,
  },
};
