<div class="relative">
  <div class="md:w-sidebarContext top-0 overflow-y-auto bg-white md:fixed md:h-screen">
    <div class="border-grey-25 shadow-small border-r md:h-screen">
      <ng-content select="[context]"></ng-content>
    </div>
  </div>
  <div class="md:pl-sidebarContext bg-grey-15">
    <div class="relative min-h-screen max-w-6xl p-4 md:p-8">
      <ng-content></ng-content>
    </div>
  </div>
</div>
