import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { LogoutFlowInitiated } from '@troyai/auth/data-access';
import { ButtonComponent } from '@troyai/ui-kit';
import { GreetingCardComponent } from '../greeting-card/greeting-card.component';

@Component({
  selector: 't-email-verification',
  standalone: true,
  imports: [CommonModule, GreetingCardComponent, ButtonComponent],
  templateUrl: './email-verification.component.html',
})
export class EmailVerificationComponent {
  constructor(private store: Store) {}

  loginWithRedirect(): void {
    this.store.dispatch(new LogoutFlowInitiated({ forceRetryLogin: true }));
  }
}
