/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 't-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  imports: [CommonModule, FormsModule],
})
export class CheckBoxComponent {
  @Input() label?: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Output() toggled = new EventEmitter<Event>();

  onCheckBoxChange(e: Event) {
    const isChecked = (e.target as HTMLInputElement).checked;
    this.checked = isChecked;
    this.toggled.emit(e);
  }
}
