import { NgIf } from '@angular/common';
import { Directive, Input, OnInit, inject } from '@angular/core';
import { distinctUntilChanged } from 'rxjs';

import { UserRoles } from '@troyai/auth/data-access';
import { UserRolesService } from '../services/user-roles.service';

@Directive({
  selector: '[tIfHasRole]',
  standalone: true,
  hostDirectives: [NgIf],
})
export class RolesDirective implements OnInit {
  constructor(private readonly userRolesService: UserRolesService) {}

  @Input() tIfHasRole?: UserRoles[] | UserRoles;
  private readonly ngIfDirective = inject(NgIf);

  ngOnInit(): void {
    if (this.tIfHasRole) {
      const requiredRoles = Array.isArray(this.tIfHasRole) ? this.tIfHasRole : [this.tIfHasRole];
      this.userRolesService
        .hasAccess(requiredRoles)
        .pipe(distinctUntilChanged())
        .subscribe((renderItem) => {
          this.ngIfDirective.ngIf = renderItem;
        });
    }
  }
}
