<div class="relative" [ngClass]="{ 'is-focused': isFocused }">
  <div
    class="wrapper bg-grey-70/5 hover:shadow-small flex h-[36px] w-full rounded border-0 transition-shadow hover:bg-white">
    <div class="relative flex-1">
      <div
        class="search-icon absolute left-2 top-2 flex h-5 w-5 items-center justify-center rounded-full">
        <t-icon key="search" color="primary" size="tiniest"> </t-icon>
      </div>

      <input
        class="text-grey-70 block h-[36px] w-full appearance-none border-none bg-white/0 px-9 py-2 text-xs focus:ring-0 focus:ring-offset-0"
        type="text"
        #searchInput
        placeholder="Search"
        [ngModel]="input"
        (ngModelChange)="onChange($event)"
        (blur)="onBlur()"
        (focus)="onFocus()" />

      <div
        (click)="clearSearch()"
        *ngIf="displayReset"
        class="close-icon bg-grey-20 absolute right-3 top-3 flex h-3 w-3 cursor-pointer items-center justify-center rounded-full">
        <t-icon key="close" color="primary" size="tiniest"> </t-icon>
      </div>
    </div>

    <div *ngIf="hasOptions" class="relative flex-initial" [id]="optionsMenuId">
      <t-options-button [tPopoverAnchor]="optionsDropdown" (click)="optionsDropdown.toggle()">
        {{ optionButtonText }}
      </t-options-button>
    </div>
  </div>
</div>
