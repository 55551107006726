@if (currentDynamicFormDataLoadingStatus$ | async; as loadingStatus) {
  @if (loadingStatus.loading) {
    <t-skeleton-loader [rows]="5" rowsHeight="huge" />
  } @else if (loadingStatus.loaded) {
    @if (currentDynamicFormData$ | async; as formData) {
      @if (formData && formData.length > 0) {
        <t-dynamic-rendered-form
          [formConfig]="formData"
          (formSubmit)="onFormSubmit($event)"
          (formSave)="onFormSave($event)" />
      }
    }
  } @else if (loadingStatus.errored) {
    <t-error-state titleText="There was an error loading the HRA form" />
  }
}
