<t-timeline [entries]="encountersItems">
  <ng-template #time let-entry let-last="last">
    <t-timeline-entry [isLast]="last" [isHighlighted]="entry.ongoing" class="block min-w-[144px]">
      <span
        [ngClass]="{
          'text-amber-70': entry.ongoing
        }">
        {{ entry.formattedTimeRange }}
      </span>
    </t-timeline-entry>
  </ng-template>

  <ng-template #content let-entry>
    <t-encounter-card [encounter]="entry"></t-encounter-card>
  </ng-template>
</t-timeline>
