import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { isPast } from 'date-fns/isPast';

@Pipe({
  name: 'expirationDate',
  standalone: true,
})
export class ExpirationDatePipe implements PipeTransform {
  transform(input: string): string {
    const inputDate = new Date(input);
    const prefix = isPast(inputDate) ? 'Expired on' : 'Expires on';
    return `${prefix} ${format(new Date(input), 'MM/dd/yyyy')}`;
  }
}
