import { Injectable } from '@angular/core';
import { RestApiClientService, RestApiResponse } from '@troyai/rest-api-client';
import { Medication } from './models/medication.interface';

@Injectable({
  providedIn: 'root',
})
export class MedicationApiService {
  constructor(private restApiService: RestApiClientService) {}

  getMedications(memberGlobalId: number) {
    return this.restApiService.get<Medication[]>(`members/${memberGlobalId}/med-recs`);
  }

  updateMedicationStatus(
    memberGlobalId: number,
    medicationId: number,
    note: string,
    active: boolean
  ) {
    return this.restApiService.patch<RestApiResponse<void>>(
      `members/${memberGlobalId}/med-recs/${medicationId}`,
      {
        id: medicationId,
        member_global_id: memberGlobalId,
        notes: note,
        type: active,
      }
    );
  }
}
