import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedTourService } from '../../guided-tour.service';
import {
  generateFirstSlideButtons,
  generateGuidedTourDefaultConfigOptions,
  generateLastSlideButtons,
  generatePositionStrategy,
} from '../../guided-tour.config';
import { TourStartingPromptComponent } from '../tour-starting-prompt/tour-starting-prompt.component';
import { ModalModule, ModalService } from '@troyai/ui-kit';

@Component({
  selector: 't-actions-tour',
  standalone: true,
  imports: [CommonModule, ModalModule],
  templateUrl: './actions-tour.component.html',
})
export class ActionsTourComponent implements AfterViewInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private modalService: ModalService
  ) {}

  ngAfterViewInit() {
    this.guidedTourService.defaultStepOptions = generateGuidedTourDefaultConfigOptions(
      this.guidedTourService
    );
    this.guidedTourService.tourName = 'actions';
    this.guidedTourService.modal = true;
    this.guidedTourService.confirmCancel = false;
    this.guidedTourService.addSteps([
      {
        id: '1-actions-search-input',
        attachTo: {
          element: '#tour-actions-search-input',
          on: generatePositionStrategy('bottom'),
        },
        title: 'Actions list',
        text: ['Search by action or patient name.'],
        buttons: generateFirstSlideButtons(this.guidedTourService),
      },
      {
        id: '2-actions-filters',
        attachTo: {
          element: '#tour-actions-filters',
          on: generatePositionStrategy('bottom'),
        },
        title: 'Actions list',
        text: ['Filter actions by type and other criteria.'],
      },
      {
        id: '3-actions-filters-summary',
        attachTo: {
          element: '#tour-actions-filters-summary',
          on: generatePositionStrategy('bottom'),
        },
        buttons: generateLastSlideButtons(this.guidedTourService),
        scrollTo: false,
        title: 'Actions list',
        text: ['See what filters are currently applied.'],
      },
    ]);

    if (
      !this.guidedTourService.getLocalStorageCompletionStatusByName(this.guidedTourService.tourName)
    ) {
      this.modalService.openPromptDialog(
        TourStartingPromptComponent,
        {
          tourIntroText: 'This is your Actions list, where you can see all your Troy.AI actions.',
        },
        {
          hideCloseButton: true,
        }
      );
    }
  }
}
