import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../icon/icon.module';

@Component({
  selector: 't-dropdown-button',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './dropdown-button.component.html',
})
export class DropdownButtonComponent {}
