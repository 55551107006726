/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorNotifyHandlerService } from './services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const errorHandlerService = this.injector.get(ErrorNotifyHandlerService);
    errorHandlerService.displayToast(error);

    /* 
        Trowing again in a try/catch block is recommended by NewRelic 
        for browser compatibility purposes 
        https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/#browser-limits
    */
    const newRelicAgent = window ? (window as any).newrelic : undefined;

    try {
      throw error;
    } catch (err) {
      if (newRelicAgent) {
        newRelicAgent.noticeError(err);

        const userId = window.localStorage.getItem('troyai-current-user');
        newRelicAgent.setUserId(userId);
      }
      console.error(err);
    }
  }
}
