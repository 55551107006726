import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Layouts } from './models/layouts.enum';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  // Default to sidebar layout, as this is the most common layout
  private layoutSubject = new BehaviorSubject<Layouts>(Layouts.Sidebar);

  public layout$ = this.layoutSubject.asObservable();

  setLayout(value: Layouts) {
    this.layoutSubject.next(value);
  }
}
