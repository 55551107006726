<div class="bg-grey-15 flex min-h-screen justify-center">
  <div class="block max-w-4xl bg-white">
    <div class="px-12 py-10">
      <!-- Important notice -->
      <div class="bg-amber-20 flex items-center gap-x-4 rounded-2xl p-4">
        <div class="bg-amber-30 text-amber-70 rounded-3xl p-5">
          <t-icon key="info-circle" size="medium" color="inherit" />
        </div>

        <div class="text-red-90 text-sm">
          <p>
            <strong class="font-semibold">Important Compliance Notice from Troy Medicare.</strong>
            Please complete the training and submit the attestation form in order to continue using
            <span class="underline">Troy.AI</span>.
          </p>
        </div>
      </div>
      <!-- /Important notice -->

      <div class="w-full pb-8 pt-12 lg:w-2/3">
        <t-heading
          class="text-grey-70 font-semibold"
          level="h3"
          value="{{ currentYear }} MODEL OF CARE PROVIDER TRAINING AND ATTESTATION" />
      </div>

      <!-- Steps -->
      <div class="space-y-3 pb-8">
        <div
          *ngFor="let step of complianceSteps; let i = index"
          class="flex items-center justify-between gap-x-4 rounded-2xl p-4"
          [ngClass]="{
            'bg-amber-20': step.pending && !step.disabled,
            'bg-grey-15': step.completed || step.disabled
          }">
          <div class="flex items-center gap-x-5">
            <div
              class="bg-amber-30 rounded-3xl p-5"
              [ngClass]="{
                'bg-green-30 text-green-90': step.completed,
                'bg-amber-30 text-amber-70': !step.completed,
                'bg-grey-25 text-grey-60': step.disabled
              }">
              <t-icon
                [key]="step.completed ? 'check-circle' : 'hourglass'"
                size="medium"
                color="inherit" />
            </div>

            <div class="text-sm text-black">
              <strong class="font-semibold">Step {{ i + 1 }}</strong>
              <p>{{ step.title }}</p>
            </div>
          </div>

          <div class="w-3/12">
            <t-button
              (click)="onStepSelection(step)"
              [disabled]="step.disabled"
              [isFullWidth]="true">
              {{ step.ctaText }}
            </t-button>
          </div>
        </div>
      </div>

      <!-- /Steps -->

      <!-- Explainer -->
      <div class="space-y-5 text-base">
        <strong class="font-semibold">Why is this important</strong>
        <p>
          The Centers for Medicare & Medicaid Services (CMS) requires all special needs plans (SNPs)
          to provide initial and annual Model of Care (MOC) training to network providers and their
          staff contracted to treat and/or provide services to Troy Medicare dual-eligible special
          needs plans (D-SNP) members. Out-of-network providers who routinely treat and/or provide
          services to Troy Medicare D-SNP members are also required to complete the MOC training.
          This training is required to be completed no later than
          {{ deadline | date: 'mediumDate' }}.
        </p>
        <p>
          The SNP MOC is the plan for delivering coordinated care management to D-SNP members,
          designed to address the specific needs of the D-SNP population.If a group training is
          provided to your medical group/practice, one Attestation Form may be submitted by an
          individual with the authority to attest on behalf of the group. An attendance roster of
          the group training must also be provided.
        </p>
      </div>
      <!-- /Explainer -->
    </div>
  </div>
</div>
