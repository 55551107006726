import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwitchComponent } from './switch.component';

@Component({
  selector: 't-toggle',
  standalone: true,
  imports: [CommonModule, SwitchComponent],
  templateUrl: './toggle.component.html',
})
export class ToggleComponent {
  @Input() on = false;
  @Input() disabled = false;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  onClick() {
    this.on = !this.on;
    this.toggled.emit(this.on);
  }
}
