<div class="w-full rounded-full border border-black border-opacity-5 bg-[#56534F0D] px-1.5 py-0.5">
  <div
    class="h-1.5 rounded-full transition-all"
    [ngClass]="{
      'bg-green-80': color === 'success',
      'bg-red-90': color === 'error',
      'bg-yellow-80': color === 'warning',
      'bg-grey-65': color === 'neutral'
    }"
    [ngStyle]="{
      'width.%': progress * 100
    }"></div>
</div>
