<div class="space-y-2">
  <label class="text-grey-70 block text-base">{{ label }}</label>
  <div
    class="file-upload-wrapper text-grey-100 relative flex h-[78px] flex-col items-center justify-center rounded-lg border border-dashed p-4 text-xs transition-colors duration-300"
    [ngClass]="{
      'border-grey-45 bg-grey-15': !isHovering,
      'border-amber-60 bg-yellow-20': isHovering
    }"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)">
    <div
      (click)="triggerFileInput(fileInput)"
      class="drop-zone flex h-32 w-full cursor-pointer items-center justify-center rounded-lg"
      [class.bg-blue-50]="isHovering"
      [class.border-blue-500]="isHovering"
      [class.border-gray-300]="!isHovering">
      <p class="text-gray-50" *ngIf="!fileName">Drag & drop a file here or click to upload</p>
      <p class="text-gray-70" *ngIf="fileName">Selected File: {{ fileName }}</p>

      <input
        type="file"
        #fileInput
        class="hidden"
        (change)="onFileSelected($event)"
        (blur)="onBlur()" />
    </div>
  </div>
</div>
