<t-greeting-card title="Welcome to Troy.AI">
  <span description class="text-sm text-white">Please log in to continue.</span>
  <t-button
    cta
    (click)="loginWithRedirect()"
    [isFullWidth]="true"
    [filled]="true"
    variant="neutral">
    LOGIN
  </t-button>
</t-greeting-card>
