<div class="flex min-h-[calc(100vh-150px)] w-full items-center justify-center">
  @if (hraItem.latest_file_id) {
    @if (singleMemberHraItemPreviewLoadingStatus$ | async; as loadingStatus) {
      @if (loadingStatus.loading) {
        <t-loader />
      } @else if (loadingStatus.loaded) {
        @if (singleMemberHraItemPreview$ | async; as preview) {
          <object
            class="min-h-[calc(100vh-150px)] w-full"
            [data]="getSanitizedAgreementURL(preview.previewSignedURL)"
            type="application/pdf">
            <p class="p-8">
              No PDF support detected. Please download the file from
              <a
                class="underline"
                [href]="getSanitizedAgreementURL(preview.previewSignedURL)"
                target="_blank">
                here.
              </a>
            </p>
          </object>
        }
      } @else if (loadingStatus.errored) {
        <t-error-state titleText="There was an error loading the preview." />
      }
    }
  } @else {
    <t-error-state titleText="No preview available." />
  }
</div>
