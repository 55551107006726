import { addWeeks, eachWeekOfInterval, format, getWeek } from 'date-fns';

export const generateActivityTimeChartData = <
  T extends {
    tooltip: string;
    value: number;
    startTime: string;
    endTime: string;
  },
>(
  actionCompletedDates: string[],
  startDate: Date,
  endDate: Date
) => {
  const chartWeeks = eachWeekOfInterval({
    start: startDate,
    end: endDate,
  });

  const chartItems = chartWeeks.reduce((acc, week) => {
    const itemStartDate = week;
    const itemEndDate = addWeeks(week, 1);
    const actionsCount = actionCompletedDates.filter(
      (completedDate) =>
        new Date(completedDate) >= itemStartDate && new Date(completedDate) < itemEndDate
    ).length;

    const weekNumber = getWeek(week);
    const weekStartLabel = format(week, 'MMM d');
    const weekEndLabel = format(itemEndDate, 'MMM d, y');

    acc.push({
      tooltip: `${actionsCount} actions completed </br> Week ${weekNumber}: ${weekStartLabel} - ${weekEndLabel}`,
      value: actionsCount,
      startTime: itemStartDate.toISOString(),
      endTime: itemEndDate.toISOString(),
    } as T);
    return acc;
  }, [] as T[]);

  return chartItems;
};
