<div class="relative flex w-full items-start gap-x-3">
  <span class="text-green-90">
    <t-icon key="check-circle" size="small" color="inherit"></t-icon>
  </span>

  <div class="flex w-full flex-col">
    <strong class="text-grey-100 text-sm font-semibold">
      {{ titleText }}
    </strong>

    <ng-content></ng-content>
  </div>
</div>
