import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icon/icon.module';

/**
 * @deprecated Use `EmptyStateCardComponent` instead.
 * @see EmptyStateCardComponent
 */
@Component({
  selector: 't-empty-state',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
  @Input() titleText = 'No results found';
}
