<t-content-wrapper-layout [isFullWidth]="true">
  <ng-container *tIfFeatureFlag="'ongoing-care-actions'">
    <ng-container *tIfHasRole="userRoles.CMInternal">
      <t-section headerTitle="Ongoing Care" class="mb-8 block">
        @if (ongoingCareActionsLoadingStatus$ | async; as ocaLoadingStatus) {
          @if (ocaLoadingStatus.loading) {
            <t-skeleton-loader color="dark" [rows]="3" [columns]="1" rowsHeight="huge" />
          } @else if (ocaLoadingStatus.loaded) {
            @if (ongoingCareActions$ | async; as ongoingCareActions) {
              <t-ongoing-actions-list
                [actions]="ongoingCareActions"
                (openAction)="triggerActionOpen($event)" />
            }
          }
        }
      </t-section>
    </ng-container>
  </ng-container>

  @if (patientActionsGroups$ | async; as actionsGroups) {
    @for (actionGroup of actionsGroups; track $index) {
      <t-section [headerTitle]="actionGroup.status" class="mb-8 block">
        <t-actions-list [actions]="actionGroup.actions" />
      </t-section>
    }
  }
</t-content-wrapper-layout>
