import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule, PopoverModule } from '@troyai/ui-kit';

@Component({
  selector: 't-pharmacy-indicator-tooltip',
  standalone: true,
  imports: [CommonModule, IconsModule, PopoverModule],
  templateUrl: './pharmacy-indicator-tooltip.component.html',
})
export class PharmacyIndicatorTooltipComponent {
  @Input() pharmacyName?: string;
}
