import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { CdkDialogContainer, DIALOG_DATA, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, Optional, ViewEncapsulation } from '@angular/core';
import { PromptModalMetaData } from './../modal-meta-data.interface';
import { IconComponent } from '../../icon/icon.component';
import { PortalModule } from '@angular/cdk/portal';

@Component({
  templateUrl: './prompt-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [PortalModule, NgIf, IconComponent],
})
export class PromptModalComponent extends CdkDialogContainer {
  constructor(
    @Optional() @Inject(DIALOG_DATA) public data: PromptModalMetaData,
    public dialogRef: DialogRef<string>,
    // Required for extending CDK Dialog container
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) _document: unknown,
    dialogConfig: DialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor?: FocusMonitor
  ) {
    super(
      elementRef,
      focusTrapFactory,
      _document,
      dialogConfig,
      interactivityChecker,
      ngZone,
      overlayRef,
      focusMonitor
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
