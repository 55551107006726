import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { DiagnosesState, GetDiagnosesList } from '@troyai/portal/diagnoses/data-access';
import {
  SkeletonLoaderComponent,
  WidgetCardComponent,
  WidgetCardContentsComponent,
  WidgetCardFooterComponent,
  WidgetCardHeaderComponent,
  WidgetUpdatedStatusComponent,
} from '@troyai/ui-kit';
import { map } from 'rxjs';

@Component({
  selector: 't-diagnoses-widget',
  standalone: true,
  imports: [
    CommonModule,
    WidgetCardComponent,
    WidgetCardFooterComponent,
    WidgetCardContentsComponent,
    WidgetCardHeaderComponent,
    WidgetUpdatedStatusComponent,
    SkeletonLoaderComponent,
  ],
  templateUrl: './diagnoses-widget.component.html',
})
export class DiagnosesWidgetComponent implements OnInit {
  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {}

  private destroyRef = inject(DestroyRef);

  diagnosesList$ = this.store.select(DiagnosesState.diagnosesList).pipe(
    map((diagnoses) => {
      if (!diagnoses) return [];

      return [...diagnoses]
        .sort((a, b) => new Date(b.added_at).getTime() - new Date(a.added_at).getTime())
        .slice(0, 3);
    })
  );
  diagnosesListLoadingStatus$ = this.store.select(DiagnosesState.diagnosesListLoadingStatus);
  lastUpdated$ = this.diagnosesList$.pipe(
    map((diagnoses) => {
      if (!diagnoses || diagnoses.length === 0) return null;

      return new Date(diagnoses[0].added_at).toString();
    })
  );

  ngOnInit() {
    this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      const patientId = params['patientGlobalId'];
      this.store.dispatch(new GetDiagnosesList(patientId));
    });
  }
}
