<div
  class="flex items-center bg-white"
  [ngClass]="{
    'shadow-small mb-2 rounded-lg px-5 py-2': variant === 'cards',
    'border-grey-20 border-y p-2': variant === 'table'
  }">
  <div
    *ngFor="let sortOption of sortingOptions"
    (click)="updateSortSelection(sortOption)"
    class="flex items-center justify-start gap-x-1 text-xs font-semibold uppercase tracking-wider"
    [class]="sortOption.config.widthClass ? sortOption.config.widthClass : ''"
    [ngClass]="{
      'text-grey-70': sortOption.isActive,
      'text-grey-50': !sortOption.isActive,
      'hover:text-grey-100 cursor-pointer': !sortingDisabled
    }">
    <span>{{ sortOption.config.label }}</span>
    <ng-container *ngIf="!sortingDisabled">
      <ng-container *ngIf="sortOption.isActive; else inactiveIcon">
        <t-icon
          *ngIf="sortOption.config.value.order === 'asc'"
          key="arrow-drop-up"
          color="inherit"
          size="tiniest"></t-icon>
        <t-icon
          *ngIf="sortOption.config.value.order === 'desc'"
          key="arrow-drop-down"
          color="inherit"
          size="tiniest"></t-icon>
      </ng-container>
      <ng-template #inactiveIcon>
        <t-icon key="unfold-more" size="tiniest" color="inherit"></t-icon>
      </ng-template>
    </ng-container>
  </div>
  <div *ngIf="displayTailPlaceholder" class="text-grey-50 flex-auto self-end text-right text-xs">
    #
  </div>
</div>
