export enum ErrorSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
}

export interface FriendlyFormattedError {
  message: string;
  severity: ErrorSeverity;
}
