<div class="space-y-2">
  <label class="text-grey-50 mb-2 block text-sm">{{ label }}</label>
  <div [formGroup]="nameForm" class="flex items-center gap-x-2">
    <t-input-text
      id="first"
      formControlName="first"
      (blur)="markAsTouched()"
      placeholder="First Name" />

    <t-input-text
      id="last"
      formControlName="last"
      (blur)="markAsTouched()"
      placeholder="Last Name" />
  </div>
</div>
