<div class="relative">
  <div
    (click)="!readOnly ? toggleColorsList() : null"
    [style.background-color]="selectedColor?.value"
    class="color-item"
    [ngClass]="{
      'read-only': readOnly
    }"></div>
  <div
    [@inOutAnimation]="isOpen ? 'open' : 'closed'"
    (@inOutAnimation.start)="$event.element.style.display = 'flex'"
    (@inOutAnimation.done)="$event.element.style.display = $event.toState ? 'flex' : 'none'"
    class="shadow-large absolute -left-2 -top-2 w-0 overflow-hidden rounded-[100px] bg-white">
    <div class="flex items-center gap-x-3 p-1.5">
      <div class="text-grey-70 hover:text-grey-85 cursor-pointer" (click)="toggleColorsList()">
        <t-icon key="keyboard-arrow-right" size="small" color="inherit"></t-icon>
      </div>
      <div *ngFor="let color of availableColors" (click)="changeColor(color)">
        <div class="color-item" [style.background-color]="color.value"></div>
      </div>
    </div>
  </div>
</div>
