<t-widget-card headerTitle="Diagnoses" [hasEmptyState]="(lastUpdated$ | async) === null">
  <t-widget-card-header>
    <span class="text-grey-70 inline-block text-xs uppercase tracking-wider"> DIAGNOSES </span>
  </t-widget-card-header>

  <t-widget-card-contents>
    @if (diagnosesListLoadingStatus$ | async; as loadingState) {
      @if (loadingState.loading) {
        <div class="flex h-full items-center">
          <t-skeleton-loader
            class="block w-full"
            [columns]="1"
            [rows]="3"
            rowsHeight="small"
            color="dark" />
        </div>
      }

      @if (loadingState.loaded) {
        @if (diagnosesList$ | async; as diagnosesList) {
          @if (diagnosesList.length > 0) {
            <div class="flex h-full items-end justify-between pb-5 pt-10">
              <div class="w-full space-y-4">
                @for (item of diagnosesList$ | async; track item.id) {
                  <div class="flex items-center justify-between text-sm">
                    <span class="text-grey-100">{{ item.name }}</span>
                    <span class="text-grey-50">{{ item.code }}</span>
                  </div>
                }
              </div>
            </div>
          } @else {
            <div class="text-grey-60 max-w-xs text-xs">
              <p>
                There is no recent diagnoses information available, as the patient doesn’t have a
                recent HRA.
              </p>
            </div>
          }
        }
      }
    }
  </t-widget-card-contents>

  @if (lastUpdated$ | async; as lastUpdated) {
    <t-widget-card-footer>
      <t-widget-updated-status [updateTime]="lastUpdated" href="diagnoses" />
    </t-widget-card-footer>
  }
</t-widget-card>
