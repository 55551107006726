import {
  NewFormSubmissionRequestPayload,
  UpdateFormSubmissionRequestPayload,
} from '../models/dynamic-forms-api.model';

export class GetDynamicFormData {
  static readonly type = '[Dynamic Form] Get dynamic form data';
  constructor(
    public formId: string,
    public prefilledData?: Record<string, any>
  ) {}
}

export class AddNewFormSubmission {
  static readonly type = '[Dynamic Form] Add new form submission';
  constructor(
    public payload: NewFormSubmissionRequestPayload,
    public directSubmit?: boolean
  ) {}
}

export class UpdateFormSubmission {
  static readonly type = '[Dynamic Form] Update form submission';
  constructor(
    public submissionId: number,
    public payload: UpdateFormSubmissionRequestPayload
  ) {}
}

export class DeleteFormSubmission {
  static readonly type = '[Dynamic Form] Delete form submission';
  constructor(public submissionId: number) {}
}

export class GetFormSubmissionContents {
  static readonly type = '[Dynamic Form] Get form submission content';
  constructor(public submissionId: number) {}
}

export class PopulateDataFromControlEnrichment {
  static readonly type = '[Dynamic Form] Populate data from control enrichment';
  constructor(
    public formControlName: string,
    public data: unknown
  ) {}
}

export class GetAllSubmissionDrafts {
  static readonly type = '[Dynamic Form] Get all submission drafts';
}
