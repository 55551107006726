import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HraAction } from '@troyai/hra/data-access';
import { PatientMetaComponent } from '@troyai/patients/ui';
import { FullNamePipe } from '@troyai/patients/util';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { DateOfBirthPipe, PhonePipe } from '@troyai/portal/common/pipes';
import {
  ButtonComponent,
  CardComponent,
  IconsModule,
  LabelComponent,
  ListExpandComponent,
  PopoverModule,
} from '@troyai/ui-kit';

@Component({
  selector: 't-care-patients-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    RouterModule,
    LabelComponent,
    ButtonComponent,
    IconsModule,
    PhonePipe,
    PopoverModule,
    ListExpandComponent,
    FullNamePipe,
    PharmacyIndicatorTooltipComponent,
    DateOfBirthPipe,
    PatientMetaComponent,
  ],
  templateUrl: './care-patients-list.component.html',
})
export class CarePatientsListComponent {
  @Input() hraActions: HraAction[] = [];
  @Input() selectedPharmacyId?: number | null = null;
}
