/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { GraphQLClient, gql } from 'graphql-request';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsGraphQLService {
  private client?: GraphQLClient;
  private endpoint = 'https://fxw3r7gdm9.execute-api.us-east-1.amazonaws.com/production/q';

  setupClient(token: string) {
    this.client = new GraphQLClient(this.endpoint, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  getNotificationsCount() {
    const query = gql`
      query GetInboxCount($params: FilterParamsInput) {
        count(params: $params)
      }
    `;

    const variables = {
      params: {
        status: 'unread',
      },
    };

    return from(this.client?.request(query, variables) as Promise<any>);
  }

  getNotificationsList() {
    const query = gql`
      query GetInboxMessages($params: FilterParamsInput, $limit: Int = 50, $after: String) {
        messages(params: $params, limit: $limit, after: $after) {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
          }
          nodes {
            created
            messageId
            preview
            read
          }
        }
      }
    `;

    const variables = {
      params: {},
    };

    return from(this.client?.request(query, variables) as Promise<any>);
  }

  markAllAsRead() {
    const query = gql`
      mutation TrackEvent {
        markAllRead
      }
    `;

    return from(this.client?.request(query) as Promise<any>);
  }

  markNotificationAsRead(id: string) {
    const query = gql`
      mutation TrackEvent($messageId: String!) {
        read(messageId: $messageId)
      }
    `;

    const variables = {
      messageId: id,
    };

    return from(this.client?.request(query, variables) as Promise<any>);
  }
}
