<div class="flex items-center justify-center md:justify-between">
  <span class="text-grey-50 hidden text-xs md:block">
    Showing {{ showingStartIndex }} - {{ showingEndIndex }} of {{ collectionSize }} entries
  </span>
  <nav>
    <ul class="flex items-center gap-x-4">
      <li *ngIf="firstLastButtons">
        <t-button
          (click)="selectPageNumber(1)"
          size="tiny"
          variant="neutral"
          icon="keyboard-double-arrow-left" />
      </li>

      <li *ngIf="nextPreviousButtons">
        <t-button (click)="previous()" size="tiny" icon="keyboard-arrow-left" variant="neutral" />
      </li>

      @if (pageNumbers) {
        <ng-container *ngFor="let p of totalPages">
          <li *ngIf="p <= currentPage + maxSize && p >= currentPage - maxSize">
            <button
              class="pag-number"
              [ngClass]="{
                active: currentPage === p
              }"
              (click)="selectPageNumber(p)">
              {{ p }}
            </button>
          </li>
        </ng-container>
      }

      <li *ngIf="nextPreviousButtons">
        <t-button (click)="next()" variant="neutral" icon="keyboard-arrow-right" size="tiny" />
      </li>

      <li *ngIf="firstLastButtons">
        <t-button
          size="tiny"
          variant="neutral"
          icon="keyboard-double-arrow-right"
          (click)="selectPageNumber(totalPages.length)" />
      </li>
    </ul>
  </nav>
</div>
