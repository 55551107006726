import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 't-timeline-entry',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './timeline-entry.component.html',
})
export class TimelineEntryComponent {
  @Input() isLast = false;
  @Input() isHighlighted = false;
}
