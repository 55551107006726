<div class="relative p-4">
  <div class="border-grey-30 overflow-hidden rounded-lg border">
    <t-input-text
      size="narrow"
      inputType="textarea"
      variant="borderless"
      placeholder="Message the Troy team..."
      [(ngModel)]="newMessage"
      id="newMessage"></t-input-text>
  </div>

  <div class="absolute right-6 top-7">
    <button
      class="text-red-80 disabled:text-grey-50 cursor-pointer disabled:cursor-not-allowed"
      [disabled]="newMessage.length < 2 || !newMessage"
      (click)="onFormSubmit()">
      <t-icon key="send" size="small" color="inherit"></t-icon>
    </button>
  </div>
</div>
