import { ActionMessagePayload } from '../models/message.interface';
import { ActionOutreachAttemptPayload } from '../models/outreach-attempts.interface';

export class GetActionsList {
  static readonly type = '[Actions] Get actions list';
  constructor(public payload?: { filter: string }) {}
}

export class EmptyActionsList {
  static readonly type = '[Actions] Empty actions list';
}

export class EmptySelectedAction {
  static readonly type = '[Actions] Empty selected action';
}

export class GetSingleAction {
  static readonly type = '[Action] Get single action';
  constructor(public payload: { globalId?: number }) {}
}

export class ClearSelectedAction {
  static readonly type = '[Action] Clear selected action';
}

export class GetActionMessages {
  static readonly type = '[Action] Get action messages';
  constructor(public payload: { globalId?: number }) {}
}

export class GetActionFormSettings {
  static readonly type = '[Action] Get action form settings';
  constructor(public payload: { globalId: number }) {}
}

export class SendActionMessage {
  static readonly type = '[Action] Send action message';
  constructor(public payload: { globalId: number; data: ActionMessagePayload }) {}
}

export class UpdateSingleActionSchedule {
  static readonly type = '[Actions] Update single action schedule';
  constructor(public payload: { date: string; address: string }) {}
}

export class UpdateSingleActionHRAState {
  static readonly type = '[Actions] Update single action HRA state';
  constructor(public payload: { date: string }) {}
}

export class GetPlanReviewMembersLeads {
  static readonly type = '[Actions] Get plan review members leads';
}

export class GetAllOutreachAttempts {
  static readonly type = '[Actions] Get all outreach attempts';
  constructor(public payload: { actionGlobalId: number }) {}
}

export class CreateOutreachAttempt {
  static readonly type = '[Actions] Create outreach attempt';
  constructor(public payload: { actionGlobalId: number; data: ActionOutreachAttemptPayload }) {}
}

export class GetOutreachAttemptsOutcomes {
  static readonly type = '[Actions] Get outreach attempts outcomes';
  constructor(public payload: { actionGlobalId: number }) {}
}
