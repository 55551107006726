import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NotificationTypes } from '@troyai/notifications/utils';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { IconsModule } from '@troyai/ui-kit';

@Component({
  selector: 't-notification-icon',
  standalone: true,
  imports: [CommonModule, IconsModule, ImageFallbackDirective],
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.css'],
})
export class NotificationIconComponent {
  @Input() image? = '';
  @Input() type: NotificationTypes | null = null;
}
