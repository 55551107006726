import { AuthorizationParams } from '@auth0/auth0-angular';
import { User as Auth0User } from '@auth0/auth0-spa-js';
import { UserSettingsUpdatePayload } from '../models/user-details.interface';

export class LoginFlowInitiated {
  static readonly type = '[Navbar] Login Flow Initiated';
  constructor(public payload: { redirectUrl: string; authorizationParams?: AuthorizationParams }) {}
}

export class LogoutFlowInitiated {
  static readonly type = '[Navbar] Logout Flow Initiated';
  constructor(public payload?: { forceRetryLogin?: boolean }) {}
}

export class UserChangedFromAuth0SDK {
  static readonly type = '[Auth0 SDK] User Changed';
  constructor(public payload: { user: Auth0User | undefined }) {}
}

export class LoadingChangedFromAuth0SDK {
  static readonly type = '[Auth0 SDK] Loading changed';
  constructor(public payload: { isLoading: boolean }) {}
}

export class GetCurrentUserDetails {
  static readonly type = '[User] Get current user details';
}

export class UpdateUserSettings {
  static readonly type = '[User] Update user settings';
  constructor(public payload: UserSettingsUpdatePayload) {}
}

export class UpdateLocalOnboardingSettingStatus {
  static readonly type = '[User] Update local onboarding setting status';
  constructor(public payload: { status: boolean }) {}
}
