import { PatchAssociateMemberFilePayload } from '@troyai/patients/data-access';
import { NewClinicalObservationPayload } from '../models/new-clinical-observation-payload.interface';

export class GetPatientClinicalObservations {
  static readonly type = '[Clinical Observations] Get patient clinical observations';
  constructor(public payload: { globalId?: number }) {}
}

export class GetSinglePatientClinicalObservation {
  static readonly type = '[Clinical Observations] Get single patient clinical observation';
  constructor(
    public payload: {
      memberGlobalId: number;
      clinicalObservationId: number;
    }
  ) {}
}

export class AddNewClinicalObservation {
  static readonly type = '[Clinical Observations] Add new clinical observation';
  constructor(
    public memberGlobalId: number,
    public payload: NewClinicalObservationPayload
  ) {}
}

export class EditClinicalObservation {
  static readonly type = '[Clinical Observations] Edit selected clinical observation';
  constructor(
    public memberGlobalId: number,
    public payload: NewClinicalObservationPayload
  ) {}
}

export class EmptySelectedClinicalObservation {
  static readonly type = '[Clinical Observations] Empty selected clinical observation';
}

export class AssociateMemberFilesToClinicalObservation {
  static readonly type = '[Clinical Observations] Associate member files to clinical observation';
  constructor(
    public payload: {
      memberGlobalId: number;
      fileIds: number[];
      data: PatchAssociateMemberFilePayload;
    }
  ) {}
}
