import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 't-action-iframe-embed',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './action-iframe-embed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionIframeEmbedComponent {
  constructor(private sanitizer: DomSanitizer) {}
  @Input() sourceURL = '';
  @Input() formMinHeight?: number;

  cacheBuster = Date.now();

  getIframeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
