import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Action } from '@troyai/actions/data-access';
import { ButtonComponent, IconsModule, ModalModule, ModalService } from '@troyai/ui-kit';

import { UserState } from '@troyai/auth/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { CustomMediumDatePipe } from '@troyai/portal/common/pipes';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 't-action-completed-cdps',
  standalone: true,
  imports: [CommonModule, IconsModule, ButtonComponent, ModalModule, CustomMediumDatePipe],
  templateUrl: './action-completed-cdps.component.html',
})
export class ActionCompletedCdpsComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private modalService: ModalService,
    private fullNamePipe: FullNamePipe
  ) {}
  @Input() action!: Action;

  destroy$: Subject<boolean> = new Subject<boolean>();
  private patientName = '';
  patient$ = this.store.select(PatientsState.selectedPatient);
  user$ = this.store.select(UserState.user);

  // Mockup functionality for demo purposes only - it should only be visible for users that have @troymedicare.com email
  displayCarePlanPOC = false;

  //The care plan modal was used for a demo, inactive until further development
  // openCarePlanModal() {
  //   this.modalService.openDialog(CarePlanModalComponent, this.action, {
  //     title: `Care Plan for ${this.patientName}`,
  //     background: 'grey',
  //   });
  // }

  ngOnInit() {
    this.patient$.pipe(takeUntil(this.destroy$)).subscribe((patient) => {
      if (patient) {
        this.patientName = this.fullNamePipe.transform(patient);
      }
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user && user.email) {
        this.displayCarePlanPOC = user.email.includes('@troymedicare.com');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
