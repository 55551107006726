import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, filter, map, mergeMap, of, take, withLatestFrom } from 'rxjs';

import {
  UserDetails,
  UserRoles,
  UserState,
  isMOCCompliantForCurrentYear,
  isPharmacyOnboarded,
} from '@troyai/auth/data-access';
import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';

/**
 * @description
 * This guard is used to prevent users from accessing the onboarding flow if they are not eligible to do so (ex: onboarding already completed)
 *
 */
export function CanAccessOnboardingGuard(): CanActivateFn {
  return () => {
    const store: Store = inject(Store);
    const router = inject(Router);

    const userDetails$ = store.select(UserState.userDetails);
    const pharmaciesList$ = store.select(PharmacyContextState.pharmaciesList);
    const selectedPharmacyId$ = store.select(PharmacyContextState.selectedPharmacyId);

    const pharmacyBoundUserOnboarding$ = combineLatest([selectedPharmacyId$, userDetails$]).pipe(
      filter(
        ([selectedPharmacyId, userDetails]) => selectedPharmacyId !== undefined && !!userDetails
      ),
      take(1),
      withLatestFrom(pharmaciesList$, ([selectedPharmacyId, userDetails], pharmaciesList) => {
        return {
          userDetails: userDetails as UserDetails,
          pharmaciesList,
          selectedPharmacyId,
        };
      }),
      map(({ selectedPharmacyId, pharmaciesList, userDetails }) => {
        const selectedPharmacy =
          pharmaciesList.find((pharmacy) => pharmacy.global_id === selectedPharmacyId) ||
          pharmaciesList[0];
        if (!selectedPharmacy) return router.parseUrl('/404');

        const requiresPharmacyOnboarding = !isPharmacyOnboarded(selectedPharmacy);

        if (!requiresPharmacyOnboarding && isMOCCompliantForCurrentYear(userDetails)) {
          return router.parseUrl(`/`);
        }

        if (!selectedPharmacy.user_is_owner && requiresPharmacyOnboarding) {
          return router.parseUrl(`/onboarding/forbidden`);
        }

        return true;
      })
    );

    return userDetails$.pipe(
      filter((userDetails) => !!userDetails),
      map((userDetails) => {
        return userDetails as UserDetails;
      }),
      mergeMap((userDetails) => {
        if (userDetails.roles.includes(UserRoles.PharmacyUser)) {
          return pharmacyBoundUserOnboarding$;
        }

        if (isMOCCompliantForCurrentYear(userDetails)) {
          return of(router.parseUrl(`/`));
        }

        return of(true);
      })
    );
  };
}
