import { format, isPast, isToday } from 'date-fns';
import { Appointment } from '../models/appointment.interface';

export const groupAppointmentsByDayKey = (appointments: Appointment[]) => {
  return appointments.reduce<Record<string, Appointment[]>>((acc, current) => {
    const dayKey = format(new Date(current.start_time), 'yyyy-MM-dd');
    if (!acc[dayKey]) {
      acc[dayKey] = [];
    }

    acc[dayKey] = [...acc[dayKey], current];

    return acc;
  }, {});
};

// The state of each day group should initially be open if the day group is today or if it includes timeslots with appointments that are not in the past.
export const getInitialOpenStateByDayKey = (dayKey: string, appointments: Appointment[]) => {
  const dayGroupKeyDate = new Date(dayKey);

  const dayGroupIncludesTimeslotsWithAppointments = appointments.some((appointment) => {
    return appointment.member_id !== '0';
  });

  const isDayGroupToday = isToday(dayGroupKeyDate);
  const isDayGroupInPast = isPast(dayGroupKeyDate);

  if (isDayGroupToday || (dayGroupIncludesTimeslotsWithAppointments && !isDayGroupInPast)) {
    return true;
  }

  return false;
};
