/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 't-minimal-file-upload',
  templateUrl: './minimal-file-upload.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinimalFileUploadComponent),
      multi: true,
    },
  ],
  imports: [CommonModule],
})
export class MinimalFileUploadComponent implements ControlValueAccessor {
  @Input() label = 'Upload File'; // Optional label for the input
  @Output() fileUploaded: EventEmitter<File> = new EventEmitter<File>();

  fileName = ''; // Holds the name of the selected file
  fileId = ''; // This will hold the file ID returned from the backend
  isHovering = false; // State to track when a file is dragged over the drop zone

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  // Register change callback
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Register touched callback
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Write value (called by the parent form control)
  writeValue(value: string): void {
    this.fileId = value;
  }

  // Handle file selection via input
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      this.handleFile(file);
    }
  }

  // Handle file selection via drag and drop
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file: File = event.dataTransfer.files[0];
      this.handleFile(file);
      this.isHovering = false; // Reset drag state
      event.dataTransfer.clearData(); // Clear the drag event
    }
  }

  // Handle drag events to set isHovering state
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isHovering = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    this.isHovering = false;
  }

  // Utility method to handle file
  handleFile(file: File): void {
    this.fileUploaded.emit(file); // Emit the file to the parent component for uploading
    this.fileName = file.name;
  }

  // Method to set the file ID after the file is uploaded
  setFileId(id: string): void {
    this.fileId = id;
    this.onChange(id); // Notify the form control of the new value (the file ID)
  }

  // Focus event handler
  onBlur(): void {
    this.onTouched();
  }

  triggerFileInput(fileInput: HTMLInputElement): void {
    fileInput.click();
  }
}
