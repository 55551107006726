<div
  class="relative w-[846px] print:visible print:block print:h-auto print:w-auto print:overflow-visible">
  <div class="bg-grey-20 flex items-center justify-between rounded p-5 print:hidden">
    <t-button (click)="triggerPrint()">PRINT</t-button>
    <div class="space-y-1 text-right">
      <span class="text-grey-70 block text-xs">Generated</span>
      <strong class="text-grey-100 block text-sm font-semibold">Today, {{ getTodayDate() }}</strong>
    </div>
  </div>
  <div
    class="modal shadow-small mt-5 max-h-[300px] overflow-y-scroll bg-white p-8 print:visible print:h-auto print:overflow-visible print:shadow-transparent">
    <t-heading level="h3" value="All actions"></t-heading>
    <span class="text-grey-100 mt-2.5 text-base">Document Generated: {{ getTodayDate() }} </span>
    <div class="divide-grey-100 border-grey-100 mt-6 divide-y border-t">
      <div *ngFor="let action of actions" class="text-grey-100 flex py-6 text-base">
        <div class="w-1/3">
          <span class="text-grey-100 block text-sm font-semibold">{{ action.title }}</span>
          <span class="text-grey-70 block text-xs">
            {{ action.due_date | expirationDate }}
          </span>
        </div>

        <div class="w-1/3">
          <span class="text-grey-70 block text-sm">
            {{ action.member | fullName | titlecase }}
          </span>
          <span class="block text-xs">
            {{ action.member.dob | dobDate }}
          </span>
        </div>

        <div class="w-1/3 text-right text-sm">
          <span class="block text-sm font-semibold">Status: {{ action.status }}</span>
          <span>
            Value:
            {{ action.fee | currency: undefined : 'symbol' : '1.0-0' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
