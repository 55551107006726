@if (scheduleLocationModalState$ | async; as modalState) {
  <div class="flex items-center justify-between">
    <span class="text-grey-100 block text-sm"> Where should the consultation take place? </span>

    @if (modalState.hasTelehealthSupport) {
      <span
        class="text-grey-100 flex items-center gap-x-2 text-xs font-semibold uppercase tracking-wider">
        Telehealth
        @if (modalState.isTelehealthReadOnly) {
          <t-toggle
            [tPopoverAnchor]="telehealthSwitchExplainer"
            [tPopoverHover]
            [on]="modalState.isTelehealthSelected"
            [disabled]="true" />
        } @else {
          <t-toggle
            [on]="modalState.isTelehealthSelected"
            (toggled)="updateTelehealthSelection($event)" />
        }
      </span>
    }
  </div>

  <div class="shadow-small divide-grey-20 mb-2 mt-5 divide-y rounded-lg bg-white">
    @for (address of availableAddresses$ | async; track address.address_hash) {
      <div class="flex items-center justify-between px-5 py-4">
        @if (address.at_pharmacy) {
          <div class="text-grey-70 flex items-center space-x-2">
            <t-icon key="location-on" color="inherit" size="tiny" />
            <span class="text-grey-100 text-sm">At the Pharmacy</span>
            <t-icon key="storefront" color="inherit" size="tiny" />
          </div>
        } @else {
          <div class="text-grey-70 flex items-center space-x-2">
            <t-icon key="location-on" color="inherit" size="tiny" />
            <span class="text-grey-100 text-sm">
              {{ address | formattedAddress }}
            </span>
          </div>
        }

        <t-button
          [disabled]="
            address.at_pharmacy &&
            !modalState.allowTelehealthAtPharmacy &&
            modalState.isTelehealthSelected
          "
          (click)="
            address.at_pharmacy &&
            modalState.isTelehealthSelected &&
            !modalState.allowTelehealthAtPharmacy
              ? null
              : chooseLocation(address)
          "
          variant="neutral"
          size="tiny">
          CHOOSE
        </t-button>
      </div>
    }
  </div>

  <t-popover verticalAlign="below" horizontalAlign="end" #telehealthSwitchExplainer>
    <p class="max-w-xs bg-black bg-opacity-90 p-3 text-xs text-white">
      The current action type does not allow changing telehealth status.
    </p>
  </t-popover>
}
