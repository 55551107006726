import { I18nPluralPipe, NgLocalization } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

export type SupportedPluralizeKeys = 'action' | 'patient';

export type ItemPluralMapping = {
  [key in SupportedPluralizeKeys]: {
    '=0': string;
    '=1': string;
    '=2'?: string;
    other: string;
  };
};

const itemPluralMapping: ItemPluralMapping = {
  action: {
    '=0': '0 actions',
    '=1': '1 action',
    other: '# actions',
  },
  patient: {
    '=0': '0 patients',
    '=1': '1 patient',
    other: '# patients',
  },
};

const itemPluralMappingNouns: ItemPluralMapping = {
  action: {
    '=0': 'actions',
    '=1': 'action',
    other: 'actions',
  },
  patient: {
    '=0': 'patients',
    '=1': 'patient',
    other: 'patients',
  },
};

@Pipe({
  name: 'pluralize',
  standalone: true,
  pure: true,
})
export class PluralizePipe implements PipeTransform {
  constructor(private ngLocalization: NgLocalization) {}

  transform(
    count: number | null | undefined,
    type: SupportedPluralizeKeys,
    excludeCount = false
  ): string {
    const mapping = excludeCount ? itemPluralMappingNouns : itemPluralMapping;
    return new I18nPluralPipe(this.ngLocalization).transform(count, mapping[type]);
  }
}
