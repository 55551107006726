import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 't-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section.component.html',
})
export class SectionComponent {
  @Input() headerTitle = '';
  @Input() hasTitlePadding = true;
}
