import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedTourService } from '../../guided-tour.service';
import {
  generateFirstSlideButtons,
  generateGuidedTourDefaultConfigOptions,
  generateLastSlideButtons,
  generatePositionStrategy,
} from '../../guided-tour.config';
import { ModalModule, ModalService } from '@troyai/ui-kit';
import { TourStartingPromptComponent } from '../tour-starting-prompt/tour-starting-prompt.component';

@Component({
  selector: 't-patients-tour',
  standalone: true,
  imports: [CommonModule, ModalModule],
  templateUrl: './patients-tour.component.html',
})
export class PatientsTourComponent implements AfterViewInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private modalService: ModalService
  ) {}

  ngAfterViewInit() {
    this.guidedTourService.defaultStepOptions = generateGuidedTourDefaultConfigOptions(
      this.guidedTourService
    );
    this.guidedTourService.tourName = 'patients';
    this.guidedTourService.modal = true;
    this.guidedTourService.confirmCancel = false;
    this.guidedTourService.addSteps([
      {
        id: '1-patients-search-input',
        attachTo: {
          element: '#tour-patients-search-input',
          on: generatePositionStrategy('bottom'),
        },
        title: 'Patients list',
        text: ['Search patients by name or date of birth.'],
        buttons: generateFirstSlideButtons(this.guidedTourService),
      },
      {
        id: '2-patients-filters',
        attachTo: {
          element: '#tour-patients-filters',
          on: generatePositionStrategy('bottom'),
        },
        title: 'Patients list',
        text: ['Apply filters to see only specific patients.'],
      },
      {
        id: '3-patients-print',
        attachTo: {
          element: '#tour-patients-print',
          on: generatePositionStrategy('bottom'),
        },
        buttons: generateLastSlideButtons(this.guidedTourService),
        scrollTo: false,
        title: 'Patients list',
        text: ['Print list.'],
      },
    ]);

    if (
      !this.guidedTourService.getLocalStorageCompletionStatusByName(this.guidedTourService.tourName)
    ) {
      this.modalService.openPromptDialog(
        TourStartingPromptComponent,
        {
          tourIntroText: 'This is your Patient list, where you can see all your Troy.AI patients.',
        },
        {
          hideCloseButton: true,
        }
      );
    }
  }
}
