import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, distinctUntilChanged, tap, throwError, timer } from 'rxjs';

import { ApplicationError } from '@troyai/error-handling';
import { ThemeService } from '@troyai/portal/theme';
import { TenantService } from 'multi-tenancy';
import { UsersApiService } from '../api/user-api.service';
import { isMOCCompliantForCurrentYear } from '../helpers/is-moc-training-required.util';
import { UserSettingsUpdatePayload } from '../models/user-details.interface';
import { UserTenancy } from '../models/user-tenancy.interface';
import {
  GetCurrentUserDetails,
  LoadingChangedFromAuth0SDK,
  LoginFlowInitiated,
  LogoutFlowInitiated,
  UpdateLocalOnboardingSettingStatus,
  UpdateUserSettings,
  UserChangedFromAuth0SDK,
} from './user.actions';
import { UserStateModel } from './user.model';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    idTokenUserData: undefined,
    userDetails: undefined,
    isLoading: true,
    isUserDetailsLoading: true,
    currentTenantId: null,
    tenantLogoUrl: null,
  },
})
@Injectable()
export class UserState {
  constructor(
    private store: Store,
    private authService: AuthService,
    private userApiService: UsersApiService,
    private tenantService: TenantService,
    private themeService: ThemeService
  ) {
    this.listenToUserChange();
    this.listenIfIsLoading();
  }

  @Selector()
  static user(state: UserStateModel) {
    return state.idTokenUserData;
  }

  @Selector()
  static userDetails(state: UserStateModel) {
    return state.userDetails;
  }

  @Selector()
  static userRoles(state: UserStateModel) {
    return state.userDetails?.roles;
  }

  @Selector()
  static userPharmacies(state: UserStateModel) {
    return state.userDetails?.pharmacies;
  }

  @Selector()
  static userSettings(state: UserStateModel) {
    return state.userDetails?.settings;
  }

  @Selector()
  static userEmailHash(state: UserStateModel) {
    return state.userDetails?.email_hash;
  }

  @Selector()
  static isLoading(state: UserStateModel) {
    return state.isLoading || state.isUserDetailsLoading;
  }

  @Selector()
  static isLoggedIn(state: UserStateModel) {
    return !!state.idTokenUserData;
  }

  @Selector()
  static hasRoles(state: UserStateModel) {
    return !!state.userDetails?.roles && !!state.userDetails?.roles.length;
  }

  @Selector()
  static isMOCCompliant(state: UserStateModel): boolean {
    if (!state.userDetails) {
      return false;
    }
    return isMOCCompliantForCurrentYear(state.userDetails);
  }

  @Selector()
  static tenants(state: UserStateModel): UserTenancy[] | null {
    if (!state.userDetails?.tenancy) {
      return null;
    }
    return state.userDetails.tenancy;
  }

  @Selector()
  static currentTenantId(state: UserStateModel) {
    return state.currentTenantId;
  }

  @Selector()
  static currentTenantLogo(state: UserStateModel) {
    return state.tenantLogoUrl;
  }

  private listenToUserChange(): void {
    this.authService.user$.subscribe((user) => {
      this.store.dispatch(new UserChangedFromAuth0SDK({ user: user || undefined }));
    });
  }

  private listenIfIsLoading(): void {
    this.authService.isLoading$.pipe(distinctUntilChanged()).subscribe((isLoading) => {
      this.store.dispatch(new LoadingChangedFromAuth0SDK({ isLoading }));
    });
  }

  @Action(LoadingChangedFromAuth0SDK)
  loadingChangedFromAuth0SDK(
    ctx: StateContext<UserStateModel>,
    actions: LoadingChangedFromAuth0SDK
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      isLoading: actions.payload.isLoading,
    });
  }

  @Action(UserChangedFromAuth0SDK)
  userChangedFromAuth0SDK(ctx: StateContext<UserStateModel>, actions: UserChangedFromAuth0SDK) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      idTokenUserData: actions.payload.user,
    });
    if (actions.payload.user) {
      ctx.dispatch([new GetCurrentUserDetails()]);
    } else {
      ctx.patchState({ isUserDetailsLoading: false });
    }
  }

  @Action(LoginFlowInitiated)
  login(_context: StateContext<UserStateModel>, action: LoginFlowInitiated) {
    return this.authService.loginWithRedirect({
      authorizationParams: action.payload.authorizationParams,
      appState: {
        target: action.payload.redirectUrl,
        returnTo: action.payload.redirectUrl,
      },
    });
  }

  @Action(LogoutFlowInitiated)
  logout({ patchState }: StateContext<UserStateModel>, action: LogoutFlowInitiated) {
    patchState({ isUserDetailsLoading: true, isLoading: true });

    // Used for forcing a new login flow, when the user just logged out
    const forceRetryLoginQueryParam = action.payload?.forceRetryLogin
      ? '?forceRetryLogin=true'
      : '';

    return timer(1000).pipe(
      tap(() => {
        this.authService.logout({
          openUrl: (url) => {
            const returnTo = encodeURIComponent(
              `${window.location.origin}/logout${forceRetryLoginQueryParam}`
            );
            const completeUrl = `${url}&returnTo=${returnTo}`;
            window.location.replace(completeUrl);
          },
        });
      })
    );
  }

  @Action(GetCurrentUserDetails)
  getCurrentUserDetails({ patchState }: StateContext<UserStateModel>) {
    patchState({ isUserDetailsLoading: true });
    return this.userApiService.getUserDetails().pipe(
      tap((userDetails) => {
        if (userDetails) {
          const currentTenantId = this.tenantService.getCurrentTenantRef();

          const currentTenantTheme = userDetails.tenancy.find((tenant) => {
            return tenant.reference === currentTenantId;
          })?.theme;
          if (currentTenantTheme) {
            this.themeService.setThemeVariables(currentTenantTheme);
          }
          patchState({ userDetails, currentTenantId, tenantLogoUrl: currentTenantTheme?.logo_url });
        }
        if (window && userDetails.global_id) {
          window.localStorage.setItem('troyai-current-user', userDetails.global_id.toString());
        }
        patchState({ isUserDetailsLoading: false });
      }),
      catchError(() => {
        patchState({ isUserDetailsLoading: false });
        return throwError(() => new ApplicationError('Failed to get current user details'));
      })
    );
  }

  @Action(UpdateUserSettings)
  updateUserSettings(
    { getState, patchState }: StateContext<UserStateModel>,
    action: UpdateUserSettings
  ) {
    const { userDetails } = getState();
    const settings = userDetails?.settings;

    // TODO: Separate flags from other settings
    const updatedUserSettings: UserSettingsUpdatePayload = settings
      ? {
          notification_settings: settings.notification_settings,
          onboarding_tour_completed: settings.flags.onboarding_tour_completed,
          ...action.payload,
        }
      : action.payload;

    return this.userApiService.updateUserSettings(updatedUserSettings).pipe(
      tap(() => {
        // TODO: Simplify this logic and reduce nesting
        if (userDetails) {
          patchState({
            userDetails: {
              ...userDetails,
              settings: {
                ...userDetails.settings,
                flags: {
                  onboarding_tour_completed:
                    updatedUserSettings.onboarding_tour_completed as boolean,
                },
              },
            },
          });
        }
      }),
      catchError(() => {
        return throwError(() => {
          return 'Failed to update user settings';
        });
      })
    );
  }

  @Action(UpdateLocalOnboardingSettingStatus)
  updateLocalOnboardingSettingStatus(
    { getState, patchState }: StateContext<UserStateModel>,
    action: UpdateLocalOnboardingSettingStatus
  ) {
    const { userDetails } = getState();

    if (userDetails) {
      patchState({
        userDetails: {
          ...userDetails,
          settings: {
            ...userDetails.settings,
            flags: {
              onboarding_tour_completed: action.payload.status,
            },
          },
        },
      });
    }
  }
}
