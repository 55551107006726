<ng-container *ngIf="files.length">
  <div class="space-y-2">
    <t-patient-file-card *ngFor="let file of files" [file]="file" class="block">
      <div class="flex gap-x-2">
        <t-link size="small" variant="light" [isButton]="true" (click)="preview(file)">
          Preview
        </t-link>
        <t-link size="small" variant="light" [isButton]="true" (click)="download(file)">
          Download
        </t-link>
      </div>
    </t-patient-file-card>
  </div>
</ng-container>

<ng-container *ngIf="newUploadedFiles.length">
  <div class="space-y-2">
    <t-patient-file-card *ngFor="let file of newUploadedFiles" [file]="file" class="block">
      <t-link size="small" variant="light" [isButton]="true" (click)="deleteFile(file)">
        Remove
      </t-link>
    </t-patient-file-card>
  </div>
</ng-container>
