import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineMilestone } from './timeline-milestone.interface';
import { eachDayOfInterval, isAfter, isSameDay, set, setHours, subDays } from 'date-fns';

@Component({
  selector: 't-segmented-timeline',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './segmented-timeline.component.html',
  styleUrls: ['./segmented-timeline.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentedTimelineComponent implements OnInit {
  @Input() currentTime = new Date();
  @Input() primaryMilestoneDate = '';
  @Input() secondaryMilestoneDate = '';

  primaryMilestoneSegments: TimelineMilestone[] = [];
  primaryMilestoneSegmentsActive = false;
  primaryMilestoneSegmentsOverdue = false;
  secondaryMilestoneSegments: TimelineMilestone[] = [];
  secondaryMilestoneSegmentsActive = false;
  secondaryMilestoneSegmentsOverdue = false;
  isOverdue = false;

  ngOnInit(): void {
    const primaryMilestoneDate = setHours(new Date(this.primaryMilestoneDate), 0);
    const secondaryMilestoneDate = subDays(setHours(new Date(this.secondaryMilestoneDate), 0), 1);
    const primaryMilestoneOffsetDate = subDays(primaryMilestoneDate, 5);
    const currentTime = set(this.currentTime, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const primaryMilestoneDays = eachDayOfInterval({
      start: primaryMilestoneOffsetDate,
      end: subDays(primaryMilestoneDate, 1),
    });

    this.primaryMilestoneSegments = primaryMilestoneDays.map((day) => {
      const isToday = isSameDay(day, currentTime);
      return {
        date: day.toString(),
        isCurrent: isToday,
      };
    });
    this.primaryMilestoneSegmentsActive = this.primaryMilestoneSegments.some(
      (segment) => segment.isCurrent
    );
    this.primaryMilestoneSegmentsOverdue =
      isSameDay(currentTime, primaryMilestoneDate) || isAfter(currentTime, primaryMilestoneDate);

    const secondaryMilestoneDays = [secondaryMilestoneDate];
    this.secondaryMilestoneSegments = secondaryMilestoneDays.map((day) => {
      const isToday = isSameDay(day, currentTime);
      return {
        date: day.toString(),
        isCurrent: isToday,
      };
    });

    this.secondaryMilestoneSegmentsActive = this.secondaryMilestoneSegments.some(
      (segment) => segment.isCurrent
    );
    this.secondaryMilestoneSegmentsOverdue = isAfter(currentTime, secondaryMilestoneDate);

    this.isOverdue = isAfter(currentTime, secondaryMilestoneDate);
  }
}
