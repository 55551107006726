import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IS_FILTERED_BY_PHARMACY } from '@troyai/pharmacy-context/data-access';
import { CACHE_OPTIONS, CacheOptions, RestApiClientService } from '@troyai/rest-api-client';
import {
  Action,
  ActionForm,
  ActionTemplate,
  GetActionsQueryParams,
} from './models/action.interface';
import { ActionMessage, ActionMessagePayload } from './models/message.interface';
import {
  ActionOutreachAttempt,
  ActionOutreachAttemptOutcome,
  ActionOutreachAttemptPayload,
} from './models/outreach-attempts.interface';
import { PlanReviewMembersLead } from './models/plan-review-lead.interface';

@Injectable({
  providedIn: 'root',
})
export class ActionsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getActions(queryParams?: GetActionsQueryParams, cacheOptions?: CacheOptions) {
    const params = queryParams?.filter
      ? new HttpParams().set('filter', queryParams?.filter || '')
      : undefined;

    const context = new HttpContext();
    context.set(IS_FILTERED_BY_PHARMACY, true);
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<Action[]>('actions', {
      params,
      context,
    });
  }

  getOngoingActions(memberGlobalId: number, cacheOptions?: CacheOptions) {
    const context = new HttpContext();
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<ActionTemplate[]>(`members/${memberGlobalId}/ongoing-actions`, {
      context,
    });
  }

  getSingleAction(globalId: number) {
    return this.restApiService.get<Action>(`actions/${globalId}`);
  }

  getActionMessages(globalId: number) {
    return this.restApiService.get<ActionMessage[]>(`actions/${globalId}/messages`);
  }

  getActionForms(globalId: number) {
    return this.restApiService.get<ActionForm>(`actions/${globalId}/forms`);
  }

  sendActionMessage(globalId: number, payload: ActionMessagePayload) {
    const body = new HttpParams().set('message', payload.message);
    return this.restApiService.post<ActionMessage>(`actions/${globalId}/messages`, body);
  }

  getPlanReviewActionMemberLeads() {
    return this.restApiService.get<PlanReviewMembersLead[]>(`members/leads`);
  }

  // Outreach attempts feature
  getOutreachAttempts(actionGlobalId: number) {
    return this.restApiService.get<ActionOutreachAttempt[]>(`actions/${actionGlobalId}/attempts`);
  }

  createOutreachAttempt(actionGlobalId: number, payload: ActionOutreachAttemptPayload) {
    const body = new HttpParams({
      fromObject: {
        outreach_result_code: payload.outreach_result_code,
        phone_number: payload.phone_number,
        reason: payload.reason,
        follow_up_call: payload.follow_up_call,
      },
    });

    return this.restApiService.post<ActionOutreachAttemptPayload>(
      `actions/${actionGlobalId}/attempts`,
      body
    );
  }
  getOutreachAttemptsOutcomes(actionGlobalId: number) {
    return this.restApiService.get<ActionOutreachAttemptOutcome[]>(
      `actions/${actionGlobalId}/outreach-attempts`
    );
  }
}
