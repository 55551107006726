<div class="space-y-3">
  @if (medicationListDataSource.page$ | async; as medPage) {
    @if (medPage.totalElements > 0) {
      <t-expandable-card class="block">
        <div header class="flex items-center gap-x-3">
          <strong class="text-grey-100 text-xs font-semibold tracking-wider">MEDICATIONS</strong>
          <t-label size="tiny" color="error" paddingSize="small">
            {{ medPage.totalElements }}
          </t-label>
        </div>

        <div class="px-8 pb-6">
          <t-data-listing
            [items]="medPage.items"
            variant="table"
            verticalSpacingSize="small"
            class="border-grey-20 block border-t">
            <ng-template #rowTemplate let-item>
              <div class="flex items-center justify-between">
                <span class="text-grey-70 text-sm">{{ item.drug_name }}</span>
                <t-label size="tiny" color="neutral" paddingSize="small">
                  {{ item.disease }}
                </t-label>
              </div>
            </ng-template>
          </t-data-listing>

          @if (medPage.totalElements > 0 && medPage.pageSize !== medPage.totalElements) {
            <div class="border-grey-20 border-t pt-4">
              <t-paginator
                [collectionSize]="medPage.totalElements"
                [currentPage]="medPage.pageNumber"
                [pageSize]="pageSize"
                [pageNumbers]="false"
                (pageChanged)="medicationListDataSource.fetch($event.pageNumber)"
                [maxSize]="2" />
            </div>
          }
        </div>
      </t-expandable-card>
    }
  }

  @if (regularDiagnosesDataSource.page$ | async; as regDiagPage) {
    @if (regDiagPage.totalElements > 0) {
      <t-expandable-card class="block">
        <div header class="flex items-center gap-x-3">
          <strong class="text-grey-100 text-xs font-semibold tracking-wider">DIAGNOSES</strong>
          <t-label size="tiny" color="error" paddingSize="small">
            {{ regDiagPage.totalElements }}
          </t-label>
        </div>

        <div class="px-8 pb-6">
          <t-data-listing
            [items]="regDiagPage.items"
            variant="table"
            verticalSpacingSize="small"
            class="border-grey-20 block border-t">
            <ng-template #rowTemplate let-item>
              <div class="flex items-center justify-between">
                <span class="text-grey-70 text-sm">{{ item.name }}</span>
                <span class="text-grey-60 text-sm">{{ item.code }}</span>
              </div>
            </ng-template>
          </t-data-listing>

          @if (
            regDiagPage.totalElements > 0 && regDiagPage.pageSize !== regDiagPage.totalElements
          ) {
            <div class="border-grey-20 border-t pt-4">
              <t-paginator
                [collectionSize]="regDiagPage.totalElements"
                [currentPage]="regDiagPage.pageNumber"
                [pageSize]="pageSize"
                [pageNumbers]="false"
                (pageChanged)="regularDiagnosesDataSource.fetch($event.pageNumber)"
                [maxSize]="2" />
            </div>
          }
        </div>
      </t-expandable-card>
    }
  }

  @if (potentialDiagnosesDataSource.page$ | async; as potDiagPage) {
    @if (potDiagPage.totalElements > 0) {
      <t-expandable-card class="block">
        <div header class="flex items-center gap-x-3">
          <strong class="text-grey-100 text-xs font-semibold tracking-wider">
            POTENTIAL DIAGNOSES
          </strong>
          <t-label size="tiny" color="error" paddingSize="small">
            {{ potDiagPage.totalElements }}
          </t-label>
        </div>

        <div class="px-8 pb-6">
          <t-data-listing
            [items]="potDiagPage.items"
            variant="table"
            verticalSpacingSize="small"
            class="border-grey-20 block border-t">
            <ng-template #rowTemplate let-item>
              <div class="flex items-center justify-between">
                <span class="text-grey-70 text-sm">{{ item.name }}</span>
                <span class="text-grey-60 text-sm">{{ item.code }}</span>
              </div>
            </ng-template>
          </t-data-listing>

          @if (
            potDiagPage.totalElements > 0 && potDiagPage.pageSize !== potDiagPage.totalElements
          ) {
            <div class="border-grey-20 border-t pt-4">
              <t-paginator
                [collectionSize]="potDiagPage.totalElements"
                [currentPage]="potDiagPage.pageNumber"
                [pageSize]="pageSize"
                [pageNumbers]="false"
                (pageChanged)="regularDiagnosesDataSource.fetch($event.pageNumber)"
                [maxSize]="2" />
            </div>
          }
        </div>
      </t-expandable-card>
    }
  }
</div>
