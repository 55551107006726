import { UpdateUserProfilePayload } from '../models/user-profile.interface';

export class GetUserProfile {
  static readonly type = '[User Profile] Get profile';
}

export class UpdateUserProfile {
  static readonly type = '[User Profile] Update profile';
  constructor(public payload: { data: UpdateUserProfilePayload }) {}
}
