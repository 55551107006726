<div class="relative">
  <input class="sr-only" type="checkbox" />
  <button
    class="relative block h-2 w-8 cursor-pointer select-none rounded-full outline-none"
    [ngClass]="{
      'bg-green-30': on,
      'bg-yellow-30': !on
    }"
    [attr.aria-label]="on ? 'On' : 'Off'"
    [attr.aria-expanded]="on">
    <span
      class="block h-4 w-4 -translate-y-1/4 rounded-full transition-transform"
      [ngClass]="{
        'bg-green-90 translate-x-4': on,
        'bg-amber-70 translate-x-0': !on
      }"></span>
  </button>
</div>
