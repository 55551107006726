import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { addWeeks, endOfWeek, format, startOfWeek, subWeeks } from 'date-fns';
import { catchError, filter, mergeMap, of, tap, throwError, timer } from 'rxjs';

import { ActionsApiService } from '@troyai/actions/data-access';
import { ApplicationError, errorResponseIncludesMessage } from '@troyai/error-handling';
import { PatientsFilesApiService } from '@troyai/patients/data-access';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import {
  getInitialOpenStateByDayKey,
  groupAppointmentsByDayKey,
} from '../helpers/group-appointments.util';
import { HraCareApiService } from '../hra-api.service';
import { MemberHra } from '../models/hra.interface';
import { updateTimeslotByAppointmentGroup } from './care-state.helpers';
import { HraCareStateModel } from './care-state.model';
import {
  AddMemberHraAddendum,
  AssignAppointmentToPatient,
  GetAllAppointmentsDataAsPharmacy,
  GetAppointmentActionPreview,
  GetAppointmentsList,
  GetAppointmentsListAsPharmacy,
  GetCareActionsList,
  GetMemberHRAItems,
  GetMemberHRAPreviewURL,
  GetNursePractitionersList,
  GetPatientsListToBeScheduled,
  NavigateToCurrentWeek,
  NavigateToNextWeek,
  NavigateToPreviousWeek,
  RemoveInnerSlot,
  RemoveSlotsFromDay,
  RestoreInnerSlot,
  SchedulePatientAppointmentAsPharmacy,
  SetScheduleAppointmentAddress,
  SetScheduleAppointmentNursePractitioner,
  ToggleAppointmentsGroup,
  UnassignAppointmentForPatient,
  UnscheduleAppointmentForPatientAsPharmacy,
  UpdateScheduleLocationModalState,
} from './care.actions';

const stateDefaults = {
  careActionsListItems: [],
  careActionListItemsLoading: false,
  appointmentsListItemsLoading: false,
  appointmentsAccordionGroups: [],
  appointmentsPeriod: {
    startDate: startOfWeek(Date.now(), {
      weekStartsOn: 1,
    }),
    endDate: endOfWeek(Date.now(), {
      weekStartsOn: 1,
    }),
  },
  patientsListToBeScheduledItems: [],
  nursePractitionersList: [],
  schedulingPatientLoading: false,
  scheduleLocationModalState: {
    hasTelehealthSupport: false,
    isTelehealthReadOnly: false,
    isTelehealthSelected: false,
    allowTelehealthAtPharmacy: false,
    hasPharmacyLocationSupport: false,
    hasMemberAddressesSupport: false,
  },
  memberHraItems: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
  singleMemberHraItemPreview: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
  addingMemberHraAddendum: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
};

@State<HraCareStateModel>({
  name: 'careState',
  defaults: stateDefaults,
})
@Injectable()
export class CareState {
  constructor(
    private careApiService: HraCareApiService,
    private actionsApiService: ActionsApiService,
    private patientsFilesApiService: PatientsFilesApiService
  ) {}

  @Selector()
  static careActionsListItems(state: HraCareStateModel) {
    return state.careActionsListItems;
  }

  @Selector()
  static careActionsListItemsLoading(state: HraCareStateModel) {
    return state.careActionListItemsLoading;
  }

  @Selector()
  static calendarAccessToken(state: HraCareStateModel) {
    return state.calendarAccessToken;
  }

  @Selector()
  static groupedAppointmentsListItems(state: HraCareStateModel) {
    return state.appointmentsAccordionGroups;
  }

  @Selector()
  static appointmentsListItemsLoading(state: HraCareStateModel) {
    return state.appointmentsListItemsLoading;
  }

  @Selector()
  static appointmentsPeriod(state: HraCareStateModel) {
    return state.appointmentsPeriod;
  }

  @Selector()
  static getPatientsToBeScheduled(state: HraCareStateModel) {
    return state.patientsListToBeScheduledItems;
  }

  @Selector()
  static schedulingPatientLoading(state: HraCareStateModel) {
    return state.schedulingPatientLoading;
  }

  @Selector()
  static nursePractitionersList(state: HraCareStateModel) {
    // NPs that don't have any counties are not eligible to be included in scheduling, so we filter them out
    return state.nursePractitionersList.filter((np) => np.counties && np.counties.length > 0);
  }

  @Selector()
  static selectedAppointmentActionPreview(state: HraCareStateModel) {
    return state.selectedAppointmentActionPreview;
  }

  @Selector()
  static scheduleAppointmentDetails(state: HraCareStateModel) {
    return state.scheduleAppointmentDetails;
  }

  @Selector()
  static scheduleLocationModalState(state: HraCareStateModel) {
    return state.scheduleLocationModalState;
  }

  @Selector()
  static getMemberHraItems(state: HraCareStateModel) {
    return state.memberHraItems.result;
  }

  @Selector()
  static getMemberHraItemsLoadingStatus(state: HraCareStateModel) {
    return getLoadingStatus(state.memberHraItems.loadingStatus);
  }

  @Selector()
  static getAddingMemberHraAddendumsLoadingStatus(state: HraCareStateModel) {
    return getLoadingStatus(state.addingMemberHraAddendum.loadingStatus);
  }

  @Selector()
  static singleMemberHraItemPreview(state: HraCareStateModel) {
    return state.singleMemberHraItemPreview.result;
  }

  @Selector()
  static singleMemberHraItemPreviewLoadingStatus(state: HraCareStateModel) {
    return getLoadingStatus(state.singleMemberHraItemPreview.loadingStatus);
  }

  @Action(GetCareActionsList)
  getCareActionsList({ setState, patchState }: StateContext<HraCareStateModel>) {
    patchState({
      careActionListItemsLoading: true,
      careActionsListItems: [],
    });
    return this.careApiService.getCareActions().pipe(
      tap((careActions) =>
        setState(
          patch<HraCareStateModel>({
            careActionsListItems: careActions,
          })
        )
      ),
      tap(() =>
        patchState({
          careActionListItemsLoading: false,
        })
      )
    );
  }

  @Action(GetAppointmentsList)
  getAppointmentsList(
    { setState, patchState }: StateContext<HraCareStateModel>,
    action: GetAppointmentsList
  ) {
    const startDate = format(action.payload.startDate, 'yyyy-MM-dd');
    const endDate = format(action.payload.endDate, 'yyyy-MM-dd');

    patchState({
      appointmentsListItemsLoading: true,
      appointmentsPeriod: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
    });
    return this.careApiService.getAppointmentsData(startDate, endDate).pipe(
      tap((appointmentsData) => {
        const { appointments } = appointmentsData;

        const groupedAppointments = appointments ? groupAppointmentsByDayKey(appointments) : {};

        const appointmentsGroup = Object.keys(groupedAppointments).map((item, idx) => {
          return {
            isOpen: getInitialOpenStateByDayKey(item, groupedAppointments[item]),
            idx,
            day: item,
            timeslots: groupedAppointments[item],
          };
        });

        setState(
          patch<HraCareStateModel>({
            calendarAccessToken: appointmentsData.access_token,
            appointmentsAccordionGroups: appointmentsGroup,
          })
        );
      }),
      tap(() =>
        patchState({
          appointmentsListItemsLoading: false,
        })
      )
    );
  }

  @Action(GetAllAppointmentsDataAsPharmacy)
  getAllAppointmentsDataAsPharmacy(
    { setState, patchState }: StateContext<HraCareStateModel>,
    action: GetAllAppointmentsDataAsPharmacy
  ) {
    const startDate = format(action.payload.startDate, 'yyyy-MM-dd');
    const endDate = format(action.payload.endDate, 'yyyy-MM-dd');

    patchState({
      appointmentsListItemsLoading: true,
      appointmentsPeriod: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
    });
    return this.careApiService.getAllAppointmentsDataAsPharmacy(startDate, endDate).pipe(
      tap((appointments) => {
        if (!appointments) {
          setState(
            patch<HraCareStateModel>({
              appointmentsAccordionGroups: [],
            })
          );
          return;
        }

        const scheduledAppointments = appointments.filter(
          (appointment) => appointment.member_global_id !== null
        );

        const groupedAppointments = groupAppointmentsByDayKey(scheduledAppointments);

        const appointmentsGroup = Object.keys(groupedAppointments).map((item, idx) => {
          return {
            isOpen: idx === 0,
            idx,
            day: item,
            timeslots: groupedAppointments[item],
          };
        });

        setState(
          patch<HraCareStateModel>({
            appointmentsAccordionGroups: appointmentsGroup,
          })
        );
      }),
      tap(() =>
        patchState({
          appointmentsListItemsLoading: false,
        })
      )
    );
  }

  @Action(GetAppointmentsListAsPharmacy)
  getAppointmentsListAsPharmacy(
    { setState, patchState }: StateContext<HraCareStateModel>,
    action: GetAppointmentsListAsPharmacy
  ) {
    const startDate = format(action.payload.startDate, 'yyyy-MM-dd');
    const endDate = format(action.payload.endDate, 'yyyy-MM-dd');

    patchState({
      appointmentsListItemsLoading: true,
      appointmentsPeriod: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
    });
    return this.careApiService
      .getAppointmentsTimeslotsAsPharmacy(
        action.payload.actionGlobalId,
        action.payload.addressHash,
        startDate,
        endDate,
        action.payload.scheduleTelehealth,
        action.payload.NPGlobalId
      )
      .pipe(
        tap((appointments) => {
          const groupedAppointments = appointments ? groupAppointmentsByDayKey(appointments) : {};
          const appointmentsGroup = Object.keys(groupedAppointments).map((item, idx) => {
            const isDayRestrictedToCounty =
              groupedAppointments[item].length > 0
                ? groupedAppointments[item].some((appointment) => appointment.restricted_to_county)
                : false;

            const initialOpenState = getInitialOpenStateByDayKey(item, groupedAppointments[item]);
            return {
              isOpen: initialOpenState,
              idx,
              day: item,
              isDayRestrictedToCounty,
              timeslots: groupedAppointments[item],
            };
          });

          setState(
            patch<HraCareStateModel>({
              appointmentsAccordionGroups: appointmentsGroup,
            })
          );
        }),
        tap(() =>
          patchState({
            appointmentsListItemsLoading: false,
          })
        )
      );
  }

  @Action(SchedulePatientAppointmentAsPharmacy)
  schedulePatientAppointment(
    { patchState }: StateContext<HraCareStateModel>,
    action: SchedulePatientAppointmentAsPharmacy
  ) {
    patchState({ schedulingPatientLoading: true });
    return timer(500).pipe(
      mergeMap(() => {
        return this.careApiService.scheduleAppointmentForPatientAsPharmacy(
          action.payload.actionId,
          action.payload.data
        );
      }),
      catchError((errorResponse) => {
        patchState({ schedulingPatientLoading: false });

        if (errorResponse instanceof HttpErrorResponse) {
          const drivingDistanceErrorMessage =
            'The driving time is more than 30 minutes. Please choose another time slot.';

          // TODO: Check for error code instead of error message when available
          if (
            errorResponse.status === 400 &&
            errorResponseIncludesMessage(errorResponse, drivingDistanceErrorMessage)
          ) {
            return throwError(() => new ApplicationError(drivingDistanceErrorMessage));
          }
        }

        throw errorResponse;
      }),
      tap(() => {
        patchState({ schedulingPatientLoading: false });
      })
    );
  }

  @Action(GetPatientsListToBeScheduled)
  getPatientsToBeScheduled({ setState }: StateContext<HraCareStateModel>) {
    return this.careApiService.getPatientsToSchedule().pipe(
      tap((patients) =>
        setState(
          patch<HraCareStateModel>({
            patientsListToBeScheduledItems: patients,
          })
        )
      )
    );
  }

  @Action(GetNursePractitionersList)
  getNPList({ setState }: StateContext<HraCareStateModel>) {
    return this.careApiService.getNursePractitionersList().pipe(
      tap((nursePractitioners) =>
        setState(
          patch<HraCareStateModel>({
            nursePractitionersList: nursePractitioners,
          })
        )
      )
    );
  }

  @Action(AssignAppointmentToPatient)
  assignAppointmentToPatient(
    { patchState, getState }: StateContext<HraCareStateModel>,
    action: AssignAppointmentToPatient
  ) {
    const { timeslot, actionId, addressHash } = action.payload;
    const { appointmentsAccordionGroups } = getState();

    const startDate = format(getState().appointmentsPeriod.startDate, 'yyyy-MM-dd');
    const endDate = format(getState().appointmentsPeriod.endDate, 'yyyy-MM-dd');

    // Marks the selected timeslot as loading
    patchState({
      appointmentsAccordionGroups: updateTimeslotByAppointmentGroup(
        appointmentsAccordionGroups,
        timeslot,
        timeslot.start_time,
        {
          isLoading: true,
        }
      ),
    });

    // Assign the appointment to the patient, then fetch the updated appointments list
    return this.careApiService
      .assignAppointmentToPatient(timeslot.global_id, {
        actionId,
        addressHash,
      })
      .pipe(
        mergeMap(() => {
          return this.careApiService.getAppointmentsData(startDate.toString(), endDate.toString());
        }),
        tap((appointmentsData) => {
          const { appointments } = appointmentsData;
          const updatedTimeslot = appointments.find(
            (item) => item.global_id === timeslot.global_id
          );

          if (updatedTimeslot) {
            const updatedAppointments = updateTimeslotByAppointmentGroup(
              appointmentsAccordionGroups,
              updatedTimeslot,
              updatedTimeslot.start_time,
              {
                ...updatedTimeslot,
                isLoading: false,
              }
            );

            patchState({
              appointmentsAccordionGroups: updatedAppointments,
            });
          }
        })
      );
  }

  @Action(UnassignAppointmentForPatient)
  unassignAppointmentForPatient(
    { patchState, getState }: StateContext<HraCareStateModel>,
    action: UnassignAppointmentForPatient
  ) {
    const { timeslotId, reason } = action.payload;
    const { appointmentsAccordionGroups } = getState();

    const startDate = format(getState().appointmentsPeriod.startDate, 'yyyy-MM-dd');
    const endDate = format(getState().appointmentsPeriod.endDate, 'yyyy-MM-dd');

    return this.careApiService
      .unassignAppointmentToPatient(timeslotId, {
        reason,
      })
      .pipe(
        mergeMap(() => {
          return this.careApiService.getAppointmentsData(startDate.toString(), endDate.toString());
        }),
        tap((appointmentsData) => {
          const { appointments } = appointmentsData;
          const updatedTimeslot = appointments.find((item) => item.global_id === timeslotId);

          if (updatedTimeslot) {
            const updatedAppointments = updateTimeslotByAppointmentGroup(
              appointmentsAccordionGroups,
              updatedTimeslot,
              updatedTimeslot.start_time,
              updatedTimeslot
            );

            patchState({
              appointmentsAccordionGroups: updatedAppointments,
            });
          }
        })
      );
  }

  @Action(UnscheduleAppointmentForPatientAsPharmacy)
  unassignAppointmentToPatientAsPharmacy(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: UnscheduleAppointmentForPatientAsPharmacy
  ) {
    const { reason, actionId, timeslotId } = action.payload;
    const startDate = format(getState().appointmentsPeriod.startDate, 'yyyy-MM-dd');
    const endDate = format(getState().appointmentsPeriod.endDate, 'yyyy-MM-dd');
    const { appointmentsAccordionGroups } = getState();

    return this.careApiService
      .unscheduleAppointmentForPatientAsPharmacy(actionId, {
        reason,
      })
      .pipe(
        filter(() => !!timeslotId),
        mergeMap(() => {
          return this.careApiService.getAppointmentsData(startDate.toString(), endDate.toString());
        }),
        tap((appointmentsData) => {
          const { appointments } = appointmentsData;
          const updatedTimeslot = appointments.find((item) => item.global_id === timeslotId);

          if (updatedTimeslot) {
            const updatedAppointments = updateTimeslotByAppointmentGroup(
              appointmentsAccordionGroups,
              updatedTimeslot,
              updatedTimeslot.start_time,
              updatedTimeslot
            );

            patchState({
              appointmentsAccordionGroups: updatedAppointments,
            });
          }
        })
      );
  }

  @Action(NavigateToCurrentWeek)
  navigateToCurrentWeek({ patchState }: StateContext<HraCareStateModel>) {
    const currentWeekStartDate = startOfWeek(Date.now(), {
      weekStartsOn: 1,
    });
    const currentWeekEndDate = endOfWeek(Date.now(), { weekStartsOn: 1 });

    patchState({
      appointmentsPeriod: {
        startDate: currentWeekStartDate,
        endDate: currentWeekEndDate,
      },
    });
  }

  @Action(NavigateToNextWeek)
  navigateToNextWeek({ getState, patchState }: StateContext<HraCareStateModel>) {
    const { appointmentsPeriod } = getState();
    const nextWeekStartDate = addWeeks(appointmentsPeriod.startDate, 1);
    const nextWeekEndDate = endOfWeek(nextWeekStartDate, {
      weekStartsOn: 1,
    });

    patchState({
      appointmentsPeriod: {
        startDate: nextWeekStartDate,
        endDate: nextWeekEndDate,
      },
    });
  }

  @Action(NavigateToPreviousWeek)
  navigateToPrevWeek({ getState, patchState }: StateContext<HraCareStateModel>) {
    const { appointmentsPeriod } = getState();
    const previousWeekStartDate = subWeeks(appointmentsPeriod.startDate, 1);
    const previousWeekEndDate = endOfWeek(previousWeekStartDate, {
      weekStartsOn: 1,
    });

    patchState({
      appointmentsPeriod: {
        startDate: previousWeekStartDate,
        endDate: previousWeekEndDate,
      },
    });
  }

  @Action(RemoveSlotsFromDay)
  removeSlotFromDay(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: RemoveSlotsFromDay
  ) {
    const { appointmentsAccordionGroups } = getState();
    const groupKey = action.payload.groupKey;
    const group = appointmentsAccordionGroups.find((item) => item.day === groupKey);

    // Get timeslots ids to be removed (only those that are not blocked and not assigned to a patient)
    const timeslotsIdsToBeRemoved = group?.timeslots
      .filter((item) => {
        return item.blocked_at === null && item.member_global_id === null;
      })
      .map((item) => item.global_id);

    if (!timeslotsIdsToBeRemoved || timeslotsIdsToBeRemoved.length === 0) {
      return of(null);
    }

    return this.careApiService.removeSlotAvailability(timeslotsIdsToBeRemoved).pipe(
      tap(() => {
        const updatedAppointments = appointmentsAccordionGroups.map((item) => {
          if (item.day === groupKey) {
            return {
              ...item,
              timeslots: item.timeslots.map((timeslot) => {
                if (timeslotsIdsToBeRemoved.includes(timeslot.global_id)) {
                  return {
                    ...timeslot,
                    blocked_at: new Date().toISOString(),
                  };
                }
                return timeslot;
              }),
            };
          }
          return item;
        });

        patchState({
          appointmentsAccordionGroups: updatedAppointments,
        });
      })
    );
  }

  @Action(RemoveInnerSlot)
  removeInnerSlot(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: RemoveInnerSlot
  ) {
    const { appointmentsAccordionGroups } = getState();
    const { day, timeslotId } = action.payload;

    return this.careApiService.removeSlotAvailability([timeslotId]).pipe(
      tap(() => {
        const updatedAppointments = updateTimeslotByAppointmentGroup(
          appointmentsAccordionGroups,
          timeslotId,
          day,
          {
            blocked_at: new Date().toISOString(),
          }
        );

        patchState({
          appointmentsAccordionGroups: updatedAppointments,
        });
      })
    );
  }

  @Action(RestoreInnerSlot)
  restoreInnerSlot(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: RestoreInnerSlot
  ) {
    const { appointmentsAccordionGroups } = getState();
    const { day, timeslotId } = action.payload;

    return this.careApiService.restoreSlotAvailability([timeslotId]).pipe(
      tap(() => {
        const updatedAppointments = appointmentsAccordionGroups.map((group) => {
          if (group.day === day) {
            const updatedSlots = group.timeslots.map((slot) => {
              if (slot.global_id === timeslotId) {
                return {
                  ...slot,
                  blocked_at: null,
                };
              }

              return slot;
            });

            return {
              ...group,
              timeslots: updatedSlots,
            };
          }
          return group;
        });

        patchState({
          appointmentsAccordionGroups: updatedAppointments,
        });
      })
    );
  }

  @Action(ToggleAppointmentsGroup)
  toggleAppointmentsGroup(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: ToggleAppointmentsGroup
  ) {
    const { appointmentsAccordionGroups } = getState();
    const { idx, expanded } = action.payload;

    const updatedAppointments = appointmentsAccordionGroups.map((group, index) => {
      if (index === idx) {
        return {
          ...group,
          isOpen: expanded,
        };
      }
      return group;
    });

    patchState({
      appointmentsAccordionGroups: updatedAppointments,
    });
  }

  @Action(GetAppointmentActionPreview)
  getAppointmentActionPreview(
    { patchState }: StateContext<HraCareStateModel>,
    action: GetAppointmentActionPreview
  ) {
    const { actionId } = action.payload;
    patchState({
      selectedAppointmentActionPreview: undefined,
    });
    return this.actionsApiService.getSingleAction(actionId).pipe(
      tap((response) => {
        patchState({
          selectedAppointmentActionPreview: response,
        });
      })
    );
  }

  @Action(SetScheduleAppointmentAddress)
  setScheduleAppointmentAddress(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: SetScheduleAppointmentAddress
  ) {
    const { scheduleAppointmentDetails } = getState();

    patchState({
      scheduleAppointmentDetails: {
        ...scheduleAppointmentDetails,
        appointmentAddress: action.payload.address,
        isTelehealth: action.payload.isTelehealth,
      },
    });
  }

  @Action(SetScheduleAppointmentNursePractitioner)
  setScheduleAppointmentNursePractitioner(
    { patchState, getState }: StateContext<HraCareStateModel>,
    action: SetScheduleAppointmentNursePractitioner
  ) {
    const { scheduleAppointmentDetails } = getState();
    patchState({
      scheduleAppointmentDetails: {
        ...scheduleAppointmentDetails,
        assignedNP: action.payload.nursePractitioner,
      },
    });
  }

  @Action(UpdateScheduleLocationModalState)
  updateScheduleLocationModalState(
    { getState, patchState }: StateContext<HraCareStateModel>,
    action: UpdateScheduleLocationModalState
  ) {
    const currentState = getState();

    patchState({
      scheduleLocationModalState: {
        ...currentState.scheduleLocationModalState,
        ...action.payload,
      },
    });
  }

  @Action(GetMemberHRAItems)
  getMemberHRAItems({ patchState }: StateContext<HraCareStateModel>, action: GetMemberHRAItems) {
    patchState({
      memberHraItems: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.careApiService.getMemberHraItems(action.payload.memberGlobalId).pipe(
      tap((hraItems) => {
        patchState({
          memberHraItems: {
            result: hraItems,
            loadingStatus: LoadingState.LOADED,
          },
        });
      })
    );
  }

  @Action(AddMemberHraAddendum)
  addMemberHraAddendum(
    { patchState, getState }: StateContext<HraCareStateModel>,
    action: AddMemberHraAddendum
  ) {
    const { memberHraItems } = getState();

    if (!memberHraItems.result) {
      return;
    }

    patchState({
      addingMemberHraAddendum: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.careApiService
      .addMemberHraAddendum(
        action.payload.memberGlobalId,
        action.payload.hraId,
        action.payload.content
      )
      .pipe(
        tap((addendum) => {
          if (!memberHraItems.result) {
            return;
          }

          patchState({
            addingMemberHraAddendum: {
              result: null,
              loadingStatus: LoadingState.LOADED,
            },
            memberHraItems: {
              result: memberHraItems.result.map((item) => {
                if (item.id === action.payload.hraId) {
                  return {
                    ...item,
                    addendums: [addendum, ...item.addendums],
                  } as MemberHra;
                }
                return item;
              }),
              loadingStatus: memberHraItems.loadingStatus,
            },
          });
        })
      );
  }

  @Action(GetMemberHRAPreviewURL)
  getMemberHRAPreviewURL(
    { patchState }: StateContext<HraCareStateModel>,
    action: GetMemberHRAPreviewURL
  ) {
    patchState({
      singleMemberHraItemPreview: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.patientsFilesApiService
      .getFileSignedURL(action.payload.memberGlobalId, action.payload.fileId)
      .pipe(
        tap((signedURL) => {
          patchState({
            singleMemberHraItemPreview: {
              result: {
                previewSignedURL: signedURL,
              },
              loadingStatus: LoadingState.LOADED,
            },
          });
        })
      );
  }
}
