import { Pipe, PipeTransform } from '@angular/core';
import { PatientBase } from '@troyai/patients/data-access';

@Pipe({
  name: 'memberId',
  standalone: true,
})
export class MemberIdPipe implements PipeTransform {
  transform(value: PatientBase): string {
    return value.member_id_original || value.member_id;
  }
}
