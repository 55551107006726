import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { IconsModule } from '../icon/icon.module';

@Component({
  selector: 't-accordion',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule, IconsModule],
  templateUrl: './accordion.component.html',
})
export class AccordionComponent {}
