import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';

import { Action } from '@troyai/actions/data-access';
import { ExpirationDatePipe } from '@troyai/portal/common/pipes';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { IconsModule, LabelComponent } from '@troyai/ui-kit';

type LayoutVariants = 'default' | 'compact';
type AlignItemsOptions = 'start' | 'center';
type ThumbnailSizes = 'small' | 'medium' | 'large';

@Component({
  selector: 't-action-single-header',
  standalone: true,
  imports: [
    CommonModule,
    LabelComponent,
    IconsModule,
    ExpirationDatePipe,
    ImageFallbackDirective,
    NgOptimizedImage,
  ],
  templateUrl: './action-single-header.component.html',
})
export class ActionSingleHeaderComponent {
  @Input({ required: true }) action!: Pick<
    Action,
    'fee' | 'due_date' | 'name' | 'fee_schedule_image' | 'status'
  >;
  @Input() layoutVariant: LayoutVariants = 'default';
  @Input() alignItems: AlignItemsOptions = 'start';
  @Input() thumbnailSize: ThumbnailSizes = 'medium';

  thumbnailSizeMapping: Record<ThumbnailSizes, number> = {
    small: 56,
    medium: 64,
    large: 72,
  };
}
