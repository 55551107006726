import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { ActionMessage } from '@troyai/actions/data-access';
import { UserState } from '@troyai/auth/data-access';
import { CustomMediumDatePipe, LinkifyPipe, SanitizeHtmlPipe } from '@troyai/portal/common/pipes';
import { IconsModule } from '@troyai/ui-kit';

@Component({
  selector: 't-messages-list',
  standalone: true,
  imports: [CommonModule, CustomMediumDatePipe, IconsModule, LinkifyPipe, SanitizeHtmlPipe],
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MessagesListComponent {
  constructor(private store: Store) {}

  user$ = this.store.select(UserState.user);
  @Input() items: ActionMessage[] = [];

  isTeamUser(email?: string) {
    if (!email) return false;
    return email.endsWith('@troy.ai') || email.endsWith('@troymedicare.com');
  }
}
