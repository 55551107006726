import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { DashboardTourComponent } from '../dashboard-tour/dashboard-tour.component';
import { CommonModule } from '@angular/common';
import { TourSegmentsName } from '../../models/completion-status.interface';
import { PatientsTourComponent } from '../patients-tour/patients-tour.component';
import { ActionsTourComponent } from '../actions-tour/actions-tour.component';
import { SinglePatientTourComponent } from '../single-patient-tour/single-patient-tour.component';
import { SingleActionTourComponent } from '../single-action-tour/single-action-tour.component';
import { Store } from '@ngxs/store';
import { UpdateUserSettings } from '@troyai/auth/data-access';
import { map } from 'rxjs';
import { GuidedTourService } from '../../guided-tour.service';
import { AdherenceTourComponent } from '../adherence-tour/adherence-tour.component';

@Component({
  selector: 't-guided-tour',
  standalone: true,
  imports: [
    DashboardTourComponent,
    CommonModule,
    PatientsTourComponent,
    ActionsTourComponent,
    SinglePatientTourComponent,
    SingleActionTourComponent,
    AdherenceTourComponent,
  ],
  templateUrl: './guided-tour.component.html',
  styleUrls: ['./guided-tour.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GuidedTourComponent implements OnInit {
  constructor(
    private store: Store,
    private guidedTourService: GuidedTourService
  ) {}

  isTourActive$ = this.store
    .select((state) => state.user?.userDetails?.settings?.flags?.onboarding_tour_completed)
    .pipe(
      map((flag) => {
        return !flag;
      })
    );
  @Input() tourName?: TourSegmentsName;

  ngOnInit() {
    // Reset tour completion flag if the tour segments have changed in LocalStorage
    const tourSegmentsDiff = this.guidedTourService.syncTourSegments();
    if (tourSegmentsDiff) {
      this.store.dispatch(
        new UpdateUserSettings({
          onboarding_tour_completed: false,
        })
      );
    }
  }
}
