import { Action } from '@troyai/actions/data-access';
import { AdherencePatientMeasure } from '@troyai/adherence/data-access';
import { Medication } from '@troyai/medication/medication-data-access';
import { Patient, PatientEncounter, PatientListItem } from '@troyai/patients/data-access';
import { PriorAuthorizationItem } from '@troyai/prior-authorization/data-access';
import {
  getRandomBoolean,
  getRandomDate,
  getRandomFirstName,
  getRandomGender,
  getRandomLastName,
  getRandomMedication,
  getRandomMemberId,
  getRandomUSPhoneNumber,
} from './fake-data.util';

export const PII_ENDPOINTS_REPLACEMENT_CONFIG = [
  {
    url: '/api/v1/members',
    urlRegex: /^\/api\/v(\d+)\/members$/,
    replacer: (data: PatientListItem[]) => {
      return data.map((item) => {
        return {
          ...item,
          first_name: getRandomFirstName(),
          last_name: getRandomLastName(),
          dob: getRandomDate(),
          gender: getRandomGender(),
          member_id: getRandomMemberId(),
        } as PatientListItem;
      });
    },
  },
  {
    url: '/api/v1/members/:id',
    urlRegex: /^\/api\/v(\d+)\/members\/(\d+)$/,
    replacer: (data: Patient) => {
      return {
        ...data,
        first_name: getRandomFirstName(),
        last_name: getRandomLastName(),
        dob: getRandomDate(),
        gender: getRandomGender(),
        member_id: getRandomMemberId(),
        beneficiary_id: getRandomMemberId(),
        pcp: {
          ...data.pcp,
          name: getRandomFirstName() + ' ' + getRandomLastName(),
        },
        care_manager: {
          name: getRandomFirstName() + ' ' + getRandomLastName(),
          phone: getRandomUSPhoneNumber(),
        },
        member_address: [
          {
            county: 'Robeson',
            state: 'NC',
            city: 'Lumberton',
            zip_code: '28358',
            address_line_1: '123 Main St',
            address_type: 'Physical',
          },
        ],
        member_phone: [
          {
            phone_number: getRandomUSPhoneNumber(),
            phone_type: 'primary',
          },
        ],
        encounters: [
          {
            id: 5670,
            facility: 'Emerald Bay Medical Center',
            facility_type: 'HOS',
            event_code: 'A03',
            discharge_time: '2023-05-11T21:05:00+03:00',
            diagnosis:
              'Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm, initial encounter',
            encounter_time: '2023-05-11T16:40:00+03:00',
          },
          {
            id: 7661,
            facility: 'Emerald Bay Medical Center',
            facility_type: 'HOS',
            event_code: 'A03',
            discharge_time: '2023-06-21T15:04:00+03:00',
            diagnosis: 'Pain in left knee',
            encounter_time: '2023-06-21T12:01:00+03:00',
          },
          {
            id: 9317,
            facility: 'Emerald Bay Medical Center',
            facility_type: 'HOS',
            event_code: 'A03',
            discharge_time: '2023-07-28T19:41:00+03:00',
            diagnosis: 'Chest pain, unspecified',
            encounter_time: '2023-07-27T12:28:00+03:00',
          },
        ],
      } as Patient;
    },
  },
  {
    url: '/api/v1/actions',
    urlRegex: /^\/api\/v(\d+)\/actions$/,
    replacer: (data: Action[]) => {
      return data.map((item) => {
        return {
          ...item,
          member: {
            first_name: getRandomFirstName(),
            last_name: getRandomLastName(),
            dob: getRandomDate(),
            full_name: getRandomFirstName() + ' ' + getRandomLastName(),
            middle_name: '',
          },
        } as Action;
      });
    },
  },
  {
    url: '/api/v1/actions/:id',
    urlRegex: /^\/api\/v(\d+)\/actions\/(\d+)$/,
    replacer: (data: Action) => {
      return {
        ...data,
        appointment_addresses: data.appointment_addresses?.map((item) => {
          return {
            ...item,
            address_street: '123 MAIN ST',
            address_city: 'ST ANDREW',
            address_county: 'ROBESON',
            address_state: 'North Carolina',
            address_zip: '22321',
          };
        }),
        scheduled_address: {
          ...data.scheduled_address,
          address_street: '123 MAIN ST',
          address_city: 'ST ANDREW',
          address_county: 'ROBESON',
          address_state: 'North Carolina',
          address_zip: '22321',
        },
      } as Action;
    },
  },
  {
    url: '/api/v1/members/:id/encounters',
    urlRegex: /^\/api\/v(\d+)\/members\/(\d+)\/encounters$/,
    replacer: (data: PatientEncounter[]) => {
      return data.map((item) => {
        return {
          ...item,
          facility: 'Emerald Bay Medical Center',
          discharge_time: '2023-09-12T12:27:00+03:00',
          diagnosis: 'Anemia, unspecified',
          encounter_time: '2023-09-10T14:07:00+03:00',
        } as PatientEncounter;
      });
    },
  },
  {
    url: '/api/v1/members/:id/med-recs',
    urlRegex: /^\/api\/v(\d+)\/members\/(\d+)\/med-recs$/,
    replacer: (data: Medication[]) => {
      return data.map((item) => {
        const randomMed = getRandomMedication();
        return {
          ...item,
          drug_name: randomMed.name,
          drug_quantity: 0,
          drug_ndc: '',
          prescriber: `Dr. ${getRandomFirstName()} ${getRandomLastName()}`,
          dosage: randomMed.dosage,
          disease: 'Glaucoma',
          active: getRandomBoolean(),
          notes: '',
          created_by: 'CMR Sheet Import',
          created_on: '2024-01-26T09:06:26Z',
          archived_on: null,
          archived_by: null,
          last_modified_by: 'marius@troymedicare.com',
          last_modified_on: '2024-03-04T19:20:05Z',
        } as Medication;
      });
    },
  },

  {
    url: '/api/v1/adherence/:id/members/:measureId',
    urlRegex: /^\/api\/v(\d+)\/adherence\/(\d+)\/members\/([^/]+)$/,
    replacer: (data: AdherencePatientMeasure[]) => {
      return data.map((item) => {
        return {
          ...item,
          first_name: getRandomFirstName(),
          last_name: getRandomLastName(),
        } as AdherencePatientMeasure;
      });
    },
  },
  {
    url: '/api/v1/prior-authorizations',
    urlRegex: /^\/api\/v(\d+)\/prior-authorizations(?:\?latest=(\d+))?$/,
    replacer: (data: PriorAuthorizationItem[]) => {
      return data.map((item) => {
        return {
          ...item,
          member_dob: getRandomDate(),
          member_name: getRandomFirstName() + ' ' + getRandomLastName(),
          req_medication: getRandomMedication().name,
        } as PriorAuthorizationItem;
      });
    },
  },
];
