<ng-container *ngIf="actionsListLoadingStatus$ | async as actionsListLoadingStatus">
  <t-content-wrapper-layout>
    <t-section headerTitle="Actions">
      <div
        *ngIf="actionsListLoadingStatus.loaded"
        sectionControls
        class="mt-5 flex w-full justify-between space-x-2 md:mt-0 md:w-auto lg:justify-end">
        <div class="relative w-full lg:w-auto">
          <t-search-input
            id="tour-actions-search-input"
            optionsMenuId="tour-actions-filters"
            class="w-full"
            [ngModel]="searchQuery"
            (ngModelChange)="searchQueryChange($event)"
            [hasOptions]="true"
            [optionsDropdown]="filtersDropdown"></t-search-input>

          <t-popover verticalAlign="below" horizontalAlign="end" #filtersDropdown hasBackdrop>
            <t-data-source-options
              class="mt-2 block min-w-[230px]"
              (filtersChange)="onFiltersChange($event)"
              [sortingOptions]="availableSortingOptions"
              (sortingChange)="onSortingChange($event)"
              [filters]="availableFilters"></t-data-source-options>
          </t-popover>
        </div>

        <div class="relative">
          <t-dropdown-button
            [tPopoverAnchor]="additionalActions"
            (click)="additionalActions.toggle()" />

          <t-popover verticalAlign="below" horizontalAlign="end" #additionalActions hasBackdrop>
            <t-dropdown-menu>
              <t-dropdown-menu-item
                (click)="additionalActions.close(); openPrintActionsModal()"
                iconKey="print">
                Print
              </t-dropdown-menu-item>
            </t-dropdown-menu>
          </t-popover>
        </div>
      </div>

      <div *ngIf="actionsListLoadingStatus.loaded" class="mb-4">
        <t-active-filter-summary
          id="tour-actions-filters-summary"
          [filters]="availableFilters"
          (filtersChange)="onFiltersChange($event)"></t-active-filter-summary>
      </div>

      <ng-container *ngIf="actionsListLoadingStatus.loaded">
        <ng-container *ngIf="data.page$ | async as page">
          <t-listing-sorting-headings
            *ngIf="page.totalElements > 0"
            class="hidden lg:block"
            [displayTailPlaceholder]="true"
            [sortingOptions]="availableSortingOptions"
            (sortingChange)="onSortingChange($event)"></t-listing-sorting-headings>

          <t-actions-list
            *ngIf="page.totalElements > 0"
            [showMemberDetails]="true"
            [selectedPharmacyId]="selectedPharmacyId$ | async"
            [actions]="page.items"></t-actions-list>

          <div
            *ngIf="page.totalElements === 0"
            class="bg-grey-60 relative flex min-h-[400px] flex-col items-center justify-center bg-opacity-5 text-center">
            <t-empty-state-card
              class="block max-w-xs"
              content="There are no actions that match the filters you’ve used. Please try to change the filters or use the clear all option."
              [hasIcon]="true">
              <t-link variant="dark" [isButton]="true" (click)="removeAllFilters()">
                Clear all filters
              </t-link>
            </t-empty-state-card>
          </div>

          <t-paginator
            class="mt-5 block"
            *ngIf="page.totalElements > 0"
            [collectionSize]="page.totalElements"
            [currentPage]="page.pageNumber"
            [pageSize]="pageSize"
            [firstLastButtons]="false"
            (pageChanged)="data.fetch($event.pageNumber)"
            [maxSize]="2" />
        </ng-container>
      </ng-container>

      <t-skeleton-loader
        *ngIf="actionsListLoadingStatus.loading"
        color="dark"
        rowsHeight="huge"
        [rows]="pageSize" />

      <t-error-state *ngIf="actionsListLoadingStatus.errored" titleText="There was an error">
        {{ (actionsListErrorState$ | async)?.message }}
      </t-error-state>
    </t-section>
  </t-content-wrapper-layout>
</ng-container>

<t-guided-tour tourName="actions" *tIfFeatureFlag="'guided-tour'"></t-guided-tour>
