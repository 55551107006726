<div class="bg-grey-10 flex h-screen w-full items-center justify-center">
  <div class="flex h-full w-full rounded md:container md:h-[420px] lg:w-[790px]">
    <!-- Left side-->
    <!-- TODO: Remove hardcoded tenant information -->
    <div
      class="flex h-full w-full flex-col justify-between p-8 md:w-1/2"
      [ngClass]="{
        'custom-bg-troy': tenantId === 'troy',
        'custom-bg-united': tenantId === 'united'
      }">
      <div class="flex items-end justify-end">
        <div>
          @if (tenantId === 'united') {
            <img
              src="https://storage.googleapis.com/troyai-public-assets/troy.ai/themes/uhg_logo.svg" />
          } @else {
            <img src="/assets/troy-logo-full.svg" />
          }

          @if (tenantId !== 'troy') {
            <div class="border-grey-20 mt-2 border-t border-opacity-20 pt-2">
              <span class="block text-xs text-white opacity-40">Powered by Troy.AI</span>
            </div>
          }
        </div>
      </div>

      <div>
        <div class="mb-5 p-2">
          <h1 class="mb-1 text-base font-semibold text-white">{{ title }}</h1>
          <ng-content select="[description]" />
        </div>

        <ng-content select="[cta]" />
      </div>
    </div>

    <!-- /Left side-->

    <!-- Right side -->
    <div class="shadow-small hidden flex-col items-center justify-center bg-white md:flex md:w-1/2">
      <p class="text-grey-100 mb-8 block w-[208px] text-center text-base">
        We’re here to help. Contact us at <strong class="font-semibold">910 460-0553</strong> if you
        have any questions.
      </p>
      <img src="/assets/media-call-center.svg" />
    </div>
    <!-- /Right side -->
  </div>
</div>
