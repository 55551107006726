<div class="flex items-center justify-between">
  <div class="text-grey-60 text-xs">
    <span *ngIf="updateTime">
      <strong class="font-semibold">{{ updateText }}</strong>
      {{ updateTime | dateDistance }}
    </span>
  </div>
  <a [routerLink]="href" [queryParams]="params" class="text-grey-50 text-xs">
    <t-button variant="neutral" size="tiny">{{ buttonText }}</t-button>
  </a>
</div>
