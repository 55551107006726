import { FeatureFlags } from '@troyai/envs';
import { UserPharmacy } from '../models/user-pharmacy.interface';

export const isPharmacyOnboarded = (
  pharmacy: UserPharmacy,
  onboardingFeatureFlags?: Partial<FeatureFlags>
) => {
  // Default return for evaluating if a pharmacy is onboarded, when no feature flags are specified
  if (!onboardingFeatureFlags) {
    return pharmacy.completed_baa_onboarding && pharmacy.completed_financial_onboarding;
  }

  // Consider onboarding step as completed, if the feature is disabled
  const completedBAAOnboarding = onboardingFeatureFlags?.['baa-onboarding-required']
    ? pharmacy.completed_baa_onboarding
    : true;
  const completedFinancialOnboarding = onboardingFeatureFlags?.['financial-onboarding-required']
    ? pharmacy.completed_financial_onboarding
    : true;

  return completedBAAOnboarding && completedFinancialOnboarding;
};
