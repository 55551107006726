import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../button/button.component';

export interface NavigatorTimeInterval {
  startDate: Date;
  endDate: Date;
}

export type WeekNavigationTarget = 'next' | 'previous' | 'current';

@Component({
  selector: 't-week-navigator',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './week-navigator.component.html',
})
export class WeekNavigatorComponent {
  @Input() timePeriod: NavigatorTimeInterval | null = {
    startDate: new Date(),
    endDate: new Date(),
  };

  @Output() weekChange = new EventEmitter<WeekNavigationTarget>();

  changeWeek(target: WeekNavigationTarget) {
    this.weekChange.emit(target);
  }
}
