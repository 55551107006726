import { Injectable } from '@angular/core';
import Shepherd from 'shepherd.js';
import Step from 'shepherd.js/src/types/step';
import { elementIsHidden, makeButton } from './guided-tour.util';
import { CompletionStatus, TourSegmentsName } from './models/completion-status.interface';
import { Store } from '@ngxs/store';
import { UpdateUserSettings } from '@troyai/auth/data-access';
import { defaultTourSegments } from './guided-tour.config';
import { TrackService } from '@troyai/tracking';

@Injectable({
  providedIn: 'root',
})
export class GuidedTourService {
  constructor(
    private store: Store,
    private trackService: TrackService
  ) {}

  confirmCancel = false;
  confirmCancelMessage: string | null = null;
  defaultStepOptions: Step.StepOptions = {};
  errorTitle = null;
  isActive = false;
  keyboardNavigation = true;
  messageForUser: string | null = null;
  modal = false;
  requiredElements = [];
  tourName?: TourSegmentsName = undefined;
  tourObject: Shepherd.Tour | null = null;

  public localStorageTourKey = 'troyai-guided-tour-status';

  /**
   * Get the tour object and call back
   */
  back() {
    if (!this.tourObject) {
      return;
    }
    this.tourObject.back();
  }

  /**
   * Cancel the tour
   */
  cancel() {
    if (!this.tourObject) {
      return;
    }
    this.tourObject.cancel();
  }

  /**
   * Complete the tour
   */
  complete() {
    if (!this.tourObject) {
      return;
    }
    this.tourObject.complete();
  }

  /**
   * Hides the current step
   */
  hide() {
    if (!this.tourObject) {
      return;
    }
    this.tourObject.hide();
  }

  /**
   * Advance the tour to the next step
   */
  next() {
    if (!this.tourObject) {
      return;
    }
    this.tourObject.next();
  }

  /**
   * Show a specific step, by passing its id
   * @param id The id of the step you want to show
   */
  show(id: string | number) {
    if (!this.tourObject) {
      return;
    }
    this.tourObject.show(id);
  }

  /**
   * Start the tour
   */
  start() {
    this.isActive = true;
    if (!this.tourObject) {
      return;
    }
    this.tourObject.start();
  }

  /**
   * This function is called when a tour is completed or cancelled to initiate cleanup.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTourFinish(_completeOrCancel: 'complete' | 'cancel') {
    const tourName = this.getTourName();
    if (!tourName) {
      return;
    }

    this.setLocalStorageCompletionStatusByName(tourName, true);

    this.trackService.trackEvent({
      eventName: 'Tour segment finished',
      eventProps: {
        tour_segment_name: tourName,
        finished_by: _completeOrCancel,
      },
    });

    // If all tours are completed, set the onboarding_tour_completed flag to true
    const updatedCompletionStatus = this.getLocalStorageCompletionStatus();
    const allToursCompleted = Object.values(updatedCompletionStatus).every(
      (value) => value === true
    );

    if (allToursCompleted) {
      this.store.dispatch(new UpdateUserSettings({ onboarding_tour_completed: true }));
    }

    this.isActive = false;
  }

  getTourName() {
    // the complete tour name is the tour name + UUID, separated by a double dash
    return this.tourName?.split('--')[0] as TourSegmentsName;
  }

  getLocalStorageCompletionStatus() {
    const completionStatus = localStorage.getItem(this.localStorageTourKey);
    if (!completionStatus) {
      return false;
    }

    try {
      return JSON.parse(completionStatus) as CompletionStatus;
    } catch {
      throw new Error('Unable to parse completion status from local storage');
    }
  }

  setLocalStorageCompletionStatus(completionStatus: CompletionStatus) {
    localStorage.setItem(this.localStorageTourKey, JSON.stringify(completionStatus));
  }

  getLocalStorageCompletionStatusByName(tourName: TourSegmentsName) {
    const completionStatus = this.getLocalStorageCompletionStatus();
    if (!completionStatus || !completionStatus[tourName]) {
      return false;
    }

    return completionStatus[tourName];
  }

  setLocalStorageCompletionStatusByName(tourName: TourSegmentsName, status: boolean) {
    const completionStatus = this.getLocalStorageCompletionStatus();
    if (!completionStatus) {
      return false;
    }

    const newCompletionStatus = { ...completionStatus, [tourName]: status };

    this.setLocalStorageCompletionStatus(newCompletionStatus);
    return completionStatus;
  }

  /**
   * Take a set of steps and create a tour object based on the current configuration
   * @param steps An array of steps
   */
  addSteps(steps: Array<Step.StepOptions>) {
    this._initialize();
    const tour = this.tourObject;

    // Return nothing if there are no steps
    if (!steps || !Array.isArray(steps) || steps.length === 0) {
      return;
    }

    if (!this.requiredElementsPresent()) {
      if (!tour) {
        return;
      }
      tour.addStep({
        buttons: [
          {
            text: 'Exit',
            action: tour.cancel,
          },
        ],
        id: 'error',
        title: this.errorTitle || 'Error',
        text: [this.messageForUser || ''],
      });
      return;
    }

    steps.forEach((step) => {
      if (!tour) {
        return;
      }
      if (step.buttons) {
        step.buttons = step.buttons.map(makeButton.bind(this), this);
      }

      tour.addStep(step);
    });
  }

  /**
   * Observes the array of requiredElements, which are the elements that must be present at the start of the tour,
   * and determines if they exist, and are visible, if either is false, it will stop the tour from executing.
   */
  private requiredElementsPresent() {
    let allElementsPresent = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.requiredElements.forEach((element: any) => {
      const selectedElement = document.querySelector(element.selector);

      if (allElementsPresent && (!selectedElement || elementIsHidden(selectedElement))) {
        allElementsPresent = false;
        this.errorTitle = element.title;
        this.messageForUser = element.message;
      }
    });

    return allElementsPresent;
  }

  /**
   * Check the default tour segments against the local storage completion status, and if there are any
   * segments that are not present in the completion status, add them to the completion status and return them
   */
  syncTourSegments() {
    const completionStatus = this.getLocalStorageCompletionStatus() || {};

    const localStorageCompletionsKeys = Object.keys(completionStatus);
    const defaultCompletionKeys = Object.keys(defaultTourSegments);

    const diff = defaultCompletionKeys.filter((key) => {
      return !localStorageCompletionsKeys.includes(key);
    }) as TourSegmentsName[];

    if (diff.length > 0) {
      const newCompletionStatusSegments = diff.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as CompletionStatus);

      const newCompletionStatus = {
        ...completionStatus,
        ...newCompletionStatusSegments,
      };
      this.setLocalStorageCompletionStatus(newCompletionStatus);

      return newCompletionStatusSegments;
    }

    return false;
  }

  /**
   * Initializes the tour, creates a new Shepherd.Tour. sets options, and binds events
   */
  private _initialize() {
    const completionStatus = this.getLocalStorageCompletionStatus();
    if (!completionStatus) {
      this.setLocalStorageCompletionStatus(defaultTourSegments);
    }

    const tourObject = new Shepherd.Tour({
      confirmCancel: this.confirmCancel,
      confirmCancelMessage: this.confirmCancelMessage || 'Are you sure you want to stop the tour?',
      defaultStepOptions: this.defaultStepOptions,
      keyboardNavigation: this.keyboardNavigation,
      tourName: this.tourName,
      useModalOverlay: this.modal,
    });

    tourObject.on('complete', this.onTourFinish.bind(this, 'complete'));
    tourObject.on('cancel', this.onTourFinish.bind(this, 'cancel'));

    this.tourObject = tourObject;
  }
}
