import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import { ActionsState, GetActionMessages, SendActionMessage } from '@troyai/actions/data-access';
import { CardComponent, IconsModule, SkeletonLoaderComponent } from '@troyai/ui-kit';
import { MessageFormComponent } from '../message-form/message-form.component';
import { MessagesListComponent } from '../messages-list/messages-list.component';

@Component({
  selector: 't-chat-window',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    MessageFormComponent,
    MessagesListComponent,
    CardComponent,
    SkeletonLoaderComponent,
  ],
  templateUrl: './chat-window.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWindowComponent implements AfterViewInit, OnInit, OnDestroy {
  constructor(
    private store: Store,
    private actions$: Actions,
    private changeDetector: ChangeDetectorRef
  ) {}

  @ViewChild('messagesList') $messagesList!: ElementRef;
  private ngAfterViewInitSubject$ = new Subject<boolean>();

  destroy$ = new Subject<boolean>();
  action$ = this.store.select(ActionsState.selectedAction);
  messages$ = this.store.select(ActionsState.getMessages).pipe(
    filter((messages) => messages !== undefined),
    map((messages) => {
      return [...messages].sort((a, b) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      });
    })
  );
  messagesLoading$ = this.store.select(ActionsState.messagesLoading);

  ngAfterViewInit() {
    this.ngAfterViewInitSubject$.next(true);
  }

  async ngOnInit() {
    this.action$
      .pipe(
        distinctUntilChanged(),
        tap((action) => {
          this.store.dispatch(new GetActionMessages({ globalId: action?.global_id }));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.actions$
      .pipe(ofActionSuccessful(SendActionMessage), takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => {
          this.$messagesList.nativeElement.scroll({
            top: this.$messagesList.nativeElement.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      });

    combineLatest([this.messages$, this.ngAfterViewInitSubject$])
      .pipe(take(1))
      .subscribe(() => {
        this.changeDetector.detectChanges();
        setTimeout(() => {
          this.$messagesList.nativeElement.scroll({
            top: this.$messagesList.nativeElement.scrollHeight,
            behavior: 'auto',
          });
        }, 100);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
