import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { PaymentsState } from '@troyai/payments/data-access';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import { addMonths, minutesToMilliseconds, startOfMonth, subYears } from 'date-fns';
import { catchError, tap, throwError } from 'rxjs';
import { ActivityApiService } from '../activity.api-service';
import { generateActivityTimeChartData } from '../helpers/generate-activity-time-chart-data.util';
import { StatsTimeInterval } from '../models/pharmacy-stats.interface';
import { ActivityStateModel } from './activity-state.model';
import {
  GetPharmacyActionsStats,
  GetPharmacyCompletedOfAssignedStats,
  GetPharmacyEngagementRatesStats,
  GetPharmacyEngagementStats,
  InitializeDashboardWidgetsPharmacyStatsData,
  PopulatePharmacyStats,
} from './activity.actions';

const stateDefaults: ActivityStateModel = {
  activityTimeChartItems: [],
  activityTimeChartItemsLoading: true,
  actionsStats: null,
  actionsStatsLoading: true,
  stats: null,
  statsLoading: true,
  lastUpdated: null,

  engagementRatesWidget: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },

  completedOfAssignedWidget: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },

  engagementWidget: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
};

@State<ActivityStateModel>({
  name: 'activityState',
  defaults: stateDefaults,
  children: [PaymentsState],
})
@Injectable()
export class ActivityState {
  constructor(private activityApiService: ActivityApiService) {}

  @Selector()
  static activityTimeChartItems(state: ActivityStateModel) {
    return state.activityTimeChartItems;
  }

  @Selector()
  static activityTimeChartItemsLoading(state: ActivityStateModel) {
    return state.activityTimeChartItemsLoading;
  }

  @Selector()
  static stats(state: ActivityStateModel) {
    return state.stats;
  }

  @Selector()
  static statsLoading(state: ActivityStateModel) {
    return state.statsLoading;
  }

  @Selector()
  static actionsStats(state: ActivityStateModel) {
    return state.actionsStats;
  }

  @Selector()
  static actionsStatsLoading(state: ActivityStateModel) {
    return state.actionsStatsLoading;
  }

  @Selector()
  static lastUpdated(state: ActivityStateModel) {
    return state.lastUpdated;
  }

  @Selector()
  static engagementRatesWidget(state: ActivityStateModel) {
    return state.engagementRatesWidget.result;
  }

  @Selector()
  static engagementRatesWidgetLoadingState(state: ActivityStateModel) {
    return getLoadingStatus(state.engagementRatesWidget.loadingStatus);
  }

  @Selector()
  static engagementWidget(state: ActivityStateModel) {
    return state.engagementWidget.result;
  }

  @Selector()
  static engagementWidgetLoadingState(state: ActivityStateModel) {
    return getLoadingStatus(state.engagementWidget.loadingStatus);
  }

  @Selector()
  static completedOfAssignedWidget(state: ActivityStateModel) {
    return state.completedOfAssignedWidget.result;
  }

  @Selector()
  static completedOfAssignedWidgetLoadingState(state: ActivityStateModel) {
    return getLoadingStatus(state.completedOfAssignedWidget.loadingStatus);
  }

  @Action(PopulatePharmacyStats, { cancelUncompleted: true })
  getStats({ patchState }: StateContext<ActivityStateModel>, action: PopulatePharmacyStats) {
    patchState({
      statsLoading: true,
      stats: null,
      activityTimeChartItemsLoading: true,
      activityTimeChartItems: [],
      lastUpdated: null,
    });

    const { pharmacyId, timeInterval } = action.payload;
    const chartEndDate = new Date();
    // One year ago, starting with the first day of the following month
    const chartStartDate = subYears(addMonths(startOfMonth(new Date()), 1), 1);

    if (pharmacyId) {
      return this.activityApiService
        .getPharmacyStatsByPharmacyId(pharmacyId, timeInterval || StatsTimeInterval.ALL, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((stats) => {
            if (!stats.completed_actions_dates) {
              return patchState({
                stats: undefined,
                statsLoading: false,
                activityTimeChartItemsLoading: false,
              });
            }

            const completedActionsDates = stats.completed_actions_dates[0].dates;
            const sortedCompletedActionsDates = completedActionsDates
              ? completedActionsDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
              : [];

            return patchState({
              stats: stats.action_stats[0],
              statsLoading: false,
              activityTimeChartItemsLoading: false,
              activityTimeChartItems: generateActivityTimeChartData(
                sortedCompletedActionsDates,
                chartStartDate,
                chartEndDate
              ),
              lastUpdated: sortedCompletedActionsDates[sortedCompletedActionsDates.length - 1],
            });
          })
        );
    }

    return this.activityApiService
      .getAllAssociatedPharmaciesStats(timeInterval || StatsTimeInterval.ALL, {
        ttl: minutesToMilliseconds(10),
      })
      .pipe(
        tap((stats) => {
          if (!stats.completed_actions_dates) {
            return;
          }

          const allCompletedActionsDates = stats.completed_actions_dates
            .reduce((acc, completedDateEntry) => {
              return [...acc, ...(completedDateEntry.dates || [])];
            }, [] as string[])
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

          const activityChartItems = generateActivityTimeChartData(
            allCompletedActionsDates,
            chartStartDate,
            chartEndDate
          );

          patchState({
            stats: stats.action_stats[0],
            statsLoading: false,
            activityTimeChartItems: activityChartItems,
            activityTimeChartItemsLoading: false,
            lastUpdated: allCompletedActionsDates[allCompletedActionsDates.length - 1],
          });
        })
      );
  }

  @Action(GetPharmacyActionsStats, { cancelUncompleted: true })
  getActionsStats(
    { patchState }: StateContext<ActivityStateModel>,
    action: GetPharmacyActionsStats
  ) {
    patchState({
      actionsStatsLoading: true,
      actionsStats: null,
    });

    const { pharmacyId, timeInterval } = action.payload;

    if (pharmacyId) {
      return this.activityApiService
        .getPharmacyStatsByPharmacyId(pharmacyId, timeInterval, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((stats) => {
            const allStats = stats.action_stats[0];
            patchState({
              actionsStats: {
                completed_actions: allStats.completed_actions,
                new_actions: allStats.new_actions,
                action_completion_rate: allStats.action_completion_rate,
                total_assigned_actions: allStats.total_assigned_actions,
              },
              actionsStatsLoading: false,
            });
          })
        );
    }

    return this.activityApiService
      .getAllAssociatedPharmaciesStats(timeInterval, {
        ttl: minutesToMilliseconds(10),
      })
      .pipe(
        tap((stats) => {
          const allStats = stats.action_stats[0];
          patchState({
            actionsStats: {
              completed_actions: allStats.completed_actions,
              new_actions: allStats.new_actions,
              action_completion_rate: allStats.action_completion_rate,
              total_assigned_actions: allStats.total_assigned_actions,
            },
            actionsStatsLoading: false,
          });
        })
      );
  }

  @Action(GetPharmacyEngagementRatesStats, { cancelUncompleted: true })
  getEngagementRatesStats(
    { patchState }: StateContext<ActivityStateModel>,
    action: GetPharmacyEngagementRatesStats
  ) {
    const { pharmacyId, timeInterval } = action.payload;

    if (pharmacyId) {
      patchState({
        engagementRatesWidget: {
          result: null,
          loadingStatus: LoadingState.LOADING,
        },
      });

      return this.activityApiService
        .getPharmacyStatsByPharmacyId(pharmacyId, timeInterval, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((stats) => {
            const statsForCurrentPharmacy = stats.action_stats.find(
              (stat) => stat.pharmacy_id === pharmacyId
            );

            if (!statsForCurrentPharmacy) {
              return patchState({
                engagementRatesWidget: {
                  result: null,
                  loadingStatus: LoadingState.LOADED,
                },
              });
            }

            return patchState({
              engagementRatesWidget: {
                result: statsForCurrentPharmacy,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }),
          catchError((err) => {
            patchState({
              engagementRatesWidget: {
                result: null,
                loadingStatus: LoadingState.ERRORED,
              },
            });
            return throwError(() => err);
          })
        );
    }

    return;
  }

  @Action(GetPharmacyEngagementStats, { cancelUncompleted: true })
  getEngagementStats(
    { patchState }: StateContext<ActivityStateModel>,
    action: GetPharmacyEngagementStats
  ) {
    const { pharmacyId, timeInterval } = action.payload;

    if (pharmacyId) {
      patchState({
        engagementWidget: {
          result: null,
          loadingStatus: LoadingState.LOADING,
        },
      });

      return this.activityApiService
        .getPharmacyStatsByPharmacyId(pharmacyId, timeInterval, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((stats) => {
            const statsForCurrentPharmacy = stats.action_stats.find(
              (stat) => stat.pharmacy_id === pharmacyId
            );

            if (!statsForCurrentPharmacy) {
              return patchState({
                engagementWidget: {
                  result: null,
                  loadingStatus: LoadingState.LOADED,
                },
              });
            }

            return patchState({
              engagementWidget: {
                result: statsForCurrentPharmacy,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }),
          catchError((err) => {
            patchState({
              engagementWidget: {
                result: null,
                loadingStatus: LoadingState.ERRORED,
              },
            });
            return throwError(() => err);
          })
        );
    }

    return;
  }

  @Action(GetPharmacyCompletedOfAssignedStats, { cancelUncompleted: true })
  getCompletedOfAssignedStats(
    { patchState }: StateContext<ActivityStateModel>,
    action: GetPharmacyCompletedOfAssignedStats
  ) {
    const { pharmacyId, timeInterval } = action.payload;

    if (pharmacyId) {
      patchState({
        completedOfAssignedWidget: {
          result: null,
          loadingStatus: LoadingState.LOADING,
        },
      });

      return this.activityApiService
        .getPharmacyStatsByPharmacyId(pharmacyId, timeInterval, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((stats) => {
            const statsForCurrentPharmacy = stats.action_stats.find(
              (stat) => stat.pharmacy_id === pharmacyId
            );

            if (!statsForCurrentPharmacy) {
              return patchState({
                completedOfAssignedWidget: {
                  result: null,
                  loadingStatus: LoadingState.LOADED,
                },
              });
            }

            return patchState({
              completedOfAssignedWidget: {
                result: statsForCurrentPharmacy,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }),
          catchError((err) => {
            patchState({
              completedOfAssignedWidget: {
                result: null,
                loadingStatus: LoadingState.ERRORED,
              },
            });
            return throwError(() => err);
          })
        );
    }

    return;
  }

  @Action(InitializeDashboardWidgetsPharmacyStatsData, { cancelUncompleted: true })
  initializeDashboardWidgetsPharmacyStatsData(
    { patchState }: StateContext<ActivityStateModel>,
    action: InitializeDashboardWidgetsPharmacyStatsData
  ) {
    patchState(stateDefaults);
    const { pharmacyId, timeInterval } = action.payload;

    if (pharmacyId) {
      return this.activityApiService
        .getPharmacyStatsByPharmacyId(pharmacyId, timeInterval, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((stats) => {
            const statsForCurrentPharmacy = stats.action_stats.find(
              (stat) => stat.pharmacy_id === pharmacyId
            );

            if (!statsForCurrentPharmacy) {
              return patchState({
                completedOfAssignedWidget: {
                  result: null,
                  loadingStatus: LoadingState.LOADED,
                },
                engagementRatesWidget: {
                  result: null,
                  loadingStatus: LoadingState.LOADED,
                },
              });
            }

            return patchState({
              completedOfAssignedWidget: {
                result: statsForCurrentPharmacy,
                loadingStatus: LoadingState.LOADED,
              },
              engagementRatesWidget: {
                result: statsForCurrentPharmacy,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }),
          catchError((err) => {
            patchState({
              completedOfAssignedWidget: {
                result: null,
                loadingStatus: LoadingState.ERRORED,
              },
              engagementRatesWidget: {
                result: null,
                loadingStatus: LoadingState.ERRORED,
              },
            });
            return throwError(() => err);
          })
        );
    }

    return;
  }
}
