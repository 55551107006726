import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { ButtonComponent } from '../button/button.component';
import { IconsModule } from '../icon/icon.module';
import { PaginatorSlices } from './paginator.interface';

@Component({
  selector: 't-paginator',
  standalone: true,
  imports: [CommonModule, IconsModule, ButtonComponent],
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() collectionSize = 0;
  @Input() pageSize = 5;
  @Input() currentPage = 1;
  @Input() maxSize = 2;
  @Input() firstLastButtons = false;
  @Input() nextPreviousButtons = true;
  @Input() pageNumbers = true;

  @Output() pageChanged: EventEmitter<PaginatorSlices> = new EventEmitter();

  totalPages: number[] = [];
  showingStartIndex = 1;
  showingEndIndex = this.pageSize;

  // Create an array of numbers from 1-N that serves as the total number of pages
  private generatePageNumbers() {
    return Array.from({ length: Math.ceil(this.collectionSize / this.pageSize) }, (_, i) => i + 1);
  }

  ngOnInit(): void {
    this.totalPages = this.generatePageNumbers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentPage']) {
      // If the currentPage changes, update the showingStartIndex and showingEndIndex
      // -1 because the currentPage is 1-indexed, but the showingStartIndex and showingEndIndex are 0-indexed
      const newCurrentPage = changes['currentPage'].currentValue - 1;
      this.showingStartIndex = Math.max(newCurrentPage * this.pageSize, 1);
      this.showingEndIndex = Math.min(
        newCurrentPage * this.pageSize + this.pageSize,
        this.collectionSize
      );
    }

    if (changes['collectionSize']) {
      this.showingEndIndex = Math.min(
        (this.currentPage - 1) * this.pageSize + this.pageSize,
        this.collectionSize
      );
    }

    this.totalPages = this.generatePageNumbers();
  }

  selectPageNumber(pageNumber: number) {
    this.pageChanged.emit({
      pageNumber: pageNumber,
      start: pageNumber * this.pageSize,
      end: pageNumber * this.pageSize,
    });
  }

  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }
}
