import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { filter, tap } from 'rxjs';

import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import { ModalModule, ModalService } from '@troyai/ui-kit';
import {
  generateFirstSlideButtons,
  generateGuidedTourDefaultConfigOptions,
  generateLastSlideButtons,
  generatePositionStrategy,
} from '../../guided-tour.config';
import { GuidedTourService } from '../../guided-tour.service';
import { WelcomeTourTriggerModalComponent } from '../welcome-tour-trigger-modal/welcome-tour-trigger-modal.component';

@Component({
  selector: 't-dashboard-tour',
  standalone: true,
  imports: [CommonModule, ModalModule],
  templateUrl: './dashboard-tour.component.html',
})
export class DashboardTourComponent implements OnInit, AfterViewInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private store: Store,
    private modalService: ModalService
  ) {}

  destroyRef = inject(DestroyRef);

  pharmaciesList$ = this.store.select(PharmacyContextState.pharmaciesList).pipe(
    filter((pharmacies) => pharmacies.length > 0),
    tap((pharmacies) => {
      this.hasMultiplePharmacies = pharmacies.length > 1;
    }),
    takeUntilDestroyed(this.destroyRef)
  );
  hasMultiplePharmacies = false;

  ngOnInit() {
    this.pharmaciesList$.subscribe();
  }

  ngAfterViewInit() {
    this.guidedTourService.defaultStepOptions = generateGuidedTourDefaultConfigOptions(
      this.guidedTourService
    );
    this.guidedTourService.tourName = 'dashboard';
    this.guidedTourService.modal = true;
    this.guidedTourService.confirmCancel = false;
    this.guidedTourService.addSteps([
      {
        id: '2-dashboard-actions',
        attachTo: {
          element: '#tour-dashboard-new-actions',
          on: generatePositionStrategy('right'),
        },
        buttons: generateFirstSlideButtons(this.guidedTourService),
        title: 'Dashboard',
        text: ['Here is a summary of your new actions available in Troy.AI.'],
      },
      {
        id: '3-dashboard-adherence',
        attachTo: {
          element: '#tour-dashboard-adherence-widget',
          on: generatePositionStrategy('right'),
        },
        scrollTo: true,
        title: 'Dashboard',
        text: ['Key Medication Adherence metrics for your Troy.AI patients.'],
      },
      {
        id: '4-dashboard-payments',
        attachTo: {
          element: '#tour-dashboard-payments-widget',
          on: generatePositionStrategy('left-start'),
        },
        scrollTo: true,
        title: 'Dashboard',
        text: [
          'See how much you’ve earned with Troy.AI so far and how much more you could earn today.',
        ],
      },
      {
        id: '5-dashboard-leaderboard',
        attachTo: {
          element: '#tour-dashboard-leaderboard-widget',
          on: generatePositionStrategy('left'),
        },
        scrollTo: true,
        title: 'Dashboard',
        text: [
          'Your pharmacy’s rank among nearby pharmacies using Troy.AI. Complete more actions to move up.',
        ],
      },
      {
        id: '6-dashboard-pa-widget',
        attachTo: {
          element: '#tour-dashboard-pa-widget',
          on: generatePositionStrategy('left'),
        },
        scrollTo: true,
        title: 'Dashboard',
        text: ['Here are your Prior Auth requests and their statuses.'],
      },
      {
        id: '7-notification-menu-item',
        attachTo: {
          element: '#tour-menu-notification-item',
          on: generatePositionStrategy('right'),
        },
        scrollTo: true,
        title: 'Menu',
        text: ['Notifications area that shows useful updates and notifications.'],
      },
      {
        id: '8-main-nav-menu',
        attachTo: {
          element: '#tour-main-menu',
          on: generatePositionStrategy('right'),
        },
        scrollTo: true,
        title: 'Menu',
        text: ['Your main navigation menu inside Troy.AI.'],
      },
      {
        id: '12-global-pharmacy-selector',
        attachTo: {
          element: '#tour-global-pharmacy-selector',
          on: generatePositionStrategy('right'),
        },
        scrollTo: true,
        showOn: () => {
          return this.hasMultiplePharmacies;
        },
        title: 'Menu',
        text: ['Switch between your pharmacies.'],
      },
      {
        id: '13-activity-menu-item',
        attachTo: {
          element: '#tour-menu-activity-item',
          on: generatePositionStrategy('right'),
        },
        scrollTo: true,
        title: 'Menu',
        text: ['View current and previous activity.'],
      },
      {
        id: '14-settings-menu-item',
        attachTo: {
          element: '#tour-menu-settings-item',
          on: generatePositionStrategy('right'),
        },
        buttons: generateLastSlideButtons(this.guidedTourService),
        scrollTo: true,
        title: 'Menu',
        text: ['Configure your account.'],
      },
    ]);

    if (
      !this.guidedTourService.getLocalStorageCompletionStatusByName(this.guidedTourService.tourName)
    ) {
      // Show modal, the tour will get triggered from the modal
      this.modalService.openDialog(WelcomeTourTriggerModalComponent, null, {
        title: 'Welcome to the new Troy.AI',
        disablePadding: true,
      });
    }
  }
}
