import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { Diagnosis } from './models/diagnosis.model';

@Injectable({
  providedIn: 'root',
})
export class DiagnosesApiService {
  constructor(private restApiService: RestApiClientService) {}

  getDiagnoses(memberGlobalId: number) {
    return this.restApiService.get<Diagnosis[]>(`members/${memberGlobalId}/diagnoses`);
  }
}
