import { Injectable } from '@angular/core';
import { PaymentsStateModel } from './payments-state.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PaymentsApiService } from '../payments-api.service';
import {
  GetPaymentsHistory,
  GetPaymentsSummary,
  GetSinglePaymentHistory,
} from './payments.actions';
import { tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';

@State<PaymentsStateModel>({
  name: 'paymentsState',
  defaults: {
    paymentsSummary: [],
    paymentsSummaryLoading: false,
    paymentsHistoryItems: [],
    selectedPaymentActions: [],
  },
})
@Injectable()
export class PaymentsState {
  constructor(private paymentsApiService: PaymentsApiService) {}

  @Selector()
  static paymentsSummary(state: PaymentsStateModel) {
    return state.paymentsSummary;
  }

  @Selector()
  static paymentsHistoryItems(state: PaymentsStateModel) {
    return state.paymentsHistoryItems;
  }

  @Selector()
  static selectedPaymentActions(state: PaymentsStateModel) {
    return state.selectedPaymentActions;
  }

  @Selector()
  static paymentsSummaryLoading(state: PaymentsStateModel) {
    return state.paymentsSummaryLoading;
  }

  @Action(GetPaymentsSummary)
  getPaymentsSummary({ setState, patchState }: StateContext<PaymentsStateModel>) {
    patchState({
      paymentsSummaryLoading: true,
    });

    return this.paymentsApiService.getPaymentsSummary().pipe(
      tap((paymentsSummary) =>
        setState(
          patch<PaymentsStateModel>({
            paymentsSummary,
          })
        )
      ),
      tap(() =>
        patchState({
          paymentsSummaryLoading: false,
        })
      )
    );
  }

  @Action(GetPaymentsHistory)
  getPaymentsHistory({ setState }: StateContext<PaymentsStateModel>) {
    return this.paymentsApiService.getPaymentsHistory().pipe(
      tap((paymentsHistoryItems) =>
        setState(
          patch<PaymentsStateModel>({
            paymentsHistoryItems,
          })
        )
      )
    );
  }

  @Action(GetSinglePaymentHistory)
  getSinglePaymentsHistory(
    { setState, patchState }: StateContext<PaymentsStateModel>,
    action: GetSinglePaymentHistory
  ) {
    patchState({
      selectedPaymentActions: [],
    });

    return this.paymentsApiService.getSinglePaymentDetails(action.payload.paymentId).pipe(
      tap((actions) =>
        setState(
          patch<PaymentsStateModel>({
            selectedPaymentActions: actions,
          })
        )
      )
    );
  }
}
