import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { CdkDialogContainer, DIALOG_DATA, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, Optional, ViewEncapsulation } from '@angular/core';

import { take } from 'rxjs';
import { IconComponent } from '../../icon/icon.component';
import { ModalService } from '../modal.service';
import { ModalMetaData } from './../modal-meta-data.interface';

@Component({
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, NgIf, IconComponent, PortalModule],
})
export class ModalComponent extends CdkDialogContainer {
  constructor(
    private modalService: ModalService,
    @Optional() @Inject(DIALOG_DATA) public modalMetaData: ModalMetaData,
    public dialogRef: DialogRef<string>,
    // Required for extending CDK Dialog container
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) _document: unknown,
    dialogConfig: DialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor?: FocusMonitor
  ) {
    super(
      elementRef,
      focusTrapFactory,
      _document,
      dialogConfig,
      interactivityChecker,
      ngZone,
      overlayRef,
      focusMonitor
    );
  }

  closeDialogAttempt() {
    if (this.modalMetaData.preventCloseOnPromptConfirm) {
      const promptRef = this.modalService.openGenericPromptDialog(
        {
          promptMessage: this.modalMetaData.preventClosePromptMessage,
          promptTitle: this.modalMetaData.preventClosePromptTitle,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Confirm',
        },
        {
          hideCloseButton: true,
        }
      );

      promptRef.closed.pipe(take(1)).subscribe((result) => {
        if (result && this.modalMetaData.preventCloseOnPromptConfirm) {
          this.modalMetaData.preventCloseOnPromptConfirm();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}
