<div
  class="relative w-[846px] print:visible print:block print:h-auto print:w-auto print:overflow-visible">
  <div class="bg-grey-20 flex items-center justify-between rounded p-5 print:hidden">
    <t-button (click)="triggerPrint()">PRINT</t-button>
    <div class="space-y-1 text-right">
      <span class="text-grey-70 block text-xs">Generated</span>
      <strong class="text-grey-100 block text-sm font-semibold">Today, {{ getTodayDate() }}</strong>
    </div>
  </div>
  <div
    *ngIf="groupedAppointmentsListItems$ | async as appointmentsGroups"
    class="modal shadow-small mt-5 max-h-[300px] overflow-y-scroll bg-white p-8 print:visible print:h-auto print:overflow-visible print:shadow-transparent">
    <t-heading level="h3" value="Appointments"></t-heading>
    <span class="text-grey-100 mt-2.5 text-base">Document Generated: {{ getTodayDate() }} </span>

    <div
      *ngFor="let appointmentGroup of appointmentsGroups"
      class="divide-grey-100 border-grey-100 mt-6 divide-y border-t">
      <div>{{ appointmentGroup.day | date: 'mediumDate' }}</div>
      <div
        *ngFor="let timeslot of appointmentGroup.timeslots"
        class="text-grey-100 flex py-6 text-base">
        <div class="w-1/3">
          <span class="block font-semibold">
            {{ timeslot.start_time | date: 'shortTime' }} -
            {{ timeslot.end_time | date: 'shortTime' }}
          </span>
          <span>{{ timeslot.member_name | titlecase }}</span>
        </div>

        <div class="w-1/3">
          {{ timeslot.address_city | titlecase }} {{ timeslot.address_zip }}
          {{ timeslot.address_city && timeslot.address_county ? ',' : '' }}
          {{ timeslot.address_county | titlecase }}
        </div>

        <div *ngIf="timeslot.primary_phone_number" class="w-1/3 text-right">
          <span class="block font-semibold">{{ timeslot.primary_phone_number | phone }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
