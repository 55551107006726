import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../../icon/icon.module';

@Component({
  selector: 't-options-button',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './options-button.component.html',
})
export class OptionsButtonComponent {}
