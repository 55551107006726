import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 't-content-wrapper-layout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './content-wrapper-layout.component.html',
})
export class ContentWrapperLayoutComponent {
  @Input() isFullWidth = false;
}
