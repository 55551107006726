export interface OnboardingStep {
  index?: number;
  id: string;
  title: string;
  description: string;
  completed: boolean;
  disabled: boolean;
  loading: boolean;
}

export const onboardingStepsSet: Record<string, OnboardingStep> = {
  detailsAndAgreement: {
    id: 'details_and_agreement',
    title: 'Review the Pharmacy Provider Agreement',
    description:
      'Review then complete our Pharmacy Provider Agreement to join the Troy.AI team and start earning payments for clinical pharmacy services.',
    completed: false,
    disabled: false,
    loading: false,
  },
  bankAccountConnection: {
    id: 'bank_account_connection',
    title: 'Set up payments for Troy.AI services',
    description:
      'Connect your bank account via Plaid to specify where you’d like us to send your earnings and ensure seamless monthly payments from Troy.AI',
    completed: false,
    disabled: false,
    loading: false,
  },
  modelOfCareTraining: {
    id: 'model_of_care_training',
    title: 'Model of Care (MOC) Compliance',
    description:
      'Read our Model of Care (MOC) training and attest to your understanding to ensure compliance with the annual MOC for D-SNP members.',
    completed: false,
    disabled: false,
    loading: false,
  },
};

export const getOnboardingStepsConfig = (steps: OnboardingStep[]) => {
  if (!steps || steps.length === 0) {
    return [];
  }

  return steps.map((step, idx) => {
    return {
      index: idx,
      ...step,
    };
  });
};
