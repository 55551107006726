import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';

import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import { minutesToMilliseconds } from 'date-fns';
import { rankLeaderboardItems } from '../helpers/rank-leaderboard-items.util';
import { LeaderboardApiService } from '../leaderboard-api.service';
import { EnrichedLeaderboardItem } from '../models/leaderboard-item.interface';
import { LeaderboardStateModel } from './leaderboard-state.model';
import { GetLeaderboardData } from './leaderboard.actions';

@State<LeaderboardStateModel>({
  name: 'leaderboardState',
  defaults: {
    globalLeaderboardItems: [],
    globalLeaderboardItemsCount: 0,
    allLeaderboardItems: [],
    leaderboardItemsLoading: true,
    leaderboardLastUpdatedTime: '',
  },
})
@Injectable()
export class LeaderboardState {
  constructor(private leaderboardApiService: LeaderboardApiService) {}

  @Selector([LeaderboardState, PharmacyContextState.selectedPharmacyId])
  static globalLeaderboardItems(
    state: LeaderboardStateModel,
    selectedPharmacyId: number
  ): EnrichedLeaderboardItem[] {
    const leaderboardWithUserPharmacy = state.globalLeaderboardItems.map((item) => {
      return {
        ...item,
        hasCurrentUserAssociated: selectedPharmacyId === item.pharmacy_id,
      };
    });

    return rankLeaderboardItems(leaderboardWithUserPharmacy);
  }

  @Selector()
  static latestUpdateTime(state: LeaderboardStateModel) {
    const latestUpdateTime = new Date(state.leaderboardLastUpdatedTime);
    return latestUpdateTime.toString();
  }

  @Selector([LeaderboardState, PharmacyContextState.selectedPharmacyId])
  static allLeaderboardItems(
    state: LeaderboardStateModel,
    selectedPharmacyId: number
  ): EnrichedLeaderboardItem[] {
    const leaderboardWithUserPharmacy = state.allLeaderboardItems.map((item) => {
      return {
        ...item,
        hasCurrentUserAssociated: selectedPharmacyId === item.pharmacy_id,
      };
    });

    return rankLeaderboardItems(leaderboardWithUserPharmacy);
  }

  @Selector()
  static leaderboardItemsLoading(state: LeaderboardStateModel) {
    return state.leaderboardItemsLoading;
  }

  @Selector()
  static allPharmaciesCount(state: LeaderboardStateModel) {
    return state.globalLeaderboardItemsCount;
  }

  @Action(GetLeaderboardData)
  getLeaderboardData({ setState, patchState }: StateContext<LeaderboardStateModel>) {
    patchState({
      leaderboardItemsLoading: true,
    });

    return this.leaderboardApiService
      .getLeaderboardData({
        ttl: minutesToMilliseconds(30),
      })
      .pipe(
        tap((data) => {
          if (data) {
            setState(
              patch<LeaderboardStateModel>({
                globalLeaderboardItems: data.global_leaderboard,
                globalLeaderboardItemsCount: data.count,
                leaderboardLastUpdatedTime: data.last_updated_time,
                allLeaderboardItems: data.all_pharmacies,
              })
            );
          }
        }),
        tap(() =>
          patchState({
            leaderboardItemsLoading: false,
          })
        )
      );
  }
}
