import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsModule } from '@troyai/ui-kit';

@Component({
  selector: 't-sidebar-layout',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('150ms cubic-bezier(0.4, 0, 0.2, 1)', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('150ms cubic-bezier(0.4, 0, 0.2, 1)', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
  standalone: true,
  providers: [],
  imports: [CommonModule, IconsModule],
  templateUrl: './sidebar-layout.component.html',
})
export class SidebarLayoutComponent {
  @Input() sidebarVisible = false;
  @Output() closeSidebarEvent = new EventEmitter<boolean>();

  shouldDisplayAlert = true;

  closeSidebar() {
    this.closeSidebarEvent.emit();
  }
}
