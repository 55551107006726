import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';

import { LoginFlowInitiated } from '@troyai/auth/data-access';
import { GreetingCardComponent } from '@troyai/auth/feature';
import { ButtonComponent } from '@troyai/ui-kit';

@Component({
  standalone: true,
  imports: [CommonModule, GreetingCardComponent, ButtonComponent],
  templateUrl: './login-shell.component.html',
})
export class LoginShellComponent {
  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {}

  destroyRef = inject(DestroyRef);

  loginWithRedirect(): void {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.store.dispatch(
        new LoginFlowInitiated({
          redirectUrl: params['returnUrl'],
        })
      );
    });
  }
}
