import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LogoutFlowInitiated, UserRoles, UserState } from '@troyai/auth/data-access';
import { RolesDirective } from '@troyai/auth/feature';
import { FeatureFlagDirective } from '@troyai/feature-flags';
import { SetGlobalPharmacy } from '@troyai/pharmacy-context/data-access';
import {
  PharmacyContextService,
  PharmacySelectorComponent,
} from '@troyai/pharmacy-context/feature';
import { NavMenuItemComponent } from '@troyai/ui-kit';
import { distinctUntilKeyChanged, filter, map, withLatestFrom } from 'rxjs';
import { HelpMenuItemComponent } from '../help-menu-item/help-menu-item.component';

@Component({
  selector: 't-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NavMenuItemComponent,
    PharmacySelectorComponent,
    RolesDirective,
    HelpMenuItemComponent,
    FeatureFlagDirective,
  ],
  templateUrl: './main-menu.component.html',
})
export class MainMenuComponent implements OnInit {
  constructor(
    private store: Store,
    private actions$: Actions,
    private pharmacyContextService: PharmacyContextService
  ) {}

  user$ = this.store.select(UserState.user);
  userRoles$ = this.store.select(UserState.userRoles);
  isMOCCompliant$ = this.store.select(UserState.isMOCCompliant);
  currentTenantId$ = this.store.select(UserState.currentTenantId);

  @Output() menuItemSelected = new EventEmitter<void>();
  userRoles = UserRoles;
  destroyRef = inject(DestroyRef);

  selectMenuItem() {
    this.menuItemSelected.emit();
  }

  logout(): void {
    this.store.dispatch(new LogoutFlowInitiated());
  }

  ngOnInit() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetGlobalPharmacy),
        withLatestFrom(this.userRoles$),
        filter(([, userRoles]) => !!userRoles && userRoles.includes(UserRoles.PharmacyUser)),
        map(([newSelectedPharmacyAction]) => newSelectedPharmacyAction.payload.pharmacy),
        filter((newSelectedPharmacy) => !!newSelectedPharmacy.global_id),
        distinctUntilKeyChanged('global_id'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((newSelectedPharmacy) => {
        this.pharmacyContextService.handlePharmacyChangeRedirects(newSelectedPharmacy);
      });
  }
}
