/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CheckBoxComponent } from '../../checkbox/checkbox.component';

export type MatrixFormInputType = 'checkbox' | 'radio';

@Component({
  selector: 't-matrix-form',
  templateUrl: './matrix-input.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CheckBoxComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatrixFormComponent),
      multi: true,
    },
  ],
})
export class MatrixFormComponent implements ControlValueAccessor, OnInit {
  @Input() rows: string[] = [];
  @Input() columns: string[] = [];
  @Input() selectionType: MatrixFormInputType = 'checkbox';
  @Input() label = '';

  value: { [row: string]: string[] | string } = {};

  private onChange = (value: any) => {};
  onTouched = () => {};

  isDisabled = false;

  ngOnInit() {
    this.initializeValue();
  }

  writeValue(value: { [row: string]: string[] | string }): void {
    if (value) {
      this.value = { ...value };
    } else {
      this.initializeValue();
    }
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private initializeValue() {
    this.value = {};
    for (const row of this.rows) {
      if (this.selectionType === 'checkbox') {
        this.value[row] = [];
      } else {
        this.value[row] = ''; // Empty string represents no selection
      }
    }
  }

  onInputChange(row: string, column: string, e: Event): void {
    if (this.selectionType === 'checkbox') {
      const isChecked = (e.target as HTMLInputElement).checked;
      this.handleCheckboxChange(row, column, isChecked);
    } else {
      this.handleRadioChange(row, column);
    }
    this.onChange(this.value);
    this.onTouched();
  }

  private handleCheckboxChange(row: string, column: string, isChecked: boolean): void {
    const selectedColumns = (this.value[row] as string[]) || [];

    let updatedColumns: string[];
    if (isChecked) {
      if (!selectedColumns.includes(column)) {
        updatedColumns = [...selectedColumns, column];
      } else {
        updatedColumns = selectedColumns;
      }
    } else {
      updatedColumns = selectedColumns.filter((col) => col !== column);
    }

    this.value = {
      ...this.value,
      [row]: updatedColumns,
    };
  }

  private handleRadioChange(row: string, column: string): void {
    this.value = {
      ...this.value,
      [row]: column,
    };
  }

  // Helper methods for trackBy
  trackByRow(index: number, row: string): string {
    return row;
  }

  trackByColumn(index: number, column: string): string {
    return column;
  }
}
