import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { PatchAssociateMemberFilePayload, UploadedFile } from './models/patient-files.interface';

@Injectable({
  providedIn: 'root',
})
export class PatientsFilesApiService {
  constructor(private restApiService: RestApiClientService) {}

  getFileSignedURL(memberGlobalId: number, fileId: number) {
    return this.restApiService.get<string>(`members/${memberGlobalId}/files/${fileId}`);
  }

  uploadFiles(memberGlobalId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_type', file.type);

    return this.restApiService.post<UploadedFile>(`members/${memberGlobalId}/files`, formData);
  }

  deleteFile(memberGlobalId: number, fileId: number) {
    return this.restApiService.delete<string>(`members/${memberGlobalId}/files/${fileId}`);
  }

  associateFile(memberGlobalId: number, fileId: number, payload: PatchAssociateMemberFilePayload) {
    return this.restApiService.patch<string>(
      `members/${memberGlobalId}/files/${fileId}/associate`,
      payload
    );
  }
}
