import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActionStatusEnum } from '@troyai/actions/data-access';
import { AppointmentsAccordionGroup, CareState } from '@troyai/hra/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { PhonePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent, HeadingComponent, IconsModule } from '@troyai/ui-kit';
import { map } from 'rxjs';

@Component({
  selector: 't-print-patients-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconsModule, HeadingComponent, FullNamePipe, PhonePipe],
  templateUrl: './print-appointments-modal.component.html',
})
export class PrintAppointmentsModalComponent {
  constructor(private store: Store) {}

  groupedAppointmentsListItems$ = this.store.select(CareState.groupedAppointmentsListItems).pipe(
    map((items) => {
      return items.reduce((acc: AppointmentsAccordionGroup[], item) => {
        // return only items with status of 'scheduled'
        const itemsWithScheduledStatus = item.timeslots.filter(
          (timeslot) => timeslot.status === ActionStatusEnum.SCHEDULED
        );
        if (itemsWithScheduledStatus.length > 0) {
          acc.push({
            ...item,
            timeslots: itemsWithScheduledStatus,
          });
        }

        return acc;
      }, [] as AppointmentsAccordionGroup[]);
    })
  );

  getTodayDate = () => {
    const today = new Date();
    return today.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  };

  triggerPrint() {
    window.print();
  }
}
