<div class="space-y-2">
  <label class="text-grey-50 mb-2 block text-sm">{{ label }}</label>
  <div class="space-y-2">
    @for (item of items; track $index) {
      <label class="flex items-center">
        <input
          class="form-radio checked:bg-red-90 checked:hover:bg-red-90 checked:active:bg-red-90 checked:focus:bg-red-90 checked:disabled:hover:bg-grey-20 accent-red-90 border-grey-50 focus:border-red-90 focus:ring-red-30 disabled:border-grey-40 disabled:bg-green-20 mr-2.5 cursor-pointer rounded-full bg-white focus:ring focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed"
          type="radio"
          [disabled]="item.disabled || false"
          [value]="item.value"
          [ngModel]="value"
          (ngModelChange)="onModelChange($event)"
          name="{{ name }}" />
        <span class="text-grey-100 cursor-pointer text-sm">{{ item.name }}</span>
      </label>
    }
  </div>
</div>
