import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  differenceInDays,
  differenceInMonths,
  isFuture,
  isPast,
  isSameDay,
  minutesToMilliseconds,
  startOfYear,
  subHours,
} from 'date-fns';
import { catchError, forkJoin, mergeMap, of, tap } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { AdherenceApiService } from '../adherence-api.service';
import {
  AdherenceCategoryItem,
  AdherenceItemPatients,
  AdherenceItemPatientsFilters,
  AdherencePatientMeasure,
} from '../models/adherence-item.interface';
import { AdherenceStateModel } from './adherence-state.model';
import {
  GetAdherenceCategories,
  GetAdherenceCategoriesItems,
  GetAdherenceDataForPatient,
  GetAdherenceItemPatientRxClaims,
} from './adherence.actions';

const stateDefaults = {
  overview: undefined,
  overviewLoading: true,
  selectedPatientAdherence: [],
  selectedPatientRxClaims: [],
  adherencePatientsByCategory: [],
  adherencePatientsByCategoryLoading: true,
  lastUpdateTime: null,
};

@State<AdherenceStateModel>({
  name: 'adherenceState',
  defaults: stateDefaults,
})
@Injectable()
export class AdherenceState {
  constructor(private adherenceRestApiService: AdherenceApiService) {}

  @Selector()
  static categoryItems(state: AdherenceStateModel) {
    const { overview } = state;
    const valuesToOrderBy = ['hypertension', 'diabetes', 'cholesterol'];

    return overview?.measures.reduce((acc, item) => {
      const index = valuesToOrderBy.indexOf(item.measure);
      acc[index] = item;
      return acc;
    }, [] as AdherenceCategoryItem[]);
  }

  @Selector()
  static overviewLoading(state: AdherenceStateModel) {
    return state.overviewLoading;
  }

  @Selector()
  static adherencePatientsByCategory(state: AdherenceStateModel) {
    const { adherencePatientsByCategory } = state;
    const valuesToOrderBy = ['hypertension', 'diabetes', 'cholesterol'];
    return adherencePatientsByCategory.reduce((acc, item) => {
      const index = valuesToOrderBy.indexOf(item.measure);
      acc[index] = item;
      return acc;
    }, [] as AdherenceItemPatients[]);
  }

  @Selector()
  static adherencePatientsByCategoryLoading(state: AdherenceStateModel) {
    return state.adherencePatientsByCategoryLoading;
  }

  @Selector()
  static selectedPatientAdherence(state: AdherenceStateModel) {
    return state.selectedPatientAdherence || [];
  }

  @Selector()
  static selectedAdherenceItemPatientRxClaims(state: AdherenceStateModel) {
    return state.selectedPatientRxClaims;
  }

  @Selector()
  static adherenceBonus(state: AdherenceStateModel) {
    return state.overview?.bonus_amount || 0;
  }

  @Selector()
  static lastUpdateTime(state: AdherenceStateModel) {
    return state.lastUpdateTime;
  }

  /*  The total maximum bonus amount that can be earned for the year
      Calculated with the formula: ELIGIBLE_MEMBERS_COUNT * $5 * 3 * number of months left in the year
  */
  @Selector()
  static maximumReachableBonusAmount(state: AdherenceStateModel) {
    const adherencePatientsByCategory = state.adherencePatientsByCategory;
    const totalEligibleMembers = adherencePatientsByCategory.reduce((acc, item) => {
      return acc + item.total_eligible_members;
    }, 0);

    // TODO: Temporary calculation, needs to be updated when the bonus calculation logic is finalized
    // For 2023, the adherence bonus starts being calculated from July 1st, 2023
    const bonusCalculationStartingDate = new Date(2023, 6, 1);
    const bonusCalculationEndingDate = startOfYear(new Date(2024, 0, 1));
    const currentYear = new Date().getFullYear();

    const numberOfMonths =
      currentYear === 2023
        ? differenceInMonths(bonusCalculationEndingDate, bonusCalculationStartingDate)
        : 12;

    return totalEligibleMembers * 5 * 3 * numberOfMonths;
  }

  @Action(GetAdherenceCategories, { cancelUncompleted: true })
  getAdherenceItemsList(
    { patchState }: StateContext<AdherenceStateModel>,
    action: GetAdherenceCategories
  ) {
    patchState({
      overviewLoading: true,
      overview: undefined,
      adherencePatientsByCategory: [],
      adherencePatientsByCategoryLoading: true,
      lastUpdateTime: null,
    });
    return this.adherenceRestApiService
      .getAdherenceItems(action.payload.pharmacyId, {
        ttl: minutesToMilliseconds(10),
      })
      .pipe(
        tap((overview) => {
          patchState({
            overview,
            overviewLoading: false,
            lastUpdateTime: subHours(new Date(), 1).toISOString(),
          });
        }),
        catchError((error) => {
          // If the error is a 404, it means that the pharmacy does not have any adherence data
          if (error instanceof HttpErrorResponse && error.status === 404) {
            patchState({
              overview: undefined,
              overviewLoading: false,
            });
          }

          return of(error);
        })
      );
  }

  @Action(GetAdherenceCategoriesItems, { cancelUncompleted: true })
  getAdherenceCategoriesItems(
    { patchState, getState }: StateContext<AdherenceStateModel>,
    action: GetAdherenceCategoriesItems
  ) {
    const { overview } = getState();
    patchState({
      adherencePatientsByCategoryLoading: true,
      adherencePatientsByCategory: [],
      lastUpdateTime: null,
    });

    return of(overview?.measures).pipe(
      mergeMap(() => {
        return forkJoin({
          hypertension: this.adherenceRestApiService.getAdherenceItemPatients(
            action.payload.pharmacyId,
            'hypertension',
            { ttl: minutesToMilliseconds(10) }
          ),
          diabetes: this.adherenceRestApiService.getAdherenceItemPatients(
            action.payload.pharmacyId,
            'diabetes',
            { ttl: minutesToMilliseconds(10) }
          ),
          cholesterol: this.adherenceRestApiService.getAdherenceItemPatients(
            action.payload.pharmacyId,
            'cholesterol',
            { ttl: minutesToMilliseconds(10) }
          ),
        });
      }),
      tap((items) => {
        const { typeFilter } = action.payload;
        const today = new Date();
        const adherencePatientsByCategory: AdherenceItemPatients[] = Object.keys(items).map(
          (key) => {
            const patients = items[key as keyof typeof items] || [];
            const overviewCategory = overview?.measures.find((item) => item.measure === key);

            let patientsCollection: AdherencePatientMeasure[] = [];

            if (typeFilter === AdherenceItemPatientsFilters.ALL) {
              patientsCollection = patients;
            }

            if (typeFilter === AdherenceItemPatientsFilters.NEEDING_ATTENTION) {
              // Returning items that require a fill within the next 5 days from now,
              // or are overdue/without coverage of their medication (daysUntilNextFillDate as negative number)
              patientsCollection = patients.filter((patient) => {
                const nextFillDate = new Date(patient.adjusted_rx_end_date);
                const daysUntilNextFillDate = differenceInDays(nextFillDate, today);
                return daysUntilNextFillDate < 5;
              });
            }

            const patientsWithNextFillToday = patientsCollection
              .filter((patient) => {
                const nextFillDate = new Date(patient.adjusted_rx_end_date);
                return isSameDay(nextFillDate, today);
              })
              .sort((a, b) => {
                return a.percentage - b.percentage;
              });

            const patientsWithNextFillIncomingDays = patientsCollection
              .filter((patient) => {
                const nextFillDate = new Date(patient.adjusted_rx_end_date);
                return isFuture(nextFillDate);
              })
              .sort((a, b) => {
                return a.percentage - b.percentage;
              });

            const patientsWithNextFillPast = patientsCollection
              .filter((patient) => {
                const nextFillDate = new Date(patient.adjusted_rx_end_date);
                return isPast(nextFillDate) && !isSameDay(nextFillDate, today);
              })
              .sort((a, b) => {
                return a.percentage - b.percentage;
              });

            return {
              measure: key,
              patients: [
                ...patientsWithNextFillToday,
                ...patientsWithNextFillIncomingDays,
                ...patientsWithNextFillPast,
              ],
              percentage: overviewCategory?.member_adherent_percent || 0,
              top_adherent_members: overviewCategory?.top_adherent_members || 0,
              total_eligible_members: overviewCategory?.total_eligible_members || 0,
            };
          }
        );
        patchState({
          adherencePatientsByCategory,
          adherencePatientsByCategoryLoading: false,
          lastUpdateTime: subHours(new Date(), 1).toISOString(),
        });
      })
    );
  }

  @Action(GetAdherenceDataForPatient, { cancelUncompleted: true })
  getAdherenceDataForPatient(
    { patchState }: StateContext<AdherenceStateModel>,
    action: GetAdherenceDataForPatient
  ) {
    patchState({ selectedPatientAdherence: [], overviewLoading: true });
    return this.adherenceRestApiService
      .getAdherenceItemsForPatient(action.payload.pharmacyId, action.payload.memberId)
      .pipe(
        tap((selectedPatientAdherence) => {
          patchState({ selectedPatientAdherence, overviewLoading: false });
        })
      );
  }

  @Action(GetAdherenceItemPatientRxClaims, { cancelUncompleted: true })
  getAdherenceItemPatientRxClaims(
    { patchState }: StateContext<AdherenceStateModel>,
    action: GetAdherenceItemPatientRxClaims
  ) {
    return this.adherenceRestApiService
      .getAdherenceItemPatientRxClaims(
        action.payload.pharmacyId,
        action.payload.adherenceItemId,
        action.payload.memberId
      )
      .pipe(
        tap((items) => {
          patchState({ selectedPatientRxClaims: items });
        })
      );
  }
}
