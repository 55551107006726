import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab.component';

@Component({
  selector: 't-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @Output() tabChange = new EventEmitter<string>();

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
    this.tabChange.emit(tab.label);
  }

  ngAfterContentInit() {
    if (this.tabs.length > 0) {
      const activeTabs = this.tabs.filter((tab) => tab.active);
      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }
  }
}
