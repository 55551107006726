import { Pipe, PipeTransform } from '@angular/core';

const memberGapDisplayNameMapping: Record<string, string> = {
  colorectal_screening: 'Colorectal Screening',
  a1c_control_for_patients_with_diabetes: 'A1C Control',
  eye_exam_for_patients_with_diabetes: 'Eye Exam',
  controlling_high_bp: 'High Blood Pressure',
  mammogram: 'Mammogram',
};

@Pipe({
  name: 'memberGap',
  standalone: true,
})
export class MemberGapPipe implements PipeTransform {
  transform(value: string) {
    return memberGapDisplayNameMapping[value] || '';
  }
}
