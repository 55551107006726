import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { ButtonComponent, DrawerService } from '@troyai/ui-kit';
import { InlineMedicationsListComponent } from './inline-medications-list.component';

@Component({
  selector: 't-inline-medications-list-trigger',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  template: `<t-button
    (click)="$event.preventDefault(); openMedicationsList()"
    icon="search"
    variant="secondary"
    size="tiny">
    View all medications
  </t-button>`,
})
export class InlineMedicationsListTriggerComponent {
  constructor(private drawerService: DrawerService) {}

  @HostBinding('class') get HeadingClass() {
    return 'absolute top-2 right-2';
  }

  openMedicationsList() {
    this.drawerService.open(InlineMedicationsListComponent);
  }
}
