import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { combineLatest, map, take, tap, withLatestFrom } from 'rxjs';

import { ActionsState, AppointmentAddress } from '@troyai/actions/data-access';
import { UserRoles } from '@troyai/auth/data-access';
import { RolesDirective, UserRolesService } from '@troyai/auth/feature';
import {
  CareState,
  HraCareStateModel,
  SetScheduleAppointmentAddress,
  SetScheduleAppointmentNursePractitioner,
  UpdateScheduleLocationModalState,
} from '@troyai/hra/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { AddressPipe } from '@troyai/portal/common/pipes';
import {
  ButtonComponent,
  IconsModule,
  ModalMetaData,
  ModalModule,
  ModalService,
  PopoverModule,
  ToggleComponent,
} from '@troyai/ui-kit';
import { NursePractitionerPatientSchedulerModalComponent } from '../np-patient-scheduler-modal/np-patient-scheduler-modal.component';
import { PatientSchedulerModalComponent } from '../patient-scheduler-modal/patient-scheduler-modal.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconsModule,
    ModalModule,
    FullNamePipe,
    AddressPipe,
    ToggleComponent,
    RolesDirective,
    PopoverModule,
  ],
  templateUrl: './patient-scheduler-location-modal.component.html',
})
export class PatientSchedulerLocationModalComponent implements OnInit {
  constructor(
    public dialogRef: DialogRef,
    private modalService: ModalService,
    private store: Store,
    private fullNamePipe: FullNamePipe,
    private userRolesService: UserRolesService,
    private actions$: Actions
  ) {}

  destroyRef = inject(DestroyRef);

  private patient$ = this.store.select(PatientsState.selectedPatient);
  private nursePractitioners$ = this.store.select(CareState.nursePractitionersList);
  private selectedAddress$ = this.store.select(CareState.scheduleAppointmentDetails);
  scheduleLocationModalState$ = this.store.select(CareState.scheduleLocationModalState);
  action$ = this.store.select(ActionsState.selectedAction);

  // Filter out addresses by the flags in the modal state.
  // Flags are set based on the action type (ex: NPTH01 only displays pharmacy addresses)
  availableAddresses$ = this.action$.pipe(
    map((action) => action?.appointment_addresses),
    withLatestFrom(this.scheduleLocationModalState$),
    map(([addresses, modalState]) => {
      if (!addresses) {
        return [];
      }

      const addressesByType = addresses.reduce(
        (acc, address: AppointmentAddress) => {
          if (address.at_pharmacy) {
            acc.pharmacyAddresses.push(address);
          } else {
            acc.memberAddresses.push(address);
          }
          return acc;
        },
        {
          pharmacyAddresses: [] as AppointmentAddress[],
          memberAddresses: [] as AppointmentAddress[],
        }
      );

      const filteredAddresses: AppointmentAddress[] = [];

      if (modalState.hasPharmacyLocationSupport) {
        filteredAddresses.push(...addressesByType.pharmacyAddresses);
      }
      if (modalState.hasMemberAddressesSupport) {
        filteredAddresses.push(...addressesByType.memberAddresses);
      }

      return filteredAddresses;
    })
  );

  chooseLocation(address: AppointmentAddress) {
    this.dialogRef.close();

    combineLatest([
      this.userRolesService.hasAccess([UserRoles.PharmacyUser]),
      this.userRolesService.hasAccess([UserRoles.CareTeamHRA]),
      this.userRolesService.hasAccess([UserRoles.CMExternal]),
      this.patient$,
      this.scheduleLocationModalState$,
    ])
      .pipe(
        tap(([isPharmacy, isCareTeam, isCMExternal, patient, modalState]) => {
          const patientName = this.fullNamePipe.transform(patient);

          const modalMetaData = {
            title: `Schedule ${
              modalState.isTelehealthSelected ? 'Telehealth' : ''
            } NP Home Visit for ${patientName}`,
            background: 'grey',
            offsetTop: 50,
          } as ModalMetaData;

          this.store.dispatch([
            new SetScheduleAppointmentAddress({
              address,
              isTelehealth: modalState.isTelehealthSelected,
            }),
          ]);

          // Split the logic for pharmacy and care team in different modals
          if (isPharmacy || isCMExternal) {
            this.modalService.openDialog(PatientSchedulerModalComponent, null, modalMetaData);
            return;
          }

          if (isCareTeam) {
            this.modalService.openDialog(
              NursePractitionerPatientSchedulerModalComponent,
              null,
              modalMetaData
            );
            return;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  updateTelehealthSelection(value: boolean) {
    this.store.dispatch(new UpdateScheduleLocationModalState({ isTelehealthSelected: value }));
  }

  ngOnInit() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetScheduleAppointmentAddress),
        withLatestFrom(this.nursePractitioners$),
        take(1)
      )
      .subscribe(([, npList]) => {
        // Check if there was already an address with an associated NP selected
        const addressWithAssociatedNP = this.store.selectSnapshot((state) => {
          const careState = state.careState as HraCareStateModel;
          return careState.scheduleAppointmentDetails?.appointmentAddress?.np_id;
        });
        const NPForSelectedAddress = addressWithAssociatedNP
          ? npList.find((np) => np.global_id === addressWithAssociatedNP)
          : null;

        // Check if there is a currently selected NP
        const currentlySelectedNP = this.store.selectSnapshot((state) => {
          const careState = state.careState as HraCareStateModel;
          return careState.scheduleAppointmentDetails?.assignedNP;
        });

        // If there is no currently selected NP, but there is an address with an associated NP, select that NP
        if (!currentlySelectedNP && NPForSelectedAddress) {
          this.store.dispatch(
            new SetScheduleAppointmentNursePractitioner({
              nursePractitioner: NPForSelectedAddress,
            })
          );
        }
      });

    this.selectedAddress$.pipe(take(1)).subscribe((address) => {
      if (address && address?.isTelehealth) {
        this.store.dispatch(
          new UpdateScheduleLocationModalState({ isTelehealthSelected: address?.isTelehealth })
        );
      }
    });
  }
}
