import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, combineLatest, take, takeUntil, tap } from 'rxjs';

import {
  ActionStatusEnum,
  ActionsState,
  ComputedActionValues,
  GetSingleAction,
} from '@troyai/actions/data-access';
import {
  ActionEstimatedCompletionTimeComponent,
  ActionResourcesListComponent,
  ActionRichTextDescriptionComponent,
  RelatedActionsListComponent,
} from '@troyai/actions/ui';
import { UserRoles } from '@troyai/auth/data-access';
import { RolesDirective, UserRolesService } from '@troyai/auth/feature';
import { FeatureFlagDirective } from '@troyai/feature-flags';
import {
  GetNursePractitionersList,
  HraScheduleLocationModalState,
  SetScheduleAppointmentAddress,
  SetScheduleAppointmentNursePractitioner,
  UpdateScheduleLocationModalState,
} from '@troyai/hra/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HraFormModalComponent } from '@troyai/hra/feature';
import { GetPatientMedicationsList } from '@troyai/medication/medication-data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { GetDiagnosesList } from '@troyai/portal/diagnoses/data-access';
import { TrackDirective, TrackService } from '@troyai/tracking';
import {
  ButtonComponent,
  CardComponent,
  IconsModule,
  ModalModule,
  ModalService,
} from '@troyai/ui-kit';
import { RelatedActionsPipe } from '../../../../pipes/related-actions.pipe';
import { ChatWindowComponent } from '../../../action-chat/chat-window/chat-window.component';
import { ActionCtaCardComponent } from '../../../action-cta-card/action-cta-card.component';
import { ActionIframeEmbedComponent } from '../../../action-iframe-embed/action-iframe-embed.component';
import { ActionLayoutRegularComponent } from '../../../action-layout-regular/action-layout-regular.component';
import { ActionSingleHeaderComponent } from '../../../action-single-header/action-single-header.component';
import { OutreachAttemptsListComponent } from '../../../outreach-attempts/outreach-attempts-list/outreach-attempts-list.component';
import { PatientSchedulerLocationModalComponent } from '../../../patient-scheduler-location-modal/patient-scheduler-location-modal.component';
import { PatientHealthDataPanelsComponent } from '../../partials/patient-health-data-panels/patient-health-data-panels.component';
import { ActionCompletedHraComponent } from '../action-completed-hra/action-completed-hra.component';

@Component({
  selector: 't-action-hra01-template',
  standalone: true,
  imports: [
    CommonModule,
    ActionLayoutRegularComponent,
    IconsModule,
    ActionRichTextDescriptionComponent,
    ActionIframeEmbedComponent,
    ActionResourcesListComponent,
    ChatWindowComponent,
    ActionSingleHeaderComponent,
    ActionCompletedHraComponent,
    RolesDirective,
    CardComponent,
    ActionEstimatedCompletionTimeComponent,
    ButtonComponent,
    ModalModule,
    FullNamePipe,
    TrackDirective,
    RelatedActionsListComponent,
    OutreachAttemptsListComponent,
    RelatedActionsPipe,
    PatientHealthDataPanelsComponent,
    FeatureFlagDirective,
    ActionCtaCardComponent,
  ],
  templateUrl: './action-hra01-template.component.html',
})
export class ActionHra01TemplateComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private modalService: ModalService,
    private fullNamePipe: FullNamePipe,
    private userRolesService: UserRolesService,
    private trackService: TrackService
  ) {}

  @Input({ required: true }) computedActionValues!: ComputedActionValues;

  displaySchedulingCTA = false;
  displayFormCTA = false;
  userRoles = UserRoles;
  actionStatuses = ActionStatusEnum;

  private patientName = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  patient$ = this.store.select(PatientsState.selectedPatient);
  action$ = this.store.select(ActionsState.selectedAction);

  openSchedulingLocationModal() {
    this.store.dispatch([
      new SetScheduleAppointmentAddress({
        address: undefined,
      }),
      new SetScheduleAppointmentNursePractitioner({
        nursePractitioner: undefined,
      }),
    ]);
    this.modalService.openDialog(PatientSchedulerLocationModalComponent, null, {
      title: `Schedule NP Health Assessment for ${this.patientName}`,
      background: 'grey',
    });
  }

  openHraFormModal() {
    const hraFormModalRef = this.modalService.openDialog(HraFormModalComponent, null, {
      title: 'HRA Form',
      isFullscreen: true,
      offsetTop: 1,
      preventCloseOnPromptConfirm: () => {
        hraFormModalRef.close();
      },
      preventClosePromptMessage: '',
      preventClosePromptTitle: 'Discard assessment',
    });
  }

  ngOnInit() {
    this.patient$.pipe(takeUntil(this.destroy$)).subscribe((patient) => {
      if (patient) {
        this.patientName = this.fullNamePipe.transform(patient);
        this.store.dispatch([
          new GetPatientMedicationsList(patient.global_id),
          new GetDiagnosesList(patient.global_id),
        ]);
      }
    });

    this.store.dispatch(new GetNursePractitionersList());

    combineLatest([
      this.action$,
      this.userRolesService.hasAccess([UserRoles.PharmacyUser]),
      this.userRolesService.hasAccess([UserRoles.CareTeamHRA]),
      this.userRolesService.hasAccess([UserRoles.CMExternal]),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([action, hasAccessAsPharmacyUser, hasAccessAsCareTeamHRA, hasAccessAsCMExternal]) => {
          this.displaySchedulingCTA =
            (hasAccessAsPharmacyUser || hasAccessAsCareTeamHRA || hasAccessAsCMExternal) &&
            action?.status === ActionStatusEnum.NEW;
          this.displayFormCTA =
            hasAccessAsCareTeamHRA && action?.status === ActionStatusEnum.SCHEDULED;

          // By default, the location modal state is set for the pharmacy user
          let locationModalState: HraScheduleLocationModalState = {
            hasTelehealthSupport: false,
            isTelehealthReadOnly: false,
            allowTelehealthAtPharmacy: false,
            hasPharmacyLocationSupport: true,
            hasMemberAddressesSupport: true,
            isTelehealthSelected: false,
          };

          if (hasAccessAsCareTeamHRA) {
            locationModalState = {
              hasTelehealthSupport: false,
              isTelehealthReadOnly: true,
              allowTelehealthAtPharmacy: false,
              hasPharmacyLocationSupport: true,
              hasMemberAddressesSupport: true,
              isTelehealthSelected: false,
            };
          }

          if (hasAccessAsCMExternal) {
            locationModalState = {
              hasTelehealthSupport: true,
              isTelehealthReadOnly: false,
              allowTelehealthAtPharmacy: true,
              hasPharmacyLocationSupport: true,
              hasMemberAddressesSupport: true,
              isTelehealthSelected: false,
            };
          }

          this.store.dispatch(new UpdateScheduleLocationModalState(locationModalState));
        }
      );
  }

  handlePostSubmitData() {
    this.action$
      .pipe(
        take(1),
        tap((action) => {
          if (action) {
            this.trackService.trackEvent({
              eventName: 'Submitted action form',
              eventProps: {
                member_id: action.member_assigned_to,
                action_id: action.global_id,
              },
            });
          }

          this.store.dispatch(new GetSingleAction({ globalId: action?.global_id }));
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
