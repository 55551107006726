<ng-container *ngIf="action$ | async as action">
  <ng-container *ngIf="action.status === 'Scheduled'; else defaultCompletedState">
    <t-action-status-card [titleText]="action.start_date | customMediumDate: 'UTC'">
      <div class="text-grey-70 mb-2 w-full space-y-2 pt-3 text-xs">
        <div class="flex items-start gap-x-2">
          <t-icon class="mt-0.5" key="location-on" size="tiniest" color="inherit"></t-icon>
          <span>
            {{ action.scheduled_address | formattedAddress }}
          </span>

          <div class="flex flex-wrap items-start gap-2">
            <t-label size="tiny" [highContrast]="true" color="warning">
              {{ action.scheduled_address?.at_pharmacy ? 'At the Pharmacy' : 'At Home' }}
            </t-label>
            <ng-container *tIfHasRole="[userRoles.CMExternal]">
              <t-label
                *ngIf="action.scheduled_address?.scheduled_telehealth"
                size="tiny"
                [highContrast]="true"
                color="success">
                Telehealth
              </t-label>
            </ng-container>
          </div>
        </div>

        <div class="flex items-start gap-x-2">
          <t-icon class="mt-0.5" key="event" size="tiniest" color="inherit"></t-icon>
          <span>
            Scheduled by {{ action.scheduled_by }} on
            {{ action.scheduled_at | customMediumDate }}
          </span>
        </div>
      </div>

      <t-link (click)="openSchedulingModal()" size="large" variant="accent" [isButton]="true">
        Reschedule
      </t-link>
    </t-action-status-card>
  </ng-container>

  <ng-template #defaultCompletedState>
    <t-action-status-card titleText="Completed on {{ action.start_date | date: 'mediumDate' }}">
      <div class="text-grey-70 w-full space-y-2 pt-3 text-xs">
        <div class="flex items-start gap-x-2">
          <t-icon class="mt-0.5" key="location-on" size="tiniest" color="inherit"></t-icon>
          <span *ngIf="action.scheduled_address?.address_street">
            {{ action.scheduled_address?.address_street }}
          </span>
          <div class="bg-amber-70 rounded-full px-2 text-xs text-white">
            {{ action.scheduled_address?.at_pharmacy ? 'At the Pharmacy' : 'At Home' }}
          </div>
        </div>

        <div class="flex items-start gap-x-2">
          <t-icon class="mt-0.5" key="event" size="tiniest" color="inherit"></t-icon>
          <span>
            Scheduled by {{ action.scheduled_by }} on
            {{ action.scheduled_at | customMediumDate }}
          </span>
        </div>
      </div>
    </t-action-status-card>
  </ng-template>
</ng-container>
