@if (viewData$ | async; as viewData) {
  @if (viewData.isModalLoading) {
    <t-skeleton-loader [rows]="3" rowsHeight="huge" />
  } @else {
    @if (viewData.action && viewData.action.status === actionStatusEnum.SCHEDULED) {
      <div class="w-full">
        <span class="text-grey-70 mb-3 block text-xs uppercase tracking-wider">
          Scheduled appointment:
        </span>
        <t-card class="block w-full" [isInteractive]="false">
          <t-action-status-card [titleText]="viewData.action.start_date | date: 'medium' : 'UTC'">
            <t-action-appointment-preview
              [action]="viewData.action"
              (canceledAppointment)="cancelAppointment()" />
          </t-action-status-card>
        </t-card>
      </div>

      <div class="border-grey-20 mb-2 border-b pb-5 pt-8">
        <span class="text-grey-70 block text-xs uppercase tracking-wider"> Reschedule </span>
      </div>
    }

    @if (viewData.scheduleDetails) {
      <div class="flex gap-x-5">
        <t-card [isInteractive]="false" class="w-2/3" paddingSize="none">
          <div class="space-y-2 p-4">
            <span class="text-grey-50 block text-sm"> Address </span>

            <div class="flex items-end justify-between">
              @if (viewData.scheduleDetails.appointmentAddress) {
                <span class="text-grey-100 text-sm">
                  {{ viewData.scheduleDetails.appointmentAddress | formattedAddress }}
                </span>
              }

              <t-link
                [isDisabled]="viewData.action?.status === actionStatusEnum.SCHEDULED"
                (click)="
                  viewData.action?.status !== actionStatusEnum.SCHEDULED
                    ? openSchedulingLocationModal()
                    : null
                "
                [isButton]="true">
                Change
              </t-link>
            </div>
          </div>
        </t-card>

        <t-card [isInteractive]="false" class="w-1/3" paddingSize="none">
          <div class="space-y-2 p-4">
            <span class="text-grey-50 block text-sm">NP</span>

            <div class="flex items-end justify-between">
              @if (viewData.scheduleDetails.assignedNP) {
                <span class="text-grey-100 text-sm">
                  {{ viewData.scheduleDetails.assignedNP.name }}
                </span>
              } @else {
                <span class="text-grey-80 text-sm">Not assigned</span>
              }

              <t-link
                [isDisabled]="viewData.action?.status === actionStatusEnum.SCHEDULED"
                (click)="
                  viewData.action?.status !== actionStatusEnum.SCHEDULED
                    ? openNpSelectionModalModal()
                    : null
                "
                [isButton]="true">
                Change
              </t-link>
            </div>
          </div>
        </t-card>
      </div>
    }

    <div class="border-grey-25 mt-5 border-t pb-6 pt-5">
      <t-week-navigator
        [timePeriod]="appointmentsPeriod$ | async"
        (weekChange)="onWeekChange($event)" />
    </div>

    @if (appointmentsListItemsLoading$ | async) {
      <t-skeleton-loader [rows]="10" />
    } @else if (!viewData.isSelectedCountyCoveredByNP) {
      <div class="mx-auto min-h-[200px] max-w-sm">
        <t-empty-state-card
          content="Unfortunately, {{
            viewData.scheduleDetails?.appointmentAddress?.address_county
          }} county is not covered by our NPs at the moment. Please leave us a message and we'll organize a visit separately."
          [hasIcon]="true">
        </t-empty-state-card>
      </div>
    } @else {
      <ng-container *ngIf="groupedAppointmentsListItems$ | async as appointmentsGroups">
        <t-accordion *ngIf="appointmentsGroups.length; else appointmentsEmptyState">
          <t-accordion-item
            *ngFor="let appointmentGroup of appointmentsGroups; let index = index"
            [idx]="index"
            [expanded]="appointmentGroup.isOpen">
            <span header class="text-grey-50 text-base font-semibold">
              {{ appointmentGroup.day | date: 'EEEE, MMMM d' }}
            </span>

            <div *ngIf="appointmentGroup.isDayRestrictedToCounty" class="px-2 py-3">
              <div class="bg-amber-20 text-amber-70 flex items-center gap-x-2 rounded-lg p-4">
                <t-icon key="info-circle" size="tiny" color="inherit" />
                <span>
                  NP available only in
                  {{ appointmentGroup.timeslots[0].restricted_to_county }} County
                </span>
              </div>
            </div>

            <div
              *ngIf="!appointmentGroup.isDayRestrictedToCounty"
              class="divide-grey-20 border-grey-20 mt-2 divide-y border-t">
              <div
                *ngFor="let timeslot of appointmentGroup.timeslots"
                class="px-2 py-3 lg:flex lg:items-center">
                <!-- Time intervals -->
                <div class="text-grey-70 mb-4 block text-sm font-semibold lg:mb-0 lg:w-3/12">
                  <!-- TODO: Handle timezones more efficiently -->
                  {{ timeslot.start_time | date: 'shortTime' : 'UTC+0' }} -
                  {{ timeslot.end_time | date: 'shortTime' : 'UTC+0' }}
                </div>
                <!-- /Time intervals -->

                <!-- Scheduled member area -->
                <div class="text-grey-50 text-base lg:w-4/12 lg:text-sm">
                  <span *ngIf="timeslot.member_id !== '0'; else availableTimeSlot">
                    <strong class="font-semibold">Booked at:</strong> {{ timeslot.address_city }},
                    {{ timeslot.address_county }}
                  </span>
                  <ng-template #availableTimeSlot>
                    <div class="flex gap-x-1">
                      <span class="text-green-90">Available</span>
                    </div>
                  </ng-template>
                </div>
                <!-- Scheduled member area -->

                <!-- Scheduling actions area -->
                <div class="text-grey-50 flex justify-end gap-x-1 text-sm lg:w-5/12">
                  <t-button
                    *ngIf="timeslot.member_id === '0'"
                    [loading]="timeslot.isLoading || false"
                    [disabled]="viewData.action?.status === 'Scheduled'"
                    (onClick)="
                      viewData.action?.status !== 'Scheduled' ? schedulePatient(timeslot) : null
                    "
                    size="tiny"
                    variant="neutral">
                    {{ timeslot.isLoading ? 'Scheduling...' : 'Schedule' }}
                  </t-button>
                </div>
                <!-- /Scheduling actions area -->
              </div>
            </div>
          </t-accordion-item>
        </t-accordion>

        <ng-template #appointmentsEmptyState>
          <div class="bg-grey-60 flex min-h-[300px] items-center justify-center bg-opacity-5">
            <t-empty-state-card content="There are no available slots this week." [hasIcon]="true">
              <t-link [isButton]="true" (click)="onWeekChange('next')">Try next week</t-link>
            </t-empty-state-card>
          </div>
        </ng-template>
      </ng-container>
    }
  }
}
