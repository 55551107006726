import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PublicGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canLoad() {
    return this.authService.isAuthenticated$.pipe(
      take(1),
      map((authStatus) => !authStatus)
    );
  }

  canActivate(): Observable<boolean> {
    return this.redirectIfAuthenticated().pipe(map((authStatus) => !authStatus));
  }

  canActivateChild(): Observable<boolean> {
    return this.redirectIfAuthenticated().pipe(map((authStatus) => !authStatus));
  }

  private redirectIfAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (loggedIn) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
}
