import { Injectable } from '@angular/core';
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  Store,
  ofActionCompleted,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { delay, filter, tap, withLatestFrom } from 'rxjs';

import { GetCurrentUserDetails, UserPharmacy, UserState } from '@troyai/auth/data-access';
import { CacheService } from '@troyai/rest-api-client';
import { SelectedPharmacyLocalStorageService } from '../selected-pharmacy-local-storage.service';
import { PharmacyContextStateModel } from './pharmacy-context-state.model';
import { SetGlobalPharmacy, SetPharmacyList } from './pharmacy-context.actions';

@State<PharmacyContextStateModel>({
  name: 'pharmacyContextState',
  defaults: {
    pharmaciesList: [],
    pharmaciesListLoading: true,
    selectedPharmacy: {
      global_id: undefined,
      npi: null,
      name: 'All pharmacies',
    },
  },
})
@Injectable()
export class PharmacyContextState {
  constructor(
    private actions$: Actions,
    private store: Store,
    private selectedPharmacyLocalStorageService: SelectedPharmacyLocalStorageService,
    private cacheService: CacheService
  ) {
    this.actions$
      .pipe(
        ofActionCompleted(GetCurrentUserDetails),
        delay(1),
        filter((action) => action.result.successful),
        tap(() => {
          this.store.dispatch(new SetPharmacyList());
        }),
        withLatestFrom(this.selectedPharmacyLocalStorageService.localStorageSelectedPharmacy$()),
        tap(([, storedPharmacy]) => {
          const currentUserState = this.store.selectSnapshot(UserState.userPharmacies);

          const currentUserAssociatedPharmaciesIds = currentUserState
            ? currentUserState.map((pharmacy) => pharmacy.global_id)
            : null;

          const pharmaciesList = this.store.selectSnapshot(PharmacyContextState.pharmaciesList);

          // Update the stored pharmacy with the latest data from the list of pharmacies
          const latestDataForStoredPharmacy = pharmaciesList.find(
            (pharmacy) => pharmacy.global_id === storedPharmacy?.global_id
          );
          let pharmacyToSelect = {
            ...storedPharmacy,
            ...latestDataForStoredPharmacy,
          } as UserPharmacy;

          // There is no selected pharmacy in local storage, so select the first pharmacy in the list
          if (!pharmacyToSelect) {
            const firstPharmacy = pharmaciesList[0];
            if (firstPharmacy) {
              this.selectedPharmacyLocalStorageService.storeSelectedPharmacy(firstPharmacy);
              pharmacyToSelect = firstPharmacy;
            } else {
              const allPharmaciesSelection = {
                global_id: null,
                name: 'All pharmacies',
                npi: null,
              };
              this.selectedPharmacyLocalStorageService.storeSelectedPharmacy(
                allPharmaciesSelection
              );
              pharmacyToSelect = allPharmaciesSelection;
            }
          }

          if (pharmacyToSelect) {
            // The case where the already selected pharmacy ID stored value is not in the list of pharmacies anymore
            if (
              !currentUserAssociatedPharmaciesIds?.includes(pharmacyToSelect.global_id) &&
              pharmacyToSelect.global_id !== null
            ) {
              const firstPharmacy = pharmaciesList[0];
              if (firstPharmacy) {
                this.selectedPharmacyLocalStorageService.storeSelectedPharmacy(firstPharmacy);
                pharmacyToSelect = firstPharmacy;
              }
            }
            store.dispatch(new SetGlobalPharmacy({ pharmacy: pharmacyToSelect }));
          }
        })
      )
      .subscribe();
  }

  @Selector()
  static pharmaciesList(state: PharmacyContextStateModel) {
    return state.pharmaciesList;
  }

  @Selector()
  static pharmaciesListLoading(state: PharmacyContextStateModel) {
    return state.pharmaciesListLoading;
  }

  @Selector()
  static selectedPharmacy(state: PharmacyContextStateModel) {
    return state.selectedPharmacy;
  }

  @Selector()
  static selectedPharmacyId(state: PharmacyContextStateModel) {
    return state.selectedPharmacy?.global_id;
  }

  @Action(SetPharmacyList)
  setPharmaciesList({ setState }: StateContext<PharmacyContextStateModel>) {
    const pharmacies = this.store.selectSnapshot(UserState.userPharmacies);
    if (pharmacies) {
      return setState(
        patch<PharmacyContextStateModel>({
          pharmaciesList: pharmacies,
        })
      );
    }

    return null;
  }

  @Action(SetGlobalPharmacy)
  setPharmacyId({ setState }: StateContext<PharmacyContextStateModel>, action: SetGlobalPharmacy) {
    const selectedPharmacy = action.payload.pharmacy;

    if (selectedPharmacy) {
      this.cacheService.clearAll();
      this.selectedPharmacyLocalStorageService.storeSelectedPharmacy(selectedPharmacy);

      return setState(
        patch<PharmacyContextStateModel>({
          selectedPharmacy,
        })
      );
    }

    return null;
  }
}
