<ol>
  <li *ngFor="let entry of entries; let last = last" class="relative pb-4 lg:flex">
    <div class="flex-none lg:w-1/4">
      <ng-container
        *ngTemplateOutlet="timeTemplate; context: { $implicit: entry, last: last }"></ng-container>
    </div>

    <div class="ml-6 flex-auto lg:ml-4">
      <ng-container
        *ngTemplateOutlet="contentTemplate; context: { $implicit: entry }"></ng-container>
    </div>
  </li>
</ol>
