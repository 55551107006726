import {
  CarePlanCreationSection,
  CarePlanCreationSectionIds,
  CarePlanTemplate,
  CarePlanTemplateHraMeta,
  CreateMemberCarePlanPayload,
  MemberCarePlan,
  PatchMemberCarePlanPayload,
  PublishMemberCarePlanPayload,
} from '../models';

export class GetAllMemberCarePlans {
  static readonly type = '[Patient][Care plans] Get all member care plans';
  constructor(public payload: { memberGlobalId: number; silently?: boolean }) {}
}

export class GetAllCarePlansTemplates {
  static readonly type = '[Care plans] Get all care plans templates';
}

export class GetSingleCarePlan {
  static readonly type = '[Care plans] Get single care plan';
  constructor(public payload: { memberGlobalId: number; carePlanId: number }) {}
}

export class CreateMemberCarePlan {
  static readonly type = '[Care plans] Create care plan for member';
  constructor(public payload: { memberGlobalId: number; data: CreateMemberCarePlanPayload }) {}
}

export class UpdateMemberCarePlan {
  static readonly type = '[Care plans] Update care plan for member';
  constructor(
    public payload: {
      memberGlobalId: number;
      carePlanId: number;
      data: PatchMemberCarePlanPayload;
    }
  ) {}
}

export class PublishMemberCarePlan {
  static readonly type = '[Care plans] Publish care plan for member';
  constructor(public payload: { memberGlobalId: number; data: PublishMemberCarePlanPayload }) {}
}

export class SetWorkingDraftCarePlanId {
  static readonly type = '[Care plans] Set working draft care plan id';
  constructor(public payload: number | null) {}
}

export class PrependMemberCarePlanToListing {
  static readonly type = '[Care plans] Prepend member care plan to listing';
  constructor(public payload: MemberCarePlan) {}
}

export class SetCarePlanForEditing {
  static readonly type = '[Care plans] Set care plan for editing';
  constructor(public payload: MemberCarePlan) {}
}

export class AssociateCaseNoteToCarePlan {
  static readonly type = '[Care plans] Associate case note to care plan';
  constructor(public payload: { memberGlobalId: number; carePlanId: number; caseNoteId: number }) {}
}

export class UnAssociateCaseNoteFromCarePlan {
  static readonly type = '[Care plans] Un-associate case note from care plan';
  constructor(public payload: { memberGlobalId: number; carePlanId: number; caseNoteId: number }) {}
}

export class SelectCarePlanTemplate {
  static readonly type = '[Care plans] Select care plan template';
  constructor(public payload: { template: CarePlanTemplate | null }) {}
}

export class ResetWorkingDraftCarePlan {
  static readonly type = '[Care plans] Reset working draft care plan';
}

export class GetCarePlanChanges {
  static readonly type = '[Care plans] Get care plan changes';
  constructor(public payload: { memberGlobalId: number; carePlanId: number }) {}
}

export class ToggleCarePlanSectionForEditing {
  static readonly type = '[Care plans] Select care plan section for editing';
  constructor(public payload: { sectionId: CarePlanCreationSectionIds | null }) {}
}

export class SetHraMetaForCarePlan {
  static readonly type = '[Care plans] Set hra meta for care plan';
  constructor(
    public payload: { hraMeta: CarePlanTemplateHraMeta; section: CarePlanCreationSection }
  ) {}
}

export class SetNextReviewDateForCarePlan {
  static readonly type = '[Care plans] Set next review date for care plan';
  constructor(public payload: { date: string }) {}
}

export class SetCarePlanDetails {
  static readonly type = '[Care plans] Set care plan details';
  constructor(public payload: { content: string }) {}
}
