import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import { concatMap, map, of, switchMap, tap } from 'rxjs';
import { DynamicFormsApiService } from '../dynamic-forms-api.service';
import { DynamicFormsApiResponse } from '../models/dynamic-forms-api.model';
import {
  AddNewFormSubmission,
  GetAllSubmissionDrafts,
  GetDynamicFormData,
  GetFormSubmissionContents,
  PopulateDataFromControlEnrichment,
  UpdateFormSubmission,
} from './dynamic-forms-state.actions';
import { DynamicFormsStateModel } from './dynamic-forms-state.model';
import {
  jotformToDynamicControls,
  transformContentKeysToFieldIds,
} from './dynamic-forms-state.util';

@State<DynamicFormsStateModel>({
  name: 'dynamicFormState',
  defaults: {
    currentDynamicFormData: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
    addFormSubmission: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
    allSubmissionDrafts: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
    currentSubmissionId: null,
  },
})
@Injectable()
export class DynamicFormState {
  constructor(private dynamicFormsApiService: DynamicFormsApiService) {}

  @Selector()
  static currentDynamicFormData(state: DynamicFormsStateModel) {
    return state.currentDynamicFormData.result;
  }

  @Selector()
  static currentDynamicFormDataLoadingStatus(state: DynamicFormsStateModel) {
    return getLoadingStatus(state.currentDynamicFormData.loadingStatus);
  }

  @Selector()
  static addingFormSubmissionLoadingStatus(state: DynamicFormsStateModel) {
    return getLoadingStatus(state.addFormSubmission.loadingStatus);
  }

  @Selector()
  static currentSubmissionId(state: DynamicFormsStateModel) {
    return state.currentSubmissionId;
  }

  @Selector()
  static allSubmissionDrafts(state: DynamicFormsStateModel) {
    return state.allSubmissionDrafts.result;
  }

  @Selector()
  static allSubmissionDraftsLoadingStatus(state: DynamicFormsStateModel) {
    return getLoadingStatus(state.allSubmissionDrafts.loadingStatus);
  }

  @Action(GetDynamicFormData)
  getDynamicFormData(
    { patchState }: StateContext<DynamicFormsStateModel>,
    action: GetDynamicFormData
  ) {
    patchState({
      currentDynamicFormData: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    const prefillData = action.prefilledData;

    return this.dynamicFormsApiService.getJotformDynamicFormData(action.formId).pipe(
      tap((formData) => {
        const entries = Object.entries(formData.controls).map((item) => {
          const entry = item[1];

          if (prefillData && entry.name && prefillData[entry.name]) {
            return {
              ...entry,
              defaultValue: prefillData[entry.name],
            };
          }

          return entry;
        });

        const eligibleEntries = entries
          .filter((item) => item.order)
          .reduce(
            (acc, item) => {
              acc[item.order] = item;
              return acc;
            },
            {} as { [key: string]: unknown }
          );

        const processedFormData = jotformToDynamicControls({
          controls: eligibleEntries,
        } as DynamicFormsApiResponse);

        const sortedProcessedFormState = [...Object.values(processedFormData)].sort(
          (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
        );

        patchState({
          currentDynamicFormData: {
            result: sortedProcessedFormState,
            loadingStatus: LoadingState.LOADED,
          },
        });
      })
    );
  }

  @Action(GetFormSubmissionContents)
  getFormSubmissionContents(
    { patchState }: StateContext<DynamicFormsStateModel>,
    action: GetFormSubmissionContents
  ) {
    patchState({
      currentDynamicFormData: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });
    return this.dynamicFormsApiService.getFormSubmission(action.submissionId).pipe(
      concatMap((formSubmissionContent) => {
        return this.dynamicFormsApiService
          .getJotformDynamicFormData(formSubmissionContent.form_id)
          .pipe(
            map((formData) => {
              return {
                formData,
                formSubmissionContent,
              };
            })
          );
      }),
      tap((data) => {
        const entries = Object.entries(data.formData.controls).map((item) => item[1]);
        const eligibleEntries = entries
          .filter((item) => item.order)
          .reduce(
            (acc, item) => {
              acc[item.qid] = item;
              return acc;
            },
            {} as { [key: string]: unknown }
          );

        const updatedKeysFormSubmissionContent = transformContentKeysToFieldIds(
          data.formSubmissionContent.content
        );

        const formDataWithValues = Object.keys(updatedKeysFormSubmissionContent).reduce(
          (newFormStructure, key) => {
            const [qid] = key.split('_'); // Extract the numeric part before the underscore
            const value = updatedKeysFormSubmissionContent[key];
            const formFieldData = newFormStructure[qid];

            if (formFieldData) {
              // Check if the formStructure[qid] is an object before spreading
              if (typeof formFieldData === 'object' && formFieldData !== null) {
                // Return a new form structure with the updated `defaultValue` for the matched `qid`
                return {
                  ...newFormStructure,
                  [qid]: {
                    ...formFieldData,
                    defaultValue: value,
                  },
                };
              }
            }

            // If there's no match, return the structure as is
            return newFormStructure;
          },
          { ...eligibleEntries }
        ); // Initial structure is the current `formStructure` copy

        const processedFormData = jotformToDynamicControls({
          controls: formDataWithValues,
        } as DynamicFormsApiResponse);

        const sortedProcessedFormState = [...Object.values(processedFormData)].sort(
          (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
        );

        patchState({
          currentDynamicFormData: {
            result: sortedProcessedFormState,
            loadingStatus: LoadingState.LOADED,
          },
          currentSubmissionId: action.submissionId,
        });
      })
    );
  }

  @Action(AddNewFormSubmission)
  createNewFormSubmission(
    { patchState }: StateContext<DynamicFormsStateModel>,
    action: AddNewFormSubmission
  ) {
    patchState({
      addFormSubmission: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    if (!action.directSubmit) {
      return this.dynamicFormsApiService.addFormSubmission(action.payload).pipe(
        tap((submissionId) => {
          patchState({
            addFormSubmission: {
              result: null,
              loadingStatus: LoadingState.LOADED,
            },
            currentSubmissionId: submissionId,
          });
        })
      );
    } else {
      return this.dynamicFormsApiService.addFormSubmission(action.payload).pipe(
        tap((submissionId) => {
          patchState({
            currentSubmissionId: submissionId,
          });
        }),
        switchMap((submissionId) => {
          return this.dynamicFormsApiService.updateFormSubmission(submissionId, {
            final_submission: true,
            ...action.payload,
          });
        }),
        tap(() => {
          patchState({
            addFormSubmission: {
              result: null,
              loadingStatus: LoadingState.LOADED,
            },
            currentSubmissionId: null,
          });
        })
      );
    }
  }

  @Action(UpdateFormSubmission)
  updateFormSubmission(
    _context: StateContext<DynamicFormsStateModel>,
    action: UpdateFormSubmission
  ) {
    return this.dynamicFormsApiService.updateFormSubmission(action.submissionId, action.payload);
  }

  @Action(PopulateDataFromControlEnrichment)
  populateDataFromControlEnrichment(
    _ctx: StateContext<DynamicFormsStateModel>,
    action: PopulateDataFromControlEnrichment
  ) {
    return of({
      formControlName: action.formControlName,
      data: action.data,
    });
  }

  @Action(GetAllSubmissionDrafts)
  getAllSubmissionDrafts({ patchState }: StateContext<DynamicFormsStateModel>) {
    patchState({
      allSubmissionDrafts: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.dynamicFormsApiService.getAllDraftSubmissions().pipe(
      tap((drafts) => {
        patchState({
          allSubmissionDrafts: {
            result: drafts,
            loadingStatus: LoadingState.LOADED,
          },
        });
      })
    );
  }
}
