import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';
import { IconsModule } from '../../icon/icon.module';

@Component({
  selector: 't-dropdown-menu-item',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './dropdown-menu-item.component.html',
})
export class DropdownMenuItemComponent {
  @Input() hasNestedMenu = false;
  @Input() iconKey: SvgIcons | null = null;
}
