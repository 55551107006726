<div class="relative space-y-5 p-4" *ngIf="user$ | async as currentUser">
  <div *ngFor="let item of items" class="flex max-w-full items-start gap-x-3">
    <div
      *ngIf="isTeamUser(item.author); else nonTeamUser"
      class="bg-red-90 flex h-8 w-8 flex-none items-center justify-center rounded-xl text-white">
      <t-icon key="troy-t-letter" size="small" color="inherit"></t-icon>
    </div>

    <ng-template #nonTeamUser>
      <div
        class="bg-amber-30 text-grey-100 flex h-8 w-8 flex-none items-center justify-center rounded-xl">
        <t-icon key="storefront" size="small" color="inherit"></t-icon>
      </div>
    </ng-template>

    <div class="w-[calc(100%-52px)] space-y-2">
      <div class="flex flex-wrap items-center justify-start gap-x-2">
        <strong class="text-grey-100 truncate text-xs font-semibold">
          {{ currentUser.email === item.author ? 'You' : item.author }}
        </strong>
        <span class="text-grey-50 text-xs">{{ item.created_at | date: 'mediumDate' }}</span>
      </div>
      <p
        class="text-grey-100 message-content w-full break-words text-xs"
        [innerHTML]="item.message | linkifyHtml | sanitizeHtml"></p>
    </div>
  </div>
</div>
