<div
  class="w-full lg:grid lg:min-h-full lg:grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_300px] lg:gap-5">
  <div class="lg:col-span-2">
    <div>
      <!-- Content Section -->
      @if (mobileMode === true) {
        <t-tabs>
          <t-tab label="ACTION">
            <ng-container *ngTemplateOutlet="contentTemplate" />
          </t-tab>
          <t-tab label="CHAT">
            <div class="relative h-[500px]">
              <ng-container *ngTemplateOutlet="sidebarContent" />
            </div>
          </t-tab>
        </t-tabs>
      } @else {
        <ng-container *ngTemplateOutlet="contentTemplate" />
      }
      <!-- /Content section -->
    </div>
  </div>

  <!-- Sidebar -->
  @if (mobileMode === false) {
    <div class="flex w-[300px] flex-col">
      <ng-container *ngTemplateOutlet="sidebarContent" />
    </div>
  }
  <!-- Sidebar -->
</div>

<ng-template #contentTemplate>
  <ng-content select="[content]" />
</ng-template>

<ng-template #sidebarContent>
  <ng-content select="[sidebar]" />
</ng-template>
