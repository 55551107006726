<a
  *ngFor="let action of actions; index as i"
  [routerLink]="['/patients', action.member_assigned_to, 'actions', action.global_id]"
  class="relative block">
  <t-card class="relative mb-2 block">
    <div class="flex flex-col md:flex-row md:items-center">
      <div class="flex flex-col items-start md:w-4/12 md:flex-row md:items-center">
        <div class="bg-grey-10 h-[60px] w-[60px] flex-none rounded-full">
          <img [src]="action.fee_schedule_image" tImgFallback="/assets/media-call-center.svg" />
        </div>

        <div class="w-1/2 space-y-1.5 pt-4 md:ml-5 md:w-auto md:pt-0">
          <span class="text-grey-100 block text-sm font-semibold">{{ action.title }}</span>
          <span class="text-grey-70 block text-xs">
            {{ action.due_date | expirationDate }}
          </span>
        </div>
      </div>

      <div class="hidden space-y-1.5 md:block md:w-5/12">
        <ng-container *ngIf="showMemberDetails">
          <span class="text-grey-70 block text-sm">
            {{ action.member | fullName | titlecase }}
          </span>
          <div class="text-grey-60 flex items-center gap-x-2">
            <span class="text-grey-60 block text-xs">
              {{ action.member.dob | dobDate }}
            </span>
            <t-pharmacy-indicator-tooltip
              *ngIf="selectedPharmacyId === null"
              class="hover:text-grey-70"
              [pharmacyName]="action.pharmacy_name" />
          </div>
        </ng-container>
      </div>

      <div
        class="border-t-grey-20 mt-4 flex items-center justify-between gap-x-2 border-t pt-4 md:mt-0 md:w-3/12 md:justify-end md:border-t-0 md:border-none md:pt-0">
        <div class="flex justify-end md:w-1/3">
          <t-label variant="pill" color="warning" size="tiny">{{ action.status }}</t-label>
        </div>

        <div
          class="absolute right-5 top-7 flex justify-end md:relative md:right-auto md:top-auto md:w-1/3">
          <t-label variant="pill" color="neutral" size="medium">
            {{ action.fee | currency: undefined : 'symbol' : '1.0-0' }}
          </t-label>
        </div>

        <div class="flex justify-end md:w-1/3">
          <t-button variant="neutral" size="tiny">VIEW</t-button>
        </div>
      </div>
    </div>
  </t-card>
</a>
