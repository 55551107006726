import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ScheduleRemoveReasonPromptComponent } from '@troyai/actions/feature';
import { ActionAppointmentPreviewComponent, ActionStatusCardComponent } from '@troyai/actions/ui';
import { UserRoles } from '@troyai/auth/data-access';
import {
  Appointment,
  CareState,
  GetAppointmentActionPreview,
  UnscheduleAppointmentForPatientAsPharmacy,
} from '@troyai/hra/data-access';
import { CardComponent, ModalService } from '@troyai/ui-kit';
import { take } from 'rxjs';

@Component({
  selector: 't-action-appointment-preview-modal',
  standalone: true,
  imports: [
    CommonModule,
    ActionAppointmentPreviewComponent,
    ActionStatusCardComponent,
    CardComponent,
  ],
  templateUrl: './action-appointment-preview-modal.component.html',
})
export class ActionAppointmentPreviewModalComponent implements OnInit {
  constructor(
    private store: Store,
    @Inject(DIALOG_DATA)
    public data: {
      timeslot: Appointment;
      day: string;
    },
    private modalService: ModalService,
    private actions$: Actions
  ) {}

  destroyRef = inject(DestroyRef);
  action$ = this.store.select(CareState.selectedAppointmentActionPreview);

  canceledAppointment() {
    this.action$.pipe(take(1)).subscribe((action) => {
      if (action) {
        this.modalService.openPromptDialog(ScheduleRemoveReasonPromptComponent, {
          asRole: UserRoles.CareTeamHRA,
          actionId: action.global_id,
          timeslotId: this.data.timeslot.global_id,
          onPromptConfirm: (reason: string) => {
            this.store.dispatch(
              new UnscheduleAppointmentForPatientAsPharmacy({
                reason,
                actionId: action.global_id,
                timeslotId: this.data.timeslot.global_id,
              })
            );
          },
        });
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(
      new GetAppointmentActionPreview({ actionId: this.data.timeslot.action_global_id })
    );

    this.actions$
      .pipe(
        ofActionSuccessful(UnscheduleAppointmentForPatientAsPharmacy),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.modalService.closeAll();
      });
  }
}
