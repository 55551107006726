import { Diagnosis } from '@troyai/portal/diagnoses/data-access';

export const splitDiagnosesByType = (diagnoses: Diagnosis[] | null) => {
  if (!diagnoses)
    return {
      regularDiagnoses: [],
      potentialDiagnoses: [],
    };

  const regularDiagnoses = diagnoses.filter((diagnosis) => diagnosis.code);
  const potentialDiagnoses = diagnoses.filter((diagnosis) => !diagnosis.code);

  return {
    regularDiagnoses,
    potentialDiagnoses,
  };
};
