import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { ActionIframeEmbedComponent } from '@troyai/actions/feature';
import { Environment } from '@troyai/envs';
import {
  CheckDetailsAndAgreement,
  OnboardingState,
  SetOnboardingStepStatus,
} from '@troyai/onboarding/data-access';
import { ButtonComponent } from '@troyai/ui-kit';
import { filter, fromEvent, map, take, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 't-details-agreement-modal',
  standalone: true,
  imports: [CommonModule, ActionIframeEmbedComponent, ButtonComponent],
  templateUrl: './details-agreement-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsAgreementModalComponent implements OnInit {
  constructor(
    private env: Environment,
    private dialogRef: DialogRef,
    private store: Store,
    private sanitizer: DomSanitizer
  ) {}

  pharmacyToOnboard$ = this.store.select(OnboardingState.pharmacyToOnboard);
  onboardingAgreementData$ = this.store.select(OnboardingState.onboardingAgreementData);
  formUrl$ = this.onboardingAgreementData$.pipe(
    withLatestFrom(this.pharmacyToOnboard$),
    filter(([agreementData]) => !!agreementData),
    map(([agreementData, pharmacy]) => {
      const prefilledFields = `?pharmacyId=${pharmacy?.global_id}`;
      return this.env.production
        ? `${agreementData?.embeddedFormURL}${prefilledFields}`
        : `https://form.jotform.com/232822722542351${prefilledFields}`;
    })
  );

  displayAgreementEmbed = true;
  destroyRef = inject(DestroyRef);

  getSanitizedAgreementURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    fromEvent(window, 'message', { capture: true })
      .pipe(
        filter((event) => {
          const messageEvent = event as MessageEvent;
          return messageEvent.data === 'CLOSE_ACTION_FORM_MODAL';
        }),
        take(1),
        tap(() => {
          this.store.dispatch([
            new SetOnboardingStepStatus('details_and_agreement', {
              loading: true,
            }),
            new CheckDetailsAndAgreement(),
          ]);
        }),
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.dialogRef.close())
      )
      .subscribe();
  }
}
