import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { LinkComponent } from '../link/link.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 't-file-upload',
  standalone: true,
  imports: [CommonModule, NgxFileDropModule, FormsModule, LinkComponent, LoaderComponent],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent {
  @Input() allowedExtensions = '.jpeg';
  @Input() isLoading = false;
  @Output() filesUploaded = new EventEmitter<NgxFileDropEntry[]>();
  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.filesUploaded.emit(files);
  }
}
