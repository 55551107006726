/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioButtonItem {
  name: string;
  value: string;
  disabled?: true;
}

@Component({
  selector: 't-radio',
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
  templateUrl: './radio.component.html',
})
export class RadioComponent implements ControlValueAccessor {
  @Input() items: Array<RadioButtonItem> = [];
  @Input() label = '';
  @Input() groupName = 'group' + (Math.random() + 1).toString(36).substring(7);
  private index = 0;

  private _name = `${this.groupName}-${this.index++}`;
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  private innerValue: string | number | boolean = '';
  get value(): string | number | boolean {
    return this.innerValue;
  }

  set value(v: string | number | boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onModelChange(v);
    }
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string | number | boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onModelChange(value: string | number | boolean) {
    this.innerValue = value;
    this.onChange(value);
    this.onTouch(value);
  }
}
