<div class="space-y-2">
  <label class="text-grey-50 mb-2 block text-sm">{{ label }}</label>
  <div class="space-y-2">
    @for (option of options; track $index) {
      <t-checkbox
        class="block"
        [label]="option.label"
        [checked]="isChecked(option.value)"
        (toggled)="onCheckboxChange($event, option.value)" />
    }
  </div>
</div>
