<div
  [ngClass]="{
    'shadow-small': shadowSize === 'small',
    'shadow-large': shadowSize === 'large',
    'px-5 py-4': paddingSize === 'small',
    'px-6 py-5': paddingSize === 'large',
    'px-8 py-6': paddingSize === 'huge',
    'p-0': paddingSize === 'none',
    'rounded-lg': isRounded && radiusSize === 'large',
    'rounded-md': isRounded && radiusSize === 'small',
    'hover:border-grey-35 cursor-pointer transition-colors hover:border': isInteractive,
    'h-full': isFullHeight
  }"
  class="block max-h-full border border-white bg-white">
  <ng-content></ng-content>
</div>
