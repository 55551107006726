<div
  *ngIf="action.status === 'In Review'; else defaultCompletedState"
  class="flex items-center justify-between">
  <div>
    <div class="flex flex-col gap-x-2 md:flex-row md:items-center md:justify-start">
      <span class="text-green-90">
        <t-icon key="check-circle" size="tiny" color="inherit"></t-icon>
      </span>
      <span class="text-grey-100 text-sm font-semibold"> HRA Completed </span>
    </div>

    <span
      class="text-grey-70 block w-full pl-[22px] pt-2 text-xs"
      *ngIf="action.approved_for_payment_date">
      {{ action.approved_for_payment_date | customMediumDate }}
    </span>
  </div>

  <!-- The care plan modal was used for a demo, inactive until further development -->
  <!-- <t-button *ngIf="displayCarePlanPOC" size="medium" (click)="openCarePlanModal()">
    CARE PLAN
  </t-button> -->
</div>

<ng-template #defaultCompletedState>
  <div class="flex flex-col gap-x-2 md:flex-row md:items-center md:justify-start">
    <span class="text-green-90">
      <t-icon key="check-circle" size="tiny" color="inherit"></t-icon>
    </span>
    <span class="text-grey-100 text-sm font-semibold">
      Completed on {{ action.approved_for_payment_date | date: 'mediumDate' }}
    </span>
  </div>
</ng-template>
