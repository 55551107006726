export const getUniqueValuesByKey = <T>(data: T[], keyName: keyof T) => {
  const values = data.map((item) => item[keyName]) as string[];
  return Array.from([...new Set(values)]);
};

export const getUniqueValuesByNestedKey = <T>(
  data: T[],
  keyName: keyof T,
  nestedPropertyPath: string
) => {
  const values: string[] = data.reduce((result: string[], item) => {
    const collection = item[keyName];
    if (Array.isArray(collection)) {
      const nestedValues = collection.map((item) => item[nestedPropertyPath]);
      return [...result, ...nestedValues];
    }

    return result;
  }, []);

  return Array.from([...new Set(values)]);
};

export const moveKeyToBeginning = (obj: Record<string | number, unknown>, key: string | number) => {
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    // the object does not have the provided key
    return obj;
  }

  // get the value of the key
  const value = obj[key];

  // TODO: refactor AI generated code
  const newObj = {
    [key]: value,
    ...Object.entries(obj)
      .filter(([k]) => k !== key)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
  };

  return newObj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const moveItemToIndexByKey = <T>(
  arr: T[],
  key: keyof T,
  value: string | number,
  index: number
) => {
  // Find the index of the object with the given key and value
  const keyIndex = arr.findIndex((obj) => obj[key] === value);

  // If the object is not found, return the original array
  if (keyIndex === -1 || index === keyIndex) {
    return arr;
  }

  // Move the object to the new index
  const movedItem = arr[keyIndex];

  const newArray = [...arr.slice(0, keyIndex), ...arr.slice(keyIndex + 1)];
  return [...newArray.slice(0, index), movedItem, ...newArray.slice(index)];
};

export const groupByKey = <T>(data: T[], key: keyof T & string) => {
  return data.reduce(
    (acc, item) => {
      const groupKey = item[key] as string;
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    },
    {} as Record<string, T[]>
  );
};

export const objectEntriesToKeyValueArray = <T>(obj: Record<string, T>) => {
  return Object.entries(obj).map(([key, value]) => ({ key, value }));
};
