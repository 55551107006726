<t-content-wrapper-layout>
  <t-section headerTitle="NP Appointments">
    <div sectionControls class="flex justify-end space-x-2">
      <t-dropdown-button
        [tPopoverAnchor]="additionalActions"
        (click)="additionalActions.toggle()"></t-dropdown-button>

      <t-popover verticalAlign="below" horizontalAlign="end" #additionalActions hasBackdrop>
        <t-dropdown-menu>
          <t-dropdown-menu-item
            (click)="additionalActions.close(); openSyncCalendarModal()"
            iconKey="event">
            Sync calendar
          </t-dropdown-menu-item>

          <t-dropdown-menu-item
            (click)="additionalActions.close(); openPrintAppointmentsModal()"
            iconKey="event">
            Print
          </t-dropdown-menu-item>
        </t-dropdown-menu>
      </t-popover>
    </div>

    <div class="border-grey-20 border-t px-2 py-6">
      <t-week-navigator
        [timePeriod]="appointmentsPeriod$ | async"
        (weekChange)="onWeekChange($event)" />
    </div>

    <t-skeleton-loader
      [rows]="10"
      color="dark"
      *ngIf="appointmentsListItemsLoading$ | async; else appointmentsList">
    </t-skeleton-loader>

    <ng-template #appointmentsList>
      <ng-container *ngIf="groupedAppointmentsListItems$ | async as appointmentsGroups">
        <t-accordion *ngIf="appointmentsGroups.length; else appointmentsEmptyState">
          <t-accordion-item
            *ngFor="let appointmentGroup of appointmentsGroups"
            [idx]="appointmentGroup.idx"
            [expanded]="appointmentGroup.isOpen"
            (toggleChanged)="handleToggleChange($event)">
            <div header class="text-grey-100 flex items-center gap-x-2 text-base font-semibold">
              {{ appointmentGroup.day | date: 'mediumDate' }}
              <span
                [tPopoverAnchor]="groupedAppointmentsDropdownMenu"
                (click)="
                  $event.preventDefault();
                  groupedAppointmentsDropdownMenu.toggle();
                  $event.stopPropagation()
                "
                class="bg-grey-20 hover:bg-grey-30 flex cursor-pointer items-center justify-center rounded-full p-0.5">
                <t-icon key="more-vert" size="tiniest" color="inherit"></t-icon>
              </span>
              <t-popover
                verticalAlign="below"
                horizontalAlign="start"
                #groupedAppointmentsDropdownMenu
                hasBackdrop>
                <t-dropdown-menu>
                  <t-dropdown-menu-item
                    (click)="
                      groupedAppointmentsDropdownMenu.close();
                      removeAvailableTimeslotsForGroup(appointmentGroup.day)
                    "
                    iconKey="delete-forever">
                    Remove all available slots
                  </t-dropdown-menu-item>
                </t-dropdown-menu>
              </t-popover>
            </div>
            <div class="divide-grey-20 border-grey-20 mt-2 divide-y border-t">
              <a
                *ngFor="let timeslot of appointmentGroup.timeslots"
                [routerLink]="
                  timeslot.action_global_id ? ['/care/patients', timeslot.action_global_id] : null
                "
                class="block bg-white px-2 py-3 lg:flex lg:items-center"
                [ngClass]="{
                  'hover:bg-grey-10 transition-colors': timeslot.action_global_id
                }">
                <!-- Time intervals -->
                <div class="text-grey-70 mb-4 block text-sm font-semibold lg:mb-0 lg:w-2/12">
                  {{ timeslot.start_time | timeInterval: timeslot.end_time }}
                </div>
                <!-- /Time intervals -->

                <!-- Scheduled member area -->
                <div class="lg:w-7/12">
                  <div
                    *ngIf="timeslot.member_global_id !== null; else unassignedTimeslot"
                    class="text-grey-100 flex items-center text-base lg:text-sm">
                    <span class="block w-2/6 font-semibold">
                      {{ timeslot.member_name | titlecase }}
                    </span>

                    <div
                      *ngIf="timeslot.scheduled_telehealth; else physicalAppointment"
                      class="flex w-4/6 items-center gap-2 truncate text-xs">
                      <t-label size="tiny" [highContrast]="true" color="success">
                        Telehealth
                      </t-label>
                      <a
                        (click)="$event.stopPropagation()"
                        class="text-grey-70 block truncate"
                        [href]="timeslot.scheduled_telehealth_link"
                        target="_blank"
                        rel="noopener noreferrer">
                        {{ timeslot.scheduled_telehealth_link }}
                      </a>
                    </div>

                    <ng-template #physicalAppointment>
                      <div class="text-grey-50 w-4/6 truncate text-sm">
                        {{ timeslot.address_city | titlecase }} {{ timeslot.address_zip }}
                        {{ timeslot.address_city && timeslot.address_county ? ',' : '' }}
                        {{ timeslot.address_county | titlecase }}
                      </div>
                    </ng-template>

                    <div class="text-grey-50 w-2/6 text-sm" *ngIf="timeslot.primary_phone_number">
                      {{ timeslot.primary_phone_number | phone }}
                    </div>
                  </div>

                  <ng-template #unassignedTimeslot>
                    <div class="flex gap-x-1 text-base lg:text-sm">
                      <ng-container *ngIf="timeslot.blocked_at === null">
                        <span class="text-green-90">Available</span>
                        <t-link
                          size="large"
                          variant="light"
                          [isButton]="true"
                          (click)="removeTimeslot(timeslot.global_id, appointmentGroup.day)">
                          Remove
                        </t-link>
                      </ng-container>

                      <ng-container *ngIf="timeslot.blocked_at !== null">
                        <span class="text-grey-50">Slot removed.</span>
                        <t-link
                          size="large"
                          variant="light"
                          [isButton]="true"
                          (click)="restoreTimeslot(timeslot.global_id, appointmentGroup.day)">
                          Make it available
                        </t-link>
                      </ng-container>
                    </div>
                  </ng-template>
                </div>
                <!-- Scheduled member area -->

                <!-- Scheduling actions area -->
                <div class="text-grey-50 flex justify-end gap-x-1 text-sm lg:w-3/12">
                  <ng-container *ngIf="timeslot.member_global_id !== null; else scheduleAction">
                    <t-button
                      size="tiny"
                      variant="neutral"
                      (click)="
                        $event.preventDefault();
                        $event.stopPropagation();
                        viewAppointmentDetails(timeslot, appointmentGroup.day)
                      ">
                      VIEW
                    </t-button>
                    <t-button
                      size="tiny"
                      variant="neutral"
                      icon="open-in-new"
                      iconPos="right"
                      (click)="
                        $event.preventDefault();
                        $event.stopPropagation();
                        fetchAndOpenForm(timeslot.action_global_id)
                      ">
                      GO TO FORM
                    </t-button>
                  </ng-container>
                  <ng-template #scheduleAction>
                    <t-button
                      *ngIf="timeslot.blocked_at === null"
                      size="tiny"
                      (onClick)="openScheduleModal(timeslot)">
                      SCHEDULE
                    </t-button>
                  </ng-template>
                </div>
                <!-- /Scheduling actions area -->
              </a>
            </div>
          </t-accordion-item>
        </t-accordion>

        <ng-template #appointmentsEmptyState>
          <div class="bg-grey-60 flex min-h-[50vh] items-center justify-center bg-opacity-5">
            <t-empty-state-card
              content="There are no appointments found for the selected week."
              [hasIcon]="true" />
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </t-section>

  <t-section headerTitle="Assessment drafts" class="mt-10 block">
    @if (allSubmissionDrafts$ | async; as drafts) {
      @if (drafts.length) {
        <t-assessment-drafts-list [items]="(allSubmissionDrafts$ | async) || []" />
      } @else {
        <div
          class="bg-grey-60 relative flex min-h-[200px] flex-col items-center justify-center bg-opacity-5 text-center">
          <t-empty-state-card
            content="There are no available assessment drafts."
            [hasIcon]="true" />
        </div>
      }
    }
  </t-section>
</t-content-wrapper-layout>
