<div class="space-y-2">
  <label class="text-grey-50 mb-2 block text-sm">{{ label }}</label>
  <table class="table-auto bg-white">
    <thead class="bg-green-20 text-xs font-normal">
      <tr>
        <th class="bg-white"></th>
        @for (column of columns; track trackByColumn) {
          <th class="px-4 py-3 text-center text-xs font-normal">{{ column }}</th>
        }
      </tr>
    </thead>
    <tbody>
      @for (row of rows; track trackByRow) {
        <tr class="border-grey-20 border">
          <td class="bg-green-20 max-w-[300px] px-4 py-3 text-xs font-normal">
            {{ row }}
          </td>

          @for (column of columns; track trackByColumn) {
            <td>
              <div class="flex justify-center">
                @if (selectionType === 'radio') {
                  <input
                    type="radio"
                    [name]="row"
                    [checked]="value[row] === column"
                    (change)="onInputChange(row, column, $event)"
                    [disabled]="isDisabled"
                    (blur)="onTouched()" />
                }

                @if (selectionType === 'checkbox') {
                  <t-checkbox
                    [checked]="value[row].includes(column)"
                    (change)="onInputChange(row, column, $event)"
                    [disabled]="isDisabled"
                    (blur)="onTouched()" />
                }
              </div>
            </td>
          }
        </tr>
      }
    </tbody>
  </table>
</div>
