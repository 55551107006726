/* eslint-disable @typescript-eslint/no-explicit-any */
export const isUndefined = (value: any) => {
  return typeof value === 'undefined';
};

export const isNull = (value: any) => {
  return value === null;
};

export const isFunction = (value: any) => {
  return typeof value === 'function';
};

export const isNumber = (value: any) => {
  return typeof value === 'number';
};

export const isString = (value: any) => {
  return typeof value === 'string';
};

export const isBoolean = (value: any) => {
  return typeof value === 'boolean';
};

export const isObject = (value: any) => {
  return value !== null && typeof value === 'object';
};

export const isNumberFinite = (value: any) => {
  return isNumber(value) && isFinite(value);
};

export const extractDeepPropertyByMapKey = (obj: any, map: string): any => {
  const keys = map.split('.');
  const head = keys.shift();

  return keys.reduce(
    (prop: any, key: string) => {
      return !isUndefined(prop) && !isNull(prop) && !isUndefined(prop[key]) ? prop[key] : undefined;
    },
    obj[head || '']
  );
};

export const extractDeepPropertyByParentMapKey = (obj: any, map: string): any => {
  const keys = map.split('.');
  const tail = keys.pop();
  const props = extractDeepPropertyByMapKey(obj, keys.join('.'));

  return { props, tail };
};

export const getKeysTwoObjects = (obj: any, other: any): any => {
  return [...Object.keys(obj), ...Object.keys(other)].filter(
    (key, index, array) => array.indexOf(key) === index
  );
};

export const isDeepEqual = (obj: any, other: any): any => {
  if (!isObject(obj) || !isObject(other)) {
    return obj === other;
  }

  return getKeysTwoObjects(obj, other).every((key: any): boolean => {
    if (!isObject(obj[key]) && !isObject(other[key])) {
      return obj[key] === other[key];
    }
    if (!isObject(obj[key]) || !isObject(other[key])) {
      return false;
    }

    return isDeepEqual(obj[key], other[key]);
  });
};
