import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 't-file-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-card.component.html',
})
export class FileCardComponent {
  @Input() link = '';
}
