<ng-container *ngIf="onboardingData$ | async as onboardingData">
  <div
    *ngIf="onboardingData.currentActiveStep"
    class="mt-[80px] flex flex-col justify-center gap-8 px-4 lg:flex-row lg:px-0">
    <t-onboarding-item
      *ngFor="let step of onboardingData.steps"
      [isComplete]="step.completed"
      [isLoading]="step.loading"
      [isDisabled]="!step.completed && onboardingData.currentActiveStep.index < step.index"
      (triggerItemStart)="
        !step.completed && onboardingData.currentActiveStep.index < step.index
          ? null
          : triggerOnboardingStep(step)
      "
      buttonText="Start"
      class="flex flex-col lg:w-1/3">
      <strong class="text-grey-70 block pb-4 text-base font-semibold">
        {{ step.title }}
      </strong>
      <p class="text-grey-55 pb-8 text-xs lg:pr-7">
        {{ step.description }}
      </p>
    </t-onboarding-item>
  </div>
</ng-container>
