<div class="relative">
  <ng-container *ngIf="variant === 'cards'">
    <t-card *ngFor="let item of items" class="relative mb-2 block">
      <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ $implicit: item }" />
    </t-card>
  </ng-container>

  <ng-container *ngIf="variant === 'table'">
    <div class="divide-grey-20 divide-y">
      <div
        *ngFor="let item of items"
        class="py-4"
        [ngClass]="{
          'py-3': verticalSpacingSize === 'small',
          'py-4': verticalSpacingSize === 'medium',
          'py-5': verticalSpacingSize === 'large'
        }">
        <ng-container
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ $implicit: item }" />
      </div>
    </div>
  </ng-container>
</div>
