import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Action } from '@troyai/actions/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { DateOfBirthPipe, ExpirationDatePipe } from '@troyai/portal/common/pipes';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { ButtonComponent, CardComponent, LabelComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-actions-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    LabelComponent,
    RouterModule,
    ExpirationDatePipe,
    DateOfBirthPipe,
    ImageFallbackDirective,
    FullNamePipe,
    PharmacyIndicatorTooltipComponent,
  ],
  templateUrl: './actions-list.component.html',
})
export class ActionsListComponent {
  @Input() actions: Action[] = [];
  @Input() showMemberDetails = false;
  @Input() selectedPharmacyId?: number | null = null;
}
