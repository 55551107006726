import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { LogoutFlowInitiated } from '@troyai/auth/data-access';
import { GreetingCardComponent } from '@troyai/auth/feature';
import { LinkComponent } from '@troyai/ui-kit';

@Component({
  standalone: true,
  imports: [CommonModule, GreetingCardComponent, LinkComponent],
  templateUrl: './access-denied-shell.component.html',
})
export class AccessDeniedShellComponent {
  constructor(private store: Store) {}

  loginWithRedirect(): void {
    this.store.dispatch(new LogoutFlowInitiated());
  }
}
