<div class="relative">
  <nav class="lg:w-sidebarNav fixed left-0 top-0 z-50 h-[56px] w-full lg:h-screen">
    <ng-content select="[top]"></ng-content>
    <div class="hidden h-full lg:block">
      <ng-content select="[nav]"></ng-content>
    </div>
  </nav>

  <!-- Content -->
  <div class="lg:pl-sidebarNav w-full pt-[56px] lg:pt-0">
    <!-- @if (shouldDisplayAlert) {
      <div class="bg-amber-40 text-grey-80 shadow-small relative z-50 space-y-2 p-3 text-xs">
        <div class="flex items-center gap-x-2">
          <t-icon key="info-circle" color="inherit" size="tiny" />
          <strong class="font-semibold">Important Announcement:</strong>
        </div>

        <p class="block">
          Due to a technical issue, the action forms are unavailable and cannot be submitted. We are
          working to resolve the issue. This will not impact payments or plan status. We will notify
          again once this issue has been resolved.
        </p>

        <div
          (click)="shouldDisplayAlert = false"
          class="text-grey-80 absolute right-1 top-0 hover:cursor-pointer">
          <t-icon key="close" color="inherit" size="tiny" />
        </div>
      </div>
    } -->

    <ng-content></ng-content>
  </div>
  <!-- /Content -->

  <div
    class="left:0 fixed top-0 z-50 h-screen w-full transition-transform lg:hidden"
    *ngIf="sidebarVisible"
    [@inOutAnimation]>
    <ng-content select="[mobileNav]"></ng-content>

    <button
      class="absolute right-5 top-4 rounded p-1 text-white hover:bg-black hover:bg-opacity-10"
      (click)="closeSidebar()">
      <t-icon key="close" color="inherit" color="inherit"></t-icon>
    </button>
  </div>
</div>
