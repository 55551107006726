export type RatingLevels = 'neutral' | 'low' | 'high';

export interface AdherencePercentageBracketsConfig {
  percentageRange: number[];
  level: RatingLevels;
  metaData?: {
    [key: string]: string | number;
  };
}

export const clamp = (input: number, min: number, max: number): number => {
  return input < min ? min : input > max ? max : input;
};

export const mapRange = (
  current: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number => {
  const mapped: number = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  return clamp(mapped, out_min, out_max);
};

export const getAdherenceBracketByPercentage = (
  percentage: number,
  percentageBracketsConfig: AdherencePercentageBracketsConfig[]
): AdherencePercentageBracketsConfig => {
  const percentageBracket = percentageBracketsConfig.find((entry) => {
    const [min, max] = entry.percentageRange;
    return percentage >= min && (percentage < max || max === 1);
  });

  if (percentageBracket) {
    return percentageBracket;
  }

  return {
    percentageRange: [0, 0],
    level: 'neutral',
  };
};
