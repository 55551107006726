import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { CareState, GetMemberHRAPreviewURL, MemberHra } from '@troyai/hra/data-access';
import { ErrorStateComponent, LoaderComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-hra-document-preview-modal',
  standalone: true,
  imports: [CommonModule, LoaderComponent, ErrorStateComponent],
  templateUrl: './hra-document-preview-modal.component.html',
})
export class HraDocumentPreviewModalComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(DIALOG_DATA) public hraItem: MemberHra,
    private store: Store
  ) {}

  singleMemberHraItemPreview$ = this.store.selectOnce(CareState.singleMemberHraItemPreview);
  singleMemberHraItemPreviewLoadingStatus$ = this.store.select(
    CareState.singleMemberHraItemPreviewLoadingStatus
  );

  getSanitizedAgreementURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit(): void {
    if (this.hraItem.latest_file_id) {
      this.store.dispatch(
        new GetMemberHRAPreviewURL({
          fileId: this.hraItem.latest_file_id,
          memberGlobalId: this.hraItem.member_global_id,
        })
      );
    }
  }
}
