import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Action } from '@troyai/actions/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { DateOfBirthPipe, ExpirationDatePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent, HeadingComponent, IconsModule } from '@troyai/ui-kit';
@Component({
  selector: 't-print-actions-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconsModule,
    HeadingComponent,
    FullNamePipe,
    ExpirationDatePipe,
    DateOfBirthPipe,
  ],
  templateUrl: './print-actions-modal.component.html',
})
export class PrintActionsModalComponent {
  constructor(@Inject(DIALOG_DATA) public actions: Action[]) {}

  getTodayDate = () => {
    const today = new Date();
    return today.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  };

  triggerPrint() {
    window.print();
  }
}
