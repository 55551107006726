import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { format } from 'date-fns';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import {
  Action,
  ActionStatusEnum,
  ActionsState,
  GetActionsList,
} from '@troyai/actions/data-access';
import { ActionsListComponent } from '@troyai/actions/ui';
import {
  ActiveFilterSummaryComponent,
  DataSourceOptionsComponent,
  Filter,
  ListingDataSource,
  ListingSortingHeadingsComponent,
  SortOptionValue,
  generateFilters,
  generatePagination,
  generateSortOptions,
} from '@troyai/data-source-listing';
import { FeatureFlagDirective } from '@troyai/feature-flags';
import { GuidedTourComponent } from '@troyai/guided-tour';
import { ContentWrapperLayoutComponent } from '@troyai/layout';
import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import {
  DropdownButtonComponent,
  DropdownMenuComponent,
  DropdownMenuItemComponent,
  EmptyStateCardComponent,
  ErrorStateComponent,
  IconsModule,
  LinkComponent,
  ModalService,
  PaginatorComponent,
  PopoverModule,
  SearchInputComponent,
  SectionComponent,
  SkeletonLoaderComponent,
} from '@troyai/ui-kit';
import { PrintActionsModalComponent } from '../../components/print-actions-modal/print-actions-modal.component';
import { actionsListingFilters, actionsSortingOptions } from './actions-listing.config';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ContentWrapperLayoutComponent,
    SectionComponent,
    SearchInputComponent,
    PaginatorComponent,
    ActionsListComponent,
    DataSourceOptionsComponent,
    ActiveFilterSummaryComponent,
    IconsModule,
    PopoverModule,
    SkeletonLoaderComponent,
    ListingSortingHeadingsComponent,
    EmptyStateCardComponent,
    ErrorStateComponent,
    LinkComponent,
    GuidedTourComponent,
    FeatureFlagDirective,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DropdownButtonComponent,
  ],
  templateUrl: './actions-listing.component.html',
})
export class ActionsListingFeatureComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedPharmacyId$ = this.store
    .select(PharmacyContextState.selectedPharmacyId)
    .pipe(filter((pharmacyId) => pharmacyId !== undefined));
  actionsList$ = this.store.select(ActionsState.getAllActions).pipe(
    filter((items) => !!items),
    map((items) =>
      // the formatted_dob property is used for live search
      items.map((item) => ({
        ...item,
        formatted_dob: format(new Date(item.member.dob), 'MM/dd/yyyy'),
        member_name: item.member.last_name,
      }))
    )
  );

  actionsListErrorState$ = this.store.select(ActionsState.actionsListErrorState);
  actionsListLoadingStatus$ = this.store.select(ActionsState.actionsListLoadingStatus);

  availableSortingOptions: SortOptionValue<Action>[] = [];
  availableFilters: Filter<Action>[] = [];
  searchQuery = '';
  searchQueryChange$: Subject<string> = new Subject<string>();
  pageSize = 10;

  data: ListingDataSource<Action> = new ListingDataSource<Action>({
    routeEvents: true,
  });

  searchQueryChange(query: string) {
    this.searchQueryChange$.next(query);
  }

  onFiltersChange(filters: Filter<Action>[]) {
    this.availableFilters = filters;
    this.data.filterBy(filters);
  }

  removeAllFilters() {
    const newFilters = this.availableFilters.map((filter) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const activeFilters = filter.values.map((filterEntry: any) => {
        return {
          id: filterEntry.id,
          isActive: false,
        };
      });

      return {
        ...filter,
        values: activeFilters,
      };
    });

    this.onFiltersChange(newFilters);
  }

  onSortingChange(sortOptions: SortOptionValue<Action>[]) {
    this.availableSortingOptions = sortOptions;
    this.data.sortBy(sortOptions);
  }

  openPrintActionsModal() {
    this.data.page$
      .pipe(
        filter((page) => page.items.length > 0),
        tap((page) => {
          this.modalService.openDialog(PrintActionsModalComponent, page.items, {
            title: 'Print Actions',
            background: 'grey',
            allowOverflow: true,
          });
        }),
        take(1)
      )
      .subscribe();

    // TODO: Find a better way to emit new values on data.page$
    this.data.filterBy(this.availableFilters);
  }

  ngOnInit() {
    this.selectedPharmacyId$
      .pipe(
        tap(() => this.store.dispatch(new GetActionsList())),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.actionsList$
      .pipe(
        withLatestFrom(this.route.queryParams, this.actionsListLoadingStatus$),
        skipWhile((data) => data[2].loading),
        tap(([actions, params]) => {
          this.availableFilters = generateFilters<Action>(actionsListingFilters, actions, params, [
            {
              status: [ActionStatusEnum.NEEDS_RESUBMISSION, ActionStatusEnum.NEW],
            },
          ]);
          this.availableSortingOptions = generateSortOptions<Action>(
            actionsSortingOptions,
            'fee',
            params
          );

          const paginationOptions = generatePagination(this.pageSize, params);

          this.data.init(
            this.actionsList$,
            this.availableSortingOptions,
            this.availableFilters,
            this.searchQuery,
            paginationOptions,
            {
              searchableProperties: ['title', 'formatted_dob', 'member_name'],
            }
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.searchQueryChange$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchText) => {
          this.searchQuery = searchText;
          this.data.searchBy(searchText);
          return of();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
