<div
  class="shadow-small relative flex h-screen max-h-screen w-screen flex-col rounded-lg bg-white print:visible print:h-auto print:max-h-min print:min-h-screen print:w-screen print:overflow-visible"
  [ngClass]="{
    'overflow-hidden': !modalMetaData.allowOverflow,
    'max-w-screen min-w-screen rounded-none lg:max-h-screen': modalMetaData.isFullscreen,
    'max-w-4xl lg:h-auto lg:max-h-[90vh]': !modalMetaData.isFullscreen
  }">
  <div
    *ngIf="modalMetaData.title"
    class="border-grey-20 shadow-small flex flex-initial items-center justify-between border-b p-6 print:hidden">
    <span>{{ modalMetaData.title }}</span>
  </div>

  <div *ngIf="!modalMetaData.title" class="pb-5 pt-8 print:hidden"></div>
  <div
    class="flex-auto rounded-b-lg"
    [ngClass]="{
      'px-8 py-6': !modalMetaData.disablePadding,
      'overflow-y-auto': !modalMetaData.allowOverflow,
      'bg-grey-10': modalMetaData.background === 'grey'
    }">
    <ng-template cdkPortalOutlet></ng-template>
  </div>

  <div
    (click)="closeDialogAttempt()"
    class="bg-grey-20 hover:bg-grey-25 absolute right-6 top-6 cursor-pointer rounded-full p-2 print:hidden">
    <t-icon key="close" size="tiniest" />
  </div>
</div>
