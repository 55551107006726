import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

export interface FragmentedNameProperties {
  first_name: string;
  middle_name?: string;
  last_name: string;
}

@Pipe({
  name: 'fullName',
  standalone: true,
})
export class FullNamePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}
  transform(value?: FragmentedNameProperties) {
    const fullName = value
      ? `${value.last_name}, ${value.first_name}${value.middle_name ? ' ' + value.middle_name : ''}`
      : 'Unnamed Patient';

    return this.titleCasePipe.transform(fullName);
  }
}
