import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 't-widget-card-contents',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './widget-card-contents.component.html',
})
export class WidgetCardContentsComponent {}
