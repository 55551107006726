import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserRoles } from '@troyai/auth/data-access';
import { UserRolesService } from '@troyai/auth/feature';
import {
  CareState,
  NursePractitioner,
  SetScheduleAppointmentNursePractitioner,
} from '@troyai/hra/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import {
  ButtonComponent,
  EmptyStateCardComponent,
  IconsModule,
  LabelComponent,
  ModalModule,
  ModalService,
} from '@troyai/ui-kit';
import { combineLatest, map, take, tap, withLatestFrom } from 'rxjs';
import { PatientSchedulerModalComponent } from '../patient-scheduler-modal/patient-scheduler-modal.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconsModule,
    ModalModule,
    FullNamePipe,
    LabelComponent,
    EmptyStateCardComponent,
  ],
  templateUrl: './np-selection-modal.component.html',
})
export class NPSelectionModalComponent {
  constructor(
    public dialogRef: DialogRef,
    private modalService: ModalService,
    private store: Store,
    private fullNamePipe: FullNamePipe,
    private userRolesService: UserRolesService
  ) {}

  patient$ = this.store.select(PatientsState.selectedPatient);
  scheduleDetails$ = this.store.select(CareState.scheduleAppointmentDetails);
  scheduleLocationModalState$ = this.store.select(CareState.scheduleLocationModalState);
  currentUserHasCMRole$ = this.userRolesService.hasAccess([
    UserRoles.CMExternal,
    UserRoles.CMInternal,
  ]);
  nursePractitioners$ = this.store.select(CareState.nursePractitionersList).pipe(
    withLatestFrom(
      this.scheduleDetails$,
      this.scheduleLocationModalState$,
      this.currentUserHasCMRole$
    ),
    map(([nps, scheduleDetails, scheduleLocationModalState, currentUserHasCMRole]) => {
      if (!scheduleDetails || !scheduleDetails.appointmentAddress) {
        return nps;
      }

      // If telehealth is selected, display all NPs
      // TODO: Return only NPs that are telehealth enabled, once we have that data available
      if (scheduleLocationModalState.isTelehealthSelected) {
        return nps;
      }

      // https://linear.app/troyai/issue/TRO-1774/internal-cm-and-external-cm-no-county-validation-for-np-scheduling
      // Any CM role should be able to select any NP, regardless of their preferred counties
      if (currentUserHasCMRole) {
        return nps;
      }

      // Just display NP that are covering the county of the appointment address
      // For safety, uppercase the counties in both the NP and the address
      const county = scheduleDetails.appointmentAddress.address_county.toUpperCase();

      return nps.filter((np) =>
        np.counties.map((npCounty) => npCounty.toUpperCase()).includes(county)
      );
    })
  );

  canChooseNursePractitioner$ = combineLatest([
    this.userRolesService.hasAccess([UserRoles.PharmacyUser]),
    this.userRolesService.hasAccess([UserRoles.CMExternal]),
  ]).pipe(map(([isPharmacy, isCMExternal]) => isPharmacy || isCMExternal));

  chooseNP(np: NursePractitioner) {
    this.dialogRef.close();

    combineLatest([
      this.canChooseNursePractitioner$,
      this.patient$,
      this.scheduleLocationModalState$,
    ])
      .pipe(
        tap(([canChooseNP, patient, scheduleLocationModalState]) => {
          if (!canChooseNP) {
            return;
          }

          this.store.dispatch([
            new SetScheduleAppointmentNursePractitioner({
              nursePractitioner: np,
            }),
          ]);

          const modalTitle = `Schedule ${scheduleLocationModalState.isTelehealthSelected ? 'Telehealth' : ''} NP Home Visit for ${this.fullNamePipe.transform(patient)}`;

          this.modalService.openDialog(PatientSchedulerModalComponent, null, {
            title: modalTitle,
            background: 'grey',
            offsetTop: 50,
          });
          return;
        }),
        take(1)
      )
      .subscribe();
  }
}
