import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  CompleteOnboardingStep,
  PlaidAccount,
  SetPreferredPaymentAccount,
  SubmitPlaidPublicToken,
} from '@troyai/onboarding/data-access';
import { ButtonComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-bank-account-selector-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonComponent],
  templateUrl: './bank-account-selector-modal.component.html',
})
export class BankAccountSelectorModalComponent {
  constructor(
    @Inject(DIALOG_DATA)
    public plaidMetadata: {
      token: string;
      pharmacyId: number;
      accounts: PlaidAccount[];
    },
    private dialogRef: DialogRef,
    private store: Store,
    private actions$: Actions
  ) {}

  destroyRef = inject(DestroyRef);
  isLoading = false;
  bankAccountSelectionForm = new FormGroup({
    account: new FormControl(null, Validators.required),
  });

  selectBankAccount() {
    if (this.bankAccountSelectionForm.invalid || !this.bankAccountSelectionForm.value.account) {
      return;
    }

    this.isLoading = true;
    const selectedPlaidAccount = this.bankAccountSelectionForm.value.account;

    this.actions$
      .pipe(ofActionSuccessful(SubmitPlaidPublicToken), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch([
          new SetPreferredPaymentAccount(selectedPlaidAccount),
          new CompleteOnboardingStep(),
        ]);
        this.dialogRef.close();
      });

    this.store.dispatch([
      new SubmitPlaidPublicToken(this.plaidMetadata.token, Number(this.plaidMetadata.pharmacyId)),
    ]);
  }
}
