import { format } from 'date-fns';

export const secondsElapsedFromTimestamp = (fromTimestamp: number, seconds: number) => {
  return Date.now() - fromTimestamp > seconds * 1000;
};

export const formatUTCDate = (date: string, dateFormat: string) => {
  const [year, month, day] = date.substring(0, 10).split('-');
  const yearNumber = parseInt(year);
  const monthNumber = parseInt(month);
  const dayNumber = parseInt(day);

  return format(new Date(yearNumber, monthNumber - 1, dayNumber), dateFormat);
};

export const parseUTCDate = (date: string) => {
  const [year, month, day] = date.substring(0, 10).split('-');
  const yearNumber = parseInt(year);
  const monthNumber = parseInt(month);
  const dayNumber = parseInt(day);

  return new Date(yearNumber, monthNumber - 1, dayNumber);
};

export const toISOLocal = (d: Date) => {
  const z = (n: number) => ('0' + n).slice(-2);
  const zz = (n: number) => ('00' + n).slice(-3);
  let off = d.getTimezoneOffset();
  const sign = off > 0 ? '-' : '+';
  off = Math.abs(off);

  return (
    d.getFullYear() +
    '-' +
    z(d.getMonth() + 1) +
    '-' +
    z(d.getDate()) +
    'T' +
    z(d.getHours()) +
    ':' +
    z(d.getMinutes()) +
    ':' +
    z(d.getSeconds()) +
    '.' +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ':' +
    z(off % 60)
  );
};
