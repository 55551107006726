import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icon/icon.module';

@Component({
  selector: 't-error-state',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './error-state.component.html',
})
export class ErrorStateComponent {
  @Input() titleText = 'Something went wrong';
  @Input() errorsList: string[] = [];
}
