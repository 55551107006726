/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, catchError, switchAll, tap } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class NotificationsWsService {
  private socket$!: WebSocketSubject<any>;
  private messagesSubject$ = new Subject<any>();
  public messages$: Observable<any> = this.messagesSubject$.pipe(
    switchAll(),
    catchError((e) => {
      throw e;
    })
  );

  public connect(token: string): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket(token);
      const messages = this.socket$.pipe(
        tap({
          error: (error) => console.log(error),
        }),
        catchError(() => EMPTY)
      );
      this.messagesSubject$.next(messages);
    }
  }

  private getNewWebSocket(token: string) {
    return webSocket(
      `wss://1x60p1o3h8.execute-api.us-east-1.amazonaws.com/production/?auth=${token}`
    );
  }
  sendMessage(msg: any) {
    this.socket$.next(msg);
  }
  close() {
    this.socket$.complete();
  }
}
