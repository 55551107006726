import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  Appointment,
  AssignAppointmentToPatient,
  CareState,
  GetPatientsListToBeScheduled,
} from '@troyai/hra/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { ButtonComponent, SelectComponent } from '@troyai/ui-kit';
import { map } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, SelectComponent, ButtonComponent, ReactiveFormsModule],
  templateUrl: './patients-list-selector-modal.component.html',
})
export class PatientsListSelectorModalComponent implements OnInit {
  constructor(
    private store: Store,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) private timeslot: Appointment,
    private actions$: Actions,
    private fullNamePipe: FullNamePipe
  ) {}
  patientsToBeScheduled$ = this.store.select(CareState.getPatientsToBeScheduled).pipe(
    map((patients) => {
      return patients.map((item) => {
        return {
          id: item.action_global_id,
          label: this.fullNamePipe.transform(item),
        };
      });
    })
  );

  selectFormControl = new FormControl();

  assignAppointmentToPatient() {
    const patientActionId = this.selectFormControl.value;
    this.store.dispatch(
      new AssignAppointmentToPatient({
        timeslot: this.timeslot,
        actionId: patientActionId,
        addressHash: '',
      })
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new GetPatientsListToBeScheduled());
    this.actions$.pipe(ofActionSuccessful(AssignAppointmentToPatient)).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
