import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_OPTIONS, CacheOptions, RestApiClientService } from '@troyai/rest-api-client';
import {
  AdherenceCategoryItem,
  AdherenceOverview,
  AdherencePatientMeasure,
  AdherencePatientRxClaims,
} from './models/adherence-item.interface';

@Injectable({
  providedIn: 'root',
})
export class AdherenceApiService {
  constructor(private restApiService: RestApiClientService) {}

  getAdherenceItems(pharmacyId: number, cacheOptions?: CacheOptions) {
    const context = new HttpContext();
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }
    return this.restApiService.get<AdherenceOverview>(`adherence/${pharmacyId}`, {
      context,
    });
  }

  getAdherenceItem(pharmacyId: number, adherenceItemId: string) {
    return this.restApiService.get<AdherenceCategoryItem>(
      `adherence/${pharmacyId}/${adherenceItemId}`
    );
  }

  getAdherenceItemsForPatient(pharmacyId: number, memberId: string) {
    return this.restApiService.get<AdherencePatientMeasure[]>(
      `adherence/${pharmacyId}/members/${memberId}`
    );
  }

  getAdherenceItemPatients(
    pharmacyId: number,
    adherenceItemId: string,
    cacheOptions?: CacheOptions
  ) {
    const context = new HttpContext();
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<AdherencePatientMeasure[] | null>(
      `adherence/${pharmacyId}/members/${adherenceItemId}`,
      {
        context,
      }
    );
  }

  getAdherenceItemPatientRxClaims(pharmacyId: number, adherenceItemId: string, memberId: string) {
    return this.restApiService.get<AdherencePatientRxClaims[]>(
      `adherence/${pharmacyId}/members/${memberId}/rx-claims/${adherenceItemId}`
    );
  }
}
