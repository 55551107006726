import { AfterViewInit, Component, DestroyRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedTourService } from '../../guided-tour.service';
import {
  generateFirstSlideButtons,
  generateGuidedTourDefaultConfigOptions,
  generateLastSlideButtons,
  generatePositionStrategy,
} from '../../guided-tour.config';
import { Store } from '@ngxs/store';
import { AdherenceState } from '@troyai/adherence/data-access';
import { combineLatest, filter, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 't-adherence-tour',
  standalone: true,
  imports: [CommonModule],
  template: '',
})
export class AdherenceTourComponent implements AfterViewInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private store: Store
  ) {}

  adherencePatientsByCategoryLoading$ = this.store
    .select(AdherenceState.adherencePatientsByCategoryLoading)
    .pipe(filter((loading) => !loading));

  destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    this.guidedTourService.defaultStepOptions = generateGuidedTourDefaultConfigOptions(
      this.guidedTourService
    );
    this.guidedTourService.tourName = 'adherence';
    this.guidedTourService.modal = true;
    this.guidedTourService.confirmCancel = false;
    this.guidedTourService.addSteps([
      {
        id: '1-adherence-metrics',
        attachTo: {
          element: '#tour-adherence-metrics',
          on: generatePositionStrategy('bottom'),
        },
        title: 'Adherence',
        text: ['Key Medication Adherence metrics for your Troy.AI patients, updated hourly.'],
        buttons: generateFirstSlideButtons(this.guidedTourService),
      },
      {
        id: '2-adherence-patients',
        attachTo: {
          element: '#tour-adherence-patients',
          on: generatePositionStrategy('right'),
        },
        title: 'Adherence',
        text: ['See members with upcoming refills and their adherence status.'],
      },
      {
        id: '3-adherence-bonus',
        attachTo: {
          element: '#tour-adherence-bonus',
          on: generatePositionStrategy('bottom'),
        },
        scrollTo: false,
        title: 'Adherence',
        text: ['Bonus accumulator, updated daily, paid out on a yearly basis.'],
      },
      {
        id: '4-adherence-info',
        attachTo: {
          element: '#tour-adherence-info',
          on: generatePositionStrategy('left'),
        },
        buttons: generateLastSlideButtons(this.guidedTourService),
        scrollTo: false,
        title: 'Adherence',
        text: ['Extensive information about the bonus program.'],
      },
    ]);

    combineLatest([this.adherencePatientsByCategoryLoading$, of(this.guidedTourService.tourName)])
      .pipe(
        tap(([, tourName]) => {
          if (!this.guidedTourService.getLocalStorageCompletionStatusByName(tourName)) {
            this.guidedTourService.start();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
