/* eslint-disable @typescript-eslint/no-explicit-any */
// src/app/interceptors/demo-mode.interceptor.ts
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserState } from '@troyai/auth/data-access';
import { Environment } from '@troyai/envs';
import { FeatureFlagsService } from '@troyai/feature-flags';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { PII_ENDPOINTS_REPLACEMENT_CONFIG } from './pii-endpoints.config';

@Injectable()
export class PIIAnonymizerInterceptor implements HttpInterceptor {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private env: Environment,
    private store: Store
  ) {}

  // User for a very specific use case, like having a demo in production environment, but only for the selected accounts
  private emailHashesWhitelist = [
    'c6fc2203787c7b94ba8ea142a014b4efb39c39456e1a391f9e3c4ce90fdd8c96', // flaviu@troymedicare.com
    '1f866d54716aefe35220d8f4cafaa356089aeb4edc9d6289b1055e6b04ddd61d', // filip@troymedicare.com
  ];
  userDetails$ = this.store.select(UserState.userDetails);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.featureFlagsService.isFeatureEnabled('demo-mode')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      withLatestFrom(this.userDetails$),
      map(([event, userDetails]) => {
        // In production environment, demo mode can only be enabled by whitelisting users
        if (
          this.env.production &&
          userDetails?.email &&
          !this.emailHashesWhitelist.includes(userDetails.email_hash)
        ) {
          return event;
        }

        // Check if the event is an HttpResponse and contains personal data
        const url = new URL(req.url);
        const replacementConfig = this.getReplacementConfig(url.pathname);

        if (event instanceof HttpResponse && replacementConfig) {
          console.log('Match:', replacementConfig.url, url.pathname);
          // Anonymize the personal data
          const modifiedBody = replacementConfig.replacer(event.body.data);

          // Replace the response body with the modified data
          return event.clone({
            body: {
              ...event.body,
              data: modifiedBody,
            },
          });
        }
        return event;
      })
    );
  }

  private getReplacementConfig(url: string) {
    return PII_ENDPOINTS_REPLACEMENT_CONFIG.find((item) => item.urlRegex.test(url));
  }
}
