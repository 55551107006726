import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, filter, map, take, tap } from 'rxjs';

import {
  Action,
  ActionForm,
  ActionStatusEnum,
  ActionsState,
  ComputedActionValues,
} from '@troyai/actions/data-access';
import { TrackService } from '@troyai/tracking';
import { ActionLayoutRegularComponent } from '../action-layout-regular/action-layout-regular.component';
import { ActionCDPS01TemplateComponent } from '../templates/cdps01/action-cdps01-template/action-cdps01-template.component';
import { ActionDefaultTemplateComponent } from '../templates/default/action-default-template/action-default-template.component';
import { ActionFCMR01TemplateComponent } from '../templates/fcmr01/action-fcmr01-template.component';
import { ActionHra01TemplateComponent } from '../templates/hra01/action-hra01-template/action-hra01-template.component';
import { ActionNPTH01TemplateComponent } from '../templates/npth01/action-npth01-template/action-npth01-template.component';

@Component({
  selector: 't-action-single-view',
  standalone: true,
  imports: [
    CommonModule,
    ActionLayoutRegularComponent,
    ActionHra01TemplateComponent,
    ActionCDPS01TemplateComponent,
    ActionFCMR01TemplateComponent,
    ActionNPTH01TemplateComponent,
    ActionDefaultTemplateComponent,
  ],
  templateUrl: './action-single-view.component.html',
})
export class ActionSingleViewComponent implements OnInit {
  constructor(
    private store: Store,
    private trackService: TrackService
  ) {}

  selectedAction$ = this.store.select(ActionsState.selectedAction);
  selectedActionFormSettings$ = this.store.select(ActionsState.selectedActionFormSettings);
  selectedActionFormSettingsLoading$ = this.store.select(
    ActionsState.selectedActionFormSettingsLoading
  );

  selectedActionWithFormSettings$ = combineLatest([
    this.selectedActionFormSettings$,
    this.selectedAction$,
  ]).pipe(
    filter(([formSettings, action]) => !!formSettings && !!action),
    map(([formSettings, action]) => {
      return {
        formSettings: formSettings as ActionForm,
        action: action as Action,
      };
    }),
    tap(({ formSettings, action }) => {
      this.computedActionValues.formDisplayType = formSettings.form_display.length
        ? formSettings.form_display
        : 'modal';

      this.computedActionValues.formURL = formSettings.form_url;
      this.computedActionValues.hasCompletedState =
        action.status !== ActionStatusEnum.NEW &&
        action.status !== ActionStatusEnum.NEEDS_RESUBMISSION &&
        action.status !== ActionStatusEnum.SCHEDULED;

      this.computedActionValues.formType = formSettings.form_type;
      this.computedActionValues.nativeFormResources = formSettings.native_form_resources;
      this.computedActionValues.hasDraft = !!action.latest_form_submission_id;
    })
  );

  computedActionValues: ComputedActionValues = {
    formDisplayType: 'modal',
    formType: 'external_link',
    formURL: '',
    hasCompletedState: false,
    nativeFormResources: {
      id: '',
      source: '',
    },
  };

  ngOnInit() {
    this.selectedAction$
      .pipe(
        take(1),
        tap((action) => {
          if (!action) return;

          this.trackService.trackEvent({
            eventName: 'Viewed action',
            eventProps: {
              member_id: action.member_assigned_to,
              pharmacy_id: action.pharmacy_id,
              action_id: action.global_id,
              action_type: action.reference_id,
              pharmacy_name: action.pharmacy_name,
            },
          });
        })
      )
      .subscribe();

    this.selectedActionWithFormSettings$.subscribe();
  }
}
