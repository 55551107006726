import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { map, tap } from 'rxjs';

import { PriorAuthorizationsApiService } from '@troyai/prior-authorization/data-access';
import { DashboardStateModel } from './dashboard-state.model';
import { GetLatestPriorAuthorizationItems } from './dashboard.actions';

@State<DashboardStateModel>({
  name: 'dashboardState',
  defaults: {
    latestPAItems: [],
    latestPAItemsLoading: true,
  },
})
@Injectable()
export class DashboardState {
  constructor(private priorAuthorizationApiService: PriorAuthorizationsApiService) {}

  @Selector()
  static latestPAItems(state: DashboardStateModel) {
    return state.latestPAItems;
  }

  @Selector()
  static latestPAItemsLoading(state: DashboardStateModel) {
    return state.latestPAItemsLoading;
  }

  @Action(GetLatestPriorAuthorizationItems)
  getLatestPAItems(
    { setState, patchState }: StateContext<DashboardStateModel>,
    action: GetLatestPriorAuthorizationItems
  ) {
    patchState({
      latestPAItemsLoading: true,
    });

    // TODO: Temporary limit of 5000. Remove when PAs can be retrieved based on filter
    return this.priorAuthorizationApiService
      .getPriorAuthorizations(5000, action.payload.pharmacyId)
      .pipe(
        map((priorAuthorizations) => {
          const pendingPA = priorAuthorizations.filter((pa) => pa.status === 'Pending');
          return pendingPA.slice(-1);
        }),
        tap((latestPAItems) =>
          setState(
            patch<DashboardStateModel>({
              latestPAItems,
            })
          )
        ),
        tap(() =>
          patchState({
            latestPAItemsLoading: false,
          })
        )
      );
  }
}
