import { Pipe, PipeTransform } from '@angular/core';
import { parseISO, format } from 'date-fns';

@Pipe({
  name: 'dobDate',
  standalone: true,
})
export class DateOfBirthPipe implements PipeTransform {
  transform(input: string): string {
    const parsedTime = parseISO(input);
    return format(parsedTime, 'MM/dd/yyyy');
  }
}
