/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

import { IconsModule } from '../../icon/icon.module';

export interface SelectOption<T> {
  id: T;
  label: string;
  [key: string]: any;
}

@Component({
  selector: 't-select',
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule, IconsModule],
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  styleUrls: [
    '../../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
    './select.component.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  private onTouched!: Function;
  private onChanged!: Function;
  @ViewChild('ngSelect', { static: true }) ngSelect!: NgSelectComponent;
  @Input() items: SelectOption<any>[] = [];
  @Input() observableInput$!: any;
  @Input() loading = false;
  @Input() bindLabel = 'label';
  @Input() bindValue = 'id';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() id = '';
  @Output() search = new EventEmitter();
  selected: any;
  ngControl!: NgControl;

  constructor(public injector: Injector) {}

  ngOnInit() {
    this.search.emit();
    this.ngControl = this.injector.get(NgControl, undefined);
  }

  clear() {
    this.selected = null;
    this.onChanged(null);
  }

  select(value: SelectOption<any>) {
    this.onTouched();
    if (value) {
      this.selected = value.id;
      this.onChanged(value.id);
    } else {
      this.selected = null;
      this.onChanged(null);
    }
  }

  writeValue(value: any) {
    this.selected = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
