import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: number | string): string {
    const stringPhone = phoneValue + '';
    try {
      const phoneNumber = parsePhoneNumber(stringPhone, 'US');
      const formatted = phoneNumber.formatNational();
      return formatted;
    } catch (e) {
      return '';
    }
  }
}
