import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, Store, ofActionCompleted, ofActionSuccessful } from '@ngxs/store';
import { endOfWeek, startOfWeek } from 'date-fns';
import {
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { ActionStatusEnum, ActionsState, GetSingleAction } from '@troyai/actions/data-access';
import { ActionAppointmentPreviewComponent, ActionStatusCardComponent } from '@troyai/actions/ui';
import { UserRoles } from '@troyai/auth/data-access';
import { UserRolesService } from '@troyai/auth/feature';
import {
  Appointment,
  CareState,
  GetAppointmentsListAsPharmacy,
  NavigateToCurrentWeek,
  NavigateToNextWeek,
  NavigateToPreviousWeek,
  SchedulePatientAppointmentAsPharmacy,
  UnscheduleAppointmentForPatientAsPharmacy,
} from '@troyai/hra/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { AddressPipe, CustomMediumDatePipe } from '@troyai/portal/common/pipes';
import {
  AccordionComponent,
  AccordionItemComponent,
  ButtonComponent,
  CardComponent,
  EmptyStateCardComponent,
  IconsModule,
  LinkComponent,
  ModalModule,
  ModalService,
  SkeletonLoaderComponent,
  WeekNavigationTarget,
  WeekNavigatorComponent,
} from '@troyai/ui-kit';
import { NPSelectionModalComponent } from '../np-selection-modal/np-selection-modal.component';
import { PatientSchedulerLocationModalComponent } from '../patient-scheduler-location-modal/patient-scheduler-location-modal.component';
import { ScheduleRemoveReasonPromptComponent } from '../schedule-remove-reason-prompt/schedule-remove-reason-prompt.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    WeekNavigatorComponent,
    SkeletonLoaderComponent,
    AccordionComponent,
    AccordionItemComponent,
    LinkComponent,
    ButtonComponent,
    IconsModule,
    ModalModule,
    ActionStatusCardComponent,
    CustomMediumDatePipe,
    CardComponent,
    EmptyStateCardComponent,
    ActionAppointmentPreviewComponent,
    AddressPipe,
  ],
  templateUrl: './patient-scheduler-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientSchedulerModalComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private actions$: Actions,
    public dialogRef: DialogRef,
    private modalService: ModalService,
    private fullNamePipe: FullNamePipe,
    private userRolesService: UserRolesService
  ) {}

  destroy$ = new Subject<boolean>();
  groupedAppointmentsListItems$ = this.store.select(CareState.groupedAppointmentsListItems);
  appointmentsListItemsLoading$ = this.store.select(CareState.appointmentsListItemsLoading);
  appointmentsPeriod$ = this.store.select(CareState.appointmentsPeriod);
  nursePractitionersList$ = this.store.select(CareState.nursePractitionersList);
  scheduleDetails$ = this.store.select(CareState.scheduleAppointmentDetails);
  action$ = this.store.select(ActionsState.selectedAction);
  selectedActionLoading$ = this.store.select(ActionsState.selectedActionLoading);
  schedulingPatientLoading$ = this.store.select(CareState.schedulingPatientLoading);
  patient$ = this.store.select(PatientsState.selectedPatient);
  scheduleLocationModalState$ = this.store.select(CareState.scheduleLocationModalState);

  isModalLoading$ = combineLatest([
    this.schedulingPatientLoading$,
    this.selectedActionLoading$,
    this.nursePractitionersList$,
    this.scheduleDetails$,
  ]).pipe(
    map(([schedulingLoading, actionLoading, npList, scheduleDetails]) => {
      return schedulingLoading || actionLoading || npList.length === 0 || scheduleDetails === null;
    })
  );

  currentUserHasCMRole$ = this.userRolesService.hasAccess([
    UserRoles.CMExternal,
    UserRoles.CMInternal,
  ]);

  /*
   * Observable to check if the selected county is covered by NP,
   * determined by the NP ID being defined in the appointment address.
   */
  isSelectedCountyCoveredByNP$ = this.scheduleDetails$.pipe(
    filter((scheduleDetails) => !!scheduleDetails),
    withLatestFrom(this.scheduleLocationModalState$, this.currentUserHasCMRole$),
    map(([scheduleDetails, scheduleLocationModalState, currentUserHasCMRole]) => {
      if (!scheduleDetails?.appointmentAddress) return false;

      // We are considering the county to be covered by NP if telehealth is selected
      if (scheduleLocationModalState.isTelehealthSelected) {
        return true;
      }

      // https://linear.app/troyai/issue/TRO-1774/internal-cm-and-external-cm-no-county-validation-for-np-scheduling
      // Users with any CM roles would see timeslots for the county, regardless of the NP supported counties
      if (currentUserHasCMRole) {
        return true;
      }

      return scheduleDetails.appointmentAddress.np_id !== 0;
    })
  );

  viewData$ = combineLatest([
    this.action$,
    this.scheduleDetails$,
    this.isModalLoading$,
    this.isSelectedCountyCoveredByNP$,
  ]).pipe(
    filter(([action, scheduleDetails]) => !!action && !!scheduleDetails),
    map(([action, scheduleDetails, isModalLoading, isSelectedCountyCoveredByNP]) => {
      return {
        action,
        scheduleDetails,
        isModalLoading,
        isSelectedCountyCoveredByNP,
      };
    })
  );

  actionStatusEnum = ActionStatusEnum;

  schedulePatient(timeslot: Appointment) {
    this.action$
      .pipe(
        withLatestFrom(this.scheduleDetails$),
        tap(([action, scheduleDetails]) => {
          if (!action || !scheduleDetails?.appointmentAddress || !scheduleDetails.assignedNP)
            return;

          this.store.dispatch(
            new SchedulePatientAppointmentAsPharmacy({
              actionId: action.global_id,
              data: {
                addressHash: scheduleDetails.appointmentAddress.address_hash,
                timeslotId: timeslot.global_id,
                NPId: scheduleDetails.assignedNP.global_id,
                isTelehealth: scheduleDetails.isTelehealth || false,
              },
            })
          );
        }),
        take(1)
      )
      .subscribe();
  }

  openSchedulingLocationModal() {
    this.dialogRef.close();

    this.patient$.pipe(take(1)).subscribe((patient) => {
      const patientName = this.fullNamePipe.transform(patient);
      this.modalService.openDialog(PatientSchedulerLocationModalComponent, null, {
        title: `Schedule NP Health Assessment for ${patientName}`,
        background: 'grey',
      });
    });
  }

  openNpSelectionModalModal() {
    this.dialogRef.close();

    this.patient$.pipe(take(1)).subscribe((patient) => {
      const patientName = this.fullNamePipe.transform(patient);
      this.modalService.openDialog(NPSelectionModalComponent, null, {
        title: `Schedule NP Health Assessment for ${patientName}`,
        background: 'grey',
      });
    });
  }

  cancelAppointment() {
    this.action$.pipe(take(1)).subscribe((action) => {
      if (action) {
        this.modalService.openPromptDialog(ScheduleRemoveReasonPromptComponent, {
          asRole: UserRoles.PharmacyUser,
          actionId: action.global_id,
          onPromptConfirm: (reason: string) => {
            this.store.dispatch(
              new UnscheduleAppointmentForPatientAsPharmacy({
                reason,
                actionId: action.global_id,
              })
            );
          },
        });
      }
    });
  }

  onWeekChange(target: WeekNavigationTarget) {
    switch (target) {
      case 'next':
        this.store.dispatch(new NavigateToNextWeek());
        break;
      case 'previous':
        this.store.dispatch(new NavigateToPreviousWeek());
        break;
      case 'current':
        this.store.dispatch(new NavigateToCurrentWeek());
        break;
    }
  }

  ngOnInit() {
    // On each action change, fetch appointments list for the current week
    combineLatest([this.action$, this.scheduleDetails$, this.scheduleLocationModalState$])
      .pipe(
        distinctUntilChanged(),
        tap(([action, scheduleDetails, scheduleLocationModalState]) => {
          if (!action || !scheduleDetails || !scheduleDetails.appointmentAddress?.address_hash) {
            return;
          }

          let NPId = scheduleDetails.assignedNP?.global_id;
          if (!NPId && scheduleLocationModalState.isTelehealthSelected) {
            NPId = 0;
          }

          if (!NPId && NPId !== 0) {
            return;
          }

          this.store.dispatch(
            new GetAppointmentsListAsPharmacy({
              actionGlobalId: action.global_id,
              addressHash: scheduleDetails?.appointmentAddress?.address_hash,
              startDate: startOfWeek(Date.now(), {
                weekStartsOn: 1,
              }),
              endDate: endOfWeek(Date.now(), {
                weekStartsOn: 1,
              }),
              NPGlobalId: NPId,
              scheduleTelehealth: scheduleDetails?.isTelehealth || false,
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionCompleted(SchedulePatientAppointmentAsPharmacy),
        withLatestFrom(this.action$),
        takeUntil(this.destroy$)
      )
      .subscribe(([data, action]) => {
        if (data.result.successful) {
          this.store.dispatch(new GetSingleAction({ globalId: action?.global_id }));
        }

        if (data.result.error) {
          this.modalService.openGenericPromptDialog({
            promptTitle: 'Error scheduling patient',
            promptMessage: data.result.error.message,
          });
        }
      });

    // Close the all modals when providing a reason for removing an appointment and cancelling the appointment
    this.actions$
      .pipe(
        ofActionSuccessful(UnscheduleAppointmentForPatientAsPharmacy),
        withLatestFrom(this.action$),
        takeUntil(this.destroy$)
      )
      .subscribe(([, action]) => {
        this.modalService.closeAll();
        this.store.dispatch(new GetSingleAction({ globalId: action?.global_id }));
      });

    // Get appointments list when navigating to next/previous week
    this.actions$
      .pipe(
        ofActionSuccessful(NavigateToNextWeek, NavigateToPreviousWeek, NavigateToCurrentWeek),
        withLatestFrom(
          this.appointmentsPeriod$,
          this.action$,
          this.scheduleDetails$,
          this.scheduleLocationModalState$
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        const [, appointmentsPeriod, action, scheduleDetails, scheduleLocationModalState] = result;
        if (
          !action ||
          !scheduleDetails?.appointmentAddress ||
          scheduleDetails.isTelehealth === undefined
        )
          return;

        let NPId = scheduleDetails.assignedNP?.global_id;
        if (!NPId && scheduleLocationModalState.isTelehealthSelected) {
          NPId = 0;
        }

        if (!NPId && NPId !== 0) {
          return;
        }

        this.store.dispatch(
          new GetAppointmentsListAsPharmacy({
            startDate: appointmentsPeriod.startDate,
            endDate: appointmentsPeriod.endDate,
            actionGlobalId: action.global_id,
            addressHash: scheduleDetails.appointmentAddress.address_hash,
            scheduleTelehealth: scheduleDetails.isTelehealth,
            NPGlobalId: NPId,
          })
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
