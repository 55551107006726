<t-greeting-card title="Email verification required">
  <span description class="text-sm text-white">
    Please verify your email address in order to login.
  </span>
  <t-button
    cta
    (click)="loginWithRedirect()"
    [isFullWidth]="true"
    [filled]="true"
    variant="neutral">
    Login
  </t-button>
</t-greeting-card>
