import { Params } from '@angular/router';
import JsonURL from '@jsonurl/jsonurl';
import { getUniqueValuesByKey, getUniqueValuesByNestedKey } from '@troyai/shared/utils';
import { sort } from 'fast-sort';
import { Filter, FilterConfig, FilterQueryParam, FilterValue } from '../models/filters.model';

export const generateFilters = <T>(
  filterConfig: FilterConfig<T>[],
  data: T[],
  queryParams?: Params,
  defaultFilters?: FilterQueryParam[]
): Filter<T>[] => {
  const filtersQueryParams: FilterQueryParam[] =
    queryParams && queryParams['filter']
      ? JsonURL.parse(queryParams?.['filter'] as string, { AQF: true })
      : defaultFilters || [];

  // Flatten the filters query params in order to be able to check if a filter is active by key
  const flattenedFiltersQueryParams = filtersQueryParams.reduce(
    (acc: FilterQueryParam, item: FilterQueryParam) => {
      return { ...acc, ...item };
    },
    {} as FilterQueryParam
  );

  return filterConfig.map((option) => {
    // Values source as 'unique' means that the filter values are generated from the data
    if (option.valuesSource === 'unique') {
      const uniqueItems = option.nestedPropertyPath
        ? getUniqueValuesByNestedKey(data, option.keyName, option.nestedPropertyPath)
        : getUniqueValuesByKey(data, option.keyName);

      const groupedItems = option.groupingPrefix
        ? uniqueItems.reduce((acc: string[], item) => {
            if (option.groupingPrefix) {
              const groupingPrefix = option.groupingPrefix.find((prefix) =>
                item.startsWith(prefix)
              );
              acc.push(groupingPrefix ? groupingPrefix : item);
            }
            return acc;
          }, [])
        : uniqueItems;

      const filterValuesItems = option.groupingPrefix
        ? Array.from([...new Set(groupedItems)])
        : uniqueItems;

      const values = filterValuesItems.map((uniqueFilterItem) => {
        const filterValue: FilterValue = {
          id: uniqueFilterItem as string,
          isActive: flattenedFiltersQueryParams[option.id]
            ? flattenedFiltersQueryParams[option.id].includes(uniqueFilterItem as string)
            : false,
        };
        return filterValue;
      });

      const sortedValues = sort(values).asc((u) => u.id);

      return {
        config: option,
        values: sortedValues,
      };
    }

    // Filters are provided
    if (option.valuesSource === 'custom') {
      if (option.customValues) {
        const values = option.customValues?.map((value) => {
          const filterValue: FilterValue = {
            id: value.label,
            isActive: flattenedFiltersQueryParams[option.id]
              ? flattenedFiltersQueryParams[option.id].includes(value.label)
              : false,
          };
          return filterValue;
        });

        const sortedValues = sort(values).asc((u) => u.id);
        return {
          config: option,
          values: sortedValues,
        };
      }
    }

    return {
      config: option,
      values: [],
    };
  });
};
