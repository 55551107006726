export class GetCMPatientsList {
  static readonly type = '[CM] Get patients list';
}

export class GetCMActionsList {
  static readonly type = '[CM] Get Actions list';
}

export class GetCMCaseNotesList {
  static readonly type = '[CM] Get Case Notes list';
}
