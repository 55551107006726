import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'img[tImgFallback]',
})
export class ImageFallbackDirective {
  @Input() tImgFallback = '';

  constructor(private ref: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.ref.nativeElement;
    element.src = this.tImgFallback || '/assets/broken-image.svg';
  }
}
