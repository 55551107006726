<div class="relative flex flex-col items-center justify-center text-center">
  <div class="bg-red-20 text-red-90 mb-4 inline-block rounded-full p-3">
    <t-icon key="close" size="tiny" color="inherit"></t-icon>
  </div>
  <strong class="mb-2 block text-base font-semibold text-red-100">
    {{ titleText }}
  </strong>

  <div *ngIf="errorsList.length; else customErrorContent" class="space-y-2">
    <p class="text-grey-70 block text-sm" *ngFor="let error of errorsList">{{ error }}</p>
  </div>

  <ng-template #customErrorContent>
    <ng-content></ng-content>
  </ng-template>
</div>
