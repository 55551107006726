<div class="min-w-[480px]">
  <div class="relative space-y-2">
    <strong class="text-grey-70 block text-base">Payments</strong>
    <p class="text-grey-70 block text-xs">Select the account you want us to use for payments.</p>
  </div>
  <form
    [formGroup]="bankAccountSelectionForm"
    (ngSubmit)="!isLoading ? selectBankAccount() : null"
    class="divide-grey-20 border-grey-20 mt-8 divide-y border-t">
    <label
      *ngFor="let account of plaidMetadata.accounts"
      [for]="account.id"
      class="hover:bg-grey-10 flex cursor-pointer items-center justify-start py-3 pl-3">
      <input
        class="form-radio checked:bg-red-90 checked:hover:bg-red-90 checked:active:bg-red-90 checked:focus:bg-red-90 checked:disabled:hover:bg-grey-20 accent-red-90 border-grey-50 focus:border-red-90 focus:ring-red-30 disabled:border-grey-40 disabled:bg-green-20 disabled:cursor-not-allowe mr-2.5 cursor-pointer rounded-full bg-white focus:ring focus:ring-opacity-50 focus:ring-offset-0"
        type="radio"
        [value]="account.id"
        [id]="account.id"
        formControlName="account" />

      <div class="text-grey-70 space-y-2 pl-5">
        <strong class="block text-sm font-semibold">{{ account.name }}</strong>
        <span class="text-grey-55 block text-sm">******{{ account.mask }}</span>
      </div>
    </label>

    <t-button
      class="mt-8 block"
      [loading]="isLoading"
      [disabled]="!bankAccountSelectionForm.valid"
      [isFullWidth]="true">
      {{ isLoading ? 'Saving...' : 'Save' }}
    </t-button>
  </form>
</div>
