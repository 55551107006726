@for (hraItem of hraItems; track $index) {
  <t-card (click)="triggerItemSelectionForPreview(hraItem)" class="relative mb-2 block">
    <div class="flex flex-col justify-between md:flex-row md:items-center">
      <!-- Assessment type -->
      <div class="flex items-center gap-x-5">
        <div class="bg-grey-20 text-grey-100 rounded-lg p-4">
          @switch (hraItem.type) {
            @case ('npa') {
              <t-icon key="clinical-notes" size="medium" color="inherit" />
            }

            @case ('hra') {
              <t-icon key="article" size="medium" color="inherit" />
            }

            @default {
              <t-icon key="error" size="medium" color="inherit" />
            }
          }
        </div>
        <div class="space-y-2">
          <strong class="text-grey-100 block text-sm font-semibold">
            @switch (hraItem.type) {
              @case ('hra') {
                Health Risk Assessment
              }

              @case ('npa') {
                NP Assessment
              }

              @default {
                Unknown assessment type
              }
            }
          </strong>
          <span class="text-grey-60 text-xs">
            {{ hraItem.submission_date | date: 'MM/dd/yyyy' }} by {{ hraItem.submitted_by }}
          </span>
        </div>
      </div>
      <!-- /Assessment type -->

      <!-- Assessment actions -->
      @if (hraItem.latest_file_id !== null) {
        <div class="flex items-center gap-x-3" *tIfFeatureFlag="'hra-listing-addendum'">
          <t-button
            (click)="
              $event.preventDefault();
              $event.stopPropagation();
              triggerItemSelectionForAddendum(hraItem)
            "
            variant="neutral"
            size="tiny">
            Addendums
            <t-label size="tiny" paddingSize="small">{{ hraItem.addendums.length }}</t-label>
          </t-button>
        </div>
      }

      <!-- /Assessment actions -->
    </div>
  </t-card>
}
