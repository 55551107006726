import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns/formatDistanceToNowStrict';
import { isPast } from 'date-fns/isPast';

export type PrefixesTypes = 'expiration';

@Pipe({
  name: 'dateDistance',
  standalone: true,
})
export class DateDistancePipe implements PipeTransform {
  transform(input: string | null, prefixType?: PrefixesTypes, includeSuffix = true): string {
    if (!input) return '';

    const inputDate = new Date(input);

    let prefix = '';
    if (prefixType === 'expiration') {
      prefix = isPast(inputDate) ? 'Expired' : 'Expires';
    }

    const humanReadableDistance = formatDistanceToNowStrict(inputDate, {
      addSuffix: includeSuffix,
    });

    return `${prefix} ${humanReadableDistance}`;
  }
}
