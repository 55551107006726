import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Params, RouterModule } from '@angular/router';

type Sizes = 'small' | 'large';
type Variants = 'light' | 'dark' | 'accent';

@Component({
  selector: 't-link',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './link.component.html',
})
export class LinkComponent implements OnInit {
  @Input() size: Sizes = 'small';
  @Input() variant: Variants = 'dark';
  @Input() isExternal = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() path: string | any[] | null | undefined = '';
  @Input() params: Params = {};
  @Input() isButton = false;
  @Input() isDisabled? = false;

  ngOnInit() {
    if (this.isButton && this.path && this.path.length > 0) {
      throw new TypeError("The 'path' input is not allowed while using LinkComponent as a button");
    }
  }
}
