import { UserDetails } from '../models/user-details.interface';
import { isInternalTeamUser } from './is-internal-team-user.util';

export const isMOCCompliantForCurrentYear = (userDetails: UserDetails) => {
  const complianceCompletedYears = userDetails?.settings?.compliance_completed_years;

  if (isInternalTeamUser(userDetails.email)) {
    return true;
  }

  return !!(
    complianceCompletedYears &&
    complianceCompletedYears.length &&
    complianceCompletedYears.includes(new Date().getFullYear())
  );
};
