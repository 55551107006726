import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { InlineDiagnosesListTriggerComponent } from './diagnoses/inline-diagnoses-list-trigger.component';
import { InlineMedicationsListTriggerComponent } from './medications/inline-medications-list-trigger.component';

@Component({
  selector: 't-dynamic-enrichment',
  standalone: true,
  template: `<ng-template></ng-template>`,
})
export class DynamicEnrichmentComponent implements OnInit {
  @Input() control = {
    dataSource: '',
  };

  constructor(private vcr: ViewContainerRef) {}

  ngOnInit() {
    if (this.control.dataSource) {
      switch (this.control.dataSource) {
        case '192_activeDiagnoses':
          this.vcr.createComponent(InlineDiagnosesListTriggerComponent);
          break;

        case '191_medicationsList':
          this.vcr.createComponent(InlineMedicationsListTriggerComponent);
          break;
      }
    }
  }
}
