import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { Resource } from './models/resource.interface';

@Injectable({
  providedIn: 'root',
})
export class ResourcesApiService {
  constructor(private restApiService: RestApiClientService) {}

  getAll() {
    return this.restApiService.get<Resource[]>('resources');
  }

  getSignedDownloadURL(resourceId: number) {
    return this.restApiService.get<string>(`resources/${resourceId}/download`);
  }
}
