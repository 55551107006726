import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { endOfWeek, startOfWeek } from 'date-fns';
import {
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { Action, ActionsState, GetSingleAction } from '@troyai/actions/data-access';
import { ActionAppointmentPreviewComponent, ActionStatusCardComponent } from '@troyai/actions/ui';
import { UserRoles } from '@troyai/auth/data-access';
import {
  Appointment,
  AssignAppointmentToPatient,
  CareState,
  GetAppointmentsList,
  NavigateToCurrentWeek,
  NavigateToNextWeek,
  NavigateToPreviousWeek,
  UnscheduleAppointmentForPatientAsPharmacy,
} from '@troyai/hra/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { AddressPipe, CustomMediumDatePipe, TimeIntervalPipe } from '@troyai/portal/common/pipes';
import {
  AccordionComponent,
  AccordionItemComponent,
  ButtonComponent,
  CardComponent,
  EmptyStateCardComponent,
  IconsModule,
  LinkComponent,
  ModalModule,
  ModalService,
  SkeletonLoaderComponent,
  WeekNavigationTarget,
  WeekNavigatorComponent,
} from '@troyai/ui-kit';
import { PatientSchedulerLocationModalComponent } from '../patient-scheduler-location-modal/patient-scheduler-location-modal.component';
import { ScheduleRemoveReasonPromptComponent } from '../schedule-remove-reason-prompt/schedule-remove-reason-prompt.component';

@Component({
  selector: 't-np-patient-scheduler-modal',
  standalone: true,
  imports: [
    CommonModule,
    WeekNavigatorComponent,
    SkeletonLoaderComponent,
    AccordionComponent,
    AccordionItemComponent,
    LinkComponent,
    ButtonComponent,
    IconsModule,
    ModalModule,
    ActionStatusCardComponent,
    CustomMediumDatePipe,
    CardComponent,
    EmptyStateCardComponent,
    ActionAppointmentPreviewComponent,
    AddressPipe,
    TimeIntervalPipe,
  ],
  templateUrl: './np-patient-scheduler-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NursePractitionerPatientSchedulerModalComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private actions$: Actions,
    public dialogRef: DialogRef,
    private modalService: ModalService,
    private fullNamePipe: FullNamePipe
  ) {}

  destroy$ = new Subject<boolean>();
  groupedAppointmentsListItems$ = this.store.select(CareState.groupedAppointmentsListItems);
  appointmentsListItemsLoading$ = this.store.select(CareState.appointmentsListItemsLoading);
  appointmentsPeriod$ = this.store.select(CareState.appointmentsPeriod);
  nursePractitionersList$ = this.store.select(CareState.nursePractitionersList);

  action$ = this.store.select(ActionsState.selectedAction);
  selectedActionLoading$ = this.store.select(ActionsState.selectedActionLoading);
  schedulingPatientLoading$ = this.store.select(CareState.schedulingPatientLoading);
  patient$ = this.store.select(PatientsState.selectedPatient);
  scheduleDetails$ = this.store.select(CareState.scheduleAppointmentDetails);

  isModalLoading$ = combineLatest([
    this.schedulingPatientLoading$,
    this.selectedActionLoading$,
  ]).pipe(
    map(([schedulingLoading, actionLoading]) => {
      return schedulingLoading || actionLoading;
    })
  );

  action?: Action;

  schedulePatient(timeslot: Appointment) {
    this.action$
      .pipe(
        withLatestFrom(this.scheduleDetails$),
        tap(([action, scheduleDetails]) => {
          if (!action || !scheduleDetails?.appointmentAddress) return;

          this.store.dispatch(
            new AssignAppointmentToPatient({
              timeslot: timeslot,
              actionId: action.global_id,
              addressHash: scheduleDetails.appointmentAddress.address_hash,
            })
          );
        }),
        take(1)
      )
      .subscribe();
  }

  openSchedulingLocationModal() {
    this.dialogRef.close();

    this.patient$.pipe(take(1)).subscribe((patient) => {
      const patientName = this.fullNamePipe.transform(patient);
      this.modalService.openDialog(PatientSchedulerLocationModalComponent, null, {
        title: `Schedule NP Health Assessment for ${patientName}`,
        background: 'grey',
      });
    });
  }

  cancelAppointment() {
    this.action$.pipe(take(1)).subscribe((action) => {
      if (action) {
        this.modalService.openPromptDialog(ScheduleRemoveReasonPromptComponent, {
          asRole: UserRoles.CareTeamHRA,
          actionId: action.global_id,
          onPromptConfirm: (reason: string) => {
            this.store.dispatch(
              new UnscheduleAppointmentForPatientAsPharmacy({
                reason,
                actionId: action.global_id,
              })
            );
          },
        });
      }
    });
  }

  onWeekChange(target: WeekNavigationTarget) {
    switch (target) {
      case 'next':
        this.store.dispatch(new NavigateToNextWeek());
        break;
      case 'previous':
        this.store.dispatch(new NavigateToPreviousWeek());
        break;
      case 'current':
        this.store.dispatch(new NavigateToCurrentWeek());
        break;
    }
  }

  async ngOnInit() {
    // On each action change, fetch appointments list for the current week
    this.action$
      .pipe(
        distinctUntilChanged(),
        tap((action) => {
          this.action = action;

          if (!action) return;
          this.store.dispatch(
            new GetAppointmentsList({
              startDate: startOfWeek(Date.now(), {
                weekStartsOn: 1,
              }),
              endDate: endOfWeek(Date.now(), {
                weekStartsOn: 1,
              }),
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Re-fetch single action when scheduling patient, so new scheduled appointment is reflected in the UI
    this.actions$
      .pipe(ofActionSuccessful(AssignAppointmentToPatient), takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new GetSingleAction({ globalId: this.action?.global_id }));
      });

    // Get appointments list when navigating to next/previous week
    this.actions$
      .pipe(
        ofActionSuccessful(NavigateToNextWeek, NavigateToPreviousWeek, NavigateToCurrentWeek),
        withLatestFrom(this.appointmentsPeriod$, this.action$),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        const [, appointmentsPeriod, action] = result;
        if (!action) return;
        this.store.dispatch(
          new GetAppointmentsList({
            startDate: appointmentsPeriod.startDate,
            endDate: appointmentsPeriod.endDate,
          })
        );
      });

    this.actions$
      .pipe(ofActionSuccessful(UnscheduleAppointmentForPatientAsPharmacy), takeUntil(this.destroy$))
      .subscribe(() => {
        this.modalService.closeAll();
        this.store.dispatch(new GetSingleAction({ globalId: this.action?.global_id }));
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
