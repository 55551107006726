import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../icon/icon.module';
import { SvgIcons } from '@ngneat/svg-icon';

type Variants = 'primary' | 'secondary';

@Component({
  selector: 't-nav-button',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './nav-button.component.html',
})
export class NavButtonComponent {
  @Input() isActive = false;
  @Input() icon: SvgIcons | null = null;
  @Input() variant: Variants = 'primary';
}
