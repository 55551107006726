import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../icon/icon.module';
import { SvgIcons } from '@ngneat/svg-icon';

type Variants = 'primary' | 'secondary' | 'tertiary';
type Sizes = 'small' | 'large';

@Component({
  selector: 't-nav-item',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.css'],
})
export class NavMenuItemComponent {
  @Input() isActive = false;
  @Input() variant: Variants = 'primary';
  @Input() size: Sizes = 'large';
  @Input() icon: SvgIcons | null = null;
  @Input() isDisabled = false;
}
