import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  generateSortOptions,
  ListingDataSource,
  SortOptionValue,
} from '@troyai/data-source-listing';
import { MedicationListItem, MedicationState } from '@troyai/medication/medication-data-access';
import {
  DataListingComponent,
  ExpandableCardComponent,
  LabelComponent,
  PaginatorComponent,
} from '@troyai/ui-kit';
import { map } from 'rxjs';

@Component({
  selector: 't-inline-medications-list',
  standalone: true,
  imports: [
    CommonModule,
    ExpandableCardComponent,
    LabelComponent,
    DataListingComponent,
    PaginatorComponent,
  ],
  templateUrl: './inline-medications-list.component.html',
})
export class InlineMedicationsListComponent implements OnInit {
  constructor(private store: Store) {}

  medicationList$ = this.store
    .select(MedicationState.medicationList)
    .pipe(map((items) => items ?? []));

  medicationListDataSource = new ListingDataSource<MedicationListItem>();

  pageSize = 10;

  medicationAvailableSortingOptions: SortOptionValue<MedicationListItem>[] =
    generateSortOptions<MedicationListItem>(
      [
        {
          label: 'Date',
          value: {
            keyName: 'created_on',
            order: 'desc',
          },
        },
      ],
      'created_on'
    );

  ngOnInit(): void {
    this.medicationListDataSource.init(
      this.medicationList$,
      this.medicationAvailableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );
  }
}
