import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IconsModule } from '../icon/icon.module';

export interface AvailableColor {
  name: string;
  value: string;
}

@Component({
  selector: 't-color-picker',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css'],
  animations: [
    trigger('inOutAnimation', [
      state(
        'open',
        style({
          width: '200px',
        })
      ),
      state(
        'closed',
        style({
          width: '0',
        })
      ),
      transition('* => closed', [animate('0.12s')]),
      transition('* => open', [animate('0.12s')]),
    ]),
  ],
})
export class ColorPickerComponent implements OnInit {
  constructor(private _elementRef: ElementRef) {}

  @Input() initialColor = '#B4B0A7';
  @Input() readOnly = false;
  @Input() availableColors: AvailableColor[] = [
    {
      name: 'bg-red-90',
      value: '#E53323',
    },
    {
      name: 'bg-amber-70',
      value: '#EA810B',
    },
    {
      name: 'bg-yellow-80',
      value: '#EBAD00',
    },
    {
      name: 'bg-green-90',
      value: '#377C3E',
    },
    {
      name: 'bg-grey-50',
      value: '#B4B0A7',
    },
    {
      name: 'bg-grey-25',
      value: '#EBE8E1',
    },
  ];

  @Output() selectedColorChange = new EventEmitter<AvailableColor>();

  isOpen = false;
  selectedColor?: AvailableColor;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(_event: MouseEvent, targetElement: HTMLElement) {
    const isClickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!isClickedInside) {
      this.isOpen = false;
    }
  }

  changeColor(color: AvailableColor) {
    this.selectedColor = color;
    this.isOpen = false;
    this.selectedColorChange.emit(color);
  }

  toggleColorsList() {
    this.isOpen = !this.isOpen;
  }

  ngOnInit() {
    this.selectedColor = this.availableColors.find((color) => color.value === this.initialColor);
  }
}
