import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedTourService } from '../../guided-tour.service';
import { ButtonComponent } from '@troyai/ui-kit';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 't-welcome-tour-trigger-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './welcome-tour-trigger-modal.component.html',
})
export class WelcomeTourTriggerModalComponent {
  constructor(
    private guidedTourService: GuidedTourService,
    private dialogRef: DialogRef
  ) {}

  startTour() {
    this.guidedTourService.start();
    this.dialogRef.close();
  }

  skipTour() {
    this.guidedTourService.cancel();
    this.dialogRef.close();
  }
}
