import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadingState, getLoadingStatus } from '@troyai/shared/state';
import { catchError, tap, throwError } from 'rxjs';
import { CareManagementApiService } from '../care-management-api.service';
import { CareManagementStateModel } from './cm-state.model';
import { GetCMActionsList, GetCMPatientsList } from './cm.actions';

const stateDefaults = {
  patients: {
    result: [],
    loadingStatus: LoadingState.INITIAL,
  },
  actions: {
    result: [],
    loadingStatus: LoadingState.INITIAL,
  },
};

@State<CareManagementStateModel>({
  name: 'careManagementState',
  defaults: stateDefaults,
})
@Injectable()
export class CareManagementState {
  constructor(private careManagementApiService: CareManagementApiService) {}

  @Selector()
  static patients(state: CareManagementStateModel) {
    return state.patients.result;
  }

  @Selector()
  static patientsLoadingStatus(state: CareManagementStateModel) {
    return getLoadingStatus(state.patients.loadingStatus);
  }

  @Selector()
  static actions(state: CareManagementStateModel) {
    return state.actions.result;
  }

  @Selector()
  static actionsLoadingStatus(state: CareManagementStateModel) {
    return getLoadingStatus(state.actions.loadingStatus);
  }

  @Action(GetCMPatientsList)
  getPatientsList({ patchState }: StateContext<CareManagementStateModel>) {
    patchState({
      patients: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.careManagementApiService.getPatients().pipe(
      tap((patients) => {
        patchState({
          patients: {
            result: patients,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((error) => {
        patchState({
          patients: {
            result: null,
            loadingStatus: LoadingState.ERRORED,
          },
        });
        return throwError(() => new Error(error));
      })
    );
  }

  @Action(GetCMActionsList)
  getActionsList({ patchState }: StateContext<CareManagementStateModel>) {
    patchState({
      actions: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.careManagementApiService.getActions().pipe(
      tap((actions) => {
        patchState({
          actions: {
            result: actions,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((error) => {
        patchState({
          actions: {
            result: null,
            loadingStatus: LoadingState.ERRORED,
          },
        });
        return throwError(() => new Error(error));
      })
    );
  }
}
