import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Resource } from '@troyai/resources/data-access';
import { FileCardComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-action-resources-list',
  standalone: true,
  imports: [CommonModule, FileCardComponent],
  templateUrl: './action-resources-list.component.html',
})
export class ActionResourcesListComponent {
  private _resources: Resource[] = [];

  @Input() set resources(value: Resource[]) {
    this._resources = value.filter((item) => item.external_link);
  }

  get resources() {
    return this._resources;
  }
}
