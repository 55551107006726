import { StatsTimeInterval } from '../models/pharmacy-stats.interface';

export class InitializeDashboardWidgetsPharmacyStatsData {
  static readonly type = '[Activity][Dashboard] Initialize dashboard widgets pharmacy stats data';
  constructor(public payload: { pharmacyId?: number | null; timeInterval: StatsTimeInterval }) {}
}

export class PopulatePharmacyStats {
  static readonly type = '[Activity] Populate pharmacy stats';
  constructor(public payload: { pharmacyId?: number | null; timeInterval: StatsTimeInterval }) {}
}

export class GetPharmacyActionsStats {
  static readonly type = '[Activity] Get pharmacy actions stats';
  constructor(public payload: { pharmacyId?: number | null; timeInterval: StatsTimeInterval }) {}
}

export class GetPharmacyEngagementRatesStats {
  static readonly type = '[Activity][Dashboard] Get pharmacy engagement rates stats';
  constructor(public payload: { pharmacyId?: number | null; timeInterval: StatsTimeInterval }) {}
}

export class GetPharmacyEngagementStats {
  static readonly type = '[Activity][Dashboard] Get pharmacy engagement stats';
  constructor(public payload: { pharmacyId?: number | null; timeInterval: StatsTimeInterval }) {}
}

export class GetPharmacyCompletedOfAssignedStats {
  static readonly type = '[Activity] Get pharmacy completed of assigned stats';
  constructor(public payload: { pharmacyId?: number | null; timeInterval: StatsTimeInterval }) {}
}
