import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_OPTIONS, CacheOptions, RestApiClientService } from '@troyai/rest-api-client';
import { LeaderboardItemsResponse } from './models/leaderboard-item.interface';

@Injectable({
  providedIn: 'root',
})
export class LeaderboardApiService {
  constructor(private restApiService: RestApiClientService) {}

  getLeaderboardData(cacheOptions?: CacheOptions) {
    const context = new HttpContext();
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<LeaderboardItemsResponse>('leaderboard', {
      context,
    });
  }
}
