<div class="space-y-2">
  @for (notification of notifications; track notification.messageId) {
    <t-card
      paddingSize="none"
      class="block"
      [ngClass]="{
        'opacity-60': notification.read
      }">
      <a [routerLink]="notification.routeTarget" (click)="readNotification(notification)">
        <div class="items-center p-4 lg:flex">
          <!-- Left side -->
          <div class="items-center space-y-4 lg:flex lg:w-1/2 lg:space-y-0">
            <t-notification-icon
              [image]="notification.preview.image_url"
              [type]="notification.preview.type" />

            <div class="space-y-2 lg:ml-4">
              @if (notification.preview.title) {
                <span class="text-grey-100 block text-sm font-semibold">
                  {{ notification.preview.title }}
                </span>
              }

              @if (notification.preview.resource) {
                <div class="flex items-center gap-x-4">
                  <div
                    *ngIf="notification.preview.resource['member_name']"
                    class="text-grey-50 flex items-center gap-x-1.5 text-xs">
                    <t-icon key="face" color="inherit" size="tiny"></t-icon>
                    <span>{{ notification.preview.resource['member_name'] }}</span>
                  </div>

                  <div
                    *ngIf="notification.preview.resource['pharmacy_name']"
                    class="text-grey-50 flex items-center gap-x-1.5 text-xs">
                    <t-icon key="storefront" color="inherit" size="tiny"></t-icon>
                    <span>{{ notification.preview.resource['pharmacy_name'] }}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          <!-- /Left side -->

          <!-- Right side -->
          <div class="items-center justify-end pt-4 lg:flex lg:w-1/2 lg:space-x-8 lg:pt-0">
            <t-label size="tiny">{{ notification.preview.label }}</t-label>
            <div
              class="border-grey-20 mt-4 flex items-center justify-between border-t pt-4 lg:mt-0 lg:space-x-8 lg:border-t-0 lg:pt-0">
              <span class="text-grey-70 block text-xs">
                {{ notification.created | dateDistance }}
              </span>
              <t-button size="small" variant="neutral">VIEW</t-button>
            </div>
          </div>
          <!-- /Right side -->
        </div>
      </a>
    </t-card>
  }
</div>
