import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Notification } from '@troyai/notifications/data-access';
import { DateDistancePipe } from '@troyai/portal/common/pipes';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { ButtonComponent, CardComponent, IconsModule, LabelComponent } from '@troyai/ui-kit';
import { NotificationIconComponent } from '../notification-icon/notification-icon.component';

@Component({
  selector: 't-notifications-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ImageFallbackDirective,
    LabelComponent,
    ButtonComponent,
    DateDistancePipe,
    NotificationIconComponent,
    RouterModule,
    IconsModule,
  ],
  templateUrl: './notifications-list.component.html',
})
export class NotificationsListComponent {
  @Input() notifications: Notification[] = [];
  @Output() readNotificationEvent = new EventEmitter<Notification>();

  readNotification(notification: Notification) {
    this.readNotificationEvent.emit(notification);
  }
}
