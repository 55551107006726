import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent, CardComponent, HeadingComponent, IconsModule } from '@troyai/ui-kit';

interface ComplianceMOCStep {
  id: string;
  title: string;
  ctaText: string;
  disabled: boolean;
  pending: boolean;
  completed: boolean;
}

@Component({
  selector: 't-moc-training-view',
  standalone: true,
  imports: [CommonModule, CardComponent, IconsModule, HeadingComponent, ButtonComponent],
  templateUrl: './moc-training-view.component.html',
  styleUrls: ['./moc-training-view.component.css'],
})
export class MocTrainingViewComponent {
  currentYear = new Date().getFullYear();
  deadline = new Date(this.currentYear, 5, 30);

  @Output() triggerFormEmbed = new EventEmitter<void>();

  complianceSteps: ComplianceMOCStep[] = [
    {
      id: 'complete_training',
      title: 'Complete the training',
      ctaText: 'TRAINING',
      disabled: false,
      pending: true,
      completed: false,
    },
    {
      id: 'sign_attestation_form',
      title: 'Complete and sign the Training Attestation Form',
      ctaText: 'ATTESTATION',
      disabled: true,
      pending: true,
      completed: false,
    },
  ];

  onStepSelection(step: ComplianceMOCStep) {
    if (step.id === 'sign_attestation_form') {
      this.triggerFormEmbed.emit();
    }

    if (step.id === 'complete_training') {
      window.open(
        `https://storage.googleapis.com/troyai-public-assets/troy.ai/moc-compliance/2024_MOC_Training.pdf`,
        '_blank'
      );

      setTimeout(() => {
        this.updateStepState('complete_training', {
          disabled: false,
          pending: false,
          completed: true,
        });

        this.updateStepState('sign_attestation_form', {
          disabled: false,
          pending: true,
          completed: false,
        });
      }, 3000);
    }
  }

  updateStepState(
    stepId: string,
    state: { disabled: boolean; pending: boolean; completed: boolean }
  ) {
    this.complianceSteps = this.complianceSteps.map((s) => {
      if (s.id === stepId) {
        return {
          ...s,
          ...state,
        };
      }

      return s;
    });
  }
}
