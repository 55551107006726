import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionForm, ActionTemplate } from '@troyai/actions/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { OngoingCareActionTitlePipe } from '@troyai/portal/actions/utils';
import { DateOfBirthPipe, ExpirationDatePipe } from '@troyai/portal/common/pipes';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { ButtonComponent, CardComponent, LabelComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-ongoing-actions-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    LabelComponent,
    ExpirationDatePipe,
    DateOfBirthPipe,
    ImageFallbackDirective,
    FullNamePipe,
    PharmacyIndicatorTooltipComponent,
    OngoingCareActionTitlePipe,
  ],
  templateUrl: './ongoing-actions-list.component.html',
})
export class OngoingActionsListComponent {
  @Input() actions: ActionTemplate[] = [];
  @Output() openAction = new EventEmitter<ActionForm>();

  triggerActionSelection(formSettings: ActionForm) {
    this.openAction.emit(formSettings);
  }
}
