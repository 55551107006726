/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsModule } from '@troyai/ui-kit';
import { SortOptionValue, SortOrder } from '../../models/page.model';

type Variants = 'cards' | 'table';

@Component({
  selector: 't-listing-sorting-headings',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './listing-sorting-headings.component.html',
})
export class ListingSortingHeadingsComponent {
  @Input() sortingOptions: SortOptionValue<any>[] = [];
  @Input() displayTailPlaceholder = false;
  @Input() sortingDisabled = false;
  @Input() variant: Variants = 'cards';
  @Output() sortingChange = new EventEmitter<SortOptionValue<any>[]>();

  updateSortSelection(sort: SortOptionValue<any>) {
    const newSortSelection = this.sortingOptions.map((option) => {
      if (option.config.value.keyName === sort.config.value.keyName) {
        let newSortingOrder: SortOrder = 'asc';
        if (option.isActive) {
          newSortingOrder = option.config.value.order === 'asc' ? 'desc' : 'asc';
        }

        return {
          ...option,
          config: {
            ...option.config,
            value: {
              ...option.config.value,
              order: newSortingOrder,
            },
          },
          isActive: true,
        };
      }

      return {
        ...option,
        isActive: false,
      };
    });

    this.sortingChange.emit(newSortSelection);
  }
}
