<div class="bg-grey-15 min-h-screen">
  <div class="mx-auto max-w-6xl">
    <div class="border-grey-25 lg:px:0 flex items-center justify-between border-b px-4 pb-4 pt-8">
      <img src="/assets/troy-logo-red.svg" />

      <div *ngIf="selectedPharmacy" class="items-center text-xs lg:flex lg:gap-x-3">
        <ng-container *tIfHasRole="userRoles.PharmacyUser">
          <div class="flex items-center gap-x-3">
            <t-icon key="storefront" color="inherit" size="tiny" />
            <strong class="text-grey-70 font-semibold">{{ selectedPharmacy.name }}</strong>
          </div>

          <span class="text-grey-50 block text-right">NPI {{ selectedPharmacy.npi }}</span>
          <t-icon
            class="cursor-pointer hover:opacity-90"
            (click)="pharmaciesList.toggle()"
            [tPopoverAnchor]="pharmaciesList"
            key="more-vert"
            color="inherit"
            size="small" />
        </ng-container>
      </div>
    </div>
  </div>

  <ng-content />
</div>

<t-popover class="hidden" verticalAlign="above" horizontalAlign="start" #pharmaciesList hasBackdrop>
  <t-pharmacy-list [showAllPharmaciesOption]="false" />
</t-popover>
