<div
  class="shadow-small flex h-full min-h-[150px] flex-col overflow-hidden rounded-lg"
  [ngClass]="{
    'bg-white': !hasEmptyState,
    'bg-grey-5': hasEmptyState
  }">
  <ng-content select="t-widget-card-header" />

  <div class="h-full grow">
    <ng-content select="t-widget-card-contents" />
  </div>

  <ng-content select="t-widget-card-footer" />
</div>
