<ng-template>
  <div
    class="popover-container relative"
    #focusTrapElement
    [ngClass]="{
      'popover-before': horizontalAlign === 'before' || horizontalAlign === 'end',
      'popover-after': horizontalAlign === 'after' || horizontalAlign === 'start',
      'popover-above': verticalAlign === 'above' || verticalAlign === 'end',
      'popover-below': verticalAlign === 'below' || verticalAlign === 'start',
      'popover-center': horizontalAlign === 'center' || verticalAlign === 'center'
    }"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="_onAnimationDone($event)">
    <ng-content></ng-content>
  </div>
</ng-template>
