export class GetPaymentsSummary {
  static readonly type = '[Payments] Get payments summary';
}

export class GetPaymentsHistory {
  static readonly type = '[Payments] Get payments history';
}

export class GetSinglePaymentHistory {
  static readonly type = '[Payments] Get single payment history';
  constructor(public payload: { paymentId: number }) {}
}
