<div class="relative h-screen space-y-3">
  @if (regularDiagnosesDataSource.page$ | async; as regDiagPage) {
    @if (regDiagPage.totalElements > 0) {
      <t-expandable-card class="block">
        <div header class="flex items-center gap-x-3">
          <strong class="text-grey-100 text-xs font-semibold tracking-wider">DIAGNOSES</strong>
          <t-label size="tiny" color="error" paddingSize="small">
            {{ regDiagPage.totalElements }}
          </t-label>
        </div>

        <div class="px-8 pb-6">
          <t-data-listing
            [items]="regDiagPage.items"
            variant="table"
            verticalSpacingSize="small"
            class="border-grey-20 block border-t">
            <ng-template #rowTemplate let-item>
              <div class="flex items-center justify-between">
                <span class="text-grey-70 text-xs">{{ item.name }}</span>
                <div class="flex items-center gap-x-1">
                  <span class="text-grey-60 text-xs">{{ item.code }}</span>
                  <t-button
                    size="tiny"
                    variant="neutral"
                    (click)="transferToFormField(item)"
                    icon="add" />
                </div>
              </div>
            </ng-template>
          </t-data-listing>
        </div>
      </t-expandable-card>
    }

    @if (regDiagPage.totalElements > 0 && regDiagPage.pageSize !== regDiagPage.totalElements) {
      <div class="border-grey-20 absolute bottom-0 left-0 w-full border-t p-4">
        <t-paginator
          [collectionSize]="regDiagPage.totalElements"
          [currentPage]="regDiagPage.pageNumber"
          [pageSize]="pageSize"
          [pageNumbers]="false"
          (pageChanged)="regularDiagnosesDataSource.fetch($event.pageNumber)"
          [maxSize]="2" />
      </div>
    }
  }

  @if (potentialDiagnosesDataSource.page$ | async; as potDiagPage) {
    @if (potDiagPage.totalElements > 0) {
      <t-expandable-card class="block">
        <div header class="flex items-center gap-x-3">
          <strong class="text-grey-100 text-xs font-semibold tracking-wider">
            POTENTIAL DIAGNOSES
          </strong>
          <t-label size="tiny" color="error" paddingSize="small">
            {{ potDiagPage.totalElements }}
          </t-label>
        </div>

        <div class="px-8 pb-6">
          <t-data-listing
            [items]="potDiagPage.items"
            variant="table"
            verticalSpacingSize="small"
            class="border-grey-20 block border-t">
            <ng-template #rowTemplate let-item>
              <div class="flex items-center justify-between">
                <span class="text-grey-70 text-sm">{{ item.name }}</span>
                <span class="text-grey-60 text-sm">{{ item.code }}</span>
              </div>
            </ng-template>
          </t-data-listing>

          @if (
            potDiagPage.totalElements > 0 && potDiagPage.pageSize !== potDiagPage.totalElements
          ) {
            <div class="border-grey-20 border-t pt-4">
              <t-paginator
                [collectionSize]="potDiagPage.totalElements"
                [currentPage]="potDiagPage.pageNumber"
                [pageSize]="pageSize"
                [pageNumbers]="false"
                (pageChanged)="regularDiagnosesDataSource.fetch($event.pageNumber)"
                [maxSize]="2" />
            </div>
          }
        </div>
      </t-expandable-card>
    }
  }
</div>
