<div class="w-full">
  <div
    class="mb-5 flex flex-wrap items-center justify-between"
    [ngClass]="{
      'px-2': hasTitlePadding
    }">
    <div class="flex items-center gap-x-2">
      <span class="text-grey-100 inline-block text-xs font-semibold uppercase tracking-wider">
        {{ headerTitle }}
      </span>
      <ng-content select="[sectionHeaderExtra]"></ng-content>
    </div>

    <ng-content select="[sectionControls]"></ng-content>
  </div>

  <div class="relative">
    <ng-content></ng-content>
  </div>
</div>
