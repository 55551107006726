import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@troyai/ui-kit';

@Component({
  selector: 't-action-estimated-completion-time',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './action-estimated-completion-time.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ActionEstimatedCompletionTimeComponent {
  @Input() completionTimeMin = 0;
  @Input() completionTimeMax = 0;
}
