<t-action-layout-regular *ngIf="action$ | async as action">
  <div content class="space-y-5">
    <!-- Action Card -->
    <t-card [isInteractive]="false" paddingSize="none" class="block">
      <t-action-single-header [action]="action" class="border-b-grey-20 block border-b p-6" />

      <div class="space-y-8 p-8">
        <div class="bg-grey-15 flex items-center gap-x-3 rounded-lg p-4">
          <t-icon key="event" size="tiny" color="inherit"></t-icon>
          <span class="text-grey-100 text-sm font-semibold">
            Last HRA:
            {{
              action.last_hra_completed_on
                ? (action.last_hra_completed_on | date: 'mediumDate')
                : 'N/A'
            }}
          </span>
        </div>

        <div class="bg-amber-20 rounded-lg p-5" *ngIf="computedActionValues.hasCompletedState">
          <t-action-completed-cdps class="block" [action]="action" />
        </div>

        <t-action-rich-text-description class="mb-5 block" [description]="action.description" />
      </div>
      @if (action.resources && action.resources.length > 0) {
        <div class="border-t-grey-20 border-t p-6">
          <strong class="text-grey-60 mb-4 block text-xs font-semibold tracking-wider">
            RESOURCES
          </strong>
          <t-action-resources-list [resources]="action.resources" />
        </div>
      }
    </t-card>
    <!-- /Action Card -->

    <t-card [isInteractive]="false" paddingSize="large" class="block">
      <t-outreach-attempts-list />
    </t-card>

    @if (action.related_actions && action.related_actions.length > 0) {
      <t-card [isInteractive]="false" paddingSize="large" class="block">
        <t-related-actions-list [actions]="action.related_actions | relatedActions" />
      </t-card>
    }
  </div>

  <ng-container sidebar>
    <t-action-cta-card
      [action]="action"
      [computedActionValues]="computedActionValues"
      (actionComplete)="handlePostSubmitData()" />

    <div class="relative max-h-[80vh] flex-1">
      <t-chat-window class="absolute h-full min-h-[300px] w-full" />
    </div>
  </ng-container>
</t-action-layout-regular>
