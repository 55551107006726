import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorSeverity,
  FriendlyFormattedError,
} from '../models/friendly-formatted-error.interface';
import { RestApiResponse } from '@troyai/rest-api-client';
import { ApplicationError } from '../models/application-error.class';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotifyHandlerService {
  constructor(private toaster: ToastrService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public displayToast(errorResponse: any): FriendlyFormattedError {
    let outputError: FriendlyFormattedError = {
      message: 'An error occurred.',
      severity: ErrorSeverity.FATAL,
    };

    if (errorResponse instanceof ApplicationError) {
      outputError = {
        message: errorResponse.getErrorMessage(),
        severity: ErrorSeverity.FATAL,
      };
    }

    if (errorResponse.error instanceof ErrorEvent) {
      outputError = {
        message: errorResponse.error,
        severity: ErrorSeverity.FATAL,
      };
    } else {
      // Get server-side error
      const httpResponse = errorResponse as HttpErrorResponse;
      if (httpResponse.error) {
        const response = httpResponse.error as RestApiResponse<unknown>;
        outputError = {
          message: response.errors[0].error,
          severity: ErrorSeverity.ERROR,
        };
      }
    }

    switch (outputError.severity) {
      case ErrorSeverity.ERROR:
      case ErrorSeverity.FATAL:
        this.toaster.error(outputError.message);
        break;
      case ErrorSeverity.WARNING:
        this.toaster.warning(outputError.message);
        break;
      default:
        this.toaster.info(outputError.message);
        break;
    }

    return outputError;
  }
}
