import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type HeaderVariants = 'regular' | 'bold';

@Component({
  selector: 't-widget-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './widget-card.component.html',
  styleUrls: ['./widget-card.component.css'],
})
export class WidgetCardComponent {
  /**
   * @deprecated Header can now be provided as content
   */
  @Input() headerTitle = '';
  @Input() hasEmptyState = false;

  /**
   * @deprecated Header can now be provided as content
   */
  @Input() headerVariant: HeaderVariants = 'regular';
}
