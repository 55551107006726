import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';

import { LoginFlowInitiated } from '@troyai/auth/data-access';
import { GreetingCardComponent } from '@troyai/auth/feature';
import { Environment } from '@troyai/envs';
import { ButtonComponent } from '@troyai/ui-kit';

@Component({
  standalone: true,
  imports: [CommonModule, GreetingCardComponent, ButtonComponent],
  templateUrl: './logout-shell.component.html',
})
export class LogoutShellComponent {
  constructor(
    private store: Store,
    private env: Environment,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap
      .pipe(
        take(1),
        tap((params) => {
          const shouldRetryLogin = params.get('forceRetryLogin');
          if (shouldRetryLogin) {
            return this.loginWithRedirect();
          }
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  isLoading = true;

  loginWithRedirect(): void {
    this.store.dispatch(
      new LoginFlowInitiated({
        redirectUrl: '',
      })
    );
  }
}
