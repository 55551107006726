<t-section headerTitle="Related actions" [hasTitlePadding]="false">
  <div class="space-y-2">
    <a
      *ngFor="let action of actions"
      [routerLink]="['/patients', action.member_assigned_to, 'actions', action.global_id]"
      class="hover:border-grey-35 border-grey-20 block cursor-pointer rounded border px-3 py-2 transition-colors hover:border">
      <div class="flex items-center justify-between">
        <div class="flex w-1/2 items-center gap-x-4">
          <div class="bg-grey-10 h-[32px] w-[32px] flex-none rounded-full">
            <img [src]="action.fee_schedule_image" tImgFallback="/assets/media-call-center.svg" />
          </div>

          <span class="text-grey-100 block text-sm font-semibold">{{ action.title }}</span>
        </div>

        <div class="flex w-1/2 items-center justify-end gap-x-8">
          <t-label variant="pill" color="warning" size="tiny" paddingSize="small">
            {{ action.status }}
          </t-label>
          <t-button variant="neutral" size="tiny">View</t-button>
        </div>
      </div>
    </a>
  </div>
</t-section>
