import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { of } from 'rxjs';
import { OnboardingAgreementData } from './models/onboarding-agreement-data.model';
import { PlaidLinkTokenResponse } from './models/plaid.interface';

@Injectable({
  providedIn: 'root',
})
export class OnboardingApiService {
  constructor(private restApiService: RestApiClientService) {}

  getPlaidLinkToken(pharmacyId: number) {
    return this.restApiService.get<PlaidLinkTokenResponse>(
      `payment-info/link-token?pharmacy_global_id=${pharmacyId}`
    );
  }

  submitPlaidPublicToken(publicToken: string, pharmacyGlobalId: number) {
    return this.restApiService.post('payment-info/public-token', {
      token: publicToken,
      pharmacy_global_id: pharmacyGlobalId,
    });
  }

  setPreferredPaymentAccount(plaidAccountId: string, pharmacyGlobalId: number) {
    return this.restApiService.patch<GenericRestApiOKResponse>(
      `pharmacy/${pharmacyGlobalId}/preferred-payment-account`,
      {
        plaid_account_id: String(plaidAccountId),
      },
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  getPreferredPaymentAccount(pharmacyGlobalId: number) {
    return this.restApiService.get<string>(
      `pharmacy/${pharmacyGlobalId}/preferred-payment-account`
    );
  }

  // TODO:MULTI-TENANT: Get specific onboarding agreement data based on tenant from the backend
  getOnboardingAgreementData(tenant: string) {
    const onboardingDataByTenant: Record<string, OnboardingAgreementData> = {
      troy: {
        embeddedFormURL: 'https://form.jotform.com/232815059096057',
        documentURL:
          'https://storage.googleapis.com/troyai-public-assets/troy.ai/Participating_Pharmacy_Partner_Agreement_10.09.2023.pdf',
      },
      united: {
        embeddedFormURL: 'https://form.jotform.com/241151999980368',
        documentURL:
          'https://storage.googleapis.com/troyai-public-assets/troy.ai/V3_Pharmacy_Onboarding_Form--edited-FS.pdf',
      },
    };

    return of(onboardingDataByTenant[tenant]);
  }
}
