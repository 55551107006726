<div class="bg-yellow-30 relative flex px-[64px] py-12">
  <div class="lg:w-1/2">
    <strong class="text-grey-80 mb-2 block text-2xl font-semibold">Hey there! 👋</strong>
    <p class="text-grey-80 mb-8 block max-w-xs text-xl">
      Using Troy.AI has never been easier, and there are plenty of new goodies to discover.
    </p>

    <div class="flex gap-x-2">
      <t-button variant="primary" (click)="startTour()">Start tour</t-button>
      <t-button variant="secondary" (click)="skipTour()">Skip</t-button>
    </div>
  </div>

  <img
    class="absolute bottom-0 right-0 hidden h-full w-auto lg:block"
    src="assets/welcome-tour-media.svg"
    alt="Welcome Tour" />
</div>
