import { SelectOption } from '../input/select/select.component';
import {
  RepeatableFieldControlDate,
  RepeatableFieldControlDropdown,
  RepeatableFieldControlText,
  RepeatableFieldControlType,
} from './repeatable-fields.model';

export const parseRepeaterFieldsInputString = (input: string) => {
  // Split by the colon (:) but limit it to 3 splits (label, type, config)
  const fieldLines = input.split(/\r?\n/);

  const results: (RepeatableFieldControlType | null)[] = fieldLines.map((fieldLine) => {
    const parts = fieldLine.split(/\s*:\s*/g); // Split by colon, allowing for spaces before/after
    const label = (parts[0] || '').trim();
    const type = (parts[1] || '').trim();
    const config = (parts[2] || '').trim();

    switch (type) {
      case 'text':
        return {
          label: label,
          type: type,
        } as RepeatableFieldControlText;

      case 'dropdown':
        return {
          label: label,
          type: type,
          options: config.split(', ').map((item) => {
            return {
              id: item,
              label: item,
            } as SelectOption<string>;
          }),
        } as RepeatableFieldControlDropdown;

      case 'dateInput':
        return {
          label: label,
          type: type,
          format: config,
        } as RepeatableFieldControlDate;
    }

    return null;
  });

  return results.filter((result): result is RepeatableFieldControlType => result !== null);
};
