<iframe
  class="w-full border-0"
  [style.minHeight]="formMinHeight ? formMinHeight + 'px' : '80vh'"
  allowtransparency="true"
  allowfullscreen="true"
  allow="geolocation; microphone; camera; autoplay; encrypted-media; fullscreen; accelerometer; gyroscope; picture-in-picture"
  [src]="getIframeURL(sourceURL)"
  frameborder="0"
  [name]="cacheBuster"
  sandbox="allow-scripts allow-forms allow-same-origin  allow-presentation"
  scrolling="yes">
</iframe>
