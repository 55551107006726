import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { CaseNoteTemplate } from './models/case-note-template.interface';
import {
  CaseNoteChanges,
  CreateMemberCaseNotePayload,
  CreateMemberCaseNoteResponse,
  MemberCaseNote,
  PatchMemberCaseNotePayload,
} from './models/member-case-note.interface';

@Injectable({
  providedIn: 'root',
})
export class CaseNotesApiService {
  constructor(private restApiService: RestApiClientService) {}

  getCaseNotesTemplates() {
    return this.restApiService.get<CaseNoteTemplate[]>('case-note-templates');
  }

  getAllMemberCaseNotes(globalId: number) {
    return this.restApiService.get<MemberCaseNote[]>(`members/${globalId}/case-notes`);
  }

  getMemberCaseNote(globalId: number, caseNoteId: number) {
    return this.restApiService.get<MemberCaseNote>(`members/${globalId}/case-notes/${caseNoteId}`);
  }

  createMemberCaseNote(globalId: number, payload: CreateMemberCaseNotePayload) {
    return this.restApiService.post<CreateMemberCaseNoteResponse>(
      `members/${globalId}/case-notes`,
      payload
    );
  }

  editMemberCaseNote(globalId: number, caseNoteId: number, payload: PatchMemberCaseNotePayload) {
    return this.restApiService.patch<GenericRestApiOKResponse>(
      `members/${globalId}/case-notes/${caseNoteId}`,
      payload
    );
  }

  getMemberCaseNoteChanges(globalId: number, caseNoteId: number) {
    return this.restApiService.get<CaseNoteChanges[]>(
      `members/${globalId}/case-notes/${caseNoteId}/changes`
    );
  }

  deleteMemberCaseNote(memberGlobalId: number, caseNoteId: number) {
    return this.restApiService.delete<GenericRestApiOKResponse>(
      `members/${memberGlobalId}/case-notes/${caseNoteId}`
    );
  }
}
