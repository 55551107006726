<div class="text-grey-70 flex justify-start text-xs">
  <div
    class="hover:text-grey-100 group flex cursor-pointer items-center justify-start space-x-1"
    *ngIf="itemsCount >= 2; else singleItem"
    [tPopoverAnchor]="listContents"
    (click)="$event.stopPropagation(); $event.preventDefault(); listContents.toggle()">
    <ng-container *ngTemplateOutlet="preview"></ng-container>
    <div
      class="bg-grey-10 group-hover:bg-grey-40 rounded-full p-0.5"
      [ngClass]="{
        'bg-grey-40': listContents.isOpen()
      }">
      <t-icon
        [key]="listContents.isOpen() ? 'keyboard-arrow-up' : 'keyboard-arrow-down'"
        size="tiniest"
        color="inherit"></t-icon>
    </div>
    <t-popover verticalAlign="below" horizontalAlign="end" #listContents hasBackdrop>
      <ng-content></ng-content>
    </t-popover>
  </div>
  <ng-template #singleItem>
    <ng-container *ngTemplateOutlet="preview"></ng-container>
  </ng-template>
</div>

<ng-template #preview>
  <div>
    <ng-content select="[initialValue]"></ng-content>
  </div>
</ng-template>
