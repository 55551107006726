<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="'action'" class="bg-grey-20 generic-icon">
    <img [src]="image" tImgFallback />
  </div>

  <div *ngSwitchCase="'payment'" class="bg-green-30 generic-icon text-green-90">
    <t-icon key="paid" size="medium" color="inherit"></t-icon>
  </div>

  <div *ngSwitchCase="'message'" class="bg-yellow-30 generic-icon text-amber-70">
    <t-icon key="mail" size="medium" color="inherit"></t-icon>
  </div>

  <div *ngSwitchCase="'pa'" class="bg-green-30 generic-icon text-green-90">
    <t-icon key="bookmark-added" size="medium" color="inherit"></t-icon>
  </div>
</ng-container>
