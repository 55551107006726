import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UserRoles } from '@troyai/auth/data-access';
import {
  ButtonComponent,
  IconsModule,
  InputTextComponent,
  PromptModalMetaData,
} from '@troyai/ui-kit';

export interface ScheduleRemoveReasonPromptData extends PromptModalMetaData {
  asRole: UserRoles;
  actionId: number;
  timeslotId: number;
}

@Component({
  selector: 't-schedule-remove-reason-prompt',
  standalone: true,
  imports: [CommonModule, InputTextComponent, FormsModule, IconsModule, ButtonComponent],
  templateUrl: './schedule-remove-reason-prompt.component.html',
})
export class ScheduleRemoveReasonPromptComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: ScheduleRemoveReasonPromptData
  ) {}

  reasonInputText = '';

  confirmAction() {
    if (this.data.onPromptConfirm) {
      this.data.onPromptConfirm(this.reasonInputText);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
