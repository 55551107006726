import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserPharmacy, isPharmacyOnboarded } from '@troyai/auth/data-access';
import { FeatureFlagsService } from '@troyai/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class PharmacyContextService {
  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagsService
  ) {}

  handlePharmacyChangeRedirects(pharmacy: UserPharmacy) {
    const currentUrl = this.router.routerState.snapshot.url;

    if (
      !isPharmacyOnboarded(pharmacy, {
        'baa-onboarding-required':
          this.featureFlagService.isFeatureEnabled('baa-onboarding-required'),
        'financial-onboarding-required': this.featureFlagService.isFeatureEnabled(
          'financial-onboarding-required'
        ),
      })
    ) {
      if (pharmacy.user_is_owner) {
        this.router.navigate(['/onboarding', pharmacy.global_id]);
      } else {
        this.router.navigate(['/onboarding/forbidden']);
      }
    } else {
      if (currentUrl.startsWith('/onboarding')) {
        this.router.navigate(['/']);
      }
    }
  }
}
