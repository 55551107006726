import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, filter, map, mergeMap, of, take, withLatestFrom } from 'rxjs';

import {
  UserDetails,
  UserRoles,
  UserState,
  isMOCCompliantForCurrentYear,
  isPharmacyOnboarded,
} from '@troyai/auth/data-access';
import { FeatureFlagsService } from '@troyai/feature-flags';
import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';

export function OnboardingGuard(): CanActivateFn {
  return () => {
    const store: Store = inject(Store);
    const router = inject(Router);
    const featureFlagService = inject(FeatureFlagsService);

    const isMOCTrainingEnabled = featureFlagService.isFeatureEnabled('moc-training-requirement');
    const isFinancialOnboardingEnabled = featureFlagService.isFeatureEnabled(
      'financial-onboarding-required'
    );

    const userDetails$ = store.select(UserState.userDetails);
    const pharmaciesList$ = store.select(PharmacyContextState.pharmaciesList);
    const selectedPharmacyId$ = store.select(PharmacyContextState.selectedPharmacyId);

    const pharmacyBoundUserOnboarding$ = combineLatest([userDetails$, selectedPharmacyId$]).pipe(
      filter(
        ([userDetails, selectedPharmacyId]) => !!userDetails && selectedPharmacyId !== undefined
      ),
      map(([userDetails, selectedPharmacyId]) => {
        return {
          userDetails: userDetails as UserDetails,
          selectedPharmacyId,
        };
      }),
      take(1),
      withLatestFrom(pharmaciesList$, ({ userDetails, selectedPharmacyId }, pharmaciesList) => {
        return {
          userDetails,
          pharmaciesList,
          selectedPharmacyId,
        };
      }),
      map(({ userDetails, selectedPharmacyId, pharmaciesList }) => {
        const selectedPharmacy =
          pharmaciesList.find((pharmacy) => pharmacy.global_id === selectedPharmacyId) ||
          pharmaciesList[0];
        if (!selectedPharmacy) return router.parseUrl('/404');

        // Only check for MOCTraining if the feature is enabled
        const requiresMOCTraining = isMOCTrainingEnabled
          ? !isMOCCompliantForCurrentYear(userDetails)
          : false;

        const requiresPharmacyOnboarding = !isPharmacyOnboarded(selectedPharmacy, {
          'baa-onboarding-required': featureFlagService.isFeatureEnabled('baa-onboarding-required'),
          'financial-onboarding-required': isFinancialOnboardingEnabled,
        });

        if (selectedPharmacy.user_is_owner && (requiresPharmacyOnboarding || requiresMOCTraining)) {
          return router.parseUrl(`/onboarding/${selectedPharmacy.global_id}`);
        }

        if (!selectedPharmacy.user_is_owner) {
          if (requiresPharmacyOnboarding) {
            return router.parseUrl(`/onboarding/${selectedPharmacy.global_id}`);
          }

          if (requiresMOCTraining) {
            return router.parseUrl(`/onboarding/compliance`);
          }
        }

        return true;
      })
    );

    return userDetails$.pipe(
      filter((userDetails) => !!userDetails),
      map((userDetails) => {
        return userDetails as UserDetails;
      }),
      mergeMap((userDetails) => {
        if (userDetails.roles.includes(UserRoles.PharmacyUser)) {
          return pharmacyBoundUserOnboarding$;
        }

        return of(true);
      })
    );
  };
}
