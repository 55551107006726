import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

import { ButtonComponent } from '../button/button.component';
import { DatePickerInputComponent } from '../input/date-picker-input/date-picker-input.component';
import { InputTextComponent } from '../input/input-text.component';
import { SelectComponent } from '../input/select/select.component';
import { RepeatableFieldControlType } from './repeatable-fields.model';

@Component({
  selector: 't-repeatable-fields',
  templateUrl: './repeatable-fields.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepeatableFieldsComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextComponent,
    DatePickerInputComponent,
    ButtonComponent,
    SelectComponent,
  ],
})
export class RepeatableFieldsComponent implements ControlValueAccessor, OnInit {
  @Input() config: RepeatableFieldControlType[] = [];
  @Input() addRowButtonText? = 'Add row';
  @Input() label = '';

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      rows: this.fb.array([]),
    });
  }

  ngOnInit() {
    // Initialize with one row if config is provided
    if (this.config && this.config.length) {
      this.addRow();
    }
  }

  get rows(): FormArray {
    return this.form.get('rows') as FormArray;
  }

  addRow(defaultValue?: any) {
    const rowGroup = this.fb.group({});

    // Dynamically create form controls based on the config
    this.config.forEach((control) => {
      const controlValue = defaultValue
        ? defaultValue[control.label]
        : control.defaultValue || null;
      rowGroup.addControl(control.label, this.fb.control(controlValue));
    });

    this.rows.push(rowGroup);
  }

  removeRow(index: number) {
    this.rows.removeAt(index);
  }

  writeValue(value: any): void {
    if (value) {
      this.rows.clear();

      value.forEach((item: any) => {
        const rowGroup = this.fb.group({});
        this.config.forEach((control) => {
          // Set default value if no value is provided in the input data
          const existingValue = item[control.label] || control.defaultValue || '';
          rowGroup.addControl(control.label, this.fb.control(existingValue));
        });
        this.rows.push(rowGroup);
      });
    }
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
