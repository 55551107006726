<div class="relative flex flex-col items-center justify-center text-center">
  <div class="bg-grey-20 text-grey-50 mb-4 inline-block rounded-full p-3">
    <t-icon key="info-circle" size="tiny" color="inherit"></t-icon>
  </div>
  <strong class="text-grey-100 mb-2 block text-base font-semibold">
    {{ titleText }}
  </strong>

  <ng-content></ng-content>
</div>
