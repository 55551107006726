import { PatchAssociateMemberFilePayload } from '@troyai/patients/data-access';
import {
  CaseNoteTemplate,
  CreateMemberCaseNotePayload,
  MemberCaseNote,
  PatchMemberCaseNotePayload,
  PublishMemberCaseNotePayload,
} from '../models';

export class GetAllMemberCaseNotes {
  static readonly type = '[Patient][Case notes] Get all member case notes';
  constructor(public payload: { memberGlobalId: number; silently?: boolean }) {}
}

export class GetAllCaseNotesTemplates {
  static readonly type = '[Case notes] Get all case notes templates';
}

export class GetSingleCaseNote {
  static readonly type = '[Case notes] Get single case note';
  constructor(public payload: { memberGlobalId: number; caseNoteId: number }) {}
}

export class CreateMemberCaseNote {
  static readonly type = '[Case notes] Create case note for member';
  constructor(public payload: { memberGlobalId: number; data: CreateMemberCaseNotePayload }) {}
}

export class UpdateMemberCaseNote {
  static readonly type = '[Case notes] Update case note for member';
  constructor(
    public payload: { memberGlobalId: number; caseNoteId: number; data: PatchMemberCaseNotePayload }
  ) {}
}

export class SelectCaseNoteTemplate {
  static readonly type = '[Case notes] Select case note template';
  constructor(public payload: { template: CaseNoteTemplate | null }) {}
}

export class PublishMemberCaseNote {
  static readonly type = '[Case notes] Publish case note for member';
  constructor(
    public payload: {
      memberGlobalId: number;
      data: PublishMemberCaseNotePayload;
    }
  ) {}
}

export class PrependMemberCaseNoteToListing {
  static readonly type = '[Case notes] Prepend member case note to listing';
  constructor(public payload: MemberCaseNote) {}
}

export class AssociateMemberFilesToCaseNote {
  static readonly type = '[Case notes] Associate member file to case note';
  constructor(
    public payload: {
      memberGlobalId: number;
      fileIds: number[];
      data: PatchAssociateMemberFilePayload;
    }
  ) {}
}

export class DeleteMemberCaseNote {
  static readonly type = '[Case notes] Delete case note for member';
  constructor(public payload: { memberGlobalId: number; caseNoteId: number }) {}
}

export class SetCaseNoteForEditing {
  static readonly type = '[Case notes] Set case note for editing';
  constructor(public payload: MemberCaseNote) {}
}

export class ResetWorkingDraftCaseNote {
  static readonly type = '[Case notes] Reset working draft case note';
}
