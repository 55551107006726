<span
  class="text-grey-70 flex w-full cursor-pointer items-center justify-center gap-x-1.5 whitespace-nowrap rounded-lg border p-2 text-center text-xs font-semibold uppercase tracking-wider transition-colors"
  [ngClass]="{
    'border-grey-25 hover:bg-grey-30 hover:border-grey-30 bg-transparent': variant === 'primary',
    'border-grey-20 hover:bg-grey-20 hover:border-grey-20 bg-transparent': variant === 'secondary',
    '!bg-grey-25 !border-grey-25': isActive && variant === 'primary',
    '!bg-grey-15 !border-grey-15': isActive && variant === 'secondary'
  }">
  @if (icon) {
    <t-icon [key]="icon" color="inherit" size="tiny" />
  }
  <ng-content />
</span>
