<div class="relative">
  <span
    class="hover:bg-grey-20 flex cursor-pointer items-center justify-center rounded-full bg-transparent p-0.5 transition-colors"
    [ngClass]="{ '!bg-grey-20 ': content.isOpen() }"
    (click)="content.toggle()">
    <t-icon key="more-vert" size="tiny" [tPopoverAnchor]="content"></t-icon>
  </span>

  <t-popover verticalAlign="start" horizontalAlign="after" #content [hasBackdrop]="true">
    <div (click)="content.close()">
      <ng-content></ng-content>
    </div>
  </t-popover>
</div>
