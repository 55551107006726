import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 't-circular-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './circular-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircularProgressComponent implements OnChanges, AfterViewInit {
  @Input() progress = 0;
  @Input() size = 90;
  @Input() matchTextColor = false;
  @Input() label = '';
  @Input() trackWidth = 10;
  @Input() indicatorWidth = 10;

  center = this.size / 2;
  radius = this.center - this.trackWidth + this.trackWidth / 2;
  dashArray = 0;
  dashOffset = 0;

  calculateValues() {
    this.center = this.size / 2;
    this.radius = this.center - this.trackWidth + this.trackWidth / 2;
    this.dashArray = 2 * Math.PI * this.radius;
    this.dashOffset = this.dashArray * ((100 - this.progress * 100) / 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['progress'].currentValue !== changes['progress'].previousValue) {
      this.calculateValues();
    }
  }

  ngAfterViewInit(): void {
    this.calculateValues();
  }
}
