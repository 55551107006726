import { Pipe, PipeTransform } from '@angular/core';
import { RelatedActions } from '@troyai/actions/data-access';

@Pipe({
  name: 'relatedActions',
  standalone: true,
})
export class RelatedActionsPipe implements PipeTransform {
  transform(value: RelatedActions[]): RelatedActions[] {
    if (value.length === 0 || !value) {
      return [];
    }

    const sortedArray = [...value].sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    // Filter to unique reference_id, keeping the first occurrence (which is the latest due to sorting)
    const uniqueLatestArray = sortedArray.reduce((acc: RelatedActions[], currentItem) => {
      const isExisting = acc.find((item) => item.reference_id === currentItem.reference_id);
      if (!isExisting) {
        acc.push(currentItem);
      }
      return acc;
    }, []);

    return uniqueLatestArray;
  }
}
