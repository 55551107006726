import { Action } from '@troyai/actions/data-access';
import { FilterConfig, SortConfig } from '@troyai/data-source-listing';
import { add, endOfMonth } from 'date-fns';

export const actionsListingFilters: FilterConfig<Action>[] = [
  {
    id: 'status',
    keyName: 'status',
    label: 'Status',
    iconKey: 'detector-status',
    valuesSource: 'unique',
    valuesBehavior: 'direct',
  },
  {
    id: 'type',
    keyName: 'title',
    label: 'Type',
    iconKey: 'all-out',
    valuesSource: 'unique',
    valuesBehavior: 'direct',
    groupingPrefix: ['Patient Assistance Application', 'HTN Management'],
  },
  {
    id: 'expiration-date',
    keyName: 'due_date',
    label: 'Expiration date',
    iconKey: 'alarm-smart-wake',
    valuesSource: 'custom',
    valuesBehavior: 'time-interval',
    customValues: [
      {
        label: 'In 3 days',
        config: {
          startDate: Date.now(),
          endDate: add(Date.now(), {
            days: 3,
          }).valueOf(),
        },
      },
      {
        label: 'In 7 days',
        config: {
          startDate: Date.now(),
          endDate: add(Date.now(), {
            days: 7,
          }).valueOf(),
        },
      },
      {
        label: 'This month',
        config: {
          startDate: Date.now(),
          endDate: endOfMonth(Date.now()).valueOf(),
        },
      },
    ],
  },
];

export const actionsSortingOptions: SortConfig<Action>[] = [
  {
    label: 'Expiration date',
    widthClass: 'w-4/12',
    value: {
      keyName: 'due_date',
      order: 'asc',
    },
  },
  {
    label: 'Patient',
    widthClass: 'w-5/12',
    value: {
      keyName: 'member_name',
      order: 'asc',
    },
  },
  {
    label: 'Status',
    widthClass: 'w-1/12 flex justify-end',
    value: {
      keyName: 'status',
      order: 'asc',
    },
  },
  {
    label: 'Value',
    widthClass: 'w-1/12 flex justify-end',
    value: {
      keyName: 'fee',
      order: 'desc',
    },
  },
];
