import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type Variants = 'pill' | 'rounded';
type Sizes = 'large' | 'medium' | 'small' | 'tiny';
type Colors = 'neutral' | 'success' | 'warning' | 'error';
type PaddingSizes = 'small' | 'regular';

@Component({
  selector: 't-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
})
export class LabelComponent {
  @Input() variant: Variants = 'pill';
  @Input() size: Sizes = 'large';
  @Input() color: Colors = 'neutral';
  @Input() highContrast = false;
  @Input() paddingSize: PaddingSizes = 'regular';
}
