<div
  *ngIf="encounter"
  class="encounter-card relative"
  [ngClass]="{
    compact: variant === 'compact',
    default: variant === 'default',
    'bg-amber-20': encounter.facility_type === 'SNF' || encounter.facility_type === 'HHA',
    'bg-grey-15': encounter.facility_type === 'HOS' || encounter.facility_type === 'HOSPICE'
  }">
  <div
    class="text-grey-70 facility-type"
    [ngClass]="{
      '!text-amber-70': encounter.facility_type === 'SNF' || encounter.facility_type === 'HHA',
      '!text-red-90': encounter.facility_type === 'HOS' || encounter.facility_type === 'HOSPICE'
    }">
    <t-icon [key]="iconsMap[encounter.facility_type]" size="tiny" color="inherit"></t-icon>
    <span>{{ encounter.facility_type }}</span>
  </div>

  <div class="encounter-contents">
    <span class="facility-name text-grey-70 block font-semibold">
      {{ encounter.facility }}
    </span>
    <span
      *ngIf="encounter.diagnosis && variant === 'default'"
      class="text-grey-50 text-xs"
      [ngClass]="{
        '!text-amber-70': encounter.facility_type === 'HHA' || encounter.facility_type === 'SNF'
      }">
      Info: {{ encounter.diagnosis }}
    </span>

    <span *ngIf="variant === 'compact'" class="text-grey-70 text-xs">
      {{ encounter.encounter_time | date }}
    </span>
  </div>

  <!-- TODO: Uncomment when additional info is available -->
  <!-- <span class="text-grey-70 absolute right-2 top-2 opacity-30">
    <t-icon key="info-circle" size="tiniest" color="inherit"></t-icon>
  </span> -->
</div>
