import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconsModule, LabelComponent, PopoverModule } from '@troyai/ui-kit';
import { PharmacyListComponent } from '../pharmacy-list/pharmacy-list.component';

@Component({
  selector: 't-inline-pharmacy-selector',
  standalone: true,
  imports: [CommonModule, LabelComponent, IconsModule, PopoverModule, PharmacyListComponent],
  templateUrl: './inline-pharmacy-selector.component.html',
})
export class InlinePharmacySelectorComponent {
  @Input() content = '';
  @Input() label = 'Select pharmacy';
  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
}
