import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  ActionsState,
  GetAllOutreachAttempts,
  GetOutreachAttemptsOutcomes,
} from '@troyai/actions/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import {
  AccordionComponent,
  AccordionItemComponent,
  ButtonComponent,
  IconsModule,
  ModalModule,
  ModalService,
} from '@troyai/ui-kit';
import { take, tap, withLatestFrom } from 'rxjs';
import { SaveOutreachAttemptModalComponent } from '../save-outreach-attempt-modal/save-outreach-attempt-modal.component';

@Component({
  selector: 't-outreach-attempts-list',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    AccordionComponent,
    AccordionItemComponent,
    ModalModule,
    ButtonComponent,
  ],
  templateUrl: './outreach-attempts-list.component.html',
})
export class OutreachAttemptsListComponent implements OnInit {
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fullNamePipe: FullNamePipe
  ) {}

  outreachAttemptsItems$ = this.store.select(ActionsState.outreachAttempts);
  outreachAttemptsOutcomes$ = this.store.select(ActionsState.outreachAttemptsOutcomes).pipe(
    tap((outcomes) => {
      this.outcomesCodesToDescriptionMapping = outcomes.reduce(
        (acc, outcome) => ({
          ...acc,
          [outcome.code]: outcome.description,
        }),
        {}
      );
    })
  );
  patient$ = this.store.select(PatientsState.selectedPatient);

  destroyRef = inject(DestroyRef);
  outcomesCodesToDescriptionMapping: Record<string, string> = {};

  openNewOutreachAttemptModal() {
    this.patient$
      .pipe(
        withLatestFrom(this.route.paramMap),
        tap(([patient, params]) => {
          const actionGlobalId = Number(params.get('actionGlobalId'));

          this.modalService.openDialog(
            SaveOutreachAttemptModalComponent,
            {
              actionGlobalId,
            },
            {
              allowOverflow: true,
              title: `Attempts details for ${this.fullNamePipe.transform(patient)}`,
            }
          );
        }),
        take(1)
      )
      .subscribe();
  }

  ngOnInit() {
    this.outreachAttemptsOutcomes$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      const globalId = Number(params.get('actionGlobalId'));
      this.store.dispatch([
        new GetAllOutreachAttempts({ actionGlobalId: globalId }),
        new GetOutreachAttemptsOutcomes({
          actionGlobalId: globalId,
        }),
      ]);
    });
  }
}
