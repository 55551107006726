import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent } from '@troyai/ui-kit';
import { TenantService } from 'multi-tenancy';

@Component({
  selector: 't-greeting-card',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './greeting-card.component.html',
  styleUrls: ['./greeting-card.component.css'],
})
export class GreetingCardComponent implements OnInit {
  constructor(private tenantService: TenantService) {}

  @Input() title = '';
  tenantId = '';

  ngOnInit() {
    // Tenant ID is used directly as in the login/logout view the user state is not yet populated
    this.tenantId = this.tenantService.getCurrentTenantRef();
  }
}
