<t-card
  paddingSize="none"
  [isInteractive]="false"
  [shadowSize]="shadowSize"
  class="overflow-hidden">
  <div>
    <div (click)="toggle()" class="flex cursor-pointer items-center justify-between px-8 py-6">
      <div>
        <ng-content select="[header]" />
      </div>

      <span class="text-black">
        <t-icon
          [key]="isOpened ? 'keyboard-arrow-left' : 'keyboard-arrow-down'"
          size="tiny"
          color="inherit" />
      </span>
    </div>

    <div [@expandAnimation]="isOpened ? 'open' : 'closed'" class="overflow-hidden transition-all">
      <ng-content />
    </div>
  </div>
</t-card>
