<t-content-wrapper-layout>
  <t-section headerTitle="NP Appointments">
    <div sectionControls class="flex justify-end space-x-2">
      <t-dropdown-button
        [tPopoverAnchor]="additionalActions"
        (click)="additionalActions.toggle()"></t-dropdown-button>

      <t-popover verticalAlign="below" horizontalAlign="end" #additionalActions hasBackdrop>
        <t-dropdown-menu>
          <t-dropdown-menu-item
            (click)="additionalActions.close(); openPrintAppointmentsModal()"
            iconKey="event">
            Print
          </t-dropdown-menu-item>
        </t-dropdown-menu>
      </t-popover>
    </div>

    <div class="border-grey-20 border-t px-2 py-6">
      <t-week-navigator
        [timePeriod]="appointmentsPeriod$ | async"
        (weekChange)="onWeekChange($event)" />
    </div>

    <t-skeleton-loader
      [rows]="6"
      color="dark"
      *ngIf="appointmentsListItemsLoading$ | async; else appointmentsList">
    </t-skeleton-loader>

    <ng-template #appointmentsList>
      <ng-container *ngIf="groupedAppointmentsListItems$ | async as appointments">
        <t-accordion *ngIf="appointments.length; else appointmentsEmptyState">
          <t-accordion-item
            *ngFor="let appointment of appointments"
            [idx]="appointment.idx"
            [expanded]="appointment.isOpen"
            (toggleChanged)="handleToggleChange($event)">
            <div header class="text-grey-100 flex items-center gap-x-2 text-base font-semibold">
              {{ appointment.day | date: 'mediumDate' }}
            </div>
            <div class="divide-grey-20 border-grey-20 mt-2 divide-y border-t">
              <div
                *ngFor="let timeslot of appointment.timeslots"
                class="px-2 py-3 lg:flex lg:items-center">
                <!-- Time intervals -->
                <div class="text-grey-70 mb-4 block text-sm font-semibold lg:mb-0 lg:w-2/12">
                  {{ timeslot.start_time | timeInterval: timeslot.end_time }}
                </div>
                <!-- /Time intervals -->

                <!-- Scheduled member area -->
                <div class="lg:w-9/12">
                  <div class="text-grey-100 flex text-base lg:text-sm">
                    <span class="flex w-1/4 items-center gap-x-2 font-semibold">
                      {{ timeslot.member_name | titlecase }}
                      <t-pharmacy-indicator-tooltip
                        *ngIf="selectedPharmacyId === null"
                        class="hover:text-grey-70"
                        [pharmacyName]="timeslot.pharmacy_name" />
                    </span>

                    <div
                      *ngIf="timeslot.scheduled_telehealth; else physicalAppointment"
                      class="flex w-2/4 items-center gap-2 truncate text-xs">
                      <t-label size="tiny" [highContrast]="true" color="success">
                        Telehealth
                      </t-label>
                      <a
                        (click)="$event.stopPropagation()"
                        class="text-grey-70 block truncate"
                        [href]="timeslot.scheduled_telehealth_link"
                        target="_blank"
                        rel="noopener noreferrer">
                        {{ timeslot.scheduled_telehealth_link }}
                      </a>
                    </div>

                    <ng-template #physicalAppointment>
                      <div class="text-grey-50 w-2/4 truncate text-sm">
                        {{ timeslot.address_city | titlecase }} {{ timeslot.address_zip }}
                        {{ timeslot.address_city && timeslot.address_county ? ',' : '' }}
                        {{ timeslot.address_county | titlecase }}
                      </div>
                    </ng-template>

                    <div class="text-grey-50 w-1/4 text-sm" *ngIf="timeslot.primary_phone_number">
                      {{ timeslot.primary_phone_number | phone }}
                    </div>
                  </div>
                </div>
                <!-- Scheduled member area -->
              </div>
            </div>
          </t-accordion-item>
        </t-accordion>

        <ng-template #appointmentsEmptyState>
          <div class="bg-grey-60 flex min-h-[50vh] items-center justify-center bg-opacity-5">
            <t-empty-state-card
              content="There are no appointments found for the selected week."
              [hasIcon]="true" />
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </t-section>
</t-content-wrapper-layout>
