/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { CardComponent } from '../card/card.component';

type Variants = 'cards' | 'table';
type VerticalSpacingSize = 'small' | 'medium' | 'large';

@Component({
  selector: 't-data-listing',
  standalone: true,
  imports: [CommonModule, CardComponent],
  templateUrl: './data-listing.component.html',
})
export class DataListingComponent {
  @Input() items: any[] = [];
  @Input() variant: Variants = 'cards';
  @Input() verticalSpacingSize: VerticalSpacingSize = 'medium';

  @ContentChild('rowTemplate')
  template!: TemplateRef<any>;
}
