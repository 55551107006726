import { CarePlanCreationSection, CarePlanTemplateHraMeta } from '../models';

interface CarePlanSectionsValues {
  htmlContent: string;
  nextReviewDate: string;
  hraMetaIds: number[];
}

export const getCarePlanSectionsValues = (
  sections: CarePlanCreationSection[]
): CarePlanSectionsValues => {
  const content = sections.find((section) => section.id === 'carePlanDetails')
    ?.selectedValues as string[];
  const nextReviewDate = sections.find((section) => section.id === 'dateOfNextReview')
    ?.selectedValues as string[];
  const hraMetaIds = sections.reduce((acc, section) => {
    if (['interventions', 'barriers', 'goals'].includes(section.id)) {
      const selectedValues = section.selectedValues as CarePlanTemplateHraMeta[];
      const ids = selectedValues.map((value) => value.id);
      return [...acc, ...ids];
    }
    return acc;
  }, [] as number[]);

  return {
    htmlContent: content[0],
    nextReviewDate: nextReviewDate[0],
    hraMetaIds: hraMetaIds,
  };
};

export const areCarePlanSectionsValid = (sectionValues: CarePlanSectionsValues) => {
  return sectionValues.htmlContent && sectionValues.nextReviewDate;
};
