import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Action } from '@troyai/actions/data-access';
import { QueryObjectToUrlService } from '@troyai/portal/common/navigation';
import { DateDistancePipe, PluralizePipe } from '@troyai/portal/common/pipes';
import { ImageFallbackDirective } from '@troyai/shared/media';
import { moveItemToIndexByKey } from '@troyai/shared/utils';
import { ButtonComponent, CardComponent, LabelComponent, LinkComponent } from '@troyai/ui-kit';
import { ActionsSetCarouselItemComponent } from './actions-set-carousel-item/actions-set-carousel-item.component';

export interface ActionGroup {
  name: string;
  count: number;
  totalValue: number;
  image: string;
  title: string;
  isFeatured: boolean;
}

interface FeaturedActionCriteria {
  minCount: number;
  title: string;
}

@Component({
  selector: 't-actions-set-carousel',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ImageFallbackDirective,
    LabelComponent,
    DateDistancePipe,
    ButtonComponent,
    RouterModule,
    LinkComponent,
    PluralizePipe,
    ActionsSetCarouselItemComponent,
  ],
  templateUrl: './actions-set-carousel.component.html',
  styleUrls: ['./actions-set-carousel.component.css'],
})

/*
  This component displays a carousel of actions groups (an action group is a group of actions with the same title)
  It's split into 2 sections:
  - Featured actions: these are actions groups that are displayed in the carousel as a single, sticky entry, not part of the carousel
  - Regular actions: these are actions that are displayed in the carousel as a group of X items per slide
*/
export class ActionsSetCarouselComponent implements OnInit {
  constructor(public queryObjectToUrl: QueryObjectToUrlService) {}
  @Input() actions: Action[] = [];
  @ViewChild('container') container!: ElementRef<HTMLDivElement>;

  // Criteria for displaying actions in the featured section
  private readonly featuredActionsCriteria: FeaturedActionCriteria[] = [
    {
      minCount: 5,
      title: 'Schedule NP Health Assessment',
    },
    {
      minCount: 1,
      title: 'Complete Post-Discharge Follow-Up',
    },
    {
      minCount: 1,
      title: 'Real-time Level of Care Triage',
    },
  ];

  regularActionGroups: ActionGroup[] = [];
  regularActionsCount = 0;
  actionGroupsBuckets: ActionGroup[][] = [];
  featuredActionGroups: ActionGroup[] = [];
  featuredActionsCount = 0;

  updateViewport(direction: 'left' | 'right') {
    const distance = this.container.nativeElement.clientWidth;
    const currentScrollPosition = this.container.nativeElement.scrollLeft;
    const newScrollPosition =
      direction === 'left' ? currentScrollPosition - distance : currentScrollPosition + distance;
    this.container.nativeElement.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  }

  ngOnInit() {
    // Group actions by title prefix in order to display them in carousel.
    // This is done to avoid displaying the multiple actions with the same title in the carousel, which would be confusing and repetitive for the user.
    const groupingTitlePrefixes = ['Patient Assistance Application', 'HTN Management'];
    const groupedActionsByTitle = this.actions.reduce(
      (acc, curr) => {
        const hasGroupingKey = groupingTitlePrefixes.find((key) => curr.title.startsWith(key));
        const currentAction = hasGroupingKey
          ? {
              ...curr,
              title: hasGroupingKey,
            }
          : curr;

        if (acc[currentAction.title]) {
          acc[currentAction.title].push(currentAction);
        } else {
          acc[currentAction.title] = [currentAction];
        }
        return acc;
      },
      {} as Record<string, Action[]>
    );

    // Build the list of action groups, with the necessary data for displaying them
    const actionsGroupsList = Object.keys(groupedActionsByTitle).map((key) => {
      const itemsByKey = groupedActionsByTitle[key];

      const isFeatured = this.featuredActionsCriteria.find(
        (criteria) => criteria.title === key && itemsByKey.length >= criteria.minCount
      )
        ? true
        : false;

      return {
        name: key,
        count: itemsByKey.length,
        totalValue: itemsByKey.reduce((acc, curr) => {
          return acc + parseInt(curr.fee);
        }, 0),
        image: itemsByKey[0].fee_schedule_image,
        title: itemsByKey[0].title,
        isFeatured,
      } as ActionGroup;
    });

    // Handle featured actions
    this.featuredActionGroups = actionsGroupsList.filter((action) => action.isFeatured);
    this.featuredActionsCount = this.featuredActionGroups.reduce(
      (acc, curr) => acc + curr.count,
      0
    );

    // Handle the rest of the actions (non-featured)
    const regularActionsGroups = actionsGroupsList.filter((action) => !action.isFeatured);
    regularActionsGroups.sort((a, b) => b.totalValue - a.totalValue);

    // Put the Complete Post-Discharge Follow-Up group at the beginning
    const orderedGroupedActionsByTitle = moveItemToIndexByKey(
      regularActionsGroups,
      'title',
      'Complete Post-Discharge Follow-Up',
      0
    );

    this.regularActionGroups = orderedGroupedActionsByTitle;
    this.regularActionsCount = this.regularActionGroups.reduce((acc, curr) => acc + curr.count, 0);

    // Create buckets of action groups, each bucket will be displayed as a slide in the carousel
    const bucketSize = Math.max(4 - this.featuredActionGroups.length, 3);
    this.actionGroupsBuckets = orderedGroupedActionsByTitle.reduce((acc, curr, index) => {
      if (index % bucketSize === 0) {
        acc.push([curr]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, [] as ActionGroup[][]);
  }
}
