import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customMediumDate',
  standalone: true,
})
export class CustomMediumDatePipe implements PipeTransform {
  transform(inputDate?: number | string | Date, timezone?: string): string {
    if (!inputDate) return '';
    const date = new Date(inputDate);
    const formattedDate = new DatePipe('en-US').transform(date, 'MMM d, y, h:mm a', timezone);
    return formattedDate || '';
  }
}
