import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { LoadingState, getLoadingStatus } from '@troyai/shared/state';
import { secondsToMilliseconds } from 'date-fns';
import { catchError, tap, throwError } from 'rxjs';
import { ActionsApiService } from '../actions-api.service';
import { ActionMessage } from '../models/message.interface';
import { ActionsStateModel } from './actions-state.model';
import {
  ClearSelectedAction,
  CreateOutreachAttempt,
  EmptyActionsList,
  EmptySelectedAction,
  GetActionFormSettings,
  GetActionMessages,
  GetActionsList,
  GetAllOutreachAttempts,
  GetOutreachAttemptsOutcomes,
  GetPlanReviewMembersLeads,
  GetSingleAction,
  SendActionMessage,
  UpdateSingleActionHRAState,
  UpdateSingleActionSchedule,
} from './actions.actions';

const stateDefaults = {
  actionsList: {
    result: [],
    loadingStatus: LoadingState.INITIAL,
  },
  itemsLoading: true,
  selectedAction: undefined,
  selectedActionError: undefined,
  selectedActionLoading: true,
  selectedActionFormSettings: {
    result: null,
    loadingStatus: LoadingState.INITIAL,
  },
  messages: [],
  messagesLoading: false,
  planReviewMembersLeads: [],
  planReviewMembersLeadsLoading: false,
  outreachAttempts: {
    result: [],
    loadingStatus: LoadingState.INITIAL,
  },
  outreachAttemptsOutcomes: {
    result: [],
    loadingStatus: LoadingState.INITIAL,
  },
};

@State<ActionsStateModel>({
  name: 'actionsState',
  defaults: stateDefaults,
})
@Injectable()
export class ActionsState {
  constructor(private actionsApiService: ActionsApiService) {}

  @Selector()
  static actionsListErrorState(state: ActionsStateModel) {
    return state.actionsList.error;
  }

  @Selector()
  static actionsListLoadingStatus(state: ActionsStateModel) {
    return {
      loading: state.actionsList.loadingStatus === LoadingState.LOADING,
      loaded: state.actionsList.loadingStatus === LoadingState.LOADED,
      errored: state.actionsList.loadingStatus === LoadingState.ERRORED,
    };
  }

  @Selector()
  static selectedAction(state: ActionsStateModel) {
    return state.selectedAction;
  }

  @Selector()
  static selectedActionError(state: ActionsStateModel) {
    return state.selectedActionError;
  }

  @Selector()
  static selectedActionLoading(state: ActionsStateModel) {
    return state.selectedActionLoading;
  }

  @Selector()
  static getAllActions(state: ActionsStateModel) {
    return state.actionsList.result;
  }

  @Selector()
  static getMessages(state: ActionsStateModel) {
    return state.messages;
  }

  @Selector()
  static messagesLoading(state: ActionsStateModel) {
    return state.messagesLoading;
  }

  @Selector()
  static planReviewMembersLeads(state: ActionsStateModel) {
    return state.planReviewMembersLeads;
  }

  @Selector()
  static planReviewMembersLeadsLoading(state: ActionsStateModel) {
    return state.planReviewMembersLeadsLoading;
  }

  @Selector()
  static outreachAttempts(state: ActionsStateModel) {
    return state.outreachAttempts.result;
  }

  @Selector()
  static outreachAttemptsLoading(state: ActionsStateModel) {
    return getLoadingStatus(state.outreachAttempts.loadingStatus);
  }

  @Selector()
  static outreachAttemptsOutcomes(state: ActionsStateModel) {
    return state.outreachAttemptsOutcomes.result;
  }

  @Selector()
  static outreachAttemptsOutcomesLoading(state: ActionsStateModel) {
    return getLoadingStatus(state.outreachAttemptsOutcomes.loadingStatus);
  }

  @Selector()
  static selectedActionFormSettings(state: ActionsStateModel) {
    return state.selectedActionFormSettings.result;
  }

  @Selector()
  static selectedActionFormSettingsLoading(state: ActionsStateModel) {
    return getLoadingStatus(state.selectedActionFormSettings.loadingStatus);
  }

  @Action(GetActionsList)
  getActionsList(
    { setState, patchState }: StateContext<ActionsStateModel>,
    action: GetActionsList
  ) {
    patchState({
      actionsList: {
        result: [],
        loadingStatus: LoadingState.LOADING,
      },
    });
    return this.actionsApiService
      .getActions(action.payload, {
        ttl: secondsToMilliseconds(30),
      })
      .pipe(
        tap((allActions) =>
          setState(
            patch<ActionsStateModel>({
              actionsList: {
                result: allActions,
                loadingStatus: LoadingState.LOADED,
              },
            })
          )
        ),
        catchError((err) => {
          setState(
            patch<ActionsStateModel>({
              actionsList: {
                result: [],
                loadingStatus: LoadingState.ERRORED,
                error: {
                  message: err.message,
                },
              },
            })
          );
          return throwError(() => err);
        })
      );
  }

  @Action(EmptyActionsList)
  emptyAllActionsList({ setState }: StateContext<ActionsStateModel>) {
    return setState(
      patch<ActionsStateModel>({
        actionsList: {
          result: [],
          loadingStatus: LoadingState.INITIAL,
        },
      })
    );
  }

  @Action(GetSingleAction)
  getSingleAction({ patchState }: StateContext<ActionsStateModel>, action: GetSingleAction) {
    patchState({
      selectedAction: undefined,
      selectedActionError: undefined,
      selectedActionLoading: true,
    });
    if (action.payload.globalId) {
      return this.actionsApiService.getSingleAction(action.payload.globalId).pipe(
        catchError((err) => {
          patchState({
            selectedActionError: true,
            selectedAction: undefined,
          });
          return throwError(() => err);
        }),
        tap((action) => patchState({ selectedAction: action, selectedActionLoading: false }))
      );
    } else {
      return patchState({ selectedAction: undefined });
    }
  }

  @Action(ClearSelectedAction)
  clearSelectedAction({ patchState }: StateContext<ActionsStateModel>) {
    patchState({ selectedAction: undefined, selectedActionError: undefined });
  }

  @Action(GetActionMessages)
  getActionMessages({ patchState }: StateContext<ActionsStateModel>, action: GetActionMessages) {
    if (action.payload.globalId) {
      patchState({
        messages: undefined,
        messagesLoading: true,
      });
      return this.actionsApiService
        .getActionMessages(action.payload.globalId)
        .pipe(tap((messages) => patchState({ messages, messagesLoading: false })));
    } else {
      return patchState({ messages: undefined, messagesLoading: false });
    }
  }

  @Action(SendActionMessage)
  sendActionMessage(
    { patchState, getState }: StateContext<ActionsStateModel>,
    action: SendActionMessage
  ) {
    const { messages } = getState();
    if (action.payload.globalId) {
      return this.actionsApiService
        .sendActionMessage(action.payload.globalId, action.payload.data)
        .pipe(
          tap(() => {
            const now = new Date();
            const message: ActionMessage = {
              message: action.payload.data.message,
              author: 'You',
              created_at: now.toISOString(),
              read_at: '',
              global_id: 0,
            };
            patchState({ messages: [message, ...messages] });
          })
        );
    } else {
      return patchState({ messagesLoading: false });
    }
  }

  @Action(UpdateSingleActionSchedule)
  updateSingleAction(
    { patchState, getState }: StateContext<ActionsStateModel>,
    action: UpdateSingleActionSchedule
  ) {
    const { selectedAction } = getState();
    if (selectedAction) {
      return patchState({
        selectedAction: {
          ...selectedAction,
          status: 'Scheduled',
          scheduled_at: action.payload.date,
        },
      });
    } else {
      return patchState({ selectedAction: undefined });
    }
  }

  @Action(UpdateSingleActionHRAState)
  updateSingleActionHRAState(
    { patchState, getState }: StateContext<ActionsStateModel>,
    action: UpdateSingleActionHRAState
  ) {
    const { selectedAction } = getState();
    if (selectedAction) {
      return patchState({
        selectedAction: {
          ...selectedAction,
          status: 'In Review',
          approved_for_payment_date: action.payload.date,
        },
      });
    } else {
      return patchState({ selectedAction: undefined });
    }
  }

  @Action(GetPlanReviewMembersLeads)
  getPlanReviewMembersLeads({ patchState }: StateContext<ActionsStateModel>) {
    patchState({
      planReviewMembersLeads: [],
      planReviewMembersLeadsLoading: true,
    });

    return this.actionsApiService.getPlanReviewActionMemberLeads().pipe(
      tap((leads) =>
        patchState({
          planReviewMembersLeads: leads,
          planReviewMembersLeadsLoading: false,
        })
      )
    );
  }

  @Action(GetAllOutreachAttempts)
  getAllOutreachAttempts(
    { patchState }: StateContext<ActionsStateModel>,
    action: GetAllOutreachAttempts
  ) {
    patchState({
      outreachAttempts: {
        result: [],
        loadingStatus: LoadingState.LOADING,
      },
    });
    return this.actionsApiService.getOutreachAttempts(action.payload.actionGlobalId).pipe(
      tap((allOutreachAttempts) =>
        patchState({
          outreachAttempts: {
            result: allOutreachAttempts,
            loadingStatus: LoadingState.LOADED,
          },
        })
      )
    );
  }

  @Action(GetOutreachAttemptsOutcomes)
  getOutreachAttemptsOutcomes(
    { getState, patchState }: StateContext<ActionsStateModel>,
    action: GetOutreachAttemptsOutcomes
  ) {
    const outcomes = getState().outreachAttemptsOutcomes;

    if (outcomes.result.length > 0) {
      return;
    }

    patchState({
      outreachAttemptsOutcomes: {
        result: [],
        loadingStatus: LoadingState.LOADING,
      },
    });
    return this.actionsApiService.getOutreachAttemptsOutcomes(action.payload.actionGlobalId).pipe(
      tap((allOutreachAttemptsOutcomes) =>
        patchState({
          outreachAttemptsOutcomes: {
            result: allOutreachAttemptsOutcomes,
            loadingStatus: LoadingState.LOADED,
          },
        })
      )
    );
  }

  @Action(CreateOutreachAttempt)
  createOutreachAttempt(
    { dispatch }: StateContext<ActionsStateModel>,
    action: CreateOutreachAttempt
  ) {
    if (!action.payload.actionGlobalId) {
      return;
    }

    return this.actionsApiService
      .createOutreachAttempt(action.payload.actionGlobalId, action.payload.data)
      .pipe(
        tap(() => {
          dispatch(new GetAllOutreachAttempts({ actionGlobalId: action.payload.actionGlobalId }));
        })
      );
  }

  @Action(EmptySelectedAction)
  emptySelectedAction({ patchState }: StateContext<ActionsStateModel>) {
    return patchState({
      selectedAction: undefined,
      selectedActionLoading: false,
    });
  }

  @Action(GetActionFormSettings)
  getActionFormSettings(
    { patchState }: StateContext<ActionsStateModel>,
    action: GetActionFormSettings
  ) {
    patchState({
      selectedActionFormSettings: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.actionsApiService.getActionForms(action.payload.globalId).pipe(
      tap((formSettings) => {
        patchState({
          selectedActionFormSettings: {
            result: formSettings,
            loadingStatus: LoadingState.LOADED,
          },
        });
      })
    );
  }
}
