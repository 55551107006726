<div
  class="relative w-[846px] print:visible print:block print:h-auto print:w-auto print:overflow-visible">
  <div class="bg-grey-20 flex items-center justify-between rounded p-5 print:hidden">
    <t-button (click)="triggerPrint()">PRINT</t-button>
    <div class="space-y-1 text-right">
      <span class="text-grey-70 block text-xs">Generated</span>
      <strong class="text-grey-100 block text-sm font-semibold">Today, {{ getTodayDate() }}</strong>
    </div>
  </div>
  <div
    *ngIf="careActionsListItems$ | async as patients"
    class="modal shadow-small mt-5 max-h-[300px] overflow-y-scroll bg-white p-8 print:visible print:h-auto print:overflow-visible">
    <t-heading level="h3" value="All patients"></t-heading>
    <span class="text-grey-100 mt-2.5 text-base">Document Generated: {{ getTodayDate() }} </span>
    <div class="divide-grey-100 border-grey-100 mt-6 divide-y border-t">
      <div *ngFor="let patient of patients" class="text-grey-100 flex py-6 text-base">
        <div class="w-1/3">
          <span class="block font-semibold">
            {{ patient | fullName }}
          </span>
          <span>DOB: {{ patient.dob | dobDate }}</span>

          <span class="text-grey-50 block text-xs">
            Enrolled: {{ patient.enrollment_date | date }}
          </span>
          <span class="text-grey-50 block text-xs">Plan {{ patient.plan_type }}</span>
        </div>

        <div class="w-1/3 text-xs">
          <ng-container *ngIf="patient.pharmacy_name">
            <span class="block font-semibold">Pharmacy name:</span>
            <span class="block">{{ patient.pharmacy_name }}</span>
          </ng-container>

          <ng-container *ngIf="patient.pharmacy_phone">
            <span class="mt-2 block font-semibold">Pharmacy phone:</span>
            <span class="block">{{ patient.pharmacy_phone | phone }}</span>
          </ng-container>
        </div>

        <div class="w-1/3 text-right text-xs">
          <ng-container *ngIf="patient.member_phone.length > 0">
            <span class="block font-semibold">Patient phone:</span>
            <ul class="divide-grey-15 divide-y">
              <li *ngFor="let contact of patient.member_phone" class="text-grey-70 p-1 text-xs">
                <span class="text-grey-70 text-xs">{{ contact.phone_number | phone }}</span>
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="patient.member_address.length > 0">
            <span class="mt-2 block font-semibold">Patient address:</span>
            <ul class="divide-grey-15 divide-y">
              <li *ngFor="let address of patient.member_address" class="text-grey-70 p-1 text-xs">
                <span class="text-grey-70 text-xs">
                  {{ address.address_line_1 }} {{ address.city }} {{ address.state }}
                  {{ address.county }} {{ address.zip_code }}
                </span>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
