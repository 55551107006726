import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, filter, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  public previousRoutePath = new BehaviorSubject<string | undefined>(undefined);

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((e) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event) => {
        this.previousRoutePath.next((event[0] as RoutesRecognized).urlAfterRedirects);
      });
  }
}
