import { FileMetadata } from '@troyai/patients/data-access';
import { CarePlanTemplateHraMeta } from './care-plan-template.interface';

export enum CarePlanStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export interface CarePlanAssociatedCaseNote {
  care_plan_id: number;
  case_note_id: number;
  type: string;
}

export interface MemberCarePlan {
  id: number;
  member_global_id: number;
  type: string;
  subject: string;
  content: string;
  created_at: string;
  next_review_date: string;
  created_by: string;
  last_modified: string;
  last_modified_by: string;
  status: CarePlanStatus;
  template_id: number | null;
  deleted_at: string | null;
  files: FileMetadata[];
  associated_case_notes: CarePlanAssociatedCaseNote[];
  hra_meta: CarePlanTemplateHraMeta[];
}

export interface EnrichedMemberCarePlan extends MemberCarePlan {
  goals: string;
  interventions: string;
  barriers: string;
}

export interface MemberCarePlanDraft extends Partial<MemberCarePlan> {
  id: number;
  last_modified: string;
}

export interface CreateMemberCarePlanPayload {
  content: string;
  type: string;
  subject: string;
  template_id: number;
  next_review_date: string;
  hra_meta: number[];
}

export interface PublishMemberCarePlanPayload extends Partial<CreateMemberCarePlanPayload> {
  carePlanId?: number;
}

export interface CreateMemberCarePlanResponse {
  id: number;
}

export interface PatchMemberCarePlanPayload {
  content: string;
  subject: string;
  status: CarePlanStatus;
  next_review_date: string;
  hra_meta: number[];
}

export interface CarePlanChanges {
  id: number;
  case_note_id: number | null;
  metadata_before: string;
  metadata_after: string;
  created_by: string;
  created_at: string;
}
