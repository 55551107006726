import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, take, tap } from 'rxjs';

import { ActionsState, ComputedActionValues, GetSingleAction } from '@troyai/actions/data-access';
import {
  ActionEstimatedCompletionTimeComponent,
  ActionResourcesListComponent,
  ActionRichTextDescriptionComponent,
  RelatedActionsListComponent,
} from '@troyai/actions/ui';
import { UserState } from '@troyai/auth/data-access';
import { RolesDirective } from '@troyai/auth/feature';
import { FullNamePipe } from '@troyai/patients/util';
import { TrackDirective, TrackService } from '@troyai/tracking';
import { ButtonComponent, CardComponent, IconsModule, ModalModule } from '@troyai/ui-kit';
import { RelatedActionsPipe } from '../../../../pipes/related-actions.pipe';
import { ChatWindowComponent } from '../../../action-chat/chat-window/chat-window.component';
import { ActionCtaCardComponent } from '../../../action-cta-card/action-cta-card.component';
import { ActionIframeEmbedComponent } from '../../../action-iframe-embed/action-iframe-embed.component';
import { ActionLayoutRegularComponent } from '../../../action-layout-regular/action-layout-regular.component';
import { ActionSingleHeaderComponent } from '../../../action-single-header/action-single-header.component';
import { OutreachAttemptsListComponent } from '../../../outreach-attempts/outreach-attempts-list/outreach-attempts-list.component';
import { ActionCompletedCdpsComponent } from '../action-completed-cdps/action-completed-cdps.component';

@Component({
  selector: 't-action-cdps01-template',
  standalone: true,
  imports: [
    CommonModule,
    ActionLayoutRegularComponent,
    IconsModule,
    ActionIframeEmbedComponent,
    ActionResourcesListComponent,
    ChatWindowComponent,
    ActionSingleHeaderComponent,
    ActionCompletedCdpsComponent,
    RolesDirective,
    CardComponent,
    ActionEstimatedCompletionTimeComponent,
    ButtonComponent,
    ModalModule,
    FullNamePipe,
    TrackDirective,
    ActionRichTextDescriptionComponent,
    ActionResourcesListComponent,
    RelatedActionsListComponent,
    OutreachAttemptsListComponent,
    RelatedActionsPipe,
    ActionCtaCardComponent,
  ],
  templateUrl: './action-cdps01-template.component.html',
})
export class ActionCDPS01TemplateComponent {
  constructor(
    private store: Store,
    private trackService: TrackService
  ) {}

  @Input({ required: true }) computedActionValues!: ComputedActionValues;

  destroy$: Subject<boolean> = new Subject<boolean>();
  action$ = this.store.select(ActionsState.selectedAction);
  user$ = this.store.select(UserState.user);

  isLoading = false;

  // Mockup functionality for demo purposes only - it should only be visible for users that have @troymedicare.com email
  // simulateHRASubmit() {
  //   this.user$.pipe(take(1)).subscribe((user) => {
  //     if (user && user.email) {
  //       if (user.email.includes('@troymedicare.com')) {
  //         this.isLoading = true;
  //         setTimeout(() => {
  //           this.changeDetectorRef.detectChanges();
  //           this.store.dispatch(new UpdateSingleActionHRAState({ date: new Date().toISOString() }));
  //         }, 3000);
  //       }
  //     }
  //   });
  // }

  handlePostSubmitData() {
    this.action$
      .pipe(
        take(1),
        tap((action) => {
          if (action) {
            this.trackService.trackEvent({
              eventName: 'Submitted action form',
              eventProps: {
                member_id: action.member_assigned_to,
                action_id: action.global_id,
              },
            });
          }

          this.store.dispatch(new GetSingleAction({ globalId: action?.global_id }));
        })
      )
      .subscribe();
  }
}
