@if (file) {
  <div class="bg-grey-20 border-yellow-30 flex items-center gap-x-5 rounded border-l-4 px-4 py-3">
    <div class="bg-yellow-20 h-[52px] w-[72px] flex-none overflow-hidden rounded-lg">
      @if (hasPreview) {
        <img
          *ngIf="file.signed_url"
          ngSrc="{{ file.signed_url }}"
          alt="{{ file.file_name_original }}"
          width="72"
          height="52"
          class="h-full w-full object-cover" />
      } @else {
        <div
          class="text-grey-70 flex h-full w-full items-center justify-center object-cover text-xs uppercase tracking-wider">
          .{{ fileExtension }}
        </div>
      }
    </div>

    <div class="flex flex-col gap-y-2">
      <strong class="text-grey-100 text-sm font-semibold">{{ file.file_name_original }}</strong>
      <ng-content />
    </div>
  </div>
}
