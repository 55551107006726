import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';

import { ActionsState, SendActionMessage } from '@troyai/actions/data-access';
import { TrackService } from '@troyai/tracking';
import { ButtonComponent, IconsModule, InputTextComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-message-form',
  standalone: true,
  imports: [CommonModule, InputTextComponent, ButtonComponent, FormsModule, IconsModule],
  templateUrl: './message-form.component.html',
})
export class MessageFormComponent implements OnDestroy {
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    private trackService: TrackService
  ) {
    this.actions$
      .pipe(
        ofActionSuccessful(SendActionMessage),
        withLatestFrom(this.action$),
        takeUntil(this.destroy$)
      )
      .subscribe(([, action]) => {
        if (action) {
          this.trackService.trackEvent({
            eventName: 'Wrote action message',
            eventProps: {
              member_id: action.member_assigned_to,
              pharmacy_id: action.pharmacy_id,
              action_id: action.global_id,
              action_type: action.reference_id,
              pharmacy_name: action.pharmacy_name,
            },
          });
        }

        this.newMessage = '';
      });
  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  action$ = this.store.select(ActionsState.selectedAction);
  newMessage = '';
  isSentDisabled = true;

  onFormSubmit() {
    if (this.newMessage && this.newMessage.length > 2) {
      const params = this.route.snapshot.params;
      const globalId = Number(params['actionGlobalId']);
      this.store.dispatch(
        new SendActionMessage({
          globalId,
          data: {
            message: this.newMessage,
          },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
