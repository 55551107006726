import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { states } from './us-states-list';

export interface FragmentedAddressProperties {
  address_city?: string;
  address_county?: string;
  address_hash?: string;
  address_state?: string;
  address_street?: string;
  address_zip?: string;
}

@Pipe({
  name: 'formattedAddress',
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}
  transform(value?: FragmentedAddressProperties) {
    if (!value) {
      return '';
    }

    let formattedAddress = '';

    formattedAddress += this.titleCasePipe.transform(value.address_street);

    if (value.address_city && value.address_state) {
      const city = this.titleCasePipe.transform(value.address_city);
      const state = states.find((state) => state.name === value.address_state);
      if (state) {
        formattedAddress += `, ${city} ${state.abbreviation}`;
      } else {
        formattedAddress += `, ${city} ${value.address_state}`;
      }
    }

    if (value.address_zip) {
      formattedAddress += `, ${value.address_zip}`;
    }

    return formattedAddress;
  }
}
