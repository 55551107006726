import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RelatedActions } from '@troyai/actions/data-access';
import { ButtonComponent, LabelComponent, SectionComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-related-actions-list',
  standalone: true,
  imports: [CommonModule, SectionComponent, RouterModule, LabelComponent, ButtonComponent],
  templateUrl: './related-actions-list.component.html',
})
export class RelatedActionsListComponent {
  @Input() actions: RelatedActions[] = [];
}
