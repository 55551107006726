import {
  CheckBoxOption,
  MatrixFormInputType,
  parseRepeaterFieldsInputString,
  RadioButtonItem,
  SelectOption,
} from '@troyai/ui-kit';
import {
  DynamicControlAddress,
  DynamicControlCheckbox,
  DynamicControlDateTime,
  DynamicControlDropdown,
  DynamicControlEmail,
  DynamicControlFileUpload,
  DynamicControlFullName,
  DynamicControlHead,
  DynamicControlMatrix,
  DynamicControlPhone,
  DynamicControlRadio,
  DynamicControlTextArea,
  DynamicControlTextBox,
  DynamicControlWidget,
  DynamicRenderedBaseControl,
  DynamicRenderedControls,
} from '../models/dynamic-form-renderer.model';
import { DynamicFormsApiResponse } from '../models/dynamic-forms-api.model';
import { JotformControlType } from '../models/jotform-controls.model';

export const jotformToDynamicControls = (formData: DynamicFormsApiResponse) => {
  const dynamicControls: DynamicRenderedControls[] = [];

  for (const formControl of Object.values(formData.controls)) {
    switch (formControl.type) {
      case 'control_textbox':
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlTextBox);
        break;

      case 'control_textarea':
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlTextArea);
        break;

      case 'control_radio': {
        const optionsConfig = formControl.options.split('|');

        if (formControl.allowOther === 'Yes') {
          optionsConfig.push(formControl.otherText);
        }

        const options: RadioButtonItem[] = optionsConfig.map((item) => {
          return {
            name: item,
            value: item,
          };
        });
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
          options,
        } as DynamicControlRadio);
        break;
      }

      case 'control_checkbox': {
        const optionsConfig = formControl.options.split('|');

        if (formControl.allowOther === 'Yes') {
          optionsConfig.push(formControl.otherText);
        }

        const options: CheckBoxOption[] = optionsConfig.map((item) => {
          return {
            label: item,
            value: item,
          };
        });

        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue:
            typeof formControl.defaultValue === 'object'
              ? Object.values(formControl.defaultValue)
              : '',
          options,
        } as DynamicControlCheckbox);
        break;
      }

      case 'control_datetime': {
        const existingValues = formControl.defaultValue;

        const dateValue =
          typeof existingValues === 'object'
            ? `${existingValues.year}-${existingValues.month}-${existingValues.day}`
            : null;

        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: dateValue ? dateValue : '',
        } as DynamicControlDateTime);
        break;
      }

      case 'control_head': {
        dynamicControls.push({
          ...processCommonProperties(formControl),
          subheader: formControl.subHeader,
        } as DynamicControlHead);
        break;
      }

      case 'control_matrix': {
        const rowsConfig = formControl.mrows.split('|');
        const columnsConfig = formControl.mcolumns.split('|');

        let inputType: MatrixFormInputType | null = null;
        let existingValues = formControl.defaultValue;

        if (formControl.inputType === 'Check Box') {
          inputType = 'checkbox';
        }

        if (formControl.inputType === 'Radio Button') {
          if (formControl.defaultValue) {
            existingValues = rowsConfig.reduce(
              (acc, row, idx) => {
                if (formControl.defaultValue[idx] && formControl.defaultValue[idx].length > 0) {
                  acc[row] = formControl.defaultValue[idx][0];
                }

                return acc;
              },
              {} as Record<string, string>
            );
          } else {
            existingValues = '';
          }

          inputType = 'radio';
        }

        dynamicControls.push({
          ...processCommonProperties(formControl),
          cols: columnsConfig,
          rows: rowsConfig,
          inputType,
          defaultValue: existingValues,
        } as DynamicControlMatrix);
        break;
      }

      case 'control_widget': {
        const fields = parseRepeaterFieldsInputString(formControl.fields);

        let contents;
        try {
          contents = formControl.defaultValue ? JSON.parse(formControl.defaultValue) : '';
        } catch (e) {
          console.log(e);
          contents = '';
        }

        dynamicControls.push({
          ...processCommonProperties(formControl),
          fields,
          defaultValue: contents,
          addRowButtonText: formControl.labelAdd.replace('+', '').trim(),
        } as DynamicControlWidget);
        break;
      }

      case 'control_dropdown': {
        const options = formControl.options.split('|').map((option) => {
          return {
            id: option,
            label: option,
          } as SelectOption<string>;
        });

        dynamicControls.push({
          ...processCommonProperties(formControl),
          options,
          defaultValue: formControl.defaultValue,
        } as DynamicControlDropdown);
        break;
      }

      case 'control_fullname': {
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlFullName);
        break;
      }

      case 'control_address': {
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlAddress);
        break;
      }

      case 'control_phone': {
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlPhone);
        break;
      }

      case 'control_email': {
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlEmail);
        break;
      }

      case 'control_fileupload': {
        dynamicControls.push({
          ...processCommonProperties(formControl),
          defaultValue: formControl.defaultValue,
        } as DynamicControlFileUpload);
        break;
      }
    }
  }

  return dynamicControls;
};

const processCommonProperties = (control: JotformControlType) => {
  return {
    type: control.type,
    name: control.name,
    label: control.text,
    required: control.required === 'Yes',
    readonly: control.readonly === 'Yes',
    order: control.order,
    qid: control.qid,
    controlName: `${control.qid}_${control.name}`,
    hidden: control.hidden === 'Yes',
  } as DynamicRenderedBaseControl;
};

export const transformContentKeysToFieldIds = (inputObj: { [key: string]: any }) => {
  const newObj: { [key: string]: any } = {};
  Object.keys(inputObj).forEach((key) => {
    // Extract the number before the underscore
    const newKey = key.split('_')[0];
    newObj[newKey] = inputObj[key];
  });
  return newObj;
};
