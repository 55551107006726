import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from '../popover/popover.module';
import { IconsModule } from '../icon/icon.module';

type ArrowPosition = 'left' | 'right';

@Component({
  selector: 't-list-expand',
  standalone: true,
  imports: [CommonModule, PopoverModule, IconsModule],
  templateUrl: './list-expand.component.html',
})
export class ListExpandComponent {
  @Input() itemsCount = 1;
  @Input() iconPos: ArrowPosition = 'left';
}
