<t-greeting-card *ngIf="!isLoading">
  <span description class="text-sm text-white">You have successfully logged out.</span>
  <t-button
    cta
    (click)="loginWithRedirect()"
    [isFullWidth]="true"
    [filled]="true"
    variant="neutral">
    LOGIN
  </t-button>
</t-greeting-card>
