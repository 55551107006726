import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CareState } from '@troyai/hra/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { AddressPipe, DateOfBirthPipe, PhonePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent, HeadingComponent, IconsModule } from '@troyai/ui-kit';
import { filter } from 'rxjs';

@Component({
  selector: 't-print-care-patients-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconsModule,
    HeadingComponent,
    FullNamePipe,
    PhonePipe,
    DateOfBirthPipe,
    AddressPipe,
  ],
  templateUrl: './print-care-patients-modal.component.html',
})
export class PrintCarePatientsModalComponent {
  constructor(private store: Store) {}

  careActionsListItems$ = this.store
    .select(CareState.careActionsListItems)
    .pipe(filter((items) => items.length > 0));

  getTodayDate = () => {
    const today = new Date();
    return today.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  };

  triggerPrint() {
    window.print();
  }
}
