<div
  class="relative flex w-full min-w-[420px] max-w-xl items-center justify-center lg:h-[250px] lg:w-full">
  <div class="w-full">
    <div class="w-full text-center">
      <div class="bg-yellow-30 text-amber-70 inline-block rounded-full p-3">
        <t-icon key="info-circle" color="inherit"></t-icon>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center space-y-4 pt-4">
      <strong class="block text-base font-semibold">Remove appointment</strong>
      <t-input-text
        size="narrow"
        inputType="textarea"
        placeholder="Please enter reason for removing this appointment."
        [(ngModel)]="reasonInputText"
        id="appointmentRemovalReason"></t-input-text>
    </div>
    <div class="flex justify-center space-x-2.5 pt-8">
      <t-button (click)="confirmAction()">CONFIRM</t-button>
      <t-button (click)="closeModal()" variant="neutral">CANCEL</t-button>
    </div>
  </div>
</div>
