import { Injectable } from '@angular/core';
import { Action } from '@troyai/actions/data-access';
import { RestApiClientService } from '@troyai/rest-api-client';
import { CMPatient } from './models/cm-patient.interface';

@Injectable({
  providedIn: 'root',
})
export class CareManagementApiService {
  constructor(private restApiService: RestApiClientService) {}

  getPatients() {
    return this.restApiService.get<CMPatient[]>('cm/members');
  }

  getActions() {
    return this.restApiService.get<Action[]>('cm/actions');
  }
}
