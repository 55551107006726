import { Params } from '@angular/router';
import { Pagination } from '../models/page.model';

export const generatePagination = (pageSize: number, queryParams?: Params): Pagination => {
  const pageNumberFromQueryParam =
    queryParams && queryParams['page'] ? Number(queryParams?.['page']) : null;

  return {
    page: pageNumberFromQueryParam ? pageNumberFromQueryParam : 1,
    size: pageSize,
  };
};
