import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export type ShadowSize = 'small' | 'large';
export type PaddingSize = 'none' | 'small' | 'large' | 'huge';
export type RadiusSize = 'small' | 'large';

@Component({
  selector: 't-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
})
export class CardComponent {
  @Input() shadowSize: ShadowSize = 'small';
  @Input() paddingSize: PaddingSize = 'small';
  @Input() isRounded = true;
  @Input() radiusSize: RadiusSize = 'small';
  @Input() isFullHeight = false;
  @Input() isInteractive = true;
}
