import { Injectable } from '@angular/core';
import { filterByProperties } from '@troyai/shared/utils';
import { sort } from 'fast-sort';
import { Observable, of } from 'rxjs';
import { Filter, FilterDimension, TimeIntervalFilter } from '../models/filters.model';
import { SortOptionValue } from '../models/page.model';

@Injectable({
  providedIn: 'root',
})
export class InfiniteLoadDataSourceService {
  generateListingEntries = <T>(
    items: T[],
    sort: SortOptionValue<T>[],
    filters: Filter<T>[],
    search: {
      value: string;
      searchableProperties: (keyof T)[];
    },
    itemsCount: number
  ): Observable<T[]> => {
    const resultsBySearchText =
      search && search.searchableProperties.length > 0
        ? filterByProperties(items, search.searchableProperties as string[], search.value)
        : items;

    const filteredResults = this.applyFilters(resultsBySearchText, filters);
    const sortedResults = this.applySorting(filteredResults, sort);
    const slicedResults = sortedResults.slice(0, itemsCount);
    return of(slicedResults);
  };

  applySorting = <T>(items: T[], sortOptions?: SortOptionValue<T>[]) => {
    if (!sortOptions) return items;

    const activeSortOption = sortOptions.find((option) => option.isActive);

    if (!activeSortOption) return items;

    const sortBy = activeSortOption.config.value.keyName;
    const sortDirection = activeSortOption.config.value.order;

    if (!sortBy || !sortDirection) return items;

    return sortDirection === 'asc'
      ? sort(items).asc((u) => u[sortBy])
      : sort(items).desc((u) => u[sortBy]);
  };

  applyFilters = <T>(items: T[], filters: Filter<T>[]) => {
    if (!items.length || !filters.length) {
      return items;
    }

    const filterDimensions: FilterDimension<T>[] = filters.map((filter) => {
      return {
        values: filter.values.filter((item) => item.isActive).map((item) => item.id),
        keyName: filter.config.keyName,
        filter: filter,
      };
    });

    return items.filter((item) => {
      return filterDimensions.every((filterItem) => {
        if (!filterItem.values.length) return true;

        if (filterItem.filter.config.valuesBehavior === 'direct') {
          if (!filterItem.filter.config.nestedPropertyPath) {
            // Handle grouping prefix differently than regular filters
            if (filterItem.filter.config.groupingPrefix) {
              const itemValue = item[filterItem.keyName] as string;
              return filterItem.values.some((value) => itemValue.startsWith(value));
            } else {
              return filterItem.values.includes(item[filterItem.keyName] as string);
            }
          } else {
            const filterValuesSet = new Set(filterItem.values);
            const itemsToFilter = item[filterItem.keyName];
            if (Array.isArray(itemsToFilter)) {
              const itemValuesSet = new Set(
                itemsToFilter.map(
                  (itemToFilter) =>
                    itemToFilter[filterItem.filter.config.nestedPropertyPath as string]
                )
              );

              const intersection = new Set(
                [...filterValuesSet].filter((x) => itemValuesSet.has(x))
              );
              return intersection.size > 0;
            }
          }
        }

        if (filterItem.filter.config.valuesBehavior === 'time-interval') {
          const itemDateLimit = Date.parse(item[filterItem.keyName] as string);

          for (const item of filterItem.values) {
            const filterTimeBrackets = filterItem.filter.config.customValues?.find(
              (value) => value.label === item
            );

            const filterConfig = filterTimeBrackets?.config as TimeIntervalFilter;
            const startDate = filterConfig.startDate;
            const endDate = filterConfig.endDate;

            return itemDateLimit >= startDate && itemDateLimit < endDate;
          }
        }

        return true;
      });
    });
  };
}
