import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { map } from 'rxjs';
import {
  DynamicFormsApiResponse,
  FormSubmissionDraftResponse,
  FormSubmissionResponse,
  NewFormSubmissionRequestPayload,
  UpdateFormSubmissionRequestPayload,
} from './models/dynamic-forms-api.model';
import { JotformControlType } from './models/jotform-controls.model';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getJotformDynamicFormData(jotformFormId: string) {
    return this.restApiService
      .get<Record<string, JotformControlType>>(`forms/jotform/${jotformFormId}`)
      .pipe(
        map((response) => {
          return {
            controls: response,
          } as DynamicFormsApiResponse;
        })
      );
  }

  addFormSubmission(payload: NewFormSubmissionRequestPayload) {
    return this.restApiService.post<number>(`form-submission/jotform`, payload);
  }

  getFormSubmission(submissionId: number) {
    return this.restApiService.get<FormSubmissionResponse>(`form-submission/${submissionId}`);
  }

  updateFormSubmission(submissionId: number, payload: UpdateFormSubmissionRequestPayload) {
    return this.restApiService.patch<GenericRestApiOKResponse>(
      `form-submission/${submissionId}`,
      payload
    );
  }

  deleteFormSubmission(submissionId: number) {
    return this.restApiService.delete<GenericRestApiOKResponse>(`form-submission/${submissionId}`);
  }

  getAllDraftSubmissions() {
    return this.restApiService.get<FormSubmissionDraftResponse[]>(`/form-submission/drafts`);
  }
}
