<div class="flex w-full justify-between">
  <div class="text-grey-70 mb-2 w-full space-y-2 pt-3 text-xs">
    <div class="flex items-start gap-x-2">
      <t-icon class="mt-0.5" key="location-on" size="tiniest" color="inherit"></t-icon>
      <span>
        {{ action.scheduled_address | formattedAddress }}
      </span>
      <t-label size="tiny" [highContrast]="true" color="warning">
        {{ action.scheduled_address?.at_pharmacy ? 'At the Pharmacy' : 'At Home' }}
      </t-label>
      <ng-container *tIfHasRole="[userRoles.CMExternal]">
        <t-label
          *ngIf="action.scheduled_address?.scheduled_telehealth"
          size="tiny"
          [highContrast]="true"
          color="success">
          Telehealth
        </t-label>
      </ng-container>
    </div>

    <div class="flex items-start gap-x-2">
      <t-icon class="mt-0.5" key="event" size="tiniest" color="inherit"></t-icon>
      <span>
        Scheduled by {{ action.scheduled_by }} on
        {{ action.scheduled_at | customMediumDate }}
      </span>
    </div>
  </div>

  <div class="flex-none self-end">
    <t-button
      size="large"
      icon="close"
      iconPos="right"
      variant="secondary"
      size="tiny"
      (click)="cancelAppointment()">
      Cancel appointment
    </t-button>
  </div>
</div>
