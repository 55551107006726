import { format } from 'date-fns';
import { EnrichedAppointment } from '../models/appointment.interface';
import { AppointmentsAccordionGroup } from './care-state.model';

export const updateTimeslotByAppointmentGroup = (
  appointmentsGroups: AppointmentsAccordionGroup[],
  timeslot: EnrichedAppointment | number,
  appointmentGroupKey: string,
  partialTimeslot?: Partial<EnrichedAppointment>
) => {
  const timeslotId = typeof timeslot === 'number' ? timeslot : timeslot.global_id;
  const groupKey = format(new Date(appointmentGroupKey), 'yyyy-MM-dd');

  return appointmentsGroups.map((group) => {
    if (group.day === groupKey) {
      const updatedSlots = group.timeslots.map((slot) => {
        if (slot.global_id === timeslotId) {
          return {
            ...slot,
            ...partialTimeslot,
          };
        }

        return slot;
      });

      return {
        ...group,
        timeslots: updatedSlots,
      };
    }
    return group;
  });
};
