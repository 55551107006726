import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CareState } from '@troyai/hra/data-access';
import { ButtonComponent, InputTextComponent } from '@troyai/ui-kit';
import { tap } from 'rxjs';

@Component({
  selector: 't-sync-calendar-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    InputTextComponent,
    ReactiveFormsModule,
    ClipboardModule,
  ],
  templateUrl: './sync-calendar-modal.component.html',
})
export class SyncCalendarModalComponent {
  constructor(
    private store: Store,
    private clipboard: Clipboard,
    private loc: Location
  ) {}

  calendarAccessFormControl = new FormControl({
    value: '',
    disabled: true,
  });
  calendarAccessToken$ = this.store.select(CareState.calendarAccessToken).pipe(
    tap((token) => {
      if (token) {
        this.calendarAccessFormControl.setValue(
          `${window.location.origin}/api/hra/calendar/${token}`
        );
      }
    })
  );

  copy() {
    if (this.calendarAccessFormControl.value) {
      this.clipboard.copy(this.calendarAccessFormControl.value);
    }
  }
}
