import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { AddMemberHraAddendum, CareState, MemberHra } from '@troyai/hra/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { NewlineToBrPipe } from '@troyai/portal/common/pipes';
import {
  ButtonComponent,
  CardComponent,
  EmptyStateCardComponent,
  FieldErrorComponent,
  IconsModule,
  InputTextComponent,
  LoaderComponent,
} from '@troyai/ui-kit';
import { take, tap } from 'rxjs';

@Component({
  selector: 't-hra-addendum-modal',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    CardComponent,
    InputTextComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    FieldErrorComponent,
    LoaderComponent,
    EmptyStateCardComponent,
    NewlineToBrPipe,
  ],
  templateUrl: './hra-addendum-modal.component.html',
})
export class HraAddendumModalComponent {
  constructor(
    @Inject(DIALOG_DATA) public hraItem: MemberHra,
    private store: Store,
    private actions$: Actions,
    public dialogRef: DialogRef
  ) {}

  selectedPatient$ = this.store.select(PatientsState.selectedPatient);
  addingMemberHraAddendumLoadingStatus$ = this.store.select(
    CareState.getAddingMemberHraAddendumsLoadingStatus
  );
  isSubmissionWarningDisplayed = false;
  isFormDisplayed = false;

  form = new FormGroup({
    addendum_content: new FormControl<string>('', [Validators.required]),
  });

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.isSubmissionWarningDisplayed = true;
    }
  }

  onAddendumConfirmation() {
    this.actions$.pipe(ofActionCompleted(AddMemberHraAddendum), take(1)).subscribe(() => {
      this.dialogRef.close();
    });

    this.selectedPatient$
      .pipe(
        tap((selectedPatient) => {
          if (!selectedPatient || !this.form.controls.addendum_content.value) {
            return;
          }

          this.store.dispatch(
            new AddMemberHraAddendum({
              memberGlobalId: selectedPatient.global_id,
              hraId: this.hraItem.id,
              content: this.form.controls.addendum_content.value,
            })
          );
        }),
        take(1)
      )
      .subscribe();
  }

  onAddendumCancellation() {
    this.isSubmissionWarningDisplayed = false;
  }

  displayForm() {
    this.isFormDisplayed = true;
  }
}
