<div *ngIf="isModalLoading$ | async; else schedulingTemplate">
  <t-skeleton-loader [rows]="3" rowsHeight="huge" />
</div>

<ng-template #schedulingTemplate>
  <div *ngIf="action && action.status === 'Scheduled'" class="w-full">
    <span class="text-grey-70 mb-3 block text-xs uppercase tracking-wider">
      Scheduled appointment:
    </span>
    <t-card class="block w-full" [isInteractive]="false">
      <t-action-status-card [titleText]="action.start_date | date: 'medium' : 'UTC'">
        <t-action-appointment-preview
          [action]="action"
          (canceledAppointment)="cancelAppointment()" />
      </t-action-status-card>
    </t-card>
  </div>

  <div
    *ngIf="action && action.status === 'Scheduled'"
    class="border-grey-20 mb-2 border-b pb-5 pt-8">
    <span class="text-grey-70 block text-xs uppercase tracking-wider"> Reschedule </span>
  </div>

  <div class="border-grey-20 mb-6 flex items-center space-x-2 border-b pb-2 text-sm">
    <span class="text-grey-50">Address: </span>

    <ng-container *ngIf="scheduleDetails$ | async as scheduleDetails">
      <span class="text-grey-100" *ngIf="scheduleDetails.appointmentAddress">
        {{ scheduleDetails.appointmentAddress | formattedAddress }}
      </span>
    </ng-container>

    <t-link
      [isDisabled]="action?.status === 'Scheduled'"
      (click)="action?.status !== 'Scheduled' ? openSchedulingLocationModal() : null"
      [isButton]="true">
      Change
    </t-link>
  </div>

  <div class="pb-6">
    <t-week-navigator
      [timePeriod]="appointmentsPeriod$ | async"
      (weekChange)="onWeekChange($event)" />
  </div>

  <t-skeleton-loader
    [rows]="10"
    *ngIf="appointmentsListItemsLoading$ | async; else appointmentsList">
  </t-skeleton-loader>
</ng-template>

<ng-template #appointmentsList>
  <ng-container *ngIf="groupedAppointmentsListItems$ | async as appointments">
    <t-accordion *ngIf="appointments.length; else appointmentsEmptyState">
      <t-accordion-item
        *ngFor="let appointment of appointments; let index = index"
        [idx]="index"
        [expanded]="appointment.isOpen">
        <span header class="text-grey-50 text-base font-semibold">
          {{ appointment.day | date: 'EEEE, MMMM d' }}
        </span>
        <div class="divide-grey-20 border-grey-20 mt-2 divide-y border-t">
          <div
            *ngFor="let timeslot of appointment.timeslots"
            class="px-2 py-3 lg:flex lg:items-center">
            <!-- Time intervals -->
            <div class="text-grey-70 mb-4 block text-sm font-semibold lg:mb-0 lg:w-3/12">
              {{ timeslot.start_time | timeInterval: timeslot.end_time }}
            </div>
            <!-- /Time intervals -->

            <!-- Scheduled member area -->
            <div class="text-grey-50 text-base lg:w-4/12 lg:text-sm">
              @if (timeslot.blocked_at) {
                <span>Not Available</span>
              } @else if (timeslot.member_id !== '0') {
                <span>
                  <strong class="font-semibold">Booked at:</strong>
                  {{ timeslot.address_city }},
                  {{ timeslot.address_county }}
                </span>
              } @else {
                <span class="text-green-90">Available</span>
              }
            </div>
            <!-- Scheduled member area -->

            <!-- Scheduling actions area -->
            <div class="text-grey-50 flex justify-end gap-x-1 text-sm lg:w-5/12">
              <t-button
                [disabled]="action?.status === 'Scheduled' || timeslot.blocked_at !== null"
                [loading]="timeslot.isLoading || false"
                *ngIf="timeslot.member_id === '0'"
                (onClick)="
                  action?.status !== 'Scheduled' && timeslot.blocked_at === null
                    ? schedulePatient(timeslot)
                    : null
                "
                size="tiny"
                [attr.title]="action?.status === 'Scheduled' ? 'Appointment already scheduled' : ''"
                variant="neutral">
                {{ timeslot.isLoading ? 'Scheduling...' : 'Schedule' }}
              </t-button>
            </div>
            <!-- /Scheduling actions area -->
          </div>
        </div>
      </t-accordion-item>
    </t-accordion>

    <ng-template #appointmentsEmptyState>
      <div class="bg-grey-60 flex min-h-[300px] items-center justify-center bg-opacity-5">
        <t-empty-state-card content="There are no available slots this week." [hasIcon]="true">
          <t-link [isButton]="true" (click)="onWeekChange('next')">Try next week</t-link>
        </t-empty-state-card>
      </div>
    </ng-template>
  </ng-container>
</ng-template>
