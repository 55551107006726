import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { IconsModule } from '../icon/icon.module';

export type ToastType = 'toast-info' | 'toast-error' | 'toast-success' | 'toast-warning';

@Component({
  selector: 't-toast',
  standalone: true,
  imports: [NgIf, NgClass, IconsModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyInOut', [
      state('void', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('void => active', animate('100ms ease-in')),
      transition('active => removed', animate('100ms ease-in')),
    ]),
  ],
})
export class ToastComponent extends Toast {
  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
    this.toastType = this.toastPackage.toastType as ToastType;
    this.iconKey = this.iconsMap[this.toastType];
  }

  toastType?: ToastType;

  // The values here are to be displayed for each toast type as keys for the icon component
  private iconsMap: { [key in ToastType]: SvgIcons } = {
    'toast-success': 'check-circle',
    'toast-info': 'info-circle',
    'toast-warning': 'info-circle',
    'toast-error': 'do-disturb-on',
  };
  iconKey?: SvgIcons;

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
