<div class="relative flex max-w-lg items-center justify-center lg:h-[250px] lg:w-full">
  <div>
    <div class="w-full text-center">
      <div class="bg-yellow-30 text-amber-70 inline-block rounded-full p-3">
        <t-icon key="info-circle" color="inherit"></t-icon>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center space-y-2 pt-4">
      <strong *ngIf="data.promptTitle" class="block text-base font-semibold">
        {{ data.promptTitle }}
      </strong>
      <p class="text-grey-70 w-2/3 text-center text-sm">
        {{ data.promptMessage }}
      </p>
    </div>
    <div class="flex justify-center space-x-2.5 pt-8">
      <t-button (click)="confirmAction()">{{ data.confirmButtonText || 'OK' }}</t-button>
      <t-button *ngIf="data.cancelButtonText" (click)="dismissModal()" variant="neutral">
        {{ data.cancelButtonText }}
      </t-button>
    </div>
  </div>
</div>
