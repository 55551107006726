<div
  class="flex justify-between"
  [ngClass]="{
    'items-start': alignItems === 'start',
    'items-center': alignItems === 'center'
  }">
  <div
    class="flex gap-x-5"
    [ngClass]="{
      'items-start': alignItems === 'start',
      'items-center': alignItems === 'center'
    }">
    <img
      [ngSrc]="action.fee_schedule_image"
      [width]="thumbnailSizeMapping[thumbnailSize]"
      [height]="thumbnailSizeMapping[thumbnailSize]"
      tImgFallback="/assets/media-call-center.svg" />
    <div class="space-y-3 text-left">
      <span
        class="text-grey-100 block text-base font-semibold"
        [ngClass]="{
          'md:text-xl': layoutVariant === 'default',
          'md:text-lg': layoutVariant === 'compact'
        }">
        {{ action.name }}
      </span>
      <div class="flex items-center gap-x-3">
        <t-label variant="pill" color="warning" size="tiny">{{ action.status }}</t-label>
        <span class="text-grey-60 flex items-center gap-x-1.5 text-xs">
          <t-icon key="alarm-smart-wake" color="inherit" size="tiniest"></t-icon>
          {{ action.due_date | expirationDate }}
        </span>
      </div>
    </div>
  </div>

  <t-label variant="pill" color="neutral" size="large">
    {{ action.fee | currency: undefined : 'symbol' : '1.0-0' }}
  </t-label>
</div>
