import { Injectable } from '@angular/core';
import { CacheableHttpResponse } from './cacheable-http.model';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: { [key: string]: CacheableHttpResponse } = {};

  set(key: string, value: CacheableHttpResponse) {
    this.cache[key] = value;
  }

  get(key: string) {
    return this.cache[key];
  }

  clear(key: string) {
    delete this.cache[key];
  }

  clearAll() {
    this.cache = {};
  }

  list() {
    return Object.keys(this.cache);
  }
}
