<div
  class="flex h-full flex-col justify-between rounded-lg p-8 transition-all"
  [ngClass]="{
    'shadow-small bg-white': !isDisabled,
    'border-grey-25 border bg-transparent': isDisabled
  }">
  <div><ng-content></ng-content></div>

  <div
    *ngIf="isLoading; else loadedTemplate"
    class="bg-grey-15 text-grey-70 flex items-center justify-between rounded px-3 py-2.5 text-sm">
    <strong class="font-semibold">Loading...</strong>
    <t-loader />
  </div>
</div>

<ng-template #loadedTemplate>
  <t-button
    *ngIf="!isComplete"
    [disabled]="isDisabled"
    (click)="triggerItemStartEvent()"
    [isFullWidth]="true"
    variant="secondary">
    {{ buttonText }}
  </t-button>

  <div
    *ngIf="isComplete"
    class="bg-grey-15 text-grey-70 flex items-center justify-between rounded px-3 py-2.5 text-sm">
    <strong class="font-semibold">Done</strong>
    <t-icon key="check" color="inherit" size="tiny" />
  </div>
</ng-template>
