<div class="space-y-2">
  <label class="text-grey-50 mb-2 block text-sm">{{ label }}</label>
  <div [formGroup]="addressForm" class="space-y-2">
    <t-input-text
      class="block"
      id="addr_line1"
      formControlName="addr_line1"
      (blur)="markAsTouched()"
      placeholder="Street Address" />

    <t-input-text
      class="block"
      id="addr_line2"
      formControlName="addr_line2"
      (blur)="markAsTouched()"
      placeholder="Street Address Line 2" />

    <div class="flex gap-x-2">
      <t-input-text id="city" formControlName="city" (blur)="markAsTouched()" placeholder="City" />

      <t-input-text
        class="block"
        id="state"
        formControlName="state"
        (blur)="markAsTouched()"
        placeholder="State / Province" />
    </div>

    <t-input-text
      class="block"
      id="postal"
      formControlName="postal"
      (blur)="markAsTouched()"
      placeholder="Postal / Zip Code" />
  </div>
</div>
