import { AbstractControl, ValidatorFn } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * Creates a phone number validator function.
 * This validator uses libphonenumber-js to validate US phone numbers.
 * It returns an error object if the phone number is invalid, or null if it's valid.
 */
export function PhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const phoneNumber = control.value;
    if (phoneNumber) {
      try {
        const parsedNumber = parsePhoneNumber(phoneNumber, 'US');
        if (!parsedNumber || !parsedNumber.isValid()) {
          // The phone number is invalid
          return { phoneNumberInvalid: true };
        }
      } catch (error) {
        // The phone number is invalid
        return { phoneNumberInvalid: true };
      }
    }

    // The phone number is valid or empty
    return null;
  };
}
