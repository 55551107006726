<div class="border-grey-20 border-b">
  <ul class="flex w-full flex-wrap">
    <li
      class="block flex-1 py-4 text-center text-xs font-semibold uppercase tracking-wider text-gray-100 hover:cursor-pointer"
      *ngFor="let tab of tabs"
      (click)="selectTab(tab)"
      [ngClass]="{
        'border-b-green-80 border-b-2 hover:bg-white': tab.active
      }">
      <span>{{ tab.label }}</span>
    </li>
  </ul>
</div>
<ng-content></ng-content>
