<div
  cdkAccordionItem
  #accordionItem="cdkAccordionItem"
  class="mb-2 w-full rounded-lg"
  [ngClass]="{
    'shadow-small block border border-white bg-white px-4 py-2': variant === 'default',
    'hover:border-grey-35 transition-colors hover:border':
      !accordionItem.expanded && variant === 'default',
    'bg-grey-15 px-4 py-3': variant === 'compact'
  }"
  [expanded]="expanded"
  tabindex="0"
  [attr.id]="'accordion-header-' + idx"
  [attr.aria-expanded]="accordionItem.expanded"
  [attr.aria-controls]="'accordion-body-' + idx">
  <!-- Header -->
  <div
    (click)="!isEmpty ? toggle(accordionItem, idx) : null"
    class="flex cursor-pointer items-center justify-between"
    [ngClass]="{
      'p-2': variant === 'default'
    }">
    <ng-content select="[header]"></ng-content>
    @if (!isEmpty) {
      <t-icon
        class="cursor-pointer"
        [key]="accordionItem.expanded ? 'keyboard-arrow-up' : 'keyboard-arrow-down'"
        size="tiny" />
    }
  </div>
  <!-- Header -->

  <!-- Content -->
  <div
    role="region"
    [style.display]="accordionItem.expanded ? '' : 'none'"
    [attr.id]="'accordion-body-' + idx"
    [attr.aria-labelledby]="'accordion-header-' + idx">
    <ng-content></ng-content>
  </div>
  <!-- Content -->
</div>
