import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  CareState,
  GetAllAppointmentsDataAsPharmacy,
  NavigateToCurrentWeek,
  NavigateToNextWeek,
  NavigateToPreviousWeek,
  ToggleAppointmentsGroup,
} from '@troyai/hra/data-access';
import { ContentWrapperLayoutComponent } from '@troyai/layout';
import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { PhonePipe, TimeIntervalPipe } from '@troyai/portal/common/pipes';
import {
  AccordionComponent,
  AccordionItemComponent,
  AccordionItemToggleEvent,
  ButtonComponent,
  DropdownButtonComponent,
  DropdownMenuComponent,
  DropdownMenuItemComponent,
  EmptyStateCardComponent,
  IconsModule,
  LabelComponent,
  LinkComponent,
  ModalModule,
  ModalService,
  PopoverModule,
  SectionComponent,
  SkeletonLoaderComponent,
  WeekNavigationTarget,
  WeekNavigatorComponent,
} from '@troyai/ui-kit';
import { endOfWeek, format, isValid, startOfWeek } from 'date-fns';
import { parse } from 'date-fns/parse';
import { combineLatest, filter, withLatestFrom } from 'rxjs';
import { PrintAppointmentsModalComponent } from '../print-appointments-modal/print-appointments-modal.component';

@Component({
  selector: 't-pharmacy-appointments-listing',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperLayoutComponent,
    SectionComponent,
    PopoverModule,
    IconsModule,
    DropdownButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    AccordionComponent,
    AccordionItemComponent,
    ButtonComponent,
    LinkComponent,
    ModalModule,
    PhonePipe,
    SkeletonLoaderComponent,
    WeekNavigatorComponent,
    EmptyStateCardComponent,
    PharmacyIndicatorTooltipComponent,
    ModalModule,
    TimeIntervalPipe,
    LabelComponent,
  ],
  templateUrl: './pharmacy-appointments-listing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PharmacyAppointmentsListingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private modalService: ModalService
  ) {}

  destroyRef = inject(DestroyRef);

  selectedPharmacyId$ = this.store
    .select(PharmacyContextState.selectedPharmacyId)
    .pipe(filter((pharmacyId) => pharmacyId !== undefined));
  groupedAppointmentsListItems$ = this.store.select(CareState.groupedAppointmentsListItems);
  appointmentsListItemsLoading$ = this.store.select(CareState.appointmentsListItemsLoading);
  appointmentsPeriod$ = this.store.select(CareState.appointmentsPeriod);

  selectedPharmacyId?: null | number = null;

  handleToggleChange(event: AccordionItemToggleEvent) {
    this.store.dispatch(
      new ToggleAppointmentsGroup({
        idx: event.idx,
        expanded: event.expanded,
      })
    );
  }

  openPrintAppointmentsModal() {
    this.modalService.openDialog(
      PrintAppointmentsModalComponent,
      {},
      {
        title: 'Print All Appointments',
        background: 'grey',
        allowOverflow: true,
      }
    );
  }

  onWeekChange(target: WeekNavigationTarget) {
    switch (target) {
      case 'next':
        this.store.dispatch(new NavigateToNextWeek());
        break;
      case 'previous':
        this.store.dispatch(new NavigateToPreviousWeek());
        break;
      case 'current':
        this.store.dispatch(new NavigateToCurrentWeek());
        break;
    }
  }

  ngOnInit() {
    combineLatest([this.route.queryParamMap, this.selectedPharmacyId$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([params, pharmacyId]) => {
        this.selectedPharmacyId = pharmacyId;
        const startDateParam = params.get('startDate') as string;
        const endDateParam = params.get('endDate') as string;

        if (startDateParam && endDateParam) {
          const startDateFromParam = parse(startDateParam, 'yyyy-MM-dd', Date.now());
          const endDateFromParam = parse(endDateParam, 'yyyy-MM-dd', Date.now());

          if (isValid(startDateFromParam) && isValid(endDateFromParam)) {
            this.store.dispatch(
              new GetAllAppointmentsDataAsPharmacy({
                startDate: startDateFromParam,
                endDate: endDateFromParam,
              })
            );
          } else {
            // Use History API directly to prevent any navigation events
            window.history.replaceState({}, document.title, this.router.url.split('?')[0]);
          }
        } else {
          this.store.dispatch(
            new GetAllAppointmentsDataAsPharmacy({
              startDate: startOfWeek(Date.now(), {
                weekStartsOn: 1,
              }),
              endDate: endOfWeek(Date.now(), {
                weekStartsOn: 1,
              }),
            })
          );
        }
      });

    // Update router query params on every navigation action
    this.actions$
      .pipe(
        ofActionSuccessful(NavigateToNextWeek, NavigateToPreviousWeek, NavigateToCurrentWeek),
        withLatestFrom(this.appointmentsPeriod$),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((result) => {
        const [, appointmentsPeriod] = result;
        this.router.navigate([], {
          queryParams: {
            startDate: format(appointmentsPeriod.startDate, 'yyyy-MM-dd'),
            endDate: format(appointmentsPeriod.endDate, 'yyyy-MM-dd'),
          },
        });
      });
  }
}
