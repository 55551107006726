<div class="relative">
  <t-card
    class="relative mb-2 block"
    *ngFor="let action of actions; index as i"
    [paddingSize]="'none'"
    [routerLink]="['/patients', patientId, 'actions', action.global_id]">
    <div class="flex flex-col justify-between p-3 md:flex-row md:items-center">
      <div class="flex items-center gap-x-4 md:w-1/2">
        <div class="bg-grey-10 h-[40px] w-[40px] rounded-full">
          <img [src]="action.fee_schedule_image" tImgFallback="/assets/media-call-center.svg" />
        </div>

        <div class="flex items-center gap-x-2">
          <span class="text-grey-100 block text-sm font-semibold">{{ action.title }}</span>
          <t-label *ngIf="action.unread_message_count > 0" size="tiny" color="error">
            {{ action.unread_message_count }}
          </t-label>
        </div>
      </div>

      <div
        class="border-t-grey-20 mt-4 flex items-center gap-x-8 border-t pt-4 md:mt-0 md:w-1/2 md:justify-end md:border-t-0 md:border-none md:pt-0">
        <t-label variant="pill" color="warning" size="tiny">{{ action.status }}</t-label>

        <span class="text-grey-50 block text-xs">
          {{ action.due_date | expirationDate }}
        </span>

        <t-label variant="pill" color="neutral" size="medium">
          {{ action.fee | currency: undefined : 'symbol' : '1.0-0' }}
        </t-label>
      </div>
    </div>
  </t-card>
</div>
