/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckBoxComponent } from './checkbox.component';

export interface CheckBoxOption {
  label: string;
  value: string;
  disabled?: true;
}

@Component({
  selector: 't-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
  imports: [CheckBoxComponent],
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  @Input() options: CheckBoxOption[] = [];
  @Input() label = '';
  value: any[] = [];
  disabled = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any[]): void {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: (value: any[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckboxChange(event: Event, optionValue: any): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      this.value = [...this.value, optionValue];
    } else {
      this.value = this.value.filter((v) => v !== optionValue);
    }

    this.onChange(this.value);
    this.onTouched();
  }

  isChecked(optionValue: any): boolean {
    return this.value.includes(optionValue);
  }
}
