import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { FormSubmissionDraftResponse } from '@troyai/portal/dynamic-forms/data-access';
import {
  ButtonComponent,
  CardComponent,
  EmptyStateCardComponent,
  LabelComponent,
} from '@troyai/ui-kit';

@Component({
  selector: 't-assessment-drafts-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    RouterModule,
    PharmacyIndicatorTooltipComponent,
    LabelComponent,
    ButtonComponent,
    EmptyStateCardComponent,
  ],
  templateUrl: './assessment-drafts-list.component.html',
})
export class AssessmentDraftsListComponent {
  @Input() items: FormSubmissionDraftResponse[] = [];
}
