<t-card shadowSize="large" paddingSize="none" [isInteractive]="false">
  <div class="border-b-grey-20 flex items-center justify-between border-b p-4">
    <t-button
      *ngIf="previousRoute; else noBackButton"
      (click)="handleBackNavigation()"
      variant="neutral"
      size="tiny"
      icon="keyboard-arrow-left"
      iconPos="left">
      Back
    </t-button>

    <div class="flex items-center space-x-4">
      <ng-content select="[headerContent]"></ng-content>
    </div>
  </div>

  <ng-template #noBackButton>
    <t-button
      (click)="handleBackNavigation()"
      variant="neutral"
      size="tiny"
      icon="close"
      iconPos="left">
      Close
    </t-button>
  </ng-template>

  <ng-content></ng-content>
</t-card>
