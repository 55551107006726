import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import { catchError, tap } from 'rxjs';
import { DiagnosesApiService } from '../diagnoses-api.service';
import { DiagnosesStateModel } from './diagnoses-state.model';
import { GetDiagnosesList } from './diagnoses.actions';

@State<DiagnosesStateModel>({
  name: 'diagnosesState',
  defaults: {
    diagnosesList: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
  },
})
@Injectable()
export class DiagnosesState {
  constructor(private diagnosesApiService: DiagnosesApiService) {}

  @Selector()
  static diagnosesList(state: DiagnosesStateModel) {
    return state.diagnosesList.result;
  }

  @Selector()
  static diagnosesListLoadingStatus(state: DiagnosesStateModel) {
    return getLoadingStatus(state.diagnosesList.loadingStatus);
  }

  @Action(GetDiagnosesList)
  getDiagnosesList({ patchState }: StateContext<DiagnosesStateModel>, action: GetDiagnosesList) {
    patchState({
      diagnosesList: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.diagnosesApiService.getDiagnoses(action.memberGlobalId).pipe(
      tap((diagnoses) => {
        patchState({
          diagnosesList: {
            result: diagnoses,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((error) => {
        patchState({
          diagnosesList: {
            result: null,
            loadingStatus: LoadingState.ERRORED,
          },
        });
        throw error;
      })
    );
  }
}
