import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { AddPatientFiles, PatientsState } from '@troyai/patients/data-access';
import {
  CustomDynamicFormControl,
  DynamicFormState,
  DynamicRenderedControls,
  PopulateDataFromControlEnrichment,
} from '@troyai/portal/dynamic-forms/data-access';
import {
  ButtonComponent,
  CheckboxGroupComponent,
  DatePickerInputComponent,
  FieldErrorComponent,
  InputAddressComponent,
  InputFullNameComponent,
  InputTextComponent,
  MatrixFormComponent,
  MinimalFileUploadComponent,
  RadioComponent,
  RepeatableFieldsComponent,
  SelectComponent,
} from '@troyai/ui-kit';
import { BehaviorSubject, tap, withLatestFrom } from 'rxjs';
import { FormRendererControlService } from '../../services/form-renderer-control.service';
import { DynamicEnrichmentComponent } from '../controls-enrichment/controls-enrichment.component';

@Component({
  selector: 't-dynamic-rendered-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextComponent,
    ButtonComponent,
    FieldErrorComponent,
    DatePickerInputComponent,
    RadioComponent,
    CheckboxGroupComponent,
    MatrixFormComponent,
    RepeatableFieldsComponent,
    SelectComponent,
    InputFullNameComponent,
    InputAddressComponent,
    DynamicEnrichmentComponent,
    MinimalFileUploadComponent,
  ],
  templateUrl: './dynamic-rendered-form.component.html',
})
export class DynamicRenderedFormComponent implements OnInit {
  constructor(
    private formControlService: FormRendererControlService,
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private store: Store
  ) {}

  @Input({ required: true }) formConfig: DynamicRenderedControls[] = [];
  @Output() formSubmit = new EventEmitter<Record<string, any>>();
  @Output() formSave = new EventEmitter<Record<string, any>>();

  addingFormSubmissionLoadingStatus$ = this.store.select(
    DynamicFormState.addingFormSubmissionLoadingStatus
  );
  errorsList$ = new BehaviorSubject<
    {
      errors: string[];
      controlName: string;
    }[]
  >([]);
  destroyRef = inject(DestroyRef);

  form!: FormGroup;

  ngOnInit() {
    const group: any = {};

    for (const controlConfig of this.formConfig) {
      const formControl = this.formControlService.toFormControl(controlConfig);
      if (formControl) {
        group[controlConfig.controlName] = formControl;
      }
    }
    this.form = new FormGroup(group);

    this.actions$
      .pipe(
        ofActionSuccessful(PopulateDataFromControlEnrichment),
        tap((data) => {
          const existingData =
            this.form.controls[data.formControlName].value.rows ||
            this.form.controls[data.formControlName].value;

          const newValue = data.data;
          const newDataToSet = existingData ? [...existingData, newValue] : [newValue];

          this.form.controls[data.formControlName].setValue(newDataToSet);
        })
      )
      .subscribe();

    // Anytime that a form value changes, react by removing errors from the errors listing
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.checkAndRemoveErrors();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  onSave() {
    const submissionData = this.formControlService.formControlsToJotformSubmission(
      this.form.controls,
      this.formConfig
    );

    this.formSave.emit(submissionData);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    const submissionData = this.formControlService.formControlsToJotformSubmission(
      this.form.controls,
      this.formConfig
    );

    if (this.form.valid) {
      this.formSubmit.emit(submissionData);
    } else {
      const errors = Object.keys(this.form.controls)
        .map((key) => {
          const control = this.form.get(key) as CustomDynamicFormControl;

          const errorMapping = {
            required: 'is required',
          } as Record<string, string>;

          if (control) {
            const controlErrors: ValidationErrors | null = control.errors;
            if (controlErrors != null) {
              const errorsTextList = Object.keys(controlErrors).map((keyError) => {
                return `Field "${control.fieldLabel}" ${errorMapping[keyError as string]}`;
              });

              return {
                controlName: key,
                errors: errorsTextList,
              };
            }
          }

          return null;
        })
        .filter((error) => !!error);

      if (errors) {
        this.errorsList$.next(
          errors as {
            errors: string[];
            controlName: string;
          }[]
        );
      }
    }
  }

  goToField(controlName: string) {
    const fieldToScrollTo = this.document.getElementById('wrapper_' + controlName);
    if (fieldToScrollTo) {
      fieldToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
      const innerField = fieldToScrollTo.querySelector('input');
      if (innerField) {
        innerField.focus();
      }
    }
  }

  checkAndRemoveErrors() {
    const errorsList = this.errorsList$.getValue();

    // Check each form control and remove errors if the field is valid
    Object.keys(this.form.controls).forEach((controlName) => {
      const control = this.form.get(controlName);

      if (control && control.valid) {
        // Remove the error for this controlName if it exists
        const updatedErrorsList = errorsList.filter((error) => error.controlName !== controlName);

        if (updatedErrorsList.length !== errorsList.length) {
          // Update the errors observable if any errors were removed
          this.errorsList$.next(updatedErrorsList);
        }
      }
    });
  }

  onFileUploaded(file: File, formControlName: string) {
    this.actions$
      .pipe(
        ofActionSuccessful(AddPatientFiles),
        withLatestFrom(this.store.select(PatientsState.uploadedPatientFilesList)),
        tap(([, uploadedFiles]) => {
          const file = uploadedFiles[0];
          this.form.get(formControlName)?.setValue(file.id);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.store.dispatch(
      new AddPatientFiles({
        memberGlobalId: 10631,
        files: [file],
      })
    );
  }
}
