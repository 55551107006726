import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { UserRoles } from '@troyai/auth/data-access';
import {
  AuthCallbackViewComponent,
  AuthenticatedGuard,
  EmailVerificationComponent,
  FeatureFlagsRedirectGuard,
  PublicGuard,
  RoleGuard,
} from '@troyai/auth/feature';
import {
  AccessDeniedShellComponent,
  LoginShellComponent,
  LogoutShellComponent,
} from '@troyai/auth/shell';
import { Layouts, setLayout } from '@troyai/layout';
import { OnboardingGuard } from '@troyai/onboarding/feature';

import { UserProfileState } from '@troyai/user-profile/data-access';

export const APP_ROUTES: Routes = [
  {
    path: 'patients',
    loadChildren: () => import('@troyai/patients/feature').then((m) => m.PATIENTS_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard()],
    data: {
      requiredRoles: [
        UserRoles.PharmacyUser,
        UserRoles.CMExternal,
        UserRoles.CMInternal,
        UserRoles.CareTeamHRA,
      ],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Patients',
  },
  {
    path: 'actions',
    loadChildren: () => import('@troyai/actions/feature').then((m) => m.ACTIONS_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard()],
    data: {
      requiredRoles: [UserRoles.PharmacyUser],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Actions',
  },

  {
    path: 'adherence',
    loadChildren: () => import('@troyai/adherence/feature').then((m) => m.ADHERENCE_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard()],
    data: {
      requiredRoles: [UserRoles.PharmacyUser],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Adherence',
  },
  {
    path: 'prior-authorization',
    loadChildren: () =>
      import('@troyai/prior-authorization/feature').then((m) => m.PRIOR_AUTHORIZATION_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard()],
    data: {
      requiredRoles: [UserRoles.PharmacyUser],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Prior Authorizations',
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('@troyai/leaderboard/feature').then((m) => m.LEADERBOARD_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard()],
    data: {
      requiredRoles: [UserRoles.PharmacyUser],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Leaderboard',
  },
  {
    path: 'resources',
    loadChildren: () => import('@troyai/resources/feature').then((m) => m.RESOURCES_ROUTES),
    canActivate: [AuthenticatedGuard, OnboardingGuard()],
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Resources',
  },
  {
    path: 'care',
    loadChildren: () => import('@troyai/portal/hra/hra-shell').then((m) => m.HRA_CARE_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard()],
    data: {
      requiredRoles: [UserRoles.CareTeamHRA, UserRoles.PharmacyUser, UserRoles.CMExternal],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Care',
  },
  {
    path: 'activity',
    loadChildren: () => import('@troyai/activity/shell').then((m) => m.ACTIVITY_ROUTES),
    canActivate: [AuthenticatedGuard],
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Activity',
  },
  {
    path: 'settings',
    loadChildren: () => import('@troyai/user-profile/feature').then((m) => m.USER_PROFILES_ROUTES),
    canActivate: [AuthenticatedGuard],
    providers: [importProvidersFrom(NgxsModule.forFeature([UserProfileState]))],
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Settings',
  },
  {
    path: 'notifications',
    loadChildren: () => import('@troyai/notifications/feature').then((m) => m.NOTIFICATIONS_ROUTES),
    canActivate: [AuthenticatedGuard, OnboardingGuard()],
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Notifications',
  },
  {
    path: 'login',
    component: LoginShellComponent,
    canActivate: [PublicGuard],
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Login',
  },
  {
    path: 'logout',
    component: LogoutShellComponent,
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    canActivate: [PublicGuard],
  },
  {
    path: 'forbidden',
    component: AccessDeniedShellComponent,
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Forbidden',
  },
  {
    path: 'onboarding',
    loadChildren: () => import('@troyai/onboarding/shell').then((m) => m.ONBOARDING_ROUTES),
    canActivate: [AuthenticatedGuard],
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Onboarding',
  },
  {
    path: 'auth-callback',
    component: AuthCallbackViewComponent,
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Authenticating...',
  },
  {
    path: 'check-email',
    component: EmailVerificationComponent,
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Check email',
  },
  {
    path: 'welcome/:base64InviteData',
    loadComponent: () =>
      import('@troyai/onboarding/shell').then((x) => x.WelcomeViewShellComponent),
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Welcome to Troy.AI',
  },
  {
    path: 'care-management',
    loadChildren: () =>
      import('@troyai/care-management/shell').then((m) => m.CARE_MANAGEMENT_ROUTES),
    canActivate: [AuthenticatedGuard, RoleGuard],
    data: {
      requiredRoles: [UserRoles.CMInternal, UserRoles.CMExternal],
    },
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    title: 'Care Management',
  },
  {
    path: 'download/:memberId/:fileId',
    loadComponent: () =>
      import('@troyai/patients/feature').then((m) => m.PatientFileDownloaderComponent),
    canActivate: [AuthenticatedGuard],
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    title: 'Download Patient File',
  },
  {
    path: '',
    loadChildren: () => import('@troyai/dashboard/feature').then((m) => m.DASHBOARD_ROUTES),
    resolve: {
      layout: setLayout(Layouts.Sidebar),
    },
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard, RoleGuard, OnboardingGuard(), FeatureFlagsRedirectGuard()],
    data: {
      requiredRoles: [UserRoles.PharmacyUser],
      rolesRoutesMapping: {
        [UserRoles.CMExternal]: '/care/patients',
        [UserRoles.CMInternal]: '/care-management/patients',
        [UserRoles.CareTeamHRA]: '/care/patients',
      },
      dashboardFeatureFallbackRoute: '/adherence',
    },
    title: 'Dashboard',
  },
  {
    path: '**',
    pathMatch: 'full',
    resolve: {
      layout: setLayout(Layouts.Minimal),
    },
    component: AccessDeniedShellComponent,
  },
];
