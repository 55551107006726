@if (selectedAction$ | async; as action) {
  @switch (action.reference_id) {
    @case ('HRA01') {
      <t-action-hra01-template [computedActionValues]="computedActionValues" />
    }

    @case ('CDPS01') {
      <t-action-cdps01-template [computedActionValues]="computedActionValues" />
    }

    @case ('FCMR01') {
      <t-action-fcmr01-template [computedActionValues]="computedActionValues" />
    }

    @case ('NPTH01') {
      <t-action-npth01-template [computedActionValues]="computedActionValues" />
    }

    @default {
      <t-action-default-template [computedActionValues]="computedActionValues" />
    }
  }
}
