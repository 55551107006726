<div *ngIf="action$ | async as action" class="space-y-3">
  <span class="text-grey-70 block text-xs uppercase tracking-wider">Schedule appointment:</span>
  <t-card class="block" [isInteractive]="false">
    <t-action-status-card [titleText]="data.timeslot.start_time | date: 'medium' : 'UTC'">
      <t-action-appointment-preview
        [action]="action"
        (canceledAppointment)="canceledAppointment()" />
    </t-action-status-card>
  </t-card>
</div>
