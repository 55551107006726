<label class="flex items-center">
  <input
    [disabled]="disabled"
    class="form-checkbox checked:bg-red-90 checked:hover:bg-red-90 checked:active:bg-red-90 checked:focus:bg-red-90 border-grey-40 focus:border-grey-40 focus:ring-red-30 disabled:border-grey-40 disabled:bg-green-20 mr-2.5 cursor-pointer rounded-sm bg-white accent-white focus:ring focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed"
    type="checkbox"
    [checked]="checked"
    (change)="onCheckBoxChange($event)" />
  <span class="text-grey-100 cursor-pointer text-sm" *ngIf="label; else contents">{{ label }}</span>
  <ng-template #contents>
    <div>
      <ng-content></ng-content>
    </div>
  </ng-template>
</label>
