import { Pipe, PipeTransform } from '@angular/core';
import Autolinker from 'autolinker';

@Pipe({ name: 'linkifyHtml', standalone: true })
export class LinkifyPipe implements PipeTransform {
  transform(value: string) {
    return Autolinker.link(value, {
      truncate: { length: 32, location: 'middle' },
    });
  }
}
