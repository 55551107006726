import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable()
export class PwaService {
  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        // Reload the page to update to the latest version.
        document.location.reload();
      });

    this.swUpdate.unrecoverable.subscribe((event) => {
      console.error('Unrecoverable error with the service worker', event);
      document.location.reload();
    });
  }
}
