import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadingState, getLoadingStatus } from '@troyai/shared/state';
import { sort } from 'fast-sort';
import { catchError, map, mergeMap, of, switchMap, tap, throwError } from 'rxjs';
import { CarePlansApiService } from '../care-plans-api.service';
import { CarePlanCreationSection, CarePlanStatus, CarePlanTemplateHraMeta } from '../models';
import { CarePlansStateModel } from './care-plans-state.model';
import {
  AssociateCaseNoteToCarePlan,
  CreateMemberCarePlan,
  GetAllCarePlansTemplates,
  GetAllMemberCarePlans,
  GetCarePlanChanges,
  GetSingleCarePlan,
  PublishMemberCarePlan,
  ResetWorkingDraftCarePlan,
  SelectCarePlanTemplate,
  SetCarePlanDetails,
  SetCarePlanForEditing,
  SetHraMetaForCarePlan,
  SetNextReviewDateForCarePlan,
  ToggleCarePlanSectionForEditing,
  UpdateMemberCarePlan,
} from './care-plans.actions';

const stateDefault: CarePlansStateModel = {
  memberCarePlans: {
    result: null,
    loadingStatus: LoadingState.LOADING,
  },
  carePlansTemplates: {
    result: null,
    loadingStatus: LoadingState.LOADING,
  },
  selectedCarePlanTemplate: null,
  selectedCarePlan: {
    result: null,
    loadingStatus: LoadingState.LOADING,
  },
  selectedCarePlanChanges: {
    result: null,
    loadingStatus: LoadingState.LOADING,
  },
  workingDraftCarePlan: null,
  carePlanCreationSections: [
    {
      id: 'goals',
      title: 'Goals',
      isOpen: false,
      icon: null,
      selectedValues: [],
      allowMultipleSelections: true,
    },
    {
      id: 'interventions',
      title: 'Interventions',
      isOpen: false,
      icon: null,
      selectedValues: [],
      allowMultipleSelections: true,
    },
    {
      id: 'barriers',
      title: 'Barriers',
      isOpen: false,
      icon: null,
      selectedValues: [],
      allowMultipleSelections: true,
    },
    {
      id: 'dateOfNextReview',
      title: 'Date of Next Review',
      isOpen: false,
      icon: 'event',
      selectedValues: [],
      allowMultipleSelections: true,
    },
    {
      id: 'carePlanDetails',
      title: 'Care Plan Details',
      isOpen: false,
      icon: 'article',
      selectedValues: [],
      allowMultipleSelections: true,
    },
  ],
};

@State<CarePlansStateModel>({
  name: 'carePlansState',
  defaults: stateDefault,
})
@Injectable()
export class CarePlansState {
  constructor(private carePlansApiService: CarePlansApiService) {}

  @Selector()
  static allMemberCarePlans(state: CarePlansStateModel) {
    return state.memberCarePlans.result?.filter((carePlan) => carePlan.status !== 'archived');
  }

  @Selector()
  static allMemberCarePlansLoadingStatus(state: CarePlansStateModel) {
    return getLoadingStatus(state.memberCarePlans.loadingStatus);
  }

  @Selector()
  static carePlansTemplates(state: CarePlansStateModel) {
    if (!state.carePlansTemplates.result) {
      return [];
    }

    return sort(state.carePlansTemplates.result).asc((u) => u.name);
  }

  @Selector()
  static carePlansTemplatesLoadingStatus(state: CarePlansStateModel) {
    return getLoadingStatus(state.carePlansTemplates.loadingStatus);
  }

  @Selector()
  static selectedCarePlan(state: CarePlansStateModel) {
    return state.selectedCarePlan.result;
  }

  @Selector()
  static selectedCarePlanLoadingStatus(state: CarePlansStateModel) {
    return getLoadingStatus(state.selectedCarePlan.loadingStatus);
  }

  @Selector()
  static workingDraftCarePlan(state: CarePlansStateModel) {
    return state.workingDraftCarePlan;
  }

  @Selector()
  static selectedCarePlanTemplate(state: CarePlansStateModel) {
    return state.selectedCarePlanTemplate;
  }

  @Selector()
  static selectedCarePlanChanges(state: CarePlansStateModel) {
    return state.selectedCarePlanChanges.result;
  }

  @Selector()
  static selectedCarePlanChangesLoadingStatus(state: CarePlansStateModel) {
    return getLoadingStatus(state.selectedCarePlanChanges.loadingStatus);
  }

  @Selector()
  static carePlanCreationSections(state: CarePlansStateModel) {
    return state.carePlanCreationSections;
  }

  @Selector()
  static carePlanTemplateGoals(state: CarePlansStateModel) {
    return state.selectedCarePlanTemplate?.hra_meta.filter((meta) => meta.type === 'goal');
  }

  @Selector()
  static carePlanTemplateInterventions(state: CarePlansStateModel) {
    return state.selectedCarePlanTemplate?.hra_meta.filter((meta) => meta.type === 'intervention');
  }

  @Selector()
  static carePlanTemplateBarriers(state: CarePlansStateModel) {
    return state.selectedCarePlanTemplate?.hra_meta.filter((meta) => meta.type === 'barrier');
  }

  @Action(GetAllMemberCarePlans)
  getAllMemberCarePlans(
    { patchState }: StateContext<CarePlansStateModel>,
    action: GetAllMemberCarePlans
  ) {
    patchState({
      memberCarePlans: {
        result: null,
        loadingStatus: action.payload.silently ? LoadingState.LOADED : LoadingState.LOADING,
      },
    });

    return this.carePlansApiService.getAllMemberCarePlans(action.payload.memberGlobalId).pipe(
      tap((allMemberCarePlans) => {
        patchState({
          memberCarePlans: {
            result: allMemberCarePlans,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((err) => {
        patchState({
          memberCarePlans: {
            result: [],
            loadingStatus: LoadingState.ERRORED,
          },
        });

        return throwError(() => err);
      })
    );
  }

  @Action(GetSingleCarePlan)
  getSingleCaseNote({ patchState }: StateContext<CarePlansStateModel>, action: GetSingleCarePlan) {
    patchState({
      selectedCarePlan: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.carePlansApiService
      .getMemberCarePlan(action.payload.memberGlobalId, action.payload.carePlanId)
      .pipe(
        tap((caseNote) =>
          patchState({
            selectedCarePlan: {
              result: caseNote,
              loadingStatus: LoadingState.LOADED,
            },
          })
        )
      );
  }

  @Action(GetAllCarePlansTemplates)
  getCarePlansTemplates({ patchState, getState }: StateContext<CarePlansStateModel>) {
    const { carePlansTemplates } = getState();

    if (carePlansTemplates.result) {
      return of(carePlansTemplates.result);
    }

    patchState({
      carePlansTemplates: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.carePlansApiService.getCarePlansTemplates().pipe(
      tap((templates) =>
        patchState({
          carePlansTemplates: {
            result: templates,
            loadingStatus: LoadingState.LOADED,
          },
        })
      )
    );
  }

  @Action(CreateMemberCarePlan)
  createMemberCarePlan(
    { patchState }: StateContext<CarePlansStateModel>,
    action: CreateMemberCarePlan
  ) {
    return this.carePlansApiService
      .createMemberCarePlan(action.payload.memberGlobalId, action.payload.data)
      .pipe(
        tap((carePlan) =>
          patchState({
            workingDraftCarePlan: {
              id: carePlan.id,
              last_modified: new Date().toString(),
            },
          })
        )
      );
  }

  @Action(PublishMemberCarePlan)
  publishMemberCarePlan(
    { patchState, getState }: StateContext<CarePlansStateModel>,
    action: PublishMemberCarePlan
  ) {
    const { workingDraftCarePlan } = getState();

    return of(workingDraftCarePlan).pipe(
      mergeMap((workingDraft) => {
        if (!action.payload.data.template_id) {
          return throwError(() => 'Template id is required');
        }

        if (workingDraft?.id) {
          return this.carePlansApiService
            .editMemberCarePlan(action.payload.memberGlobalId, workingDraft.id, {
              content: action.payload.data.content || '',
              subject: action.payload.data.subject || '',
              next_review_date: action.payload.data.next_review_date || '',
              status: CarePlanStatus.PUBLISHED,
              hra_meta: action.payload.data.hra_meta || [],
            })
            .pipe(map(() => workingDraft.id));
        } else {
          return this.carePlansApiService
            .createMemberCarePlan(action.payload.memberGlobalId, {
              content: action.payload.data.content || '',
              subject: action.payload.data.subject || '',
              type: action.payload.data.type || '',
              next_review_date: action.payload.data.next_review_date || '',
              template_id: action.payload.data.template_id,
              hra_meta: action.payload.data.hra_meta || [],
            })
            .pipe(
              switchMap((carePlan) =>
                this.carePlansApiService
                  .editMemberCarePlan(action.payload.memberGlobalId, carePlan.id, {
                    content: action.payload.data.content || '',
                    subject: action.payload.data.subject || '',
                    next_review_date: action.payload.data.next_review_date || '',
                    status: CarePlanStatus.PUBLISHED,
                    hra_meta: action.payload.data.hra_meta || [],
                  })
                  .pipe(map(() => carePlan.id))
              )
            );
        }
      }),
      mergeMap((carePlanId) =>
        this.carePlansApiService.getMemberCarePlan(action.payload.memberGlobalId, carePlanId)
      ),
      tap((carePlan) => {
        patchState({
          selectedCarePlan: {
            result: carePlan,
            loadingStatus: LoadingState.LOADED,
          },
          workingDraftCarePlan: null,
        });
      })
    );
  }

  @Action(UpdateMemberCarePlan)
  editMemberCarePlan(
    { patchState, getState }: StateContext<CarePlansStateModel>,
    action: UpdateMemberCarePlan
  ) {
    const { workingDraftCarePlan } = getState();

    return this.carePlansApiService
      .editMemberCarePlan(action.payload.memberGlobalId, action.payload.carePlanId, {
        content: action.payload.data.content,
        status: action.payload.data.status,
        subject: action.payload.data.subject,
        next_review_date: action.payload.data.next_review_date || '',
        hra_meta: action.payload.data.hra_meta || [],
      })
      .pipe(
        tap(() =>
          patchState({
            workingDraftCarePlan: {
              ...workingDraftCarePlan,
              content: action.payload.data.content,
              id: action.payload.carePlanId,
              last_modified: new Date().toString(),
            },
          })
        )
      );
  }

  @Action(SetCarePlanForEditing)
  setCarePlanForEditing(
    { patchState }: StateContext<CarePlansStateModel>,
    action: SetCarePlanForEditing
  ) {
    const carePlanBeingEdited = action.payload;
    const newCarePlanCreationSections = stateDefault.carePlanCreationSections.map((section) => {
      switch (section.id) {
        case 'goals': {
          const goals = carePlanBeingEdited.hra_meta.filter((meta) => meta.type === 'goal');
          return {
            ...section,
            selectedValues: goals,
          } as CarePlanCreationSection;
        }
        case 'interventions':
          return {
            ...section,
            selectedValues: action.payload.hra_meta.filter((meta) => meta.type === 'intervention'),
          } as CarePlanCreationSection;
        case 'barriers':
          return {
            ...section,
            selectedValues: action.payload.hra_meta.filter((meta) => meta.type === 'barrier'),
          } as CarePlanCreationSection;
        case 'dateOfNextReview':
          return {
            ...section,
            selectedValues: [action.payload.next_review_date],
          } as CarePlanCreationSection;
        case 'carePlanDetails':
          return {
            ...section,
            selectedValues: [action.payload.content],
          } as CarePlanCreationSection;
        default:
          return section;
      }
    });

    patchState({
      selectedCarePlan: {
        loadingStatus: LoadingState.INITIAL,
        result: null,
      },
      workingDraftCarePlan: action.payload,
      carePlanCreationSections: newCarePlanCreationSections,
    });
  }

  @Action(AssociateCaseNoteToCarePlan)
  associateCaseNoteToCarePlan(
    _ctx: StateContext<CarePlansStateModel>,
    action: AssociateCaseNoteToCarePlan
  ) {
    return this.carePlansApiService.associateCaseNoteToCarePlan(
      action.payload.memberGlobalId,
      action.payload.carePlanId,
      action.payload.caseNoteId
    );
  }

  @Action(SelectCarePlanTemplate)
  selectCarePlanTemplate(
    { patchState }: StateContext<CarePlansStateModel>,
    action: SelectCarePlanTemplate
  ) {
    patchState({
      selectedCarePlanTemplate: action.payload.template,
    });
  }

  @Action(ResetWorkingDraftCarePlan)
  resetWorkingDraftCarePlan({ patchState }: StateContext<CarePlansStateModel>) {
    patchState({
      workingDraftCarePlan: null,
      selectedCarePlanTemplate: null,
      selectedCarePlan: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
      carePlanCreationSections: stateDefault.carePlanCreationSections,
    });
  }

  @Action(GetCarePlanChanges)
  getCarePlanChanges(
    { patchState }: StateContext<CarePlansStateModel>,
    action: GetCarePlanChanges
  ) {
    patchState({
      selectedCarePlanChanges: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.carePlansApiService
      .getCarePlanChanges(action.payload.memberGlobalId, action.payload.carePlanId)
      .pipe(
        tap((changes) =>
          patchState({
            selectedCarePlanChanges: {
              result: changes,
              loadingStatus: LoadingState.LOADED,
            },
          })
        )
      );
  }

  @Action(ToggleCarePlanSectionForEditing)
  toggleCarePlanSectionForEditing(
    { patchState, getState }: StateContext<CarePlansStateModel>,
    action: ToggleCarePlanSectionForEditing
  ) {
    const { carePlanCreationSections } = getState();

    patchState({
      carePlanCreationSections: carePlanCreationSections.map((section) => {
        if (section.id === action.payload.sectionId) {
          return {
            ...section,
            isOpen: true,
          };
        } else {
          return {
            ...section,
            isOpen: false,
          };
        }
      }),
    });
  }

  @Action(SetHraMetaForCarePlan)
  addHraMetaToCarePlan(
    { patchState, getState }: StateContext<CarePlansStateModel>,
    action: SetHraMetaForCarePlan
  ) {
    const { carePlanCreationSections } = getState();

    const { hraMeta, section } = action.payload;

    if (!section.allowMultipleSelections) {
      patchState({
        carePlanCreationSections: carePlanCreationSections.map((section) => {
          if (section.id === action.payload.section.id) {
            return {
              ...section,
              selectedValues: [action.payload.hraMeta],
            } as CarePlanCreationSection;
          } else {
            return section;
          }
        }),
      });

      return;
    } else {
      const selectedValues = section.selectedValues as CarePlanTemplateHraMeta[];
      const isSelected = selectedValues.find((meta) => meta.id === hraMeta.id);

      if (isSelected) {
        patchState({
          carePlanCreationSections: carePlanCreationSections.map((section) => {
            if (section.id === action.payload.section.id) {
              const newSelectedValues = selectedValues.filter((meta) => meta.id !== hraMeta.id);

              return {
                ...section,
                selectedValues: newSelectedValues,
              };
            } else {
              return section;
            }
          }),
        });
      } else {
        patchState({
          carePlanCreationSections: carePlanCreationSections.map((section) => {
            if (section.id === action.payload.section.id) {
              const newSelectedValues = [...selectedValues, action.payload.hraMeta];

              return {
                ...section,
                selectedValues: newSelectedValues,
              };
            } else {
              return section;
            }
          }),
        });
      }
    }
  }

  @Action(SetNextReviewDateForCarePlan)
  setNextReviewDateForCarePlan(
    { patchState, getState }: StateContext<CarePlansStateModel>,
    action: SetNextReviewDateForCarePlan
  ) {
    const { carePlanCreationSections } = getState();

    patchState({
      carePlanCreationSections: carePlanCreationSections.map((section) => {
        if (section.id === 'dateOfNextReview') {
          return {
            ...section,
            selectedValues: [action.payload.date],
          };
        } else {
          return section;
        }
      }),
    });
  }

  @Action(SetCarePlanDetails)
  setCarePlanDetails(
    { patchState, getState }: StateContext<CarePlansStateModel>,
    action: SetCarePlanDetails
  ) {
    const { carePlanCreationSections } = getState();

    patchState({
      carePlanCreationSections: carePlanCreationSections.map((section) => {
        if (section.id === 'carePlanDetails') {
          return {
            ...section,
            selectedValues: [action.payload.content],
          };
        } else {
          return section;
        }
      }),
    });
  }
}
