import { NgModule } from '@angular/core';
import { ModalComponent } from './modal-component/modal.component';
import { DialogModule } from '@angular/cdk/dialog';
import { IconsModule } from '../icon/icon.module';
import { ModalService } from './modal.service';
import { CommonModule } from '@angular/common';
import { PromptModalComponent } from './prompt-modal-component/prompt-modal.component';

@NgModule({
  providers: [ModalService],
  imports: [CommonModule, DialogModule, IconsModule, ModalComponent, PromptModalComponent],
})
export class ModalModule {}
