export interface AdherenceOverview {
  bonus_amount: number;
  measures: AdherenceCategoryItem[];
}

export interface AdherenceCategoryItem {
  measure: string;
  member_adherent_percent: number;
  members_next_cutpoint: number;
  label: string;
  description: string;
  top_adherent_members: number;
  total_eligible_members: number;
}

export interface AdherencePatientMeasure {
  global_id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  measure: string;
  pharmacy_id: number;
  next_fill: number;
  percentage: number;
  dob: string;
  next_fill_date: string;
  last_fill_date: string;
  dispensable_name: string;
  adjusted_rx_end_date: string;
}

export interface AdherencePatientRxClaims {
  member_id: string;
  measure: string;
  ndc: string;
  dispensable_name: string;
  pharmacy_npi: string;
  pharmacy_name: string;
  claim_number: number;
  days_supply: number;
  processed_date: string;
  adjusted_rx_start_date: string;
  adjusted_rx_end_date: string;
  fill_order_number: number;
}

export interface AdherenceItemPatients {
  patients: AdherencePatientMeasure[];
  measure: string;
  percentage: number;
  top_adherent_members: number;
  total_eligible_members: number;
}

export enum AdherenceItemPatientsFilters {
  ALL = 'all',
  NEEDING_ATTENTION = 'needing_attention',
}
