<ng-container *ngIf="selectedPharmacy$ | async as selectedPharmacy">
  <ng-container *ngIf="pharmaciesList$ | async as pharmacies">
    <div class="shadow-large max-h-[70vh] min-w-[300px] overflow-y-auto rounded bg-white p-2">
      <ul class="space-y-2">
        <li
          *ngFor="let pharmacy of pharmacies"
          (click)="selectPharmacy(pharmacy)"
          class="text-grey-40 hover:bg-grey-20 flex cursor-pointer items-center justify-start gap-x-2.5 rounded px-3 py-2"
          [ngClass]="{
            '!text-green-90': pharmacy.global_id === selectedPharmacy.global_id
          }">
          <t-icon
            [key]="
              pharmacy.global_id === selectedPharmacy.global_id ? 'check-circle' : 'storefront'
            "
            color="inherit"
            size="tiny" />
          <span class="text-grey-70 text-sm">{{ pharmacy.name }}</span>
        </li>

        <li
          *ngIf="showAllPharmaciesOption"
          (click)="selectPharmacy(allPharmaciesOption)"
          class="text-grey-40 hover:bg-grey-20 border-grey-20 flex cursor-pointer items-center justify-start gap-x-2.5 rounded border-t px-3 py-2"
          [ngClass]="{
            '!text-green-90': selectedPharmacy.global_id === null
          }">
          <t-icon key="check" color="inherit" size="tiny" />
          <span class="text-grey-70 text-sm">All pharmacies</span>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-container>
