import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ActionIframeEmbedComponent } from '../action-iframe-embed/action-iframe-embed.component';

@Component({
  standalone: true,
  imports: [CommonModule, ActionIframeEmbedComponent],
  templateUrl: './action-embed-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEmbedModalComponent {
  constructor(@Inject(DIALOG_DATA) public formURL: string) {}
}
