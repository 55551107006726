/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';

@Component({
  selector: 't-editor',
  standalone: true,
  imports: [CommonModule, NgxEditorModule, FormsModule],
  templateUrl: './editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
  styleUrls: ['./editor.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  constructor(@Optional() private controlContainer: ControlContainer) {}

  @Input() formControl?: FormControl;
  @Input() formControlName = '';

  get control() {
    return (
      this.formControl ||
      (this.controlContainer?.control
        ? this.controlContainer?.control.get(this.formControlName)
        : null)
    );
  }

  hasError = false;
  htmlContent = '';
  toolbar: Toolbar = [['bold', 'italic', 'underline', 'strike'], ['link'], ['bullet_list']];
  editor!: Editor;

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.editor.setContent(value);
    this.htmlContent = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = (newValue: any) => {
      fn(newValue);

      if (this.control) {
        this.hasError =
          (this.control.touched || this.control.dirty) && this.control.errors !== null;
      }
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    this.editor = new Editor({
      content: '',
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
      plugins: [],
      nodeViews: {},
      attributes: {},
      linkValidationPattern: '',
    });
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
