<div class="space-y-1">
  <div
    class="text-grey-70 hover:bg-grey-15 group flex cursor-pointer items-center justify-between rounded-md px-3 py-2.5">
    <div class="flex items-center gap-x-3">
      @if (iconKey) {
        <t-icon [key]="iconKey" size="tiny" color="inherit" />
      }

      <span class="text-sm">
        <ng-content />
      </span>
    </div>
    <t-icon
      *ngIf="hasNestedMenu"
      key="keyboard-arrow-right"
      size="tiny"
      color="inherit"
      class="opacity-0 group-hover:opacity-100" />
  </div>
</div>
