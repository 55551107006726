import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule, TimelineComponent, TimelineEntryComponent } from '@troyai/ui-kit';
import { PatientEncounter } from '@troyai/patients/data-access';
import { EncounterCardComponent } from '../encounter-card/encounter-card.component';
import { format } from 'date-fns';

interface ExtendedPatientEncounter extends PatientEncounter {
  ongoing?: boolean;
  formattedTimeRange?: string;
}

@Component({
  selector: 't-encounters-timeline-listing',
  standalone: true,
  imports: [
    CommonModule,
    TimelineComponent,
    TimelineEntryComponent,
    IconsModule,
    EncounterCardComponent,
  ],
  templateUrl: './encounters-timeline-listing.component.html',
})
export class EncountersTimelineListingComponent {
  _encountersItems: ExtendedPatientEncounter[] = [];

  @Input() set encountersItems(value: ExtendedPatientEncounter[]) {
    this._encountersItems = value.map((encounter, idx) => {
      const encounterStartDate = new Date(encounter.encounter_time);
      const encounterEndDate = encounter.discharge_time ? new Date(encounter.discharge_time) : null;
      const ongoing = !encounterEndDate && idx === 0;
      let formattedTimeRange = `${format(encounterStartDate, 'MMM d, y')}`;

      if (ongoing) {
        formattedTimeRange = `${format(encounterStartDate, 'MMM d')} — Present`;
      }

      if (!ongoing && encounterEndDate) {
        formattedTimeRange = `${format(encounterStartDate, 'MMM d')} — ${format(
          encounterEndDate,
          'MMM d, y'
        )}`;
      }

      return {
        ...encounter,
        ongoing,
        formattedTimeRange,
      };
    });
  }

  get encountersItems(): ExtendedPatientEncounter[] {
    return this._encountersItems;
  }
}
