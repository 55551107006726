<ngx-editor
  placeholder="Add description..."
  [editor]="editor"
  [ngModel]="htmlContent"
  (ngModelChange)="onChange($event)"
  [ngClass]="{
    'ngx-editor-has-error': hasError
  }" />
<ngx-editor-floating-menu [editor]="editor">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" />
</ngx-editor-floating-menu>
