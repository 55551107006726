import { UserPharmacy } from '@troyai/auth/data-access';
import { OnboardingStep } from '../models/onboarding-step.interface';

export class CheckOnboardingSteps {
  static readonly type = '[Onboarding] Check onboarding steps';
}

export class CompleteOnboarding {
  static readonly type = '[Onboarding] Complete onboarding';
}

export class CheckDetailsAndAgreement {
  static readonly type = '[Onboarding] Check details and agreement';
}

export class CheckMOCTrainingStatus {
  static readonly type = '[Onboarding] Check MOC training status';
}

export class InitiateOnboardingState {
  static readonly type = '[Onboarding] Initiate onboarding state';
  constructor(public pharmacy?: UserPharmacy) {}
}

export class InitiatePlaidLink {
  static readonly type = '[Onboarding] Initiate Plaid Link';
  constructor(public pharmacyId: number) {}
}

export class SubmitPlaidPublicToken {
  static readonly type = '[Onboarding] Submit Plaid public token';
  constructor(
    public token: string,
    public pharmacyId: number
  ) {}
}

export class SetOnboardingStepStatus {
  static readonly type = '[Onboarding] Set onboarding step status';
  constructor(
    public stepId: string,
    public status: Partial<
      Record<keyof Pick<OnboardingStep, 'completed' | 'loading' | 'disabled'>, boolean>
    >
  ) {}
}

export class CompleteOnboardingStep {
  static readonly type = '[Onboarding] Complete onboarding step';
}

export class SetPreferredPaymentAccount {
  static readonly type = '[Onboarding] Set preferred payment account';
  constructor(public plaidAccountId: string) {}
}

export class SetOnboardingSteps {
  static readonly type = '[Onboarding] Set onboarding steps';
  constructor(public steps: OnboardingStep[]) {}
}

export class GetOnboardingAgreementData {
  static readonly type = '[Onboarding] Get onboarding agreement data';
  constructor(public tenant: string) {}
}
