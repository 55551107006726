<t-card [isInteractive]="false" paddingSize="none" [isFullHeight]="true" class="block h-full">
  <div class="flex h-full flex-col">
    <!-- Header -->
    <div class="border-grey-20 flex-none space-y-2 border-b p-4">
      <div class="flex justify-between">
        <span class="text-grey-100 text-xs">Message the Troy team</span>
        <div class="flex items-center gap-x-2">
          <div class="bg-green-80 h-1 w-1 rounded-full"></div>
          <span class="text-green-80 text-xs font-semibold">Online</span>
        </div>
      </div>

      <div class="text-grey-70 flex items-center gap-x-1.5 opacity-50">
        <span class="text-xs">Usually replies within 1 hour</span>
        <t-icon key="alarm-smart-wake" size="tiniest" color="inherit"></t-icon>
      </div>
    </div>
    <!-- Header -->

    <div #messagesList class="h-0 flex-auto overflow-y-auto">
      <div *ngIf="messagesLoading$ | async; else messageListTemplate" class="p-4">
        <t-skeleton-loader [rows]="5" rowsHeight="small" />
      </div>
    </div>

    <div class="flex-none">
      <t-message-form />
    </div>
  </div>
</t-card>

<ng-template #messageListTemplate>
  <ng-container *ngIf="messages$ | async as messages; else noMessagesState">
    <t-messages-list *ngIf="messages.length > 0; else noMessagesState" [items]="messages" />
  </ng-container>
</ng-template>

<ng-template #noMessagesState>
  <div class="flex h-full flex-col items-center justify-center px-10">
    <p class="text-grey-50 text-center text-base">
      Reach out with any questions or feedback about this patient.
    </p>
  </div>
</ng-template>
