import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { map } from 'rxjs';
import { ClinicalObservation } from './models/clinical-observation.interface';
import { NewClinicalObservationPayload } from './models/new-clinical-observation-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class ClinicalObservationsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getClinicalObservations(globalId: number) {
    return this.restApiService
      .get<ClinicalObservation[]>(`members/${globalId}/clinical-observations`)
      .pipe(
        map((clinicalObservations) => {
          if (!clinicalObservations || clinicalObservations.length === 0) {
            return [];
          }
          return clinicalObservations;
        })
      );
  }

  getSingleClinicalObservation(memberGlobalId: number, clinicalObservationId: number) {
    return this.restApiService.get<ClinicalObservation>(
      `members/${memberGlobalId}/clinical-observations/${clinicalObservationId}`
    );
  }

  addNewClinicalObservation(memberGlobalId: number, payload: NewClinicalObservationPayload) {
    return this.restApiService.post<ClinicalObservation>(
      `members/${memberGlobalId}/clinical-observations`,
      {
        test: payload.test,
        value: payload.value,
        comment: payload.comment,
        source: payload.source,
        date_of_service: payload.dateOfService,
      }
    );
  }

  editClinicalObservation(
    memberGlobalId: number,
    clinicalObservationId: number,
    payload: NewClinicalObservationPayload
  ) {
    return this.restApiService.patch<GenericRestApiOKResponse>(
      `members/${memberGlobalId}/clinical-observations/${clinicalObservationId}`,
      {
        test: payload.test,
        value: payload.value,
        comment: payload.comment,
        source: payload.source,
        date_of_service: payload.dateOfService,
      }
    );
  }
}
