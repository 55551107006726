<div class="relative">
  <a
    *ngFor="let hraAction of hraActions"
    [routerLink]="['/patients', hraAction.member_global_id, 'actions', hraAction.action_global_id]">
    <t-card class="mb-2 block">
      <div
        class="divide-grey-20 flex flex-col divide-y md:flex-row md:items-center md:justify-between md:divide-y-0">
        <!-- Patient name column -->
        <div class="pb-4 md:w-3/12 md:pb-0">
          <span class="text-grey-100 mb-2 block text-sm font-semibold">
            {{ hraAction | fullName | titlecase }}
          </span>
          <div class="text-grey-50 flex items-center gap-x-2">
            <t-patient-meta
              [metaData]="{
                dateOfBirth: hraAction.dob
              }"
              class="hover:text-grey-70" />
            <t-pharmacy-indicator-tooltip
              *ngIf="selectedPharmacyId === null"
              class="hover:text-grey-70"
              [pharmacyName]="hraAction.pharmacy_name" />
          </div>
        </div>
        <!-- /Patient name column -->

        <!-- Patient's enrollment column -->
        <div class="hidden py-4 md:block md:w-2/12 md:py-0">
          <div class="text-grey-70 mb-1.5 text-sm">
            <span class="text-grey-70 text-sm">
              {{ hraAction.plan_type }}
            </span>
          </div>
          <span class="text-grey-50 block text-xs">
            Enrolled: {{ hraAction.enrollment_date | date: 'mediumDate' }}
          </span>
        </div>
        <!-- /Patient's enrollment column -->

        <!-- Patient's pharmacy column -->
        <div class="space-y-1.5 py-4 md:w-2/12 md:py-0">
          <span class="text-grey-70 block truncate pr-2 text-xs">
            {{ hraAction.pharmacy_name }}
          </span>
          <div class="text-grey-50 flex space-x-1 text-xs" *ngIf="hraAction.pharmacy_phone">
            <span>{{ hraAction.pharmacy_phone | phone }}</span>
            <t-icon class="block" key="call" color="inherit" size="tiny"></t-icon>
          </div>
        </div>
        <!-- /Patient's pharmacy column -->

        <!-- Patient's contact column -->
        <div class="space-y-1.5 py-4 md:w-3/12 md:py-0">
          <div class="text-grey-50 flex space-x-1">
            <t-icon class="block" key="call" color="inherit" size="tiny"></t-icon>
            <t-list-expand
              class="block"
              [itemsCount]="hraAction.member_phone.length"
              *ngIf="hraAction.member_phone.length > 0">
              <ng-container initialValue>
                {{ hraAction.member_phone[0].phone_number | phone }}
              </ng-container>
              <ul class="shadow-large rounded bg-white">
                <li
                  *ngFor="let contact of hraAction.member_phone | slice: 1"
                  class="text-grey-50 flex cursor-pointer items-center space-x-1.5 px-3 py-2">
                  <t-icon key="call" color="inherit" size="tiny"></t-icon>
                  <span class="text-grey-70 text-sm">{{ contact.phone_number | phone }}</span>
                </li>
              </ul>
            </t-list-expand>
          </div>

          <div class="text-grey-50 flex space-x-1">
            <t-icon class="block" key="location-on" color="inherit" size="tiny"></t-icon>
            <t-list-expand
              class="block"
              [itemsCount]="hraAction.member_address.length"
              *ngIf="hraAction.member_address.length > 0">
              <ng-container initialValue>
                {{ hraAction.member_address[0].city }},
                {{ hraAction.member_address[0].zip_code }}
              </ng-container>
              <ul class="shadow-large rounded bg-white">
                <li
                  *ngFor="let address of hraAction.member_address | slice: 1"
                  class="text-grey-50 flex cursor-pointer items-center space-x-1.5 px-3 py-2">
                  <t-icon key="location-on" color="inherit" size="tiny"></t-icon>
                  <span class="text-grey-70 text-sm">
                    {{ address.city }} {{ address.state }}, {{ address.zip_code }}
                  </span>
                </li>
              </ul>
            </t-list-expand>
          </div>
        </div>
        <!-- /Patient's contact column -->

        <!-- Patient status column-->
        <div class="flex items-center justify-between pt-4 md:w-2/12 md:pt-0">
          <div class="md:1/2 flex justify-start">
            <t-label variant="pill" color="success" size="small">{{ hraAction.status }}</t-label>
          </div>
          <div class-="md:1/2">
            <t-button variant="neutral" size="tiny">VIEW</t-button>
          </div>
        </div>
        <!-- /Patient status column-->
      </div>
    </t-card>
  </a>
</div>
