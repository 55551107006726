export class SetupNotificationsToken {
  static readonly type = '[Global] Setup notifications token';
}

export class SetupWebSocketConnection {
  static readonly type = '[Global] Setup notifications web socket connection';
}

export class SetupGraphQLConnection {
  static readonly type = '[Global] Setup notifications graphQL connection';
}

export class NotificationsSetupReady {
  static readonly type = '[Global] Setup notifications is ready';
}

export class GetNotificationsCount {
  static readonly type = '[Global] Get notifications count';
}

export class GetNotificationsList {
  static readonly type = '[Notifications] Get notifications list';
}

export class NotificationReceived {
  static readonly type = '[Global] Notification received';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: { notificationData: any }) {}
}

export class MarkAllNotificationsAsRead {
  static readonly type = '[Notifications] Mark all notifications as read';
}

export class MarkNotificationAsRead {
  static readonly type = '[Notifications] Mark notification as read';
  constructor(public payload: { id: string }) {}
}
