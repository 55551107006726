import { EnrichedLeaderboardItem } from '../models/leaderboard-item.interface';

/* Sort by actions completed, then by whether the user is associated with the pharmacy
  This is to ensure that the user's pharmacy is always at the top, 
  even if there are other pharmacies with the same number of actions completed.
  */
export const rankLeaderboardItems = (leaderboardItems: EnrichedLeaderboardItem[]) => {
  return leaderboardItems
    .sort(
      (a, b) =>
        b.actions_completed - a.actions_completed ||
        Number(b.hasCurrentUserAssociated) - Number(a.hasCurrentUserAssociated)
    )
    .map((item, index) => ({ ...item, rank: index + 1 }));
};
