import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { LoadingState } from '@troyai/shared/state';
import { tap } from 'rxjs';
import { ResourcesApiService } from '../resources-api.service';
import { ResourcesStateModel } from './resources-state.model';
import { GetResourcesList, GetSignedDownloadURL } from './resources.actions';

@State<ResourcesStateModel>({
  name: 'resourcesState',
  defaults: {
    resources: {
      loadingStatus: LoadingState.INITIAL,
      result: [],
    },
  },
})
@Injectable()
export class ResourcesState {
  constructor(private resourcesApiService: ResourcesApiService) {}

  @Selector()
  static resourcesList(state: ResourcesStateModel) {
    return state.resources.result;
  }

  @Selector()
  static resourcesLoadingStatus(state: ResourcesStateModel) {
    return {
      loading: state.resources.loadingStatus === LoadingState.LOADING,
      loaded: state.resources.loadingStatus === LoadingState.LOADED,
      errored: state.resources.loadingStatus === LoadingState.ERRORED,
    };
  }

  @Action(GetResourcesList)
  getResourcesList({ setState, patchState }: StateContext<ResourcesStateModel>) {
    patchState({
      resources: {
        loadingStatus: LoadingState.LOADING,
        result: [],
      },
    });
    return this.resourcesApiService.getAll().pipe(
      tap((allResources) =>
        setState(
          patch<ResourcesStateModel>({
            resources: {
              loadingStatus: LoadingState.LOADED,
              result: allResources,
            },
          })
        )
      )
    );
  }

  @Action(GetSignedDownloadURL)
  getSignedDownloadURL(_ctx: StateContext<ResourcesStateModel>, { payload }: GetSignedDownloadURL) {
    return this.resourcesApiService
      .getSignedDownloadURL(payload.resourceId)
      .pipe(tap((signedURL) => window.open(signedURL)));
  }
}
