import { DestroyRef, Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { TrackService } from './tracking.service';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TrackEvent } from './models/track-event.interface';

@Directive({
  selector: '[tTrack]',
  standalone: true,
})
export class TrackDirective implements OnInit {
  @Input() trackOn: 'click' | 'focus' | 'blur' = 'click';
  @Input({ required: true }) tTrack!: TrackEvent;

  constructor(
    private element: ElementRef,
    private trackService: TrackService
  ) {}

  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    fromEvent(this.element.nativeElement, this.trackOn)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.trackService.trackEvent(this.tTrack);
      });
  }
}
