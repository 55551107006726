import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UserPharmacy, UserRoles } from '@troyai/auth/data-access';
import { RolesDirective } from '@troyai/auth/feature';
import { PharmacyListComponent } from '@troyai/pharmacy-context/feature';
import { IconsModule, PopoverModule } from '@troyai/ui-kit';

@Component({
  selector: 't-onboarding-layout',
  standalone: true,
  imports: [CommonModule, IconsModule, PopoverModule, PharmacyListComponent, RolesDirective],
  templateUrl: './onboarding-layout.component.html',
})
export class OnboardingLayoutComponent {
  @Input() selectedPharmacy: UserPharmacy | null = null;

  userRoles = UserRoles;
}
