<a *ngFor="let action of actions; index as i" class="relative block">
  <t-card class="relative mb-2 block">
    <div class="flex flex-col md:flex-row md:items-center">
      <div class="flex flex-col items-start md:w-4/12 md:flex-row md:items-center">
        <div class="bg-grey-10 h-[60px] w-[60px] flex-none rounded-full">
          <img [src]="action.fee_schedule_image" tImgFallback="/assets/media-call-center.svg" />
        </div>

        <div class="w-1/2 space-y-1.5 pt-4 md:ml-5 md:w-auto md:pt-0">
          <span class="text-grey-100 block text-sm font-semibold">
            {{ action.reference_id | ongoingCareActionTitle }}
          </span>
          <span class="text-grey-70 block text-xs"> Ongoing Care </span>
        </div>
      </div>

      <div
        class="border-t-grey-20 mt-4 flex items-center justify-end border-t pt-4 md:mt-0 md:w-8/12 md:border-t-0 md:border-none md:pt-0">
        <t-button
          (click)="triggerActionSelection(action.form_settings)"
          variant="neutral"
          size="tiny">
          OPEN
        </t-button>
      </div>
    </div>
  </t-card>
</a>
