import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PAGE_TITLE_SUFFIX } from './page-title.config';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
  constructor(private titleService: Title) {}

  getTitle() {
    this.titleService.getTitle();
  }
  setTitle(newTitle: string) {
    this.titleService.setTitle(`${newTitle}${PAGE_TITLE_SUFFIX}`);
  }
}
