import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { parseISO } from 'date-fns';

@Pipe({
  name: 'timeInterval',
  standalone: true,
  pure: true,
})
export class TimeIntervalPipe implements PipeTransform {
  private readonly TIME_INTERVAL_FORMAT = 'h:mm a';

  transform(startTime: string, endTime: string): string {
    const parsedStartTime = parseISO(startTime);
    const parsedEndTime = parseISO(endTime);

    return (
      new DatePipe('en-US').transform(parsedStartTime, this.TIME_INTERVAL_FORMAT, 'UTC') +
      ' - ' +
      new DatePipe('en-US').transform(parsedEndTime, this.TIME_INTERVAL_FORMAT, 'UTC')
    );
  }
}
