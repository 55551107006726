<div
  *ngIf="actions.length; else noNewActionsAvailable"
  class="shadow-small relative max-w-full rounded-lg bg-white">
  <div class="p-6">
    <!-- Featured actions -->
    <div *ngIf="featuredActionGroups.length > 0" class="space-y-8 pb-8">
      <div class="flex items-center space-x-3">
        <strong class="text-grey-70 text-xs font-semibold tracking-wider">
          RECOMMENDED ACTIONS
        </strong>
        <t-label variant="pill" color="warning" size="tiny" paddingSize="small">
          {{ featuredActionsCount }}
        </t-label>
      </div>

      <div class="divide-grey-20 w-full divide-y">
        <a
          *ngFor="let actionGroup of featuredActionGroups"
          [routerLink]="['/actions']"
          [queryParams]="{
            filter: queryObjectToUrl.stringify([{ status: ['New'], type: [actionGroup.name] }])
          }"
          class="block [&:not(:last-child)]:mb-3">
          <t-actions-set-carousel-item [isFeatured]="true" [actionGroup]="actionGroup" />
        </a>
      </div>
    </div>
    <!-- /Featured actions -->

    <!-- Regular actions -->
    <div class="flex items-center space-x-3 pb-8">
      <strong class="text-grey-70 text-xs font-semibold tracking-wider">
        {{ featuredActionGroups.length > 0 ? 'OTHER NEW ACTIONS' : 'NEW ACTIONS' }}
      </strong>
      <t-label variant="pill" color="warning" size="tiny" paddingSize="small">
        {{ regularActionsCount }}
      </t-label>
    </div>

    <div class="wrapper flex snap-x flex-nowrap overflow-x-scroll" #container>
      <div
        *ngFor="let actionGroupBucket of actionGroupsBuckets"
        class="item divide-grey-20 w-full flex-none snap-center divide-y">
        <div *ngFor="let actionGroup of actionGroupBucket">
          <a
            [routerLink]="['/actions']"
            [queryParams]="{
              filter: queryObjectToUrl.stringify([{ status: ['New'], type: [actionGroup.name] }])
            }">
            <t-actions-set-carousel-item [actionGroup]="actionGroup" />
          </a>
        </div>
      </div>
    </div>
    <!-- /Regular actions -->
  </div>

  <div
    class="shadow-small border-grey-20 flex items-center rounded-b-lg border-t bg-white px-6 py-5"
    [ngClass]="{
      'justify-between': actionGroupsBuckets.length > 1,
      'justify-end': actionGroupsBuckets.length <= 1
    }">
    <div *ngIf="actionGroupsBuckets.length > 1" class="hidden space-x-2 lg:flex">
      <t-button
        (onClick)="updateViewport('left')"
        variant="neutral"
        size="tiny"
        icon="keyboard-arrow-left"></t-button>
      <t-button
        (onClick)="updateViewport('right')"
        variant="neutral"
        size="tiny"
        icon="keyboard-arrow-right"></t-button>
    </div>

    <!-- TODO: Replace hardcoded query params with JSON URL -->
    <a [routerLink]="['/actions']" [queryParams]="{ filter: '((status:(New)))' }">
      <t-button variant="neutral" size="tiny"> VIEW ALL ACTIONS </t-button>
    </a>
  </div>
</div>

<ng-template #noNewActionsAvailable>
  <div class="bg-grey-20 shadow-small rounded-lg p-6">
    <div class="text-grey-60 py-8 pb-[56px] text-center text-sm">
      <p>There are no new actions for this patient.</p>
    </div>
    <t-card paddingSize="none" [isInteractive]="false">
      <div class="p-6">
        <t-label size="tiny" color="warning">Note</t-label>
        <p class="text-grey-60 mr-1 mt-4 block text-sm lg:inline-block">
          Do you have any suggestions for other types of actions?
        </p>
        <t-link class="inline" size="large">Send us a message.</t-link>
      </div>
    </t-card>
  </div>
</ng-template>
