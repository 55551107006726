import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import JsonURL from '@jsonurl/jsonurl';
import { sort } from 'fast-sort';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { filterByProperties } from '@troyai/shared/utils';
import { Filter, FilterDimension, TimeIntervalFilter } from '../models/filters.model';
import { DataSourceListingConfig, Page, Pagination, SortOptionValue } from '../models/page.model';

@Injectable({
  providedIn: 'root',
})
export class DataSourceListingService {
  constructor(
    private router?: Router,
    private route?: ActivatedRoute
  ) {}

  generateListingEntries = <T>(
    items: T[],
    sort: SortOptionValue<T>[],
    filters: Filter<T>[],
    search: string,
    pagination: Pagination,
    config: DataSourceListingConfig<T>
  ): Observable<Page<T>> => {
    const resultsBySearchText = search
      ? filterByProperties(items, config.searchableProperties as string[], search)
      : items;

    const filteredResults = this.applyFilters(resultsBySearchText, filters);
    const sortedResults = this.applySorting(filteredResults, sort);
    const paginatedResults = this.applyPagination(sortedResults, pagination);

    this.updateQueryParams(filters, sort, pagination);

    const page = {
      items: paginatedResults,
      pageNumber: pagination.page,
      pageSize: paginatedResults.length,
      totalElements: filteredResults.length,
    };

    return of(page).pipe(delay(100));
  };

  updateQueryParams = <T>(
    filters: Filter<T>[],
    sortOptions?: SortOptionValue<T>[],
    pagination?: Pagination
  ) => {
    const queryParams: Params = {};

    // Update sorting query params
    if (sortOptions) {
      const activeSortOption = sortOptions.find((option) => option.isActive);

      if (activeSortOption) {
        const sortBy = activeSortOption.config.value.keyName;
        const sortDirection = activeSortOption.config.value.order;

        const sortingQueryParams = {
          [sortBy as string]: sortDirection,
        };

        // TODO: Replace with util function
        queryParams['sort'] = JsonURL.stringify(sortingQueryParams, { AQF: true });
      }
    }

    // Update filter query params
    if (filters.length) {
      const filtersQueryParams = filters
        .map((filter) => {
          const values = filter.values.filter((item) => item.isActive).map((item) => item.id);
          if (values.length === 0) return;

          return {
            [filter.config.id]: values,
          };
        })
        .filter((item) => !!item);

      // TODO: Replace with util function
      queryParams['filter'] = filtersQueryParams.length
        ? JsonURL.stringify(filtersQueryParams, { AQF: true })
        : null;
    }

    if (pagination) {
      queryParams['page'] = pagination.page > 1 ? pagination.page : null;
    }

    if (this.router) {
      this.router.navigate([], {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  };

  applyPagination = <T>(items: T[], pagination: Pagination): T[] => {
    // -1 is subtraction because the page starts from 1, and the slice starts from 0
    const start = Math.max(0, (pagination.page - 1) * pagination.size);
    const end = start + pagination.size;
    return items.slice(start, end);
  };

  applySorting = <T>(items: T[], sortOptions?: SortOptionValue<T>[]) => {
    if (!sortOptions) return items;

    const activeSortOption = sortOptions.find((option) => option.isActive);

    if (!activeSortOption) return items;

    const sortBy = activeSortOption.config.value.keyName;
    const sortDirection = activeSortOption.config.value.order;

    if (!sortBy || !sortDirection) return items;

    return sortDirection === 'asc'
      ? sort(items).asc((u) => u[sortBy])
      : sort(items).desc((u) => u[sortBy]);
  };

  applyFilters = <T>(items: T[], filters: Filter<T>[]) => {
    if (!items.length || !filters.length) {
      return items;
    }

    const filterDimensions: FilterDimension<T>[] = filters.map((filter) => {
      return {
        values: filter.values.filter((item) => item.isActive).map((item) => item.id),
        keyName: filter.config.keyName,
        filter: filter,
      };
    });

    return items.filter((item) => {
      return filterDimensions.every((filterItem) => {
        if (!filterItem.values.length) return true;

        if (filterItem.filter.config.valuesBehavior === 'direct') {
          if (!filterItem.filter.config.nestedPropertyPath) {
            // Handle grouping prefix differently than regular filters
            if (filterItem.filter.config.groupingPrefix) {
              const itemValue = item[filterItem.keyName] as string;
              return filterItem.values.some((value) => itemValue.startsWith(value));
            } else {
              return filterItem.values.includes(item[filterItem.keyName] as string);
            }
          } else {
            const filterValuesSet = new Set(filterItem.values);
            const itemsToFilter = item[filterItem.keyName];
            if (Array.isArray(itemsToFilter)) {
              const itemValuesSet = new Set(
                itemsToFilter.map(
                  (itemToFilter) =>
                    itemToFilter[filterItem.filter.config.nestedPropertyPath as string]
                )
              );

              const intersection = new Set(
                [...filterValuesSet].filter((x) => itemValuesSet.has(x))
              );
              return intersection.size > 0;
            }
          }
        }

        if (filterItem.filter.config.valuesBehavior === 'time-interval') {
          const itemDateLimit = Date.parse(item[filterItem.keyName] as string);

          for (const item of filterItem.values) {
            const filterTimeBrackets = filterItem.filter.config.customValues?.find(
              (value) => value.label === item
            );

            const filterConfig = filterTimeBrackets?.config as TimeIntervalFilter;
            const startDate = filterConfig.startDate;
            const endDate = filterConfig.endDate;

            return itemDateLimit >= startDate && itemDateLimit < endDate;
          }
        }

        return true;
      });
    });
  };
}
