import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { CarePlanTemplate } from './models/care-plan-template.interface';
import {
  CarePlanChanges,
  CreateMemberCarePlanPayload,
  CreateMemberCarePlanResponse,
  MemberCarePlan,
  PatchMemberCarePlanPayload,
} from './models/member-care-plan.interface';

@Injectable({
  providedIn: 'root',
})
export class CarePlansApiService {
  constructor(private restApiService: RestApiClientService) {}

  getCarePlansTemplates() {
    return this.restApiService.get<CarePlanTemplate[]>('care-plan-templates');
  }

  getAllMemberCarePlans(memberGlobalId: number) {
    return this.restApiService.get<MemberCarePlan[]>(`members/${memberGlobalId}/care-plans`);
  }

  getMemberCarePlan(memberGlobalId: number, carePlanId: number) {
    return this.restApiService.get<MemberCarePlan>(
      `members/${memberGlobalId}/care-plans/${carePlanId}`
    );
  }

  createMemberCarePlan(memberGlobalId: number, payload: CreateMemberCarePlanPayload) {
    return this.restApiService.post<CreateMemberCarePlanResponse>(
      `members/${memberGlobalId}/care-plans`,
      payload
    );
  }

  editMemberCarePlan(
    memberGlobalId: number,
    carePlanId: number,
    payload: PatchMemberCarePlanPayload
  ) {
    return this.restApiService.patch(`members/${memberGlobalId}/care-plans/${carePlanId}`, payload);
  }

  associateCaseNoteToCarePlan(memberGlobalId: number, carePlanId: number, caseNoteId: number) {
    return this.restApiService.put<GenericRestApiOKResponse>(
      `members/${memberGlobalId}/care-plans/${carePlanId}/case-note/${caseNoteId}`,
      {}
    );
  }

  unAssociateCaseNoteFromCarePlan(memberGlobalId: number, carePlanId: number, caseNoteId: number) {
    return this.restApiService.delete<GenericRestApiOKResponse>(
      `members/${memberGlobalId}/care-plans/${carePlanId}/case-note/${caseNoteId}`
    );
  }

  getCarePlanChanges(memberGlobalId: number, carePlanId: number) {
    return this.restApiService.get<CarePlanChanges[]>(
      `members/${memberGlobalId}/care-plans/${carePlanId}/changes`
    );
  }
}
