/* eslint-disable @typescript-eslint/no-explicit-any */
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Action, ActionNativeFormResources, ActionsState } from '@troyai/actions/data-access';
import { UserDetails, UserState } from '@troyai/auth/data-access';
import { Patient, PatientsState } from '@troyai/patients/data-access';
import {
  AddNewFormSubmission,
  DynamicFormState,
  GetDynamicFormData,
  GetFormSubmissionContents,
  UpdateFormSubmission,
} from '@troyai/portal/dynamic-forms/data-access';
import { DynamicRenderedFormComponent } from '@troyai/portal/dynamic-forms/feature';
import { ErrorStateComponent, ModalService, SkeletonLoaderComponent } from '@troyai/ui-kit';
import { getDate, getMonth, getYear, parseISO } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, filter, map, take, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 't-hra-form-modal',
  standalone: true,
  imports: [
    CommonModule,
    DynamicRenderedFormComponent,
    SkeletonLoaderComponent,
    ErrorStateComponent,
  ],
  templateUrl: './hra-form-modal.component.html',
})
export class HraFormModalComponent implements OnInit {
  constructor(
    private store: Store,
    private toastrService: ToastrService,
    private actions$: Actions,
    private modalService: ModalService,
    @Inject(DIALOG_DATA) private actionNativeFormResources: ActionNativeFormResources
  ) {}

  private jotformId = this.actionNativeFormResources.id;

  currentDynamicFormData$ = this.store.select(DynamicFormState.currentDynamicFormData);
  currentDynamicFormDataLoadingStatus$ = this.store.select(
    DynamicFormState.currentDynamicFormDataLoadingStatus
  );
  currentSubmissionId$ = this.store.select(DynamicFormState.currentSubmissionId);
  selectedAction$ = this.store.select(ActionsState.selectedAction);
  selectedPatient$ = this.store.select(PatientsState.selectedPatient);
  currentUserDetails$ = this.store.select(UserState.userDetails);

  onFormSubmit(submissionData: Record<string, any>) {
    this.modalService.openGenericPromptDialog({
      cancelButtonText: 'Continue editing',
      confirmButtonText: 'Submit',
      promptMessage:
        'This will save the final assessment. Any other updates will be required to be added through an addendum',
      promptTitle: 'Submit final assessment',
      onPromptConfirm: () => {
        // Submission successful, close all modals
        this.actions$
          .pipe(
            ofActionSuccessful(UpdateFormSubmission, AddNewFormSubmission),
            tap(() => {
              this.modalService.closeAll();
            }),
            take(1)
          )
          .subscribe();

        combineLatest([
          this.selectedAction$,
          this.selectedPatient$,
          this.currentSubmissionId$,
          this.currentUserDetails$,
        ])
          .pipe(
            filter(([action, patient]) => {
              return !!action && !!patient;
            }),
            map(([action, patient, submissionId, currentUserDetails]) => {
              return {
                action,
                patient,
                submissionId,
                currentUserDetails,
              } as {
                action: Action;
                patient: Patient;
                submissionId: number | null;
                currentUserDetails: UserDetails;
              };
            }),
            tap((data) => {
              if (data.submissionId) {
                this.store.dispatch(
                  new UpdateFormSubmission(data.submissionId, {
                    final_submission: true,
                    content: submissionData,
                    action_global_id: data.action.global_id,
                    member_global_id: data.patient.global_id,
                    form_id: this.jotformId,
                    form_provider: 'jotform',
                    submitted_by: data.currentUserDetails.email,
                  })
                );
              } else {
                this.store.dispatch(
                  new AddNewFormSubmission(
                    {
                      content: submissionData,
                      action_global_id: data.action.global_id,
                      member_global_id: data.patient.global_id,
                      form_id: this.jotformId,
                      form_provider: 'jotform',
                      submitted_by: data.currentUserDetails.email,
                    },
                    true
                  )
                );
              }
            }),
            take(1)
          )
          .subscribe();
      },
    });
  }

  onFormSave(submissionData: Record<string, any>) {
    this.actions$
      .pipe(ofActionSuccessful(AddNewFormSubmission, UpdateFormSubmission))
      .pipe(
        tap((action) => {
          if (action instanceof AddNewFormSubmission) {
            this.toastrService.success('New assessment draft created');
          }

          if (action instanceof UpdateFormSubmission) {
            this.toastrService.success('Assessment draft saved');
          }
        }),
        take(1)
      )
      .subscribe();

    combineLatest([
      this.selectedAction$,
      this.selectedPatient$,
      this.currentSubmissionId$,
      this.currentUserDetails$,
    ])
      .pipe(
        filter(([action, patient]) => {
          return !!action && !!patient;
        }),
        map(([action, patient, submissionId, currentUserDetails]) => {
          return {
            action,
            patient,
            submissionId,
            currentUserDetails,
          } as {
            action: Action;
            patient: Patient;
            submissionId: number | null;
            currentUserDetails: UserDetails;
          };
        }),
        tap((data) => {
          if (data.submissionId) {
            this.store.dispatch(
              new UpdateFormSubmission(data.submissionId, {
                final_submission: false,
                content: submissionData,
                action_global_id: data.action.global_id,
                member_global_id: data.patient.global_id,
                form_id: this.jotformId,
                form_provider: 'jotform',
                submitted_by: data.currentUserDetails.email,
              })
            );
          } else {
            this.store.dispatch(
              new AddNewFormSubmission({
                content: submissionData,
                action_global_id: data.action.global_id,
                member_global_id: data.patient.global_id,
                form_id: this.jotformId,
                form_provider: 'jotform',
                submitted_by: data.currentUserDetails.email,
              })
            );
          }
        }),
        take(1)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.selectedAction$
      .pipe(
        withLatestFrom(this.currentUserDetails$, this.selectedPatient$),
        tap(([action, currentUserDetails, patient]) => {
          const submissionId = action?.latest_form_submission_id;

          // Get data about the patient and format it so it can be prefilled
          const memberAddress = patient?.member_address.find(
            (address) => address.address_type === 'Physical'
          );

          const prefilledAddress = memberAddress
            ? {
                city: memberAddress.city,
                state: memberAddress.state,
                postal: memberAddress.zip_code,
                addr_line1: memberAddress.address_line_1,
                addr_line2: memberAddress.address_line_2 || '',
              }
            : {};

          const dateOfBirth = patient?.dob ? parseISO(patient?.dob) : null;
          const dateOfBirthPrefill = dateOfBirth
            ? {
                day: getDate(dateOfBirth),
                month: getMonth(dateOfBirth) + 1,
                year: getYear(dateOfBirth),
              }
            : {};

          const actionToDispatch = submissionId
            ? new GetFormSubmissionContents(submissionId)
            : new GetDynamicFormData(this.jotformId, {
                email: currentUserDetails?.email || '',
                memberId: String(action?.member_assigned_to),
                actionId: String(action?.global_id),
                membersName: {
                  first: patient?.first_name || '',
                  last: patient?.last_name || '',
                },
                membersAddress: prefilledAddress,
                membersDate: dateOfBirthPrefill,
              });

          this.store.dispatch(actionToDispatch);
        }),
        take(1)
      )
      .subscribe();
  }
}
