<div class="relative">
  <a *ngFor="let patient of patients" [routerLink]="['/patients', patient.global_id]">
    <t-card class="mb-2 block">
      <div
        class="divide-grey-20 flex flex-col divide-y md:flex-row md:items-center md:justify-between md:divide-y-0">
        <div class="space-y-2 pb-4 md:w-4/12 md:pb-0">
          <span class="text-grey-100 block text-sm font-semibold">
            {{ patient | fullName | titlecase }}
          </span>
          <div class="text-grey-50 flex items-center gap-x-2">
            <t-patient-meta
              [metaData]="{
                dateOfBirth: patient.dob,
                dateOfDeath: patient.deceased_on,
                gender: patient.gender
              }" />
            <t-pharmacy-indicator-tooltip
              *ngIf="selectedPharmacyId === null"
              class="hover:text-grey-70"
              [pharmacyName]="patient.pharmacy_name" />
          </div>
        </div>
        <div class="flex-1 space-y-2 py-4 md:w-5/12 md:py-0">
          <div class="text-grey-70 text-sm" *tIfFeatureFlag="'enrollment-plan-details'">
            <span class="text-grey-70 text-sm"> Enrolled {{ patient.plan_type }}</span>
            <span *ngIf="patient.lis_copay_cat"> (LICS {{ patient.lis_copay_cat }})</span>
          </div>
          <span class="text-grey-50 block text-xs">ID: {{ patient | memberId }}</span>
        </div>

        <div class="flex items-center justify-between pt-4 md:w-3/12 md:pt-0">
          <div class="flex justify-end md:w-1/3">
            <span class="text-grey-70 text-sm" *tIfFeatureFlag="'actions-view'">
              {{ patient.count_member_new_actions | pluralize: 'action' }}
            </span>
          </div>
          <div class="flex justify-end md:w-1/3">
            <t-label variant="pill" color="neutral" size="medium" *tIfFeatureFlag="'actions-view'">
              {{ patient.count_member_opportunity | currency: undefined : 'symbol' : '1.0-0' }}
            </t-label>
          </div>
          <div class="flex justify-end md:w-1/3">
            <t-button variant="neutral" size="tiny">VIEW</t-button>
          </div>
        </div>
      </div>
    </t-card>
  </a>
</div>
