import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeatureFlagDirective } from '@troyai/feature-flags';
import { PatientListItem } from '@troyai/patients/data-access';
import { FullNamePipe, MemberIdPipe } from '@troyai/patients/util';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { DateOfBirthPipe, PluralizePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent, CardComponent, LabelComponent } from '@troyai/ui-kit';
import { PatientMetaComponent } from '../patient-meta/patient-meta.component';

@Component({
  selector: 't-patients-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    RouterModule,
    LabelComponent,
    ButtonComponent,
    FullNamePipe,
    PluralizePipe,
    DateOfBirthPipe,
    PharmacyIndicatorTooltipComponent,
    FeatureFlagDirective,
    PatientMetaComponent,
    MemberIdPipe,
  ],
  templateUrl: './patients-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientsListComponent {
  @Input() patients: PatientListItem[] = [];
  @Input() selectedPharmacyId?: number | null = null;
}
