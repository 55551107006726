export class GetResourcesList {
  static readonly type = '[Resources] Get resources list';
}

export class GetSignedDownloadURL {
  static readonly type = '[Resources] Get signed download URL';
  constructor(
    public payload: {
      resourceId: number;
    }
  ) {}
}

export class SearchInResourceList {
  static readonly type = '[Resources] Search in resources list';
  constructor(
    public payload: {
      search: string;
    }
  ) {}
}
