<div class="relative">
  <t-icon
    [tPopoverHover]="50"
    [tPopoverAnchor]="content"
    key="storefront"
    size="tiny"
    color="inherit" />
  <t-popover verticalAlign="center" horizontalAlign="after" #content>
    <span class="shadow-small bg-grey-80 max-w-xs px-4 py-3 text-xs text-white">
      {{ pharmacyName }}
    </span>
  </t-popover>
</div>
