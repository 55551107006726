import { CriticalCommentSubmitPayload } from '../models/critical-comment.interface';
import { UploadedFile } from '../models/patient-files.interface';
import { PatientBase } from '../models/patient.interface';

export class GetPatientsList {
  static readonly type = '[Patients] Get patients list';
}

export class EmptyPatientsList {
  static readonly type = '[Patients] Empty patients list';
}

export class EmptySelectedPatient {
  static readonly type = '[Patients] Empty selected patient';
}

export class GetSinglePatient {
  static readonly type = '[Patient] Get single patient';
  constructor(public payload: { globalId?: number }) {}
}

export class GetSinglePatientAsNP {
  static readonly type = '[Patient][Care] Get single patient as NP';
  constructor(public payload: { globalId?: number }) {}
}

export class PrePopulateSinglePatient {
  static readonly type = '[Patient] Pre-populate single patient';
  constructor(public payload: { patient: Partial<PatientBase> }) {}
}

export class GetPatientRxClaims {
  static readonly type = '[Patient] Get Rx Claims';
  constructor(public payload: { globalId?: number }) {}
}

export class RequestPatientReassignment {
  static readonly type = '[Patient] Request patient reassignment';
}

export class GetPatientEncounters {
  static readonly type = '[Patient] Get patient encounters';
  constructor(public payload: { globalId?: number }) {}
}

export class SubmitCriticalComment {
  static readonly type = '[Patient] Submit critical comment';
  constructor(
    public payload: {
      globalId: number;
      data: CriticalCommentSubmitPayload;
    }
  ) {}
}

export class PopulatePatientFiles {
  static readonly type = '[Patient] Populate patient file';
  constructor(
    public payload: {
      files: UploadedFile[];
    }
  ) {}
}

export class AddPatientFiles {
  static readonly type = '[Patient] Add patient file';
  constructor(
    public payload: {
      memberGlobalId: number;
      files: File[];
    }
  ) {}
}

export class EmptyPatientFiles {
  constructor(
    public payload: {
      discard?: boolean;
      memberGlobalId?: number;
    }
  ) {}
  static readonly type = '[Patient] Empty patient files';
}

export class DownloadPatientFile {
  static readonly type = '[Patient] Download patient file';
  constructor(
    public payload: {
      memberId: number;
      fileId: number;
    }
  ) {}
}

export class DeletePatientFile {
  static readonly type = '[Patient] Delete patient file';
  constructor(
    public payload: {
      memberGlobalId: number;
      fileId: number;
    }
  ) {}
}

export class GetPatientOngoingCareActions {
  static readonly type = '[Patient] Get patient ongoing care actions';
  constructor(
    public payload: {
      memberGlobalId: number;
    }
  ) {}
}
