import { UserPharmacy } from '@troyai/auth/data-access';

export class SetGlobalPharmacy {
  static readonly type = '[Global] Set global pharmacy';
  constructor(public payload: { pharmacy: UserPharmacy }) {}
}

export class SetPharmacyList {
  static readonly type = '[Global] Set pharmacies list';
}
