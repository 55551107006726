import { Environment, FeatureFlags } from '@troyai/envs';
import { getTenantRef } from 'multi-tenancy';
import { commonFeatureFlags } from './feature-flags.config';

const apiHost = window.location.hostname;
const apiUrl = `https://${apiHost}/api/v1`;

// TODO:MULTI-TENANT: get specific feature flags for each tenant from the REST API, remove hardcoded values
const getFeatureFlagsByTenant = (tenantRef: string) => {
  const featureFlagsByTenant: Record<string, FeatureFlags> = {
    ...commonFeatureFlags,
    troy: {
      ...commonFeatureFlags['troy'],
      'patient-diagnoses': true,
    },
  };

  return featureFlagsByTenant[tenantRef] || featureFlagsByTenant['troy'];
};

export const environment: Environment = {
  production: true,
  enableDebugTools: false,
  logLevel: 'error',
  apiHost,
  apiUrl,
  auth0ConnectionData: {
    domain: 'auth.troy.ai',
    clientId: 'jHgUtflqqpLPlcO7qLzbuZ799iHM5PA6',
    audience: 'https://portal.staging.troymedicare.com/api',
  },
  featureFlags: getFeatureFlagsByTenant(getTenantRef()),
};
