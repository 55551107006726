/* eslint-disable @typescript-eslint/no-explicit-any */
import { VALID_HORIZ_ALIGN, VALID_VERT_ALIGN, VALID_SCROLL } from './types';

export function getUnanchoredPopoverError(): Error {
  return Error('Popover does not have an anchor.');
}

export function getInvalidPopoverAnchorInstanceError(): Error {
  return Error('Popover#anchor must be an instance of PopoverAnchor, ElementRef, or HTMLElement.');
}

export function getInvalidPopoverError(): Error {
  return Error('PopoverAnchor#PopoverAnchor must be an instance of Popover.');
}

export function getInvalidPopoverAnchorError(): Error {
  return Error(
    `PopoverAnchor must be associated with a ` +
      `Popover component. ` +
      `Examples: <t-popover [anchor]="PopoverAnchorTemplateRef"> or ` +
      `<button tPopoverAnchor [tPopoverAnchor]="tPopoverTemplateRef">`
  );
}

export function getInvalidHorizontalAlignError(alignment: any): Error {
  return Error(generateGenericError('horizontalAlign/xAlign', alignment, VALID_HORIZ_ALIGN));
}

export function getInvalidVerticalAlignError(alignment: any): Error {
  return Error(generateGenericError('verticalAlign/yAlign', alignment, VALID_VERT_ALIGN));
}

export function getInvalidScrollStrategyError(strategy: any): Error {
  return Error(generateGenericError('scrollStrategy', strategy, VALID_SCROLL));
}

function generateGenericError(apiName: string, invalid: any, valid: string[]): string {
  return `Invalid ${apiName}: '${invalid}'. Valid options are ${valid
    .map((v) => `'${v}'`)
    .join(', ')}.`;
}
