import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputTextComponent } from '../input-text.component';

@Component({
  selector: 't-input-full-name',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputTextComponent],
  templateUrl: './input-full-name.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFullNameComponent),
      multi: true,
    },
  ],
})
export class InputFullNameComponent {
  @Input() label = '';

  nameForm: FormGroup = new FormGroup({
    first: new FormControl(''),
    last: new FormControl(''),
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  // This method is called by Angular when the value of the control needs to be updated.
  writeValue(value: any): void {
    if (value) {
      this.nameForm.setValue(value, { emitEvent: false });
    }
  }

  // Registers a callback function to be called when the control's value changes.
  registerOnChange(fn: any): void {
    this.nameForm.valueChanges.subscribe(fn);
  }

  // Registers a callback function to be called when the control is touched.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Sets the disabled state of the control.
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.nameForm.disable();
    } else {
      this.nameForm.enable();
    }
  }

  // Call the touch handler when a field is touched
  markAsTouched() {
    this.onTouched();
  }
}
