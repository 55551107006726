import { Inject, Injectable } from '@angular/core';
import { TrackEvent } from './models/track-event.interface';
import { TRACKER_PLUGIN } from './tracker-plugin.token';
import { TrackerPlugin } from './models/tracker-plugin.interface';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackService {
  constructor(@Inject(TRACKER_PLUGIN) private plugins: TrackerPlugin[]) {}

  trackEvent(event: TrackEvent) {
    for (const plugin of this.plugins) {
      plugin.trackEvent(event).pipe(take(1)).subscribe();
    }
  }
}
