import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsModule } from '../../icon/icon.module';
import { PopoverModule } from '../../popover/popover.module';

export interface CompactSelectOption<T> {
  id: T;
  label: string;
  [key: string]: unknown;
}

@Component({
  selector: 't-compact-select',
  standalone: true,
  imports: [CommonModule, IconsModule, PopoverModule],
  templateUrl: './compact-select.component.html',
})
export class CompactSelectComponent implements OnInit {
  @Input() options: CompactSelectOption<string>[] = [];
  @Input() initialSelectedOption: CompactSelectOption<string> | null = null;
  @Output() selected = new EventEmitter<CompactSelectOption<unknown>>();

  currentSelection: CompactSelectOption<unknown> | null = null;
  selectItem(item: CompactSelectOption<unknown>) {
    this.currentSelection = item;
    this.selected.emit(item);
  }

  ngOnInit() {
    this.currentSelection = this.initialSelectedOption || this.options[0] || null;
  }
}
