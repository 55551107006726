<div class="flex gap-x-3">
  <t-button
    *ngFor="let groupToggleOption of options"
    (click)="optionSelected(groupToggleOption)"
    size="tiny"
    [selected]="groupToggleOption.selected"
    variant="neutral">
    {{ groupToggleOption.label }}
  </t-button>
</div>
