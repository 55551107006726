import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  ActionsState,
  CreateOutreachAttempt,
  GetOutreachAttemptsOutcomes,
} from '@troyai/actions/data-access';
import {
  ButtonComponent,
  DatePickerInputComponent,
  FieldErrorComponent,
  InputTextComponent,
  PhoneNumberValidator,
  SelectComponent,
  SelectOption,
} from '@troyai/ui-kit';
import { format } from 'date-fns';
import { map, take, withLatestFrom } from 'rxjs';

@Component({
  selector: 't-save-outreach-attempt-modal',
  standalone: true,
  imports: [
    CommonModule,
    SelectComponent,
    ButtonComponent,
    InputTextComponent,
    ReactiveFormsModule,
    FieldErrorComponent,
    RouterModule,
    DatePickerInputComponent,
  ],
  templateUrl: './save-outreach-attempt-modal.component.html',
})
export class SaveOutreachAttemptModalComponent implements OnInit {
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { actionGlobalId: number }
  ) {}

  outreachAttemptsOutcomes$ = this.store.select(ActionsState.outreachAttemptsOutcomes).pipe(
    map((outcomes) => {
      return outcomes.map((outcome) => {
        return {
          id: outcome.code,
          label: outcome.description,
        } as SelectOption<string>;
      });
    })
  );

  outreachAttemptsOutcomesLoading$ = this.store.select(
    ActionsState.outreachAttemptsOutcomesLoading
  );
  form = new FormGroup({
    outcome: new FormControl<string>('contact_success_member_refused', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, PhoneNumberValidator()]),
    reason: new FormControl(''),
    followUpCallDate: new FormControl('', [Validators.required]),
  });

  displayReasonField = false;
  displayFollowUpCallField = false;
  destroyRef = inject(DestroyRef);

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.actions$.pipe(ofActionSuccessful(CreateOutreachAttempt), take(1)).subscribe(() => {
        this.dialogRef.close();
      });

      if (!this.form.controls.outcome.value || !this.form.controls.phoneNumber.value) return;

      const formattedFollowUpCallDate = this.form.controls.followUpCallDate.value
        ? format(this.form.controls.followUpCallDate.value || '', 'y-MM-d')
        : '';

      this.store.dispatch(
        new CreateOutreachAttempt({
          actionGlobalId: this.data.actionGlobalId,
          data: {
            outreach_result_code: this.form.controls.outcome.value as string,
            phone_number: this.form.controls.phoneNumber.value,
            reason: this.form.controls.reason.value || '',
            follow_up_call: formattedFollowUpCallDate,
          },
        })
      );
    }
  }

  ngOnInit() {
    this.form.controls.outcome.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((outcome) => {
        if (!outcome) return;

        this.displayReasonField = false;
        this.displayFollowUpCallField = false;
        this.form.controls.reason.clearValidators();
        this.form.controls.followUpCallDate.clearValidators();

        if (outcome === 'contact_success_member_refused' || outcome === 'member_not_contacted') {
          this.displayReasonField = true;
          this.form.controls.reason.setValidators(Validators.required);
        }

        if (outcome === 'contact_success_member_not_available') {
          this.displayFollowUpCallField = true;
          this.form.controls.followUpCallDate.setValidators(Validators.required);
        }

        this.form.controls.reason.updateValueAndValidity();
        this.form.controls.followUpCallDate.updateValueAndValidity();
      });

    // Select a default outcome when the outcomes are loaded
    this.actions$
      .pipe(
        ofActionSuccessful(GetOutreachAttemptsOutcomes),
        withLatestFrom(this.outreachAttemptsOutcomes$)
      )
      .subscribe(([, outcomes]) => {
        const defaultOutcome = outcomes.find(
          (outcome) => outcome.id === 'contact_success_member_refused'
        );
        if (defaultOutcome) {
          this.form.controls.outcome.setValue(defaultOutcome.id);
        }
      });

    this.store.dispatch(
      new GetOutreachAttemptsOutcomes({
        actionGlobalId: this.data.actionGlobalId,
      })
    );
  }
}
