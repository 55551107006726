import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  generateSortOptions,
  ListingDataSource,
  SortOptionValue,
} from '@troyai/data-source-listing';
import { MedicationListItem, MedicationState } from '@troyai/medication/medication-data-access';
import { DiagnosesState, Diagnosis } from '@troyai/portal/diagnoses/data-access';
import { splitDiagnosesByType } from '@troyai/portal/diagnoses/feature';
import {
  DataListingComponent,
  ExpandableCardComponent,
  LabelComponent,
  PaginatorComponent,
} from '@troyai/ui-kit';
import { map } from 'rxjs';

@Component({
  selector: 't-patient-health-data-panels',
  standalone: true,
  imports: [
    CommonModule,
    ExpandableCardComponent,
    LabelComponent,
    DataListingComponent,
    PaginatorComponent,
  ],
  templateUrl: './patient-health-data-panels.component.html',
})
export class PatientHealthDataPanelsComponent implements OnInit {
  constructor(private store: Store) {}

  medicationList$ = this.store
    .select(MedicationState.medicationList)
    .pipe(map((items) => items ?? []));

  diagnosesList$ = this.store.select(DiagnosesState.diagnosesList).pipe(
    map((diagnoses) => {
      return splitDiagnosesByType(diagnoses);
    })
  );

  regularDiagnosesDataSource = new ListingDataSource<Diagnosis>();
  potentialDiagnosesDataSource = new ListingDataSource<Diagnosis>();
  medicationListDataSource = new ListingDataSource<MedicationListItem>();

  pageSize = 5;
  diagnosesAvailableSortingOptions: SortOptionValue<Diagnosis>[] = generateSortOptions<Diagnosis>(
    [
      {
        label: 'Date',
        value: {
          keyName: 'added_at',
          order: 'desc',
        },
      },
    ],
    'added_at'
  );
  medicationAvailableSortingOptions: SortOptionValue<MedicationListItem>[] =
    generateSortOptions<MedicationListItem>(
      [
        {
          label: 'Date',
          value: {
            keyName: 'created_on',
            order: 'desc',
          },
        },
      ],
      'created_on'
    );

  ngOnInit(): void {
    this.regularDiagnosesDataSource.init(
      this.diagnosesList$.pipe(map((items) => items.regularDiagnoses)),
      this.diagnosesAvailableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );

    this.potentialDiagnosesDataSource.init(
      this.diagnosesList$.pipe(map((items) => items.potentialDiagnoses)),
      this.diagnosesAvailableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );

    this.medicationListDataSource.init(
      this.medicationList$,
      this.medicationAvailableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );
  }
}
