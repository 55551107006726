import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { of, tap } from 'rxjs';

import { minutesToMilliseconds } from 'date-fns';
import { PriorAuthorizationsApiService } from '../prior-authorizations-api.service';
import { SinglePatientPriorAuthorizationStateModel } from './prior-authorization-state.model';
import { GetSinglePatientPriorAuthorizationsList } from './prior-authorization.actions';

@State<SinglePatientPriorAuthorizationStateModel>({
  name: 'singlePatientPriorAuthorizationState',
  defaults: {
    itemsLoading: false,
    items: [],
  },
})
@Injectable()
export class SinglePatientPriorAuthorizationState {
  constructor(private priorAuthorizationApiService: PriorAuthorizationsApiService) {}

  @Selector()
  static itemsList(state: SinglePatientPriorAuthorizationStateModel) {
    return state.items;
  }

  @Selector()
  static itemsListLoading(state: SinglePatientPriorAuthorizationStateModel) {
    return state.itemsLoading;
  }

  @Action(GetSinglePatientPriorAuthorizationsList)
  getSinglePatientPriorAuthorizationList(
    { setState, patchState }: StateContext<SinglePatientPriorAuthorizationStateModel>,
    action: GetSinglePatientPriorAuthorizationsList
  ) {
    patchState({
      itemsLoading: true,
    });

    if (action.payload.globalId) {
      return this.priorAuthorizationApiService
        .getPriorAuthorizationByMemberId(action.payload.globalId, 500, {
          ttl: minutesToMilliseconds(10),
        })
        .pipe(
          tap((priorAuthorizations) =>
            setState(
              patch<SinglePatientPriorAuthorizationStateModel>({
                items: priorAuthorizations,
              })
            )
          ),
          tap(() =>
            patchState({
              itemsLoading: false,
            })
          )
        );
    } else {
      return of([]);
    }
  }
}
