/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { UpdateUserProfilePayload, UserProfile } from './models/user-profile.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserProfileApiService {
  constructor(private restApiService: RestApiClientService) {}

  getProfile() {
    return this.restApiService.get<UserProfile>('profile');
  }

  updateProfile(payload: UpdateUserProfilePayload) {
    const body = new HttpParams({
      fromObject: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone_number: payload.phone_number,
      },
    });

    return this.restApiService.patch<GenericRestApiOKResponse>('profile', body);
  }
}
