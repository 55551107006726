<div
  class="relative flex w-[300px] items-center justify-between rounded-lg border p-4 will-change-transform"
  [ngClass]="{
    'bg-green-20 border-green-80': toastType === 'toast-success',
    'bg-red-10 border-red-90': toastType === 'toast-error',
    'bg-amber-20 border-amber-70': toastType === 'toast-warning',
    'bg-grey-10 border-grey-40': toastType === 'toast-info'
  }">
  <div class="flex items-start gap-x-3">
    <div
      [ngClass]="{
        'text-green-90': toastType === 'toast-success',
        'text-red-90': toastType === 'toast-error',
        'text-amber-70': toastType === 'toast-warning',
        'text-grey-70': toastType === 'toast-info'
      }">
      <t-icon *ngIf="iconKey" [key]="iconKey" size="small" color="inherit"></t-icon>
    </div>

    <div>
      <div class="text-grey-70 text-sm">
        <div
          *ngIf="title"
          class="text-grey-70 mb-2 font-semibold"
          [class]="options.titleClass"
          [attr.aria-label]="title">
          {{ title }}
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          [class]="options.messageClass"
          [innerHTML]="message"></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          [class]="options.messageClass"
          [attr.aria-label]="message">
          {{ message }}
        </div>
      </div>
    </div>
  </div>

  <div class="text-grey-70 toast-close-button" *ngIf="options.closeButton">
    <t-icon class="cursor-pointer" key="close" (click)="remove()" size="tiny"></t-icon>
  </div>

  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
