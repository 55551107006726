import { Inject, Injectable } from '@angular/core';
import { ADHERENCE_CONFIG_TOKEN } from './adherence-percentages-config.token';
import {
  AdherencePercentageBracketsConfig,
  getAdherenceBracketByPercentage,
} from '@troyai/adherence/util';

@Injectable({
  providedIn: 'root',
})
export class AdherenceRatingsService {
  constructor(
    @Inject(ADHERENCE_CONFIG_TOKEN)
    private adherenceRatingsConfig: AdherencePercentageBracketsConfig[]
  ) {}

  getRatingValueByPercentage(percent: number): AdherencePercentageBracketsConfig {
    return getAdherenceBracketByPercentage(percent, this.adherenceRatingsConfig);
  }
}
