import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, Store, ofActionErrored } from '@ngxs/store';
import { GetCurrentUserDetails, UserRoles, UserState } from '@troyai/auth/data-access';
import { LayoutService, Layouts } from '@troyai/layout';
import { NotificationsState } from '@troyai/notifications/data-access';
import { RouterHistoryService } from '@troyai/portal/common/navigation';
import { TrackService } from '@troyai/tracking';
import { combineLatest, map } from 'rxjs';

interface Gtag {
  (command: 'config' | 'event', targetId: string, config?: unknown): void;
}

declare const gtag: Gtag;

@Component({
  selector: 't-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private store: Store,
    private actions$: Actions,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document,
    public routerHistoryService: RouterHistoryService,
    private trackService: TrackService,
    public layoutsService: LayoutService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.actions$.pipe(ofActionErrored(GetCurrentUserDetails)).subscribe(() => {
      this.router.navigateByUrl('/forbidden');
    });

    this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.mobileMode = !result.matches;
      });

    this.router.events.subscribe((navigationEvent) => {
      if (navigationEvent instanceof NavigationEnd) {
        this.trackService.trackEvent({
          eventName: '$pageview',
          eventProps: {
            $current_url: this.document.defaultView?.window.location.href,
            title: document.title,
          },
        });
        if (gtag) {
          gtag('event', 'page_view', {
            page_path: navigationEvent.urlAfterRedirects,
            page_title: document.title,
          });
        }
      }
    });
  }

  readonly Layouts = Layouts;
  isAuthLoading$ = this.store.select(UserState.isLoading);
  isAuthenticated$ = this.store.select(UserState.isLoggedIn);
  hasRoles$ = this.store.select(UserState.hasRoles);
  hasAccess$ = combineLatest([this.isAuthenticated$, this.hasRoles$]).pipe(
    map(([isAuthenticated, hasRoles]) => isAuthenticated && hasRoles)
  );
  isMOCCompliant$ = this.store.select(UserState.isMOCCompliant);
  tenantLogoUrl$ = this.store.select(UserState.currentTenantLogo);

  notificationsCount$ = this.store.select(NotificationsState.notificationsCount);
  readonly userRoles = UserRoles;

  sidebarVisible = false;
  mobileMode: boolean | null = null;

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }
}
