import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, IconsModule, LoaderComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-onboarding-item',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconsModule, LoaderComponent],
  templateUrl: './onboarding-item.component.html',
})
export class OnboardingItemComponent {
  @Input() buttonText = '';
  @Input() isDisabled = false;
  @Input() isComplete = false;
  @Input() isLoading = false;
  @Output() triggerItemStart = new EventEmitter();

  triggerItemStartEvent() {
    this.triggerItemStart.emit();
  }
}
