@if (nursePractitioners$ | async; as nursePractitioners) {
  @if (nursePractitioners.length > 0) {
    <span class="text-grey-100 block text-sm">Choose another NP </span>

    <div class="shadow-small divide-grey-20 mb-2 mt-5 divide-y rounded-lg bg-white">
      @for (np of nursePractitioners; track np.global_id) {
        <div class="flex items-center justify-between px-5 py-5">
          <div class="space-y-2">
            <div class="text-grey-70 flex items-center space-x-2">
              <strong class="text-grey-90 text-sm font-semibold">{{ np.name }}</strong>
              @if (scheduleDetails$ | async; as scheduleDetails) {
                @if (scheduleDetails.assignedNP?.global_id === np.global_id) {
                  <t-label size="small" paddingSize="small" color="warning">
                    <div class="flex items-center gap-x-1">
                      <t-icon key="check-circle" color="inherit" size="tiny" />
                      <span class="font-normal">currently selected</span>
                    </div>
                  </t-label>
                }
              }
            </div>

            <div class="text-grey-70 flex items-center space-x-2">
              <span class="text-grey-60 text-xs">Preferred counties:</span>
              @for (county of np.counties; track $index) {
                <t-label size="tiny" paddingSize="small">{{ county | titlecase }}</t-label>
              }
            </div>
          </div>

          <t-button (click)="chooseNP(np)" variant="neutral" size="tiny">CHOOSE</t-button>
        </div>
      }
    </div>
  } @else {
    <div class="bg-grey-60 flex min-h-[300px] items-center justify-center bg-opacity-5">
      <t-empty-state-card
        content="Unfortunately, there are no other NPs available for the selected address."
        [hasIcon]="true" />
    </div>
  }
}
