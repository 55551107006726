import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { UserDetails, UserSettingsUpdatePayload } from '../models/user-details.interface';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private restApiService: RestApiClientService) {}

  getUserDetails(skipCache = false) {
    const headers = skipCache
      ? new HttpHeaders({
          'Cache-Control': 'no-cache',
        })
      : {};

    return this.restApiService.get<UserDetails>('me', {
      headers,
    });
  }

  updateUserSettings(payload: UserSettingsUpdatePayload) {
    return this.restApiService.post<GenericRestApiOKResponse>('profile-settings', payload);
  }
}
