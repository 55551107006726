<form [formGroup]="form" (ngSubmit)="onSubmit()" class="space-y-10">
  @if (errorsList$ | async; as errorsList) {
    @if (errorsList.length) {
      <div class="bg-red-90 sticky left-0 top-0 z-50 w-full space-y-1 p-3 text-sm text-white">
        @for (error of errorsList; track $index) {
          <span
            class="block cursor-pointer hover:font-semibold"
            (click)="goToField(error.controlName)">
            {{ error.errors[0] }}
          </span>
        }
      </div>
    }
  }
  <div class="space-y-12">
    @for (formItem of formConfig; track $index) {
      <div
        [id]="'wrapper_' + formItem.controlName"
        [ngClass]="{
          hidden: formItem.hidden
        }">
        @switch (formItem.type) {
          @case ('control_textbox') {
            <div class="relative w-1/2">
              <t-input-text
                [label]="formItem.label"
                [id]="formItem.controlName"
                [formControlName]="formItem.controlName" />
              <t-dynamic-enrichment
                [control]="{
                  dataSource: formItem.controlName
                }" />
            </div>
          }

          @case ('control_textarea') {
            <div class="relative">
              <t-input-text
                [label]="formItem.label"
                inputType="textarea"
                [formControlName]="formItem.controlName" />
              <t-dynamic-enrichment
                [control]="{
                  dataSource: formItem.controlName
                }" />
            </div>
          }

          @case ('control_datetime') {
            <div class="w-1/2">
              <t-date-picker-input
                dateFormat="yyyy-MM-dd"
                [label]="formItem.label"
                [formControlName]="formItem.controlName" />
            </div>
          }

          @case ('control_radio') {
            <t-radio
              class="block"
              [groupName]="'group-' + formItem.controlName"
              [label]="formItem.label"
              [items]="formItem.options"
              [formControlName]="formItem.controlName" />
          }

          @case ('control_checkbox') {
            <div>
              <t-checkbox-group
                [label]="formItem.label"
                [options]="formItem.options"
                [formControlName]="formItem.controlName" />
            </div>
          }

          @case ('control_head') {
            <div class="border-grey-20 mb-2 space-y-2 border-b py-2">
              <strong class="text-grey-80 block font-semibold">{{ formItem.label }}</strong>
              <span class="text-grey-65 block text-xs">{{ formItem.subheader }}</span>
            </div>
          }

          @case ('control_matrix') {
            <div>
              <t-matrix-form
                [selectionType]="formItem.inputType"
                [columns]="formItem.cols"
                [rows]="formItem.rows"
                [formControlName]="formItem.controlName"
                [label]="formItem.label" />
            </div>
          }

          @case ('control_widget') {
            <div class="relative mb-[60px]">
              <t-repeatable-fields
                [config]="formItem.fields"
                [label]="formItem.label"
                [addRowButtonText]="formItem.addRowButtonText"
                [formControlName]="formItem.controlName" />
              <t-dynamic-enrichment
                [control]="{
                  dataSource: formItem.controlName
                }" />
            </div>
          }

          @case ('control_dropdown') {
            <div class="w-1/2">
              <t-select
                [items]="formItem.options"
                [formControlName]="formItem.controlName"
                [label]="formItem.label" />
            </div>
          }

          @case ('control_fullname') {
            <div class="w-1/2">
              <t-input-full-name
                [id]="formItem.controlName"
                [formControlName]="formItem.controlName"
                [label]="formItem.label" />
            </div>
          }

          @case ('control_email') {
            <div class="w-1/2">
              <t-input-text
                inputType="email"
                [id]="formItem.controlName"
                [formControlName]="formItem.controlName"
                [label]="formItem.label" />
            </div>
          }

          @case ('control_address') {
            <div>
              <t-input-address [formControlName]="formItem.controlName" [label]="formItem.label" />
            </div>
          }

          @case ('control_phone') {
            <div class="w-1/2">
              <t-input-text
                inputType="phone"
                [formControlName]="formItem.controlName"
                [label]="formItem.label" />
            </div>
          }

          @case ('control_fileupload') {
            <t-minimal-file-upload
              [formControlName]="formItem.controlName"
              [label]="formItem.label"
              (fileUploaded)="onFileUploaded($event, formItem.controlName)" />
          }
        }

        @if (formItem.required) {
          <t-field-error [controlName]="form.controls[formItem.controlName]" />
        }
      </div>
    }
  </div>

  @if (addingFormSubmissionLoadingStatus$ | async; as loadingStatus) {
    <div
      class="shadow-large border-grey-20 sticky bottom-0 left-0 flex w-full justify-end gap-x-5 rounded-lg border bg-white p-4">
      <t-button
        [loading]="loadingStatus.loading"
        (click)="$event.preventDefault(); onSave()"
        variant="secondary">
        Save draft
      </t-button>
      <t-button [loading]="loadingStatus.loading" type="submit">Submit</t-button>
    </div>
  }
</form>
