<div
  class="flex w-full items-center justify-between rounded-lg px-3 py-3 transition-colors"
  [ngClass]="{
    'bg-grey-15 hover:bg-grey-20': isFeatured,
    'hover:bg-grey-10 bg-white': !isFeatured
  }">
  <div class="flex items-center">
    <div class="bg-grey-10 h-[56px] w-[56px] flex-none rounded-full">
      <img [src]="actionGroup.image" tImgFallback="/assets/media-call-center.svg" />
    </div>

    <div class="ml-5 space-y-1.5">
      <span class="text-grey-100 block text-sm font-semibold">
        {{ actionGroup.title }}
      </span>
      <t-link [isButton]="true" variant="light" class="block text-xs">
        {{ actionGroup.count | pluralize: 'action' }}
      </t-link>
    </div>
  </div>
  <t-label size="large" color="neutral">
    {{ actionGroup.totalValue | currency: undefined : 'symbol' : '1.0-0' }}
  </t-label>
</div>
