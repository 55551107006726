import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';

import {
  ExtraActionData,
  ExtraActionDataRecentMedicationList,
  RecentMedicationList,
} from '@troyai/actions/data-access';
import {
  DataSourceListingService,
  ListingDataSource,
  ListingSortingHeadingsComponent,
  SortOptionValue,
  generateSortOptions,
} from '@troyai/data-source-listing';
import { PharmacyIndicatorTooltipComponent } from '@troyai/pharmacy-context/feature';
import { CardComponent, DataListingComponent, PaginatorComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-action-extra-data-fcmr01',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ListingSortingHeadingsComponent,
    PharmacyIndicatorTooltipComponent,
    PaginatorComponent,
    DataListingComponent,
  ],
  templateUrl: './action-fcmr01-extra-data.component.html',
})
export class ActionFCMR01ExtraDataComponent implements OnInit {
  constructor(private dataSourceListingService: DataSourceListingService) {}

  recentMedicationListData?: ExtraActionDataRecentMedicationList;

  pageSize = 10;
  recentMedicationDataSource = new ListingDataSource<RecentMedicationList>();

  onSortingChange(sortOptions: SortOptionValue<RecentMedicationList>[]) {
    this.availableSortingOptions = sortOptions;
    this.recentMedicationDataSource.sortBy(sortOptions);
  }
  availableSortingOptions: SortOptionValue<RecentMedicationList>[] =
    generateSortOptions<RecentMedicationList>(
      [
        {
          label: 'Name',
          widthClass: 'md:w-2/3',
          value: {
            keyName: 'drug',
            order: 'asc',
          },
        },

        {
          label: 'Date',
          widthClass: 'md:w-1/3 flex justify-end self-end text-right',
          value: {
            keyName: 'service_date',
            order: 'desc',
          },
        },
      ],
      'service_date'
    );

  @Input({ required: true })
  set extraData(value: ExtraActionData[] | undefined) {
    if (!value) {
      return;
    }

    this.recentMedicationListData = value.find(
      (item) => item?.type === 'recent_medication_list'
    ) as ExtraActionDataRecentMedicationList;
  }

  ngOnInit() {
    this.recentMedicationDataSource.init(
      of(this.recentMedicationListData?.data ?? []),
      this.availableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );
  }
}
