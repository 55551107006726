<ng-container *ngIf="recentMedicationDataSource.page$ | async as page">
  <div class="relative mb-5">
    <span class="text-grey-50 block pb-5 text-xs font-semibold uppercase tracking-wider">
      Medication
    </span>

    <!-- TODO: Unify sorting header variants with data listing variants -->
    <t-listing-sorting-headings
      variant="table"
      listingHeading
      *ngIf="page.totalElements > 0"
      [displayTailPlaceholder]="false"
      [sortingOptions]="availableSortingOptions"
      (sortingChange)="onSortingChange($event)" />

    <t-data-listing [items]="page.items" variant="table">
      <ng-template #rowTemplate let-item>
        <div class="text-grey-70 flex items-center gap-x-2 text-xs">
          <div class="flex w-2/3 gap-x-1">
            <t-pharmacy-indicator-tooltip
              class="hover:cursor-pointer"
              [pharmacyName]="item.pharmacy" />
            <span class="text-grey-100 truncate" [title]="item.drug">
              {{ item.drug }}
            </span>
          </div>

          <span class="block w-1/3 text-right">
            {{ item.service_date | date: 'MM/dd/yyyy' }}
          </span>
        </div>
      </ng-template>
    </t-data-listing>
  </div>

  <t-paginator
    *ngIf="page.totalElements > 0 && page.pageSize !== page.totalElements"
    [collectionSize]="page.totalElements"
    [currentPage]="page.pageNumber"
    [pageSize]="pageSize"
    [firstLastButtons]="false"
    (pageChanged)="recentMedicationDataSource.fetch($event.pageNumber)"
    [maxSize]="2"></t-paginator>
</ng-container>
