import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icon/icon.module';

@Component({
  selector: 't-empty-state-card',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './empty-state-card.component.html',
})
export class EmptyStateCardComponent {
  @Input() content = '';
  @Input() hasIcon = false;
}
