<div *ngIf="user$ | async as currentUser" class="flex h-full flex-col justify-between">
  <div class="px-4">
    @if ((currentTenantId$ | async) !== 'troy') {
      <div class="border-grey-20 hidden border-t border-opacity-20 pb-5 pt-4 lg:block">
        <span class="block text-xs text-white opacity-40">Powered by Troy.AI</span>
      </div>
    }
    <div class="space-y-0.5 py-4" id="tour-main-menu">
      <ng-container *tIfFeatureFlag="'dashboard-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/"
          routerLinkActive
          #rlaRoot="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaRoot.isActive">
            Dashboard
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'patients-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/patients"
          routerLinkActive
          #rlaPatients="routerLinkActive">
          <t-nav-item
            (click)="selectMenuItem()"
            [isActive]="rlaPatients.isActive"
            class="guided-tour-patient-menu-item block">
            Patients
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'actions-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/actions"
          routerLinkActive
          #rlaActions="routerLinkActive">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaActions.isActive">
            Actions
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'adherence-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/adherence"
          routerLinkActive
          #rlaAdherence="routerLinkActive">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaAdherence.isActive">
            Adherence
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'prior-authorization-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/prior-authorization"
          routerLinkActive
          #rlaPriorAuthorization="routerLinkActive">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaPriorAuthorization.isActive">
            Prior Authorization
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'np-patients-view'">
        <a
          *tIfHasRole="[userRoles.CareTeamHRA, userRoles.CMExternal]"
          routerLink="/care/patients"
          routerLinkActive
          #rlaCarePatients="routerLinkActive">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaCarePatients.isActive">
            <span *tIfHasRole="[userRoles.PharmacyUser]">NP&nbsp;</span>Patients
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'np-appointments-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser, userRoles.CareTeamHRA]"
          routerLink="/care/appointments"
          routerLinkActive
          #rlaCareAppointments="routerLinkActive">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaCareAppointments.isActive">
            NP Appointments
          </t-nav-item>
        </a>
      </ng-container>

      <ng-container *tIfFeatureFlag="'resources-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/resources"
          routerLinkActive
          #rlaResources="routerLinkActive">
          <t-nav-item (click)="selectMenuItem()" [isActive]="rlaResources.isActive">
            Resources
          </t-nav-item>
        </a>
      </ng-container>

      <!-- CM Internal items -->
      <a
        *tIfHasRole="[userRoles.CMInternal]"
        routerLink="/care-management/patients"
        routerLinkActive
        #rlaCMPatients="routerLinkActive">
        <t-nav-item (click)="selectMenuItem()" [isActive]="rlaCMPatients.isActive">
          Patients
        </t-nav-item>
      </a>

      <a
        *tIfHasRole="[userRoles.CMInternal]"
        routerLink="/care-management/actions"
        routerLinkActive
        #rlaCMActions="routerLinkActive">
        <t-nav-item (click)="selectMenuItem()" [isActive]="rlaCMActions.isActive">
          Actions
        </t-nav-item>
      </a>
      <!-- /CM Internal items -->
    </div>

    <div
      class="border-grey-20 border-t border-opacity-20 py-4"
      *tIfHasRole="[userRoles.PharmacyUser]">
      <t-pharmacy-selector id="tour-global-pharmacy-selector" />
    </div>
  </div>

  <div class="p-4">
    <div class="space-y-0.5">
      <div class="py-4" *tIfHasRole="[userRoles.CareTeamHRA]">
        <strong class="text-grey-60 block text-sm font-semibold">
          {{ currentUser.name }}
        </strong>
        <span class="text-grey-60 block text-xs">
          {{ currentUser.email }}
        </span>
      </div>

      <ng-container *tIfFeatureFlag="'activity-view'">
        <a
          *tIfHasRole="[userRoles.PharmacyUser]"
          class="block"
          routerLink="/activity"
          routerLinkActive
          #rlaActivity="routerLinkActive"
          id="tour-menu-activity-item">
          <t-nav-item
            icon="earthquake"
            size="small"
            (click)="selectMenuItem()"
            [isActive]="rlaActivity.isActive">
            Activity
          </t-nav-item>
        </a>
      </ng-container>

      <a
        class="block"
        routerLink="/settings"
        routerLinkActive
        #rlaSettings="routerLinkActive"
        id="tour-menu-settings-item">
        <t-nav-item
          icon="person"
          size="small"
          (click)="selectMenuItem()"
          [isActive]="rlaSettings.isActive">
          Settings
        </t-nav-item>
      </a>

      <t-nav-item class="block" icon="logout" size="small" (click)="logout()"> Logout </t-nav-item>
    </div>

    <div class="border-grey-20 mt-1.5 border-t border-opacity-20 pt-1.5">
      <t-help-menu-item />
    </div>
  </div>
</div>
