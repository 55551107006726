import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, take, tap } from 'rxjs';

import { ActionStatusEnum, ActionsState } from '@troyai/actions/data-access';
import { ActionStatusCardComponent } from '@troyai/actions/ui';
import { UserRoles } from '@troyai/auth/data-access';
import { RolesDirective, UserRolesService } from '@troyai/auth/feature';
import {
  CareState,
  SetScheduleAppointmentAddress,
  SetScheduleAppointmentNursePractitioner,
} from '@troyai/hra/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { AddressPipe, CustomMediumDatePipe } from '@troyai/portal/common/pipes';
import {
  IconsModule,
  LabelComponent,
  LinkComponent,
  ModalMetaData,
  ModalModule,
  ModalService,
} from '@troyai/ui-kit';
import { NursePractitionerPatientSchedulerModalComponent } from '../../../np-patient-scheduler-modal/np-patient-scheduler-modal.component';
import { PatientSchedulerModalComponent } from '../../../patient-scheduler-modal/patient-scheduler-modal.component';

@Component({
  selector: 't-action-completed-hra',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    CustomMediumDatePipe,
    LinkComponent,
    ModalModule,
    FullNamePipe,
    ActionStatusCardComponent,
    AddressPipe,
    LabelComponent,
    RolesDirective,
  ],
  templateUrl: './action-completed-hra.component.html',
})
export class ActionCompletedHraComponent {
  constructor(
    private modalService: ModalService,
    private store: Store,
    private fullNamePipe: FullNamePipe,
    private userRolesService: UserRolesService
  ) {}

  patient$ = this.store.select(PatientsState.selectedPatient);
  action$ = this.store.select(ActionsState.selectedAction);
  nursePractitioners$ = this.store.select(CareState.nursePractitionersList);

  userRoles = UserRoles;

  openSchedulingModal() {
    combineLatest([
      this.patient$,
      this.action$,
      this.nursePractitioners$.pipe(take(1)),
      this.userRolesService.hasAccess([UserRoles.PharmacyUser]),
      this.userRolesService.hasAccess([UserRoles.CareTeamHRA]),
      this.userRolesService.hasAccess([UserRoles.CMExternal]),
    ])
      .pipe(
        tap(
          ([
            patient,
            action,
            npList,
            hasPharmacyUserAccess,
            hasCareTeamHRAAccess,
            hasCMExternalAccess,
          ]) => {
            if (!patient || !action) return;

            if (action.status === ActionStatusEnum.SCHEDULED) {
              const assignedNP = npList.find(
                (np) => np.global_id === action.scheduled_address?.np_id
              );

              if (action.scheduled_address && assignedNP) {
                this.store.dispatch([
                  new SetScheduleAppointmentAddress({
                    address: action.scheduled_address,
                  }),
                  new SetScheduleAppointmentNursePractitioner({
                    nursePractitioner: assignedNP,
                  }),
                ]);
              }
            }

            const patientName = this.fullNamePipe.transform(patient);
            const modalMetaData: ModalMetaData = {
              title: `Schedule NP Home Visit for ${patientName}`,
              background: 'grey',
              offsetTop: 50,
            };

            if (hasPharmacyUserAccess || hasCMExternalAccess) {
              this.modalService.openDialog(
                PatientSchedulerModalComponent,
                action.scheduled_address,
                modalMetaData
              );
              return;
            }

            if (hasCareTeamHRAAccess) {
              this.modalService.openDialog(
                NursePractitionerPatientSchedulerModalComponent,
                action.scheduled_address,
                modalMetaData
              );
              return;
            }
          }
        ),
        take(1)
      )
      .subscribe();
  }
}
