<div>
  <div class="flex items-center justify-between">
    <strong class="text-grey-100 text-xs font-semibold uppercase tracking-wider">
      Outreach Attempts
    </strong>

    <div class="text-grey-70 flex items-center gap-x-1">
      <t-button (click)="openNewOutreachAttemptModal()" size="tiny" variant="secondary">
        Log Attempt
      </t-button>
    </div>
  </div>

  @if (outreachAttemptsItems$ | async; as outreachAttemptsItems) {
    @if (outreachAttemptsItems.length > 0) {
      <t-accordion class="mt-5 block">
        @for (item of outreachAttemptsItems; track item.global_id; let index = $index) {
          <t-accordion-item variant="compact" [idx]="index" [expanded]="index === 0">
            <div header class="flex items-center gap-x-3">
              <strong class="text-grey-100 text-xs font-semibold">
                {{ item.created_at | date: 'M/d/yy' }}
              </strong>
              <span class="text-grey-100 text-xs">
                {{ outcomesCodesToDescriptionMapping[item.result] }}
              </span>
            </div>

            <div class="mt-5 space-y-2">
              @if (item.follow_up_call_date) {
                <div class="text-grey-60 flex items-center gap-x-2 text-xs">
                  <t-icon key="call" size="tiny" color="inherit"></t-icon>
                  <span class="font-semibold">
                    Time of follow-up call: {{ item.follow_up_call_date | date: 'M/d/yy' }}
                  </span>
                </div>
              }

              @if (item.reason) {
                <p class="text-grey-60 block text-xs">
                  {{ item.reason }}
                </p>
              }
            </div>
          </t-accordion-item>
        }
      </t-accordion>
    }
  }
</div>
