<span
  class="wrapper variant-{{ variant }} size-{{ size }}"
  [ngClass]="{
    'is-active': isActive,
    'is-disabled': isDisabled
  }">
  <span class="flex">
    <t-icon
      class="pr-2.5"
      *ngIf="icon"
      [key]="icon"
      color="inherit"
      [size]="size === 'large' ? 'small' : 'tiny'"></t-icon>
    <ng-content></ng-content>
  </span>

  <t-icon
    class="opacity-40"
    *ngIf="isActive"
    key="keyboard-arrow-right"
    color="inherit"
    [size]="size === 'large' ? 'small' : 'tiny'"></t-icon>
</span>
