export class GetPriorAuthorizationsList {
  static readonly type = '[PA List] Get prior authorizations list';
  constructor(public payload: { pharmacyId: number }) {}
}

export class EmptyPriorAuthorizationsList {
  static readonly type = '[PA List] Empty prior authorizations list';
}

export class GetSinglePatientPriorAuthorizationsList {
  static readonly type = '[Patient] Get single patient prior authorizations list';
  constructor(public payload: { globalId?: number }) {}
}
