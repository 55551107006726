import { AppointmentAddress } from '@troyai/actions/data-access';
import {
  Appointment,
  ScheduleAppointmentToPatientAsPharmacyPayload,
} from '../models/appointment.interface';
import { NursePractitioner } from '../models/nurse-practitioner.interface';
import { HraScheduleLocationModalState } from './care-state.model';

export class GetCareActionsList {
  static readonly type = '[Care] Get care actions list';
}

export class GetAppointmentsList {
  static readonly type = '[Care][Appointments] Get appointments list';
  constructor(public payload: { startDate: Date; endDate: Date }) {}
}

export class NavigateToNextWeek {
  static readonly type = '[Care][Appointments] Navigate to next week';
}

export class NavigateToPreviousWeek {
  static readonly type = '[Care][Appointments] Navigate to previous week';
}

export class NavigateToCurrentWeek {
  static readonly type = '[Care][Appointments] Navigate to current week';
}

export class RemoveSlotsFromDay {
  static readonly type = '[Care][Appointments] Remove slots from day';
  constructor(public payload: { groupKey: string }) {}
}

export class GetPatientsListToBeScheduled {
  static readonly type = '[Care][Appointments] Get patients list to be scheduled';
}

export class AssignAppointmentToPatient {
  static readonly type = '[Care][Appointments] Assign appointment to patient';
  constructor(public payload: { timeslot: Appointment; actionId: number; addressHash: string }) {}
}

export class UnassignAppointmentForPatient {
  static readonly type = '[Care][Appointments] Unassign appointment for patient';
  constructor(public payload: { timeslotId: number; reason: string }) {}
}

export class RemoveInnerSlot {
  static readonly type = '[Care][Appointments] Remove inner slot';
  constructor(public payload: { timeslotId: number; day: string }) {}
}

export class RestoreInnerSlot {
  static readonly type = '[Care][Appointments] Restore inner slot';
  constructor(public payload: { timeslotId: number; day: string }) {}
}

export class ToggleAppointmentsGroup {
  static readonly type = '[Care][Appointments] Toggle appointments group';
  constructor(public payload: { expanded: boolean; idx: number }) {}
}

export class GetNursePractitionersList {
  static readonly type = '[Care][Appointments] Get nurse practitioners list';
}

// Care scheduling as pharmacy user
export class GetAppointmentsListAsPharmacy {
  static readonly type = '[Patient][Appointments] Get appointments list as pharmacy';
  constructor(
    public payload: {
      actionGlobalId: number;
      addressHash: string;
      startDate: Date;
      endDate: Date;
      scheduleTelehealth: boolean;
      NPGlobalId: number;
    }
  ) {}
}

export class GetAllAppointmentsDataAsPharmacy {
  static readonly type = '[Care][Appointments] Get all appointments as pharmacy';
  constructor(public payload: { startDate: Date; endDate: Date }) {}
}

export class SchedulePatientAppointmentAsPharmacy {
  static readonly type = '[Care][Appointments] Schedule patient appointment as pharmacy';
  constructor(
    public payload: {
      actionId: number;
      data: ScheduleAppointmentToPatientAsPharmacyPayload;
    }
  ) {}
}

export class UnscheduleAppointmentForPatientAsPharmacy {
  static readonly type = '[Care][Appointments] Unschedule patient appointment as pharmacy';
  constructor(
    public payload: {
      reason: string;
      actionId: number;
      timeslotId?: number;
    }
  ) {}
}

export class GetAppointmentActionPreview {
  static readonly type = '[Care][Appointments] Get appointment action preview';
  constructor(public payload: { actionId: number }) {}
}

export class SetScheduleAppointmentAddress {
  static readonly type = '[Patient][Appointments] Set schedule appointment address';
  constructor(public payload: { address?: AppointmentAddress; isTelehealth?: boolean }) {}
}

export class SetScheduleAppointmentNursePractitioner {
  static readonly type = '[Patient][Appointments] Set schedule appointment nurse practitioner';
  constructor(public payload: { nursePractitioner?: NursePractitioner }) {}
}

export class UpdateScheduleLocationModalState {
  static readonly type = '[Care][Appointments] Update scheduling location modal state';
  constructor(public payload: Partial<HraScheduleLocationModalState>) {}
}

export class GetMemberHRAItems {
  static readonly type = '[HRA] Get member HRA items';
  constructor(
    public payload: {
      memberGlobalId: number;
    }
  ) {}
}

export class GetMemberHRAPreviewURL {
  static readonly type = '[HRA] Get member HRA preview URL';
  constructor(
    public payload: {
      memberGlobalId: number;
      fileId: number;
    }
  ) {}
}

export class AddMemberHraAddendum {
  static readonly type = '[HRA] Add member HRA addendum';
  constructor(
    public payload: {
      memberGlobalId: number;
      hraId: number;
      content: string;
    }
  ) {}
}
