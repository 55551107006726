import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateDistancePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 't-widget-card-footer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, DateDistancePipe, RouterModule],
  templateUrl: './widget-card-footer.component.html',
})
export class WidgetCardFooterComponent {}
