import { GenericRestApiOKResponse, RestApiClientService } from '@troyai/rest-api-client';
import { TrackEvent } from '../models/track-event.interface';
import { TrackerPlugin } from '../models/tracker-plugin.interface';
import { inject } from '@angular/core';
import { catchError, of } from 'rxjs';

export class SelfHostedPostHog implements TrackerPlugin {
  name = 'Self-Hosted PostHog Plugin';

  private restApiService = inject(RestApiClientService);

  trackEvent(event: TrackEvent) {
    // convert all event.eventProps to strings
    const postHogEventProps = Object.entries(event.eventProps).reduce(
      (acc, [key, value]) => {
        acc[key] = String(value);
        return acc;
      },
      {} as Record<string, string>
    );

    const postHogEvent = {
      ...event,
      eventProps: postHogEventProps,
    };

    return this.restApiService.post<GenericRestApiOKResponse>('posthog-event', postHogEvent).pipe(
      catchError(() => {
        // TODO: create a silent type of error, that does not trigger toast notifications
        return of(null);
      })
    );
  }
}
