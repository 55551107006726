import { AfterViewInit, Component, DestroyRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedTourService } from '../../guided-tour.service';
import {
  generateFirstSlideButtons,
  generateGuidedTourDefaultConfigOptions,
  generateLastSlideButtons,
  generatePositionStrategy,
} from '../../guided-tour.config';
import { Store } from '@ngxs/store';
import { PatientsState } from '@troyai/patients/data-access';
import { combineLatest, delay, filter, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TourStartingPromptComponent } from '../tour-starting-prompt/tour-starting-prompt.component';
import { ModalService } from '@troyai/ui-kit';

@Component({
  selector: 't-single-patient-tour',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-patient-tour.component.html',
})
export class SinglePatientTourComponent implements AfterViewInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private store: Store,
    private modalService: ModalService
  ) {}

  selectedPatient$ = this.store
    .select(PatientsState.selectedPatient)
    .pipe(filter((patient) => !!patient));

  destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    this.guidedTourService.defaultStepOptions = generateGuidedTourDefaultConfigOptions(
      this.guidedTourService
    );
    this.guidedTourService.tourName = 'single-patient';
    this.guidedTourService.modal = true;
    this.guidedTourService.confirmCancel = false;
    this.guidedTourService.addSteps([
      {
        id: '1-patient-context-overview',
        attachTo: {
          element: '#tour-patient-context-overview',
          on: generatePositionStrategy('right'),
        },
        title: 'Patient overview',
        text: ['The Patient sidebar shows all the basic patient information.'],
        buttons: generateFirstSlideButtons(this.guidedTourService),
      },
      {
        id: '2-patient-context-tabs',
        attachTo: {
          element: '#tour-patient-context-tabs',
          on: generatePositionStrategy('bottom'),
        },
        title: 'Patient overview',
        text: ['Switch between general info and contact details.'],
      },
      {
        id: '3-patient-critical-comments',
        attachTo: {
          element: '#tour-patient-critical-comments',
          on: generatePositionStrategy('right'),
        },
        scrollTo: false,
        title: 'Patient overview',
        text: [
          'Introducing "critical comments", a simple way for you to store critical details about the patient.',
        ],
      },
      {
        id: '4-patient-nav-items',
        attachTo: {
          element: '#tour-patient-nav-items',
          on: generatePositionStrategy('bottom'),
        },
        scrollTo: false,
        title: 'Patient overview',
        text: ['Browse through various types of information we have about the patient.'],
      },
      {
        id: '5-patient-medical-records',
        attachTo: {
          element: '#tour-patient-medical-records',
          on: generatePositionStrategy('bottom'),
        },
        buttons: generateLastSlideButtons(this.guidedTourService),
        scrollTo: true,
        title: 'Patient overview',
        text: ['See data from the patient’s medical records.'],
      },
    ]);

    combineLatest([this.selectedPatient$, of(this.guidedTourService.tourName)])
      .pipe(
        delay(500),
        tap(([, tourName]) => {
          if (!this.guidedTourService.getLocalStorageCompletionStatusByName(tourName)) {
            this.modalService.openPromptDialog(
              TourStartingPromptComponent,
              {
                tourIntroText:
                  'This is the Patient profile page, and it contains patient detail from their medical record.',
              },
              {
                hideCloseButton: true,
              }
            );
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
