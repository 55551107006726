/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { Environment } from '@troyai/envs';
import { PharmacyContextState } from './+state/pharmacy-context.state';

export const IS_FILTERED_BY_PHARMACY = new HttpContextToken<boolean>(() => false);

@Injectable()
export class SelectedPharmacyInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private env: Environment
  ) {}
  selectedPharmacyId$ = this.store.select(PharmacyContextState.selectedPharmacyId);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check all the requests that are GET requests and have the pharmacy selection context set to true
    if (
      request.url.includes('/api/v1/') &&
      request.method === 'GET' &&
      request.context.get(IS_FILTERED_BY_PHARMACY) === true
    ) {
      return this.selectedPharmacyId$.pipe(
        // No need to proceed if the selected pharmacy is not null (All pharmacies selected) or a valid ID (specific pharmacy selected)
        filter((selectedPharmacyId) => selectedPharmacyId !== undefined),
        distinctUntilChanged(),
        take(1),
        switchMap((selectedPharmacyId) => {
          // A null value means that all pharmacies are selected
          if (selectedPharmacyId === null) {
            return next.handle(request);
          }

          // If a valid ID is selected, filter the response to only include the selected pharmacy items
          return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                if (
                  event.body &&
                  event.body.data &&
                  Array.isArray(event.body.data) &&
                  event.body.data.length > 0 &&
                  event.body.data[0].pharmacy_id !== undefined
                ) {
                  const filteredData = event.body.data.filter(
                    (obj: any) => obj.pharmacy_id === selectedPharmacyId
                  );

                  if (!this.env.production) {
                    console.log(`Filtered data for ${request.url}`, filteredData);
                  }

                  return event.clone({
                    body: {
                      data: filteredData,
                    },
                  });
                }
              }
              return event;
            })
          );
        })
      );
    }
    // Pass the request through if it doesn't match the criteria
    return next.handle(request);
  }
}
