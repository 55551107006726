import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ActionsApiService } from '@troyai/actions/data-access';
import { UserRoles, UserState } from '@troyai/auth/data-access';
import { ApplicationError } from '@troyai/error-handling';
import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import { SinglePatientPriorAuthorizationState } from '@troyai/prior-authorization/data-access';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import { minutesToMilliseconds } from 'date-fns';
import { catchError, from, map, mergeMap, tap, throwError, timer, toArray } from 'rxjs';
import { Patient } from '../models/patient.interface';
import { PatientsFilesApiService } from '../patient-files-api.service';
import { PatientsApiService } from '../patients-api.service';
import { PatientsStateModel } from './patients-state.model';
import {
  AddPatientFiles,
  DeletePatientFile,
  DownloadPatientFile,
  EmptyPatientFiles,
  EmptyPatientsList,
  EmptySelectedPatient,
  GetPatientEncounters,
  GetPatientOngoingCareActions,
  GetPatientRxClaims,
  GetPatientsList,
  GetSinglePatient,
  GetSinglePatientAsNP,
  PopulatePatientFiles,
  PrePopulateSinglePatient,
  RequestPatientReassignment,
  SubmitCriticalComment,
} from './patients.actions';

@State<PatientsStateModel>({
  name: 'patientsState',
  defaults: {
    patients: [],
    patientsLoading: false,
    selectedPatient: undefined,
    selectedPatientLoading: false,
    selectedPatientError: false,
    selectedPatientPharmacyMismatch: false,
    selectedPatientRxClaims: [],
    selectedPatientEncounters: [],
    selectedPatientEncountersLoading: false,
    reassignPatientRequest: {
      loading: false,
      errors: [],
    },
    uploadedPatientFilesList: {
      result: [],
      loadingStatus: LoadingState.INITIAL,
    },
    ongoingCareActions: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
  },
  children: [SinglePatientPriorAuthorizationState],
})
@Injectable()
export class PatientsState {
  constructor(
    private patientsApiService: PatientsApiService,
    private patientFilesApiService: PatientsFilesApiService,
    private actionsApiService: ActionsApiService,
    private store: Store
  ) {}

  @Selector()
  static patientsList(state: PatientsStateModel) {
    return state.patients;
  }

  @Selector()
  static patientsLoading(state: PatientsStateModel) {
    return state.patientsLoading;
  }

  @Selector()
  static selectedPatient(state: PatientsStateModel) {
    return state.selectedPatient;
  }

  @Selector()
  static selectedPatientError(state: PatientsStateModel) {
    return state.selectedPatientError;
  }

  @Selector()
  static selectedPatientLoading(state: PatientsStateModel) {
    return state.selectedPatientLoading;
  }

  @Selector()
  static selectedPatientPharmacyMismatch(state: PatientsStateModel) {
    return state.selectedPatientPharmacyMismatch;
  }

  @Selector()
  static selectedPatientRxClaims(state: PatientsStateModel) {
    return state.selectedPatientRxClaims;
  }

  @Selector()
  static getReassignPatientRequest(state: PatientsStateModel) {
    return state.reassignPatientRequest;
  }

  @Selector()
  static getPatientActions(state: PatientsStateModel) {
    return state.selectedPatient?.actions;
  }

  @Selector()
  static getPatientEncountersSummary(state: PatientsStateModel) {
    return state.selectedPatient?.encounters;
  }

  @Selector()
  static getPatientEncounters(state: PatientsStateModel) {
    return state.selectedPatientEncounters;
  }

  @Selector()
  static getPatientEncountersLoading(state: PatientsStateModel) {
    return state.selectedPatientEncountersLoading;
  }

  @Selector()
  static uploadedPatientFilesList(state: PatientsStateModel) {
    return state.uploadedPatientFilesList.result;
  }

  @Selector()
  static uploadedPatientFilesListLoadingState(state: PatientsStateModel) {
    return getLoadingStatus(state.uploadedPatientFilesList.loadingStatus);
  }

  @Selector()
  static ongoingCareActions(state: PatientsStateModel) {
    return state.ongoingCareActions.result;
  }

  @Selector()
  static ongoingCareActionsLoadingStatus(state: PatientsStateModel) {
    return getLoadingStatus(state.ongoingCareActions.loadingStatus);
  }

  @Action(GetPatientsList)
  getPatientsList({ setState, patchState }: StateContext<PatientsStateModel>) {
    patchState({ patientsLoading: true });
    return this.patientsApiService
      .getAll({
        ttl: minutesToMilliseconds(4),
      })
      .pipe(
        tap(() => {
          patchState({
            patients: [],
          });
        }),
        tap((allPatients) =>
          setState(
            patch<PatientsStateModel>({
              patients: allPatients,
            })
          )
        ),
        tap(() =>
          patchState({
            patientsLoading: false,
          })
        )
      );
  }

  @Action(EmptyPatientsList)
  emptyAllActionsList({ setState }: StateContext<PatientsStateModel>) {
    return setState(
      patch<PatientsStateModel>({
        patients: [],
      })
    );
  }

  @Action(EmptySelectedPatient)
  emptySelectedPatient({ setState }: StateContext<PatientsStateModel>) {
    return setState(
      patch<PatientsStateModel>({
        selectedPatient: undefined,
      })
    );
  }

  @Action(GetSinglePatient)
  getSinglePatient({ patchState }: StateContext<PatientsStateModel>, action: GetSinglePatient) {
    const currentSelectedGlobalPharmacy = this.store.selectSnapshot(
      PharmacyContextState.selectedPharmacyId
    );
    const currentUserRoles = this.store.selectSnapshot(UserState.userRoles);

    patchState({
      selectedPatient: undefined,
      selectedPatientError: undefined,
      selectedPatientPharmacyMismatch: false,
      selectedPatientLoading: true,
    });
    if (action.payload.globalId) {
      return this.patientsApiService.getSinglePatient(action.payload.globalId).pipe(
        map((patient) => {
          // Only the pharmacy user role is bound to a pharmacy when viewing a patient
          if (currentUserRoles?.includes(UserRoles.PharmacyUser) && currentUserRoles.length === 1) {
            if (
              currentSelectedGlobalPharmacy !== null &&
              patient.pharmacy_global_id !== currentSelectedGlobalPharmacy
            ) {
              patchState({
                selectedPatientPharmacyMismatch: true,
                selectedPatient: undefined,
                selectedPatientLoading: false,
              });
              throw new ApplicationError(
                'Patient doesn’t belong to the currently selected pharmacy'
              );
            }
          }
          return patient;
        }),
        catchError((err) => {
          if (err instanceof ApplicationError) {
            patchState({
              selectedPatient: undefined,
              selectedPatientError: false,
              selectedPatientPharmacyMismatch: true,
              selectedPatientLoading: false,
            });
          } else {
            patchState({
              selectedPatient: undefined,
              selectedPatientError: true,
              selectedPatientPharmacyMismatch: false,
              selectedPatientLoading: false,
            });
          }

          return throwError(() => err);
        }),
        tap((patient) => {
          patchState({ selectedPatient: patient, selectedPatientLoading: false });
        })
      );
    } else {
      return patchState({ selectedPatient: undefined });
    }
  }

  @Action(GetSinglePatientAsNP)
  getSinglePatientAsNP(
    { patchState }: StateContext<PatientsStateModel>,
    action: GetSinglePatientAsNP
  ) {
    patchState({
      selectedPatient: undefined,
      selectedPatientError: undefined,
    });
    if (action.payload.globalId) {
      return this.patientsApiService.getSinglePatient(action.payload.globalId).pipe(
        catchError((err) => {
          patchState({
            selectedPatient: undefined,
            selectedPatientError: true,
            selectedPatientPharmacyMismatch: false,
          });

          return throwError(() => err);
        }),
        tap((patient) => {
          patchState({ selectedPatient: patient });
        })
      );
    } else {
      return patchState({ selectedPatient: undefined });
    }
  }

  @Action(PrePopulateSinglePatient)
  prePopulateSinglePatient(
    { patchState }: StateContext<PatientsStateModel>,
    action: PrePopulateSinglePatient
  ) {
    patchState({ selectedPatient: undefined, selectedPatientError: undefined });
    if (action.payload.patient) {
      patchState({ selectedPatient: action.payload.patient as Patient });
    }
  }

  @Action(GetPatientRxClaims)
  getPatientRxClaims({ patchState }: StateContext<PatientsStateModel>, action: GetPatientRxClaims) {
    if (action.payload.globalId) {
      return this.patientsApiService
        .getRxClaims(action.payload.globalId)
        .pipe(tap((rxClaims) => patchState({ selectedPatientRxClaims: rxClaims })));
    } else {
      return patchState({ selectedPatientRxClaims: [] });
    }
  }

  @Action(RequestPatientReassignment)
  requestPatientReassignment({ patchState, getState }: StateContext<PatientsStateModel>) {
    const { selectedPatient } = getState();
    if (selectedPatient) {
      patchState({
        reassignPatientRequest: {
          loading: true,
          errors: [],
        },
      });
      return timer(500).pipe(
        mergeMap(() => {
          return this.patientsApiService.requestPatientReassignment(selectedPatient.global_id).pipe(
            catchError((errors) => {
              patchState({
                reassignPatientRequest: {
                  loading: false,
                  errors,
                },
              });
              return throwError(() => errors);
            }),
            tap(() =>
              patchState({
                reassignPatientRequest: {
                  loading: false,
                  errors: [],
                },
              })
            )
          );
        })
      );
    }

    return;
  }

  @Action(GetPatientEncounters)
  getPatientEncounters(
    { patchState }: StateContext<PatientsStateModel>,
    action: GetPatientEncounters
  ) {
    patchState({ selectedPatientEncountersLoading: true });

    if (action.payload.globalId) {
      return this.patientsApiService.getEncounters(action.payload.globalId).pipe(
        tap((encountersData) =>
          patchState({
            selectedPatientEncounters: encountersData,
            selectedPatientEncountersLoading: false,
          })
        )
      );
    } else {
      return;
    }
  }

  @Action(SubmitCriticalComment)
  submitCriticalComment(
    { patchState, getState }: StateContext<PatientsStateModel>,
    action: SubmitCriticalComment
  ) {
    const { selectedPatient } = getState();

    if (action.payload.globalId && selectedPatient) {
      return this.patientsApiService
        .submitCriticalComment(action.payload.globalId, action.payload.data)
        .pipe(
          tap((criticalComment) => {
            patchState({
              selectedPatient: {
                ...selectedPatient,
                critical_comments: [criticalComment, ...selectedPatient.critical_comments],
              },
            });
          })
        );
    } else {
      return;
    }
  }

  @Action(AddPatientFiles)
  addPatientFile(
    { getState, patchState }: StateContext<PatientsStateModel>,
    action: AddPatientFiles
  ) {
    const { selectedPatient, uploadedPatientFilesList } = getState();

    patchState({
      uploadedPatientFilesList: {
        ...uploadedPatientFilesList,
        loadingStatus: LoadingState.LOADING,
      },
    });

    if (action.payload.memberGlobalId && selectedPatient) {
      const files = action.payload.files;

      return from(files).pipe(
        mergeMap((file) => {
          return this.patientFilesApiService.uploadFiles(action.payload.memberGlobalId, file);
        }, 5),
        toArray(),
        tap((newFiles) => {
          patchState({
            uploadedPatientFilesList: {
              result: [...newFiles, ...(uploadedPatientFilesList.result || [])],
              loadingStatus: LoadingState.LOADED,
            },
          });
        })
      );
    } else {
      return;
    }
  }

  @Action(DownloadPatientFile)
  downloadPatientFile(_: StateContext<PatientsStateModel>, action: DownloadPatientFile) {
    return this.patientFilesApiService
      .getFileSignedURL(action.payload.memberId, action.payload.fileId)
      .pipe(
        map((signedUrl) => {
          window.location.href = signedUrl;
        })
      );
  }

  @Action(EmptyPatientFiles)
  emptyPatientFiles(
    { patchState, getState }: StateContext<PatientsStateModel>,
    action: EmptyPatientFiles
  ) {
    const { memberGlobalId, discard } = action.payload;
    if (memberGlobalId && discard) {
      const { uploadedPatientFilesList } = getState();
      const files = uploadedPatientFilesList.result || [];

      if (files.length === 0) {
        return;
      }

      const fileIds = files.map((file) => file.id);

      return from(fileIds).pipe(
        mergeMap((fileId: number) => {
          return this.patientFilesApiService.deleteFile(memberGlobalId, fileId);
        }, 3),
        tap(() =>
          patchState({
            uploadedPatientFilesList: {
              result: [],
              loadingStatus: LoadingState.INITIAL,
            },
          })
        ) // Maximum 3 file deletion at once
      );
    } else {
      return patchState({
        uploadedPatientFilesList: {
          result: [],
          loadingStatus: LoadingState.INITIAL,
        },
      });
    }
  }

  @Action(DeletePatientFile)
  deletePatientFile(
    { getState, patchState }: StateContext<PatientsStateModel>,
    action: DeletePatientFile
  ) {
    const { selectedPatient, uploadedPatientFilesList } = getState();

    const files = uploadedPatientFilesList.result || [];

    if (action.payload.memberGlobalId && selectedPatient) {
      return this.patientFilesApiService
        .deleteFile(action.payload.memberGlobalId, action.payload.fileId)
        .pipe(
          tap(() => {
            const remainingPatientFiles = files.filter((item) => item.id !== action.payload.fileId);
            patchState({
              uploadedPatientFilesList: {
                result: [...remainingPatientFiles],
                loadingStatus: LoadingState.LOADED,
              },
            });
          })
        );
    } else {
      return;
    }
  }

  @Action(PopulatePatientFiles)
  populatePatientFile(
    { patchState }: StateContext<PatientsStateModel>,
    action: PopulatePatientFiles
  ) {
    return patchState({
      uploadedPatientFilesList: {
        result: action.payload.files,
        loadingStatus: LoadingState.LOADED,
      },
    });
  }

  @Action(GetPatientOngoingCareActions)
  getPatientOngoingCareActions(
    { patchState }: StateContext<PatientsStateModel>,
    action: GetPatientOngoingCareActions
  ) {
    patchState({
      ongoingCareActions: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.actionsApiService
      .getOngoingActions(action.payload.memberGlobalId, {
        ttl: minutesToMilliseconds(30),
      })
      .pipe(
        tap((actions) => {
          patchState({
            ongoingCareActions: {
              result: actions,
              loadingStatus: LoadingState.LOADED,
            },
          });
        })
      );
  }
}
