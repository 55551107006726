import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardComponent, ShadowSize } from '../card/card.component';
import { IconsModule } from '../icon/icon.module';

@Component({
  selector: 't-expandable-card',
  standalone: true,
  imports: [CommonModule, CardComponent, IconsModule],
  templateUrl: './expandable-card.component.html',
  animations: [
    trigger('expandAnimation', [
      state(
        'closed',
        style({
          height: '0px',
          opacity: 0,
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('closed => open', [
        group([
          animate(
            '180ms ease-in-out',
            style({
              height: '*',
            })
          ),
          animate(
            '120ms 120ms ease-in-out',
            style({
              opacity: 1,
            })
          ),
        ]),
      ]),
      transition('open => closed', [
        group([
          animate(
            '120ms ease-in-out',
            style({
              opacity: 0,
            })
          ),
          animate(
            '180ms 180ms ease-in-out',
            style({
              height: '0px',
            })
          ),
        ]),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableCardComponent {
  _isOpened = true;

  @Input() shadowSize: ShadowSize = 'small';
  @Input() set isOpened(openState) {
    this._isOpened = openState;
  }

  get isOpened() {
    return this._isOpened;
  }

  toggle() {
    this._isOpened = !this._isOpened;
  }
}
