/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  extractDeepPropertyByMapKey,
  extractDeepPropertyByParentMapKey,
  isBoolean,
  isNumberFinite,
  isString,
  isUndefined,
} from './helpers';

export function filterByProperties<T>(
  input: T,
  props: Array<string>,
  search?: any,
  strict?: boolean
): T;
export function filterByProperties(
  input: any[],
  props: Array<string>,
  search?: any,
  strict?: boolean
): any[];
export function filterByProperties(
  input: any,
  props: Array<string>,
  search: any = '',
  strict = false
): any {
  if (
    !Array.isArray(input) ||
    (!Array.isArray(search) && !isString(search) && !isNumberFinite(search) && !isBoolean(search))
  ) {
    return input;
  }

  // This would add support for multiple search criteria, following OR logic.
  // Temporary disabled, as it's not used anywhere.

  // const terms = String(search).toLowerCase().split(',');
  const terms = [String(search).toLowerCase()];

  return input.filter((obj) => {
    return props.some((prop) => {
      return terms.some((term) => {
        const value = extractDeepPropertyByMapKey(obj, prop);

        const { props, tail } = extractDeepPropertyByParentMapKey(obj, prop);

        if (isUndefined(value) && !isUndefined(props) && Array.isArray(props)) {
          return props.some((parent) => {
            const str = String(parent[tail]).toLowerCase();

            return strict ? str === term : !!~str.indexOf(term);
          });
        }

        if (isUndefined(value)) {
          return false;
        }

        const strValue: string = String(value).toLowerCase();
        // For debugging purposes
        // const match = !!~strValue.indexOf(term);
        // if (match) {
        //   console.log(`Match found for "${term}" in ${prop} having value ${strValue}`);
        // }

        return strict ? term === strValue : !!~strValue.indexOf(term);
      });
    });
  });
}
