import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsModule } from '../icon/icon.module';

export interface AccordionItemToggleEvent {
  expanded: boolean;
  idx: number;
}

export type Variants = 'default' | 'compact';

@Component({
  selector: 't-accordion-item',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule, IconsModule],
  templateUrl: './accordion-item.component.html',
})
export class AccordionItemComponent {
  @Input() idx = 0;
  @Input() expanded = false;
  @Input() isEmpty = false;
  @Input() variant: Variants = 'default';

  @Output() toggleChanged = new EventEmitter<AccordionItemToggleEvent>();

  toggle(accordionItem: CdkAccordionItem, idx: number) {
    accordionItem.toggle();
    this.toggleChanged.emit({
      expanded: accordionItem.expanded,
      idx,
    });
  }
}
