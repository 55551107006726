<div class="relative">
  <label *ngIf="label" class="text-grey-50 mb-1 block text-sm font-medium">
    {{ label }}
  </label>
  <div class="relative">
    <t-input-text
      [placeholder]="placeholder"
      [readonly]="true"
      [formControl]="dateViewControl"
      (click)="picker.showPicker()" />
    <input
      class="absolute left-0 top-[100%] h-0 w-full p-0 opacity-0"
      type="date"
      [min]="disablePast ? todayDate : ''"
      [max]="disableFuture ? todayDate : ''"
      [formControl]="dateControl"
      (blur)="onTouched()"
      (input)="onTouched()"
      #picker />
    <div
      class="text-grey-55 hover:text-grey-80 absolute right-2 top-[50%] -translate-y-[50%] cursor-pointer cursor-pointer">
      <t-icon
        (click)="$event.preventDefault(); picker.showPicker()"
        key="event"
        color="inherit"
        size="small" />
    </div>
  </div>
</div>
