import { Pipe, PipeTransform } from '@angular/core';
import { differenceInYears } from 'date-fns';

@Pipe({
  name: 'ageByDob',
  standalone: true,
})
export class AgeByDobPipe implements PipeTransform {
  transform(input: string, dateOfDeath?: string | null): string {
    const dateOfBirthDate = new Date(input);
    const todayOrDateOfDeath = dateOfDeath ? new Date(dateOfDeath) : new Date();
    const years = differenceInYears(todayOrDateOfDeath, dateOfBirthDate);
    return `age ${years}`;
  }
}
