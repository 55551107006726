import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UploadedFile } from '@troyai/patients/data-access';

@Component({
  selector: 't-patient-file-card',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './patient-file-card.component.html',
})
export class PatientFileCardComponent implements OnInit {
  @Input() file?: UploadedFile;

  hasPreview = false;
  fileExtension = '';
  private extensionWithPreview = ['jpg', 'png', 'jpeg'];

  private getFileExtension(fileName: string) {
    const lastDotIndex = fileName.lastIndexOf('.');

    if (lastDotIndex === -1 || lastDotIndex === 0) {
      return '';
    }

    return fileName.substring(lastDotIndex + 1);
  }

  ngOnInit() {
    const fileName = this.file ? this.file.file_name_original : false;

    if (fileName) {
      this.fileExtension = this.getFileExtension(fileName);
      this.hasPreview = this.extensionWithPreview.includes(this.fileExtension);
    }
  }
}
