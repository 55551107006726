import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icon/icon.module';
import { PopoverModule } from '../popover/popover.module';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 't-kebab-menu',
  standalone: true,
  imports: [CommonModule, IconsModule, PopoverModule, CardComponent],
  templateUrl: './kebab-menu.component.html',
})
export class KebabMenuComponent {}
