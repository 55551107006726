import { CommonModule, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  NgModule,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthHttpInterceptor, AuthModule, HttpMethod } from '@auth0/auth0-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { provideToastr } from 'ngx-toastr';

import { ActionsState } from '@troyai/actions/data-access';
import { ActivityState } from '@troyai/activity/data-access';
import { AdherenceState } from '@troyai/adherence/data-access';
import {
  ADHERENCE_CONFIG_TOKEN,
  AdherenceRatingsService,
  adherencePercentagesConfig,
} from '@troyai/adherence/services';
import { UserState, getAuth0RedirectUri } from '@troyai/auth/data-access';
import { RolesDirective, UserRolesService } from '@troyai/auth/feature';
import { CareManagementState } from '@troyai/care-management/data-access';
import { CarePlansState, CaseNotesState } from '@troyai/case-notes/data-access';
import { DashboardState } from '@troyai/dashboard/data-access';
import { PIIAnonymizerInterceptor } from '@troyai/demo-mode';
import { Environment } from '@troyai/envs';
import { GlobalErrorHandler, HttpErrorInterceptor } from '@troyai/error-handling';
import { FeatureFlagDirective, FeatureFlagsService } from '@troyai/feature-flags';
import { CareState } from '@troyai/hra/data-access';
import { MinimalLayoutComponent, SidebarLayoutComponent } from '@troyai/layout';
import { LeaderboardState } from '@troyai/leaderboard/data-access';
import { MedicationState } from '@troyai/medication/medication-data-access';
import { MainMenuComponent } from '@troyai/navigation';
import { NotificationsState } from '@troyai/notifications/data-access';
import { NotificationsCountBadgeComponent } from '@troyai/notifications/ui';
import { OnboardingState } from '@troyai/onboarding/data-access';
import { PatientsState } from '@troyai/patients/data-access';
import { FullNamePipe } from '@troyai/patients/util';
import { PaymentsState } from '@troyai/payments/data-access';
import {
  PharmacyContextState,
  SelectedPharmacyInterceptor,
} from '@troyai/pharmacy-context/data-access';
import { BrandingComponent } from '@troyai/portal/branding';
import { ClinicalObservationsState } from '@troyai/portal/clinical-observations/data-access';
import { CustomPageTitleStrategy } from '@troyai/portal/common/navigation';
import { DiagnosesState } from '@troyai/portal/diagnoses/data-access';
import { DynamicFormState } from '@troyai/portal/dynamic-forms/data-access';
import { ThemeService } from '@troyai/portal/theme';
import {
  PriorAuthorizationsState,
  SinglePatientPriorAuthorizationState,
} from '@troyai/prior-authorization/data-access';
import { PwaService } from '@troyai/pwa';
import { ResourcesState } from '@troyai/resources/data-access';
import {
  API_BASE_URL,
  HttpCachingInterceptor,
  RestApiClientService,
} from '@troyai/rest-api-client';
import { SelfHostedPostHog, TRACKER_PLUGIN, TrackService } from '@troyai/tracking';
import { IconsModule, LoaderComponent, ToastComponent } from '@troyai/ui-kit';
import { TenantRefInterceptor, TenantService } from 'multi-tenancy';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';

let devImports = [NgxsReduxDevtoolsPluginModule.forRoot()];

if (environment.production) {
  devImports = [];
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    SidebarLayoutComponent,
    MinimalLayoutComponent,
    IconsModule,
    MainMenuComponent,
    LoaderComponent,
    NotificationsCountBadgeComponent,
    NgxsModule.forRoot(
      [
        UserState,
        PatientsState,
        ActionsState,
        ResourcesState,
        CareState,
        PriorAuthorizationsState,
        SinglePatientPriorAuthorizationState,
        NotificationsState,
        DashboardState,
        AdherenceState,
        PaymentsState,
        PharmacyContextState,
        LeaderboardState,
        ActivityState,
        OnboardingState,
        CaseNotesState,
        CarePlansState,
        CareManagementState,
        MedicationState,
        DiagnosesState,
        DynamicFormState,
        ClinicalObservationsState,
      ],
      {
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
        developmentMode: !environment.production,
      }
    ),
    RouterModule.forRoot(APP_ROUTES, {
      paramsInheritanceStrategy: 'always',
    }),
    AuthModule.forRoot({
      domain: environment.auth0ConnectionData.domain,
      clientId: environment.auth0ConnectionData.clientId,
      authorizationParams: {
        audience: environment.auth0ConnectionData.audience,
        scope: 'profile email',
        redirect_uri: getAuth0RedirectUri(),
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}/*`,
            httpMethod: HttpMethod.Get,
          },
          {
            uri: `${environment.apiUrl}/*`,
            httpMethod: HttpMethod.Post,
          },
          {
            uri: `${environment.apiUrl}/*`,
            httpMethod: HttpMethod.Patch,
          },
          {
            uri: `${environment.apiUrl}/*`,
            httpMethod: HttpMethod.Delete,
          },
          {
            uri: `${environment.apiUrl}/*`,
            httpMethod: HttpMethod.Put,
          },
        ],
      },
    }),
    RolesDirective,
    FeatureFlagDirective,
    BrandingComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ...devImports,
  ],
  declarations: [AppComponent],
  providers: [
    provideAnimations(),
    provideToastr({
      timeOut: 5000,
      toastComponent: ToastComponent,
      closeButton: true,
      tapToDismiss: false,
      onActivateTick: true,
    }),
    FeatureFlagsService,
    TenantService,
    {
      provide: APP_INITIALIZER,
      useFactory: (featureFlagsService: FeatureFlagsService) => () => {
        return featureFlagsService.loadConfig();
      },
      deps: [FeatureFlagsService],
      multi: true,
    },
    UserRolesService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SelectedPharmacyInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PIIAnonymizerInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TenantRefInterceptor, multi: true },
    {
      provide: Environment,
      useValue: environment,
    },
    {
      provide: RestApiClientService,
      useClass: RestApiClientService,
    },
    {
      provide: API_BASE_URL,
      useValue: environment.apiUrl,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    {
      provide: TitleStrategy,
      useClass: CustomPageTitleStrategy,
    },
    AdherenceRatingsService,
    {
      provide: ADHERENCE_CONFIG_TOKEN,
      useValue: adherencePercentagesConfig,
    },
    FullNamePipe,
    TitleCasePipe,
    TrackService,
    { provide: TRACKER_PLUGIN, useClass: SelfHostedPostHog, multi: true },
    PwaService,
    ThemeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
