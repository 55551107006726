<div>
  <t-card class="relative mb-2 block" [isInteractive]="false">
    <div class="flex flex-col justify-between md:flex-row md:items-center">
      <div class="flex items-center gap-x-5">
        <div class="bg-grey-20 text-grey-100 rounded-lg p-4">
          <t-icon key="article" size="medium" color="inherit" />
        </div>
        <div class="space-y-2">
          <strong class="text-grey-100 block text-sm font-semibold">
            @switch (hraItem.type) {
              @case ('hra') {
                Health Risk Assessment
              }

              @case ('npa') {
                NP Assessment
              }

              @default {
                Unknown assessment type
              }
            }
          </strong>
          <span class="text-grey-60 text-xs">
            {{ hraItem.submission_date | date: 'MM/dd/yyyy' }} by {{ hraItem.submitted_by }}
          </span>
        </div>
      </div>
    </div>

    <div class="bg-grey-20 my-5 h-px"></div>

    @if (isFormDisplayed) {
      <form (ngSubmit)="onSubmit()" [formGroup]="form" class="space-y-5">
        <t-input-text
          formControlName="addendum_content"
          id="addendum_content"
          [autofocus]="true"
          inputType="textarea"
          label="Addendum content"
          [readonly]="isSubmissionWarningDisplayed"
          class="block" />
        <t-field-error [controlName]="form.controls['addendum_content']" />

        @if (!isSubmissionWarningDisplayed) {
          <t-button type="submit" class="block">Add addendum</t-button>
        }
      </form>
    }

    @if (isSubmissionWarningDisplayed) {
      <div class="bg-amber-20 mt-5 space-y-5 rounded-lg p-5">
        @if (addingMemberHraAddendumLoadingStatus$ | async; as loadingStatus) {
          @if (loadingStatus.loading) {
            <div class="flex items-center gap-x-2">
              <t-loader size="small" />
              <span class="text-grey-100 text-sm">Adding...</span>
            </div>
          } @else {
            <strong class="text-grey-100 text-sm font-semibold">
              Are you sure you want to add this addendum?
            </strong>

            <div class="flex gap-x-3">
              <t-button (click)="onAddendumConfirmation()" size="small">YES</t-button>
              <t-button (click)="onAddendumCancellation()" size="small" variant="neutral">
                Cancel
              </t-button>
            </div>
          }
        }
      </div>
    }

    <div
      [ngClass]="{
        'pt-12': isFormDisplayed
      }">
      <div class="flex items-center justify-between">
        <strong class="text-sm font-semibold">{{ hraItem.addendums.length }} addendums</strong>
        <t-button (click)="displayForm()" size="tiny" icon="add" iconPos="right">Add</t-button>
      </div>

      @if (hraItem.addendums.length > 0) {
        <div class="bg-grey-20 my-5 h-px"></div>
        <div class="space-y-8">
          @for (addendum of hraItem.addendums; track $index) {
            <div class="space-y-3">
              <span class="text-grey-60 text-xs">
                Append on {{ addendum.created_on | date: 'MM/dd/yyyy' }} by
                {{ addendum.created_by }}
              </span>
              <p
                class="text-grey-70 bg-grey-15 rounded-lg px-5 py-4 text-xs"
                [innerHTML]="addendum.content | newlineToBr"></p>
            </div>
          }
        </div>
      } @else {
        <div class="bg-grey-60 mt-3 flex min-h-[100px] items-center justify-center bg-opacity-5">
          <t-empty-state-card content="There are no addendums for this assessment.">
            @if (hraItem.addendums.length === 5) {
              <p class="text-grey-60 text-xs">
                This assessment has the maximum number of addendums.
              </p>
            } @else {
              <p class="text-grey-60 text-xs">
                You can add up to {{ 5 - hraItem.addendums.length }} addendums.
              </p>
            }
          </t-empty-state-card>
        </div>
      }
    </div>
  </t-card>
</div>
