export class GetAdherenceCategories {
  static readonly type = '[Adherence] Get adherence categories';
  constructor(public payload: { pharmacyId: number }) {}
}

export class GetAdherenceCategoriesItems {
  static readonly type = '[Adherence] Get adherence categories items';
  constructor(public payload: { pharmacyId: number; typeFilter: string }) {}
}

export class GetSingleAdherenceItem {
  static readonly type = '[Adherence] Get single adherence item';
  constructor(public payload: { pharmacyId: number; adherenceItemId: string }) {}
}

export class GetAdherenceDataForPatient {
  static readonly type = '[Adherence] Get adherence items for patient';
  constructor(public payload: { pharmacyId: number; memberId: string }) {}
}

export class GetSingleAdherenceItemPatients {
  static readonly type = '[Adherence] Get single adherence item patients';
  constructor(public payload: { pharmacyId: number; adherenceItemId: string }) {}
}

export class GetAdherenceItemPatientRxClaims {
  static readonly type = '[Adherence] Get single adherence item patients rx-claims';
  constructor(public payload: { pharmacyId: number; adherenceItemId: string; memberId: string }) {}
}
