import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { NotificationsJwtToken } from './models/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationsLocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.retrieveToken();
  }

  private localStorageKey = 'notifications-token';
  private _token = new BehaviorSubject<string | null>(null);
  private _userIdScope = new BehaviorSubject<string | null>(null);

  localToken$() {
    return this._token.asObservable();
  }

  userIdScope$() {
    return this._userIdScope.asObservable();
  }

  retrieveToken() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const token = localStorage.getItem(this.localStorageKey);

    if (token) {
      const decodedToken: NotificationsJwtToken = jwtDecode(token);

      if (decodedToken.exp < Date.now() / 1000) {
        localStorage.removeItem(this.localStorageKey);
        this._token.next(null);
      } else {
        this._token.next(token);
      }
    }
  }

  setToken(token: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    localStorage.setItem(this.localStorageKey, token);
    this._token.next(token);
  }
}
