import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { PaymentsSummary } from './models/payments-summary.interface';
import { HttpContext } from '@angular/common/http';
import { IS_FILTERED_BY_PHARMACY } from '@troyai/pharmacy-context/data-access';
import { PaymentHistory } from './models/payments-history.interface';
import { Action } from '@troyai/actions/data-access';

@Injectable({
  providedIn: 'root',
})
export class PaymentsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getPaymentsSummary() {
    return this.restApiService.get<PaymentsSummary[]>('payments', {
      context: new HttpContext().set(IS_FILTERED_BY_PHARMACY, true),
    });
  }

  getPaymentsHistory() {
    return this.restApiService.get<PaymentHistory[]>('payments-history', {
      context: new HttpContext().set(IS_FILTERED_BY_PHARMACY, true),
    });
  }

  getSinglePaymentDetails(paymentId: number) {
    return this.restApiService.get<Action[]>(`payments-history/${paymentId}`, {
      context: new HttpContext().set(IS_FILTERED_BY_PHARMACY, true),
    });
  }
}
