import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import { IconsModule, PopoverModule } from '@troyai/ui-kit';
import { PharmacyListComponent } from '../pharmacy-list/pharmacy-list.component';

@Component({
  selector: 't-pharmacy-selector',
  standalone: true,
  imports: [CommonModule, PopoverModule, IconsModule, PharmacyListComponent],
  templateUrl: './pharmacy-selector.component.html',
})
export class PharmacySelectorComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.mobileMode = !result.matches;
      });
  }

  mobileMode = false;
  destroy$ = new Subject<boolean>();
  selectedPharmacy$ = this.store.select(PharmacyContextState.selectedPharmacy);
  pharmaciesList$ = this.store.select(PharmacyContextState.pharmaciesList);

  hasMultiplePharmacies = false;

  ngOnInit() {
    this.pharmaciesList$
      .pipe(
        tap((pharmacies) => {
          this.hasMultiplePharmacies = pharmacies.length > 1;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
