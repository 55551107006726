import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { delay, take } from 'rxjs';

import { UpdateLocalOnboardingSettingStatus } from '@troyai/auth/data-access';
import { GuidedTourService } from '@troyai/guided-tour';
import { ButtonComponent, NavMenuItemComponent, PopoverModule } from '@troyai/ui-kit';

@Component({
  selector: 't-help-menu-item',
  standalone: true,
  imports: [CommonModule, NavMenuItemComponent, PopoverModule, ButtonComponent],
  templateUrl: './help-menu-item.component.html',
})
export class HelpMenuItemComponent implements OnInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private store: Store,
    private actions$: Actions,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document,
    public location: Location
  ) {
    this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.mobileMode = !result.matches;
      });
  }

  private tourRoutesBlacklist = [
    '/prior-authorization',
    '/resources',
    '/care/appointments',
    '/notifications',
    '/wallet',
    '/settings',
    '/compliance',
  ];
  private checkRoutesBlacklist(url: string) {
    for (const route of this.tourRoutesBlacklist) {
      if (url.startsWith(route)) {
        return false;
      }
    }
    return true;
  }

  showTourStartButton = true;
  mobileMode = false;
  startCurrentViewTourSegment() {
    this.actions$
      .pipe(ofActionSuccessful(UpdateLocalOnboardingSettingStatus), delay(500), take(1))
      .subscribe(() => {
        const isTourAvailable = this.document.querySelector('t-guided-tour');
        if (!isTourAvailable) return;

        this.guidedTourService.start();
      });

    this.store.dispatch(
      new UpdateLocalOnboardingSettingStatus({
        status: false,
      })
    );
  }

  ngOnInit() {
    // Show tour start button only on pages that are not blacklisted
    this.showTourStartButton = this.checkRoutesBlacklist(this.location.path());
    this.location.onUrlChange((url) => {
      this.showTourStartButton = this.checkRoutesBlacklist(url);
    });
  }
}
