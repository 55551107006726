<t-action-layout-regular *ngIf="action$ | async as action">
  <ng-container content>
    <div class="space-y-5">
      <t-card [isInteractive]="false" paddingSize="none" class="space-y-8">
        <t-action-single-header [action]="action" class="border-b-grey-20 block border-b p-6" />

        <div class="space-y-8 p-8">
          @if (computedActionValues.hasCompletedState) {
            <div class="bg-amber-20 rounded-lg p-5">
              <div class="flex flex-col gap-x-2 md:flex-row md:items-center md:justify-start">
                <span class="text-green-90">
                  <t-icon key="check-circle" size="tiny" color="inherit"></t-icon>
                </span>

                <span class="text-grey-100 text-sm font-semibold">
                  {{
                    action.approved_for_payment_date
                      ? 'Completed on ' + (action.approved_for_payment_date | customMediumDate)
                      : 'Completed'
                  }}
                </span>
              </div>
            </div>
          }

          <t-action-rich-text-description class="mb-5 block" [description]="action.description" />
        </div>

        @if (action.resources && action.resources.length > 0) {
          <div class="border-t-grey-20 border-t p-6">
            <strong class="text-grey-60 mb-4 block text-xs font-semibold tracking-wider">
              RESOURCES
            </strong>
            <t-action-resources-list [resources]="action.resources" />
          </div>
        }
      </t-card>

      <t-card [isInteractive]="false" paddingSize="large" class="block">
        <t-outreach-attempts-list />
      </t-card>

      @if (action.extra_data) {
        <t-card [isInteractive]="false" paddingSize="large" class="block">
          <t-action-extra-data-fcmr01 [extraData]="action.extra_data" />
        </t-card>
      }

      @if (action.related_actions && action.related_actions.length > 0) {
        <t-card [isInteractive]="false" paddingSize="large" class="block">
          <t-related-actions-list [actions]="action.related_actions | relatedActions" />
        </t-card>
      }
    </div>
  </ng-container>

  <ng-container sidebar>
    <t-action-cta-card
      [action]="action"
      [computedActionValues]="computedActionValues"
      (actionComplete)="handlePostSubmitData()" />

    <div class="relative max-h-[80vh] flex-1">
      <t-chat-window class="absolute h-full min-h-[300px] w-full" />
    </div>
  </ng-container>
</t-action-layout-regular>
