import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IS_FILTERED_BY_PHARMACY } from '@troyai/pharmacy-context/data-access';
import {
  CACHE_OPTIONS,
  CacheOptions,
  RestApiClientService,
  RestApiResponse,
} from '@troyai/rest-api-client';
import { CriticalComment, CriticalCommentSubmitPayload } from './models/critical-comment.interface';
import { PatientEncounter } from './models/encounter.interface';
import { Patient, PatientListItem } from './models/patient.interface';
import { RxClaim } from './models/rx-claim.interface';

@Injectable({
  providedIn: 'root',
})
export class PatientsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getAll(cacheOptions?: CacheOptions) {
    const context = new HttpContext().set(IS_FILTERED_BY_PHARMACY, true);
    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<PatientListItem[]>('members', {
      context,
    });
  }

  getSinglePatient(globalId: number) {
    return this.restApiService.get<Patient>(`members/${globalId}`);
  }

  getRxClaims(globalId: number) {
    return this.restApiService.get<RxClaim[]>(`members/${globalId}/rx-claims`);
  }

  requestPatientReassignment(globalId: number) {
    return this.restApiService.post<RestApiResponse<void>>(
      `members/${globalId}/request-reassign`,
      {}
    );
  }

  getEncounters(globalId: number) {
    return this.restApiService.get<PatientEncounter[]>(`members/${globalId}/encounters`);
  }

  submitCriticalComment(globalId: number, payload: CriticalCommentSubmitPayload) {
    return this.restApiService.post<CriticalComment>(
      `members/${globalId}/critical-comments`,
      payload
    );
  }
}
