import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genderLetter',
  standalone: true,
})
export class GenderLetterPipe implements PipeTransform {
  transform(value: string) {
    if (!value) return '';

    // The input value is expected to be a string (Male/Female), so we can safely assume that the first character is a letter.
    return value.charAt(0).toUpperCase();
  }
}
