<div class="bg-grey-15 mb-3 w-full rounded-lg p-2">
  <label class="text-grey-70 block text-base">{{ label }}</label>
</div>
<form [formGroup]="form" class="px-2">
  <div formArrayName="rows">
    <div
      *ngFor="let row of rows.controls; let i = index"
      [formGroupName]="i"
      class="mb-4 flex items-center gap-x-2">
      <div *ngFor="let control of config">
        @switch (control.type) {
          @case ('text') {
            <t-input-text [formControlName]="control.label" [label]="control.label" />
          }

          @case ('dateInput') {
            <t-date-picker-input
              dateFormat="yyyy-MM-dd"
              [formControlName]="control.label"
              [label]="control.label" />
          }

          @case ('dropdown') {
            <t-select
              [label]="control.label"
              [items]="control.options"
              [formControlName]="control.label" />
          }
        }
      </div>

      @if (rows.controls.length > 1) {
        <div class="text-grey-60 relative top-3">
          <t-button
            (click)="removeRow(i)"
            variant="neutral"
            icon="delete"
            color="inherit"
            size="small" />
        </div>
      }
    </div>
  </div>

  <t-button (click)="addRow()" variant="neutral" size="tiny" icon="add" iconPos="left" class="mt-2">
    {{ addRowButtonText }}
  </t-button>
</form>
