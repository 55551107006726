import { BehaviorSubject, Observable, combineLatest, of, share, switchMap } from 'rxjs';
import { Filter } from './models/filters.model';
import { SortOptionValue } from './models/page.model';
import { InfiniteLoadDataSourceService } from './services/infinite-load-data-source.service';

export interface InfiniteLoadDataSourceConfig<T> {
  source$: Observable<T[] | null | undefined>;
  sort: SortOptionValue<T>[];
  filters: Filter<T>[];
  search: {
    initialValue: string;
    searchableProperties: (keyof T)[];
  };
  items: {
    initialCount: number;
    increment: number;
  };
}

export class InfiniteLoadDataSource<T> {
  constructor() {
    this.listingService = new InfiniteLoadDataSourceService();
  }

  private listingService: InfiniteLoadDataSourceService;
  private sort!: BehaviorSubject<SortOptionValue<T>[]>;
  private filters!: BehaviorSubject<Filter<T>[]>;
  private search!: BehaviorSubject<string>;
  private itemsCount!: BehaviorSubject<number>;
  private increment = 0;
  private initialCount = 0;
  private initialized = new BehaviorSubject<boolean>(false);

  private loading = new BehaviorSubject<boolean>(false);
  public loading$ = this.loading.asObservable();
  public currentItemsCount$: Observable<number> = of(0);
  public initialized$ = this.initialized.asObservable();
  public items$: Observable<T[]> = of([]);

  init(config: InfiniteLoadDataSourceConfig<T>): void {
    this.sort = new BehaviorSubject<SortOptionValue<T>[]>(config.sort);
    this.filters = new BehaviorSubject<Filter<T>[]>(config.filters);
    this.search = new BehaviorSubject<string>(config.search.initialValue);
    this.itemsCount = new BehaviorSubject<number>(config.items.initialCount);
    this.initialCount = config.items.initialCount;
    this.currentItemsCount$ = this.itemsCount.asObservable();
    this.increment = config.items.increment;
    this.initialized.next(true);

    const allParams$ = combineLatest([
      config.source$,
      this.sort,
      this.filters,
      this.search,
      this.itemsCount,
    ]);
    this.items$ = allParams$.pipe(
      switchMap(([sourceItems, sort, filters, search, itemsCount]) => {
        if (!sourceItems) {
          return [];
        }

        return this.listingService.generateListingEntries(
          sourceItems,
          sort,
          filters,
          {
            value: search,
            searchableProperties: config.search.searchableProperties,
          },
          itemsCount
        );
      }),
      share()
    );
  }

  loadMoreItems() {
    this.itemsCount.next(this.itemsCount.value + this.increment);
  }

  sortBy(sort: SortOptionValue<T>[]): void {
    this.sort.next(sort);
  }

  filterBy(filters: Filter<T>[]): void {
    this.filters.next(filters);
  }

  searchBy(searchText: string): void {
    this.search.next(searchText);
    this.itemsCount.next(this.initialCount);
  }

  updateSource(config: InfiniteLoadDataSourceConfig<T>, items: T[]): void {
    const allParams$ = combineLatest([
      of(items),
      this.sort,
      this.filters,
      this.search,
      this.itemsCount,
    ]);
    this.items$ = allParams$.pipe(
      switchMap(([sourceItems, sort, filters, search, itemsCount]) => {
        return this.listingService.generateListingEntries(
          sourceItems,
          sort,
          filters,
          {
            value: search,
            searchableProperties: config.search.searchableProperties,
          },
          itemsCount
        );
      }),
      share()
    );
  }
}
