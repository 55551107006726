import { Params } from '@angular/router';
import JsonURL from '@jsonurl/jsonurl';
import { SortQueryParam } from '../models/filters.model';
import { SortConfig, SortOptionValue, SortOrder } from '../models/page.model';

export const generateSortOptions = <T>(
  sortConfig: SortConfig<T>[],
  initialActiveKeyName: keyof T,
  queryParams?: Params
): SortOptionValue<T>[] => {
  const sortingQueryParams: SortQueryParam =
    queryParams && queryParams['sort']
      ? JsonURL.parse(queryParams?.['sort'] as string, { AQF: true })
      : null;

  const hasSortOptionFromQueryParam =
    sortingQueryParams && Object.keys(sortingQueryParams).length > 0;

  return sortConfig.map((config) => {
    const keyName = config.value.keyName as string;

    // Take the order from the query param if it exists, otherwise take the default order
    const paramOrderDirection =
      hasSortOptionFromQueryParam && keyName in sortingQueryParams
        ? (sortingQueryParams[keyName] as SortOrder)
        : config.value.order;

    // Check if the current sort option is active based on the query param
    const isActive = hasSortOptionFromQueryParam
      ? sortingQueryParams[keyName] !== undefined
      : initialActiveKeyName === config.value.keyName;

    const sorting = {
      isActive: isActive,
      config: {
        ...config,
        value: {
          ...config.value,
          order: paramOrderDirection,
        },
      },
    };

    return sorting;
  });
};
