import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, IconsModule } from '@troyai/ui-kit';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { GuidedTourService } from '../../guided-tour.service';

@Component({
  selector: 't-tour-starting-prompt',
  standalone: true,
  imports: [CommonModule, IconsModule, ButtonComponent],
  templateUrl: './tour-starting-prompt.component.html',
})
export class TourStartingPromptComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: { tourIntroText: string },
    private guidedTourService: GuidedTourService,
    private dialogRef: DialogRef<TourStartingPromptComponent>
  ) {}

  startTour() {
    this.guidedTourService.start();
    this.dialogRef.close();
  }

  skipTour() {
    this.guidedTourService.cancel();
    this.dialogRef.close();
  }
}
