import { UserPharmacy } from '@troyai/auth/data-access';
import { NotificationPreview } from '../models/notification.interface';

export const enrichNotificationPreview = (
  notificationPreview: NotificationPreview,
  pharmaciesList?: UserPharmacy[]
) => {
  if (!pharmaciesList || pharmaciesList.length === 0) {
    return notificationPreview;
  }

  const pharmaciesIndexMap = pharmaciesList
    ? pharmaciesList.reduce(
        (acc, curr) => {
          if (curr.global_id) {
            acc[curr.global_id] = curr.name;
          }

          return acc;
        },
        {} as Record<string, string>
      )
    : {};

  const previewPharmacyId = notificationPreview.resource
    ? notificationPreview.resource['pharmacy_id']
    : null;

  // There is only pharmacy_id in the preview, so we need to enrich it with the pharmacy name
  return previewPharmacyId
    ? {
        ...notificationPreview,
        resource: {
          ...notificationPreview.resource,
          pharmacy_name: previewPharmacyId ? pharmaciesIndexMap[previewPharmacyId] : '',
        },
      }
    : notificationPreview;
};
