<div class="relative flex w-full flex-col items-center justify-center text-center">
  @if (hasIcon) {
    <div class="bg-grey-5 text-grey-60 mb-4 inline-block rounded-full p-3">
      <t-icon key="info-circle" size="medium" color="inherit"></t-icon>
    </div>
  }

  @if (content) {
    <p class="text-grey-60 mb-2 block text-xs">
      {{ content }}
    </p>
  }

  <ng-content></ng-content>
</div>
