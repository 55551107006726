import { Injectable } from '@angular/core';
import JsonURL from '@jsonurl/jsonurl';

@Injectable({
  providedIn: 'root',
})
export class QueryObjectToUrlService {
  parse(input: string) {
    return JsonURL.parse(input, { AQF: true });
  }

  stringify(input: unknown) {
    try {
      return JsonURL.stringify(input, { AQF: true });
    } catch (e) {
      return '';
    }
  }
}
