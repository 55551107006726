import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { PriorAuthorizationsApiService } from '../prior-authorizations-api.service';
import { PriorAuthorizationsStateModel } from './prior-authorization-state.model';
import { GetPriorAuthorizationsList } from './prior-authorization.actions';

@State<PriorAuthorizationsStateModel>({
  name: 'priorAuthorizationsState',
  defaults: {
    priorAuthorizations: [],
    priorAuthorizationsLoading: false,
  },
})
@Injectable()
export class PriorAuthorizationsState {
  constructor(private priorAuthorizationApiService: PriorAuthorizationsApiService) {}

  @Selector()
  static priorAuthorizationsList(state: PriorAuthorizationsStateModel) {
    return state.priorAuthorizations;
  }

  @Selector()
  static priorAuthorizationLoading(state: PriorAuthorizationsStateModel) {
    return state.priorAuthorizationsLoading;
  }

  @Action(GetPriorAuthorizationsList)
  getPriorAuthorizationsList(
    { setState, patchState }: StateContext<PriorAuthorizationsStateModel>,
    action: GetPriorAuthorizationsList
  ) {
    patchState({
      priorAuthorizationsLoading: true,
      priorAuthorizations: [],
    });

    return this.priorAuthorizationApiService
      .getPriorAuthorizations(10000, action.payload.pharmacyId)
      .pipe(
        tap((allPriorAuthorizations) =>
          setState(
            patch<PriorAuthorizationsStateModel>({
              priorAuthorizations: allPriorAuthorizations,
            })
          )
        ),
        tap(() =>
          patchState({
            priorAuthorizationsLoading: false,
          })
        )
      );
  }
}
