import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 't-timeline',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './timeline.component.html',
})
export class TimelineComponent<T> {
  @Input() entries: T[] = [];

  @ContentChild('time') timeTemplate: TemplateRef<never> | null = null;
  @ContentChild('content') contentTemplate: TemplateRef<never> | null = null;
}
