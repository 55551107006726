<a
  *ngFor="let item of items; index as i"
  [routerLink]="['/patients', item.member_global_id, 'actions', item.action_global_id]"
  class="relative block">
  <t-card class="relative mb-2 block">
    <div class="flex flex-col md:flex-row md:items-center">
      <div class="flex flex-col items-start md:w-9/12 md:flex-row md:items-center">
        <div class="w-1/2 space-y-1.5 pt-4 md:w-auto md:pt-0">
          <div class="flex items-center gap-x-2">
            <span class="text-grey-100 block text-sm font-semibold">
              {{ item.member_name | titlecase }}
            </span>
            <t-pharmacy-indicator-tooltip
              class="hover:text-grey-70"
              [pharmacyName]="item.pharmacy_name" />
          </div>

          <span class="text-grey-70 block text-xs">
            Created by {{ item.created_by }} on {{ item.created_on | date }}
          </span>
        </div>
      </div>

      <div
        class="border-t-grey-20 mt-4 flex items-center justify-between gap-x-2 border-t pt-4 md:mt-0 md:w-3/12 md:justify-end md:border-t-0 md:border-none md:pt-0">
        <div class="flex justify-end md:w-1/2">
          <t-label variant="pill" color="warning" size="tiny">Draft</t-label>
        </div>

        <div class="flex justify-end md:w-1/2">
          <t-button variant="neutral" size="tiny">VIEW</t-button>
        </div>
      </div>
    </div>
  </t-card>
</a>
