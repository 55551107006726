<div
  class="border-grey-25 flex flex-wrap items-center border-t py-4"
  *ngIf="activeFilters.length > 0">
  <div
    *ngFor="let activeFilter of activeFilters; let last = last"
    class="mb-3 flex flex-wrap items-center gap-x-2 lg:mb-0 lg:flex-nowrap"
    [ngClass]="{
      'pr-5': last
    }">
    <span class="text-grey-50 block w-full flex-none text-sm lg:w-auto lg:flex-auto">
      {{ activeFilter.config.label }} is:
    </span>
    <ul class="flex flex-wrap gap-x-2">
      <li
        *ngFor="let filterValue of activeFilter.values"
        class="hover:bg-grey-25 flex items-center space-x-2 rounded py-1 lg:px-2"
        [ngClass]="{
          hidden: !filterValue.isActive
        }">
        <span class="text-grey-70 text-sm font-semibold">{{ filterValue.id }}</span>
        <div
          class="text-grey-70 hover:bg-grey-30 cursor-pointer rounded-full p-1"
          (click)="removeFilterSelection(activeFilter.config.keyName, filterValue.id)">
          <t-icon key="close" color="inherit" size="micro"></t-icon>
        </div>
      </li>
    </ul>
  </div>

  <t-link class="flex" [isButton]="true" size="large" (click)="removeAllFilters()">
    Clear all
  </t-link>
</div>
