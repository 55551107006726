/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Filter } from '../../models/filters.model';
import { IconsModule, LinkComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-active-filter-summary',
  standalone: true,
  imports: [CommonModule, IconsModule, LinkComponent],
  templateUrl: './active-filter-summary.component.html',
})
export class ActiveFilterSummaryComponent implements OnChanges {
  @Input() filters: Filter<any>[] = [];
  @Output() filtersChange = new EventEmitter<Filter<any>[]>();
  activeFilters: Filter<any>[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      this.activeFilters = this.filters.filter((filter) => {
        return filter.values.some((value) => value.isActive);
      });
    }
  }

  removeAllFilters() {
    const newFilters = this.filters.map((filter) => {
      const activeFilters = filter.values.map((filterEntry: any) => {
        return {
          id: filterEntry.id,
          isActive: false,
        };
      });

      return {
        ...filter,
        values: activeFilters,
      };
    });

    this.filtersChange.emit(newFilters);
  }

  removeFilterSelection(filterKeyName: string, filterValueId: string) {
    const newFilters = this.filters.map((filter) => {
      if (filter.config.keyName === filterKeyName) {
        const activeFilters = filter.values.map((filterEntry: any) => {
          if (filterEntry.id === filterValueId) {
            return {
              id: filterEntry.id,
              isActive: false,
            };
          }
          return filterEntry;
        });

        return {
          ...filter,
          values: activeFilters,
        };
      } else {
        return filter;
      }
    });

    this.filtersChange.emit(newFilters);
  }
}
