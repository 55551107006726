import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { ActionIframeEmbedComponent } from '@troyai/actions/feature';
import { UserState } from '@troyai/auth/data-access';
import { Environment } from '@troyai/envs';
import {
  CheckMOCTrainingStatus,
  OnboardingState,
  SetOnboardingStepStatus,
} from '@troyai/onboarding/data-access';
import { PharmacyContextState } from '@troyai/pharmacy-context/data-access';
import { filter, fromEvent, take, tap, withLatestFrom } from 'rxjs';
import { MocTrainingViewComponent } from '../moc-training-view/moc-training-view.component';

@Component({
  selector: 't-moc-training-modal',
  standalone: true,
  imports: [CommonModule, MocTrainingViewComponent, ActionIframeEmbedComponent],
  templateUrl: './moc-training-modal.component.html',
})
export class MocTrainingModalComponent implements OnInit {
  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private env: Environment
  ) {}

  pharmacyToOnboard$ = this.store.select(OnboardingState.pharmacyToOnboard);
  selectedPharmacy$ = this.store.select(PharmacyContextState.selectedPharmacy);
  userDetails$ = this.store.select(UserState.userDetails);

  private destroyRef = inject(DestroyRef);
  displayFormEmbed = false;
  formURL = '';

  switchToFormEmbed() {
    this.displayFormEmbed = true;
  }

  ngOnInit() {
    this.selectedPharmacy$
      .pipe(
        withLatestFrom(this.userDetails$),
        tap(([pharmacy, userDetails]) => {
          const prefilledQueryParams = new URLSearchParams({
            pharmacyId: String(pharmacy?.global_id),
            pharmacy_name: pharmacy?.name || '',
            pharmacy_npi: pharmacy?.npi || '',
            user_email: userDetails?.email || '',
          });

          if (pharmacy.address) {
            prefilledQueryParams.append(
              'pharmacy_address[addr_line1]',
              pharmacy.address.street_1 || ''
            );

            prefilledQueryParams.append(
              'pharmacy_address[addr_line2]',
              pharmacy.address.street_2 || ''
            );

            prefilledQueryParams.append('pharmacy_address[state]', pharmacy.address.state || '');
            prefilledQueryParams.append('pharmacy_address[city]', pharmacy.address.city || '');
            prefilledQueryParams.append('pharmacy_address[postal]', pharmacy.address.zip || '');
          }

          this.formURL = this.env.production
            ? `https://form.jotform.com/233254921555053?${prefilledQueryParams}`
            : `https://form.jotform.com/233254921555053?${prefilledQueryParams}`;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    fromEvent(window, 'message', { capture: true })
      .pipe(
        filter((event) => {
          const messageEvent = event as MessageEvent;
          return messageEvent.data === 'CLOSE_ACTION_FORM_MODAL';
        }),
        take(1),
        tap(() => {
          this.store.dispatch([
            new SetOnboardingStepStatus('model_of_care_training', {
              loading: true,
              completed: false,
            }),
            new CheckMOCTrainingStatus(),
          ]);
        }),
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.dialogRef.close())
      )
      .subscribe();
  }
}
