<span
  class="block variant-{{ variant }} size-{{ size }} whitespace-nowrap"
  [ngClass]="{
    neutral: color === 'neutral',
    success: color === 'success',
    warning: color === 'warning',
    error: color === 'error',
    '!px-2': paddingSize === 'small',
    'has-high-contrast': highContrast
  }">
  <ng-content></ng-content>
</span>
