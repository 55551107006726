import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Theme } from './theme.interface';

@Injectable()
export class ThemeService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}
  setThemeVariables(themeOptions: Theme) {
    this.document.documentElement.style.setProperty(
      '--color-bg-sidebar',
      themeOptions.sidebar_background_color
    );
  }
}
