<div class="relative py-5">
  <div class="relative flex">
    <div
      *ngFor="let segment of primaryMilestoneSegments; let i = index"
      class="relative h-1 flex-auto primary-segment primary-segment-{{ i }}"
      [ngClass]="{
        'is-active': primaryMilestoneSegmentsActive,
        'is-current': segment.isCurrent,
        'rounded-l-full': i === 0
      }">
      <ng-container *ngIf="i === 0">
        <strong
          *ngIf="!primaryMilestoneSegmentsOverdue"
          class="text-green-80 absolute bottom-full block whitespace-nowrap pb-1.5 text-[9px] font-semibold leading-4">
          FILL BEFORE
          {{ primaryMilestoneDate | date: 'MMM d' }}
        </strong>

        <strong
          *ngIf="primaryMilestoneSegmentsOverdue"
          class="text-grey-50 absolute bottom-full block whitespace-nowrap pb-1.5 text-[9px] font-semibold leading-4">
          Missed
        </strong>

        <span
          class="absolute left-0 top-full block pt-2 text-[11px] font-semibold leading-4"
          [ngClass]="{
            'text-green-80': !primaryMilestoneSegmentsOverdue,
            'text-grey-50 print:text-grey-40 line-through': primaryMilestoneSegmentsOverdue
          }">
          $5
        </span>
      </ng-container>
    </div>

    <div
      *ngFor="let segment of secondaryMilestoneSegments; let i = index"
      class="relative h-1 flex-auto secondary-segment secondary-segment-{{ i }}"
      [ngClass]="{
        'is-active': secondaryMilestoneSegmentsActive,
        'is-current': segment.isCurrent
      }">
      <ng-container *ngIf="i === 0">
        <strong
          *ngIf="!secondaryMilestoneSegmentsOverdue"
          class="absolute bottom-full block whitespace-nowrap pb-1.5 text-[9px] font-semibold leading-4"
          [ngClass]="{
            'text-amber-70': secondaryMilestoneSegmentsActive,
            'text-grey-50': !secondaryMilestoneSegmentsActive
          }">
          {{ primaryMilestoneDate | date: 'MMM d' }}
        </strong>

        <strong
          *ngIf="secondaryMilestoneSegmentsOverdue"
          class="text-grey-50 absolute bottom-full block whitespace-nowrap pb-1.5 text-[9px] font-semibold leading-4">
          Missed
        </strong>

        <span
          class="absolute left-0 top-full block pt-2 text-[11px] font-semibold leading-4"
          [ngClass]="{
            'text-amber-70': !secondaryMilestoneSegmentsOverdue && secondaryMilestoneSegmentsActive,
            'text-grey-50': !secondaryMilestoneSegmentsActive,
            'print:text-grey-40 line-through': secondaryMilestoneSegmentsOverdue
          }">
          $2
        </span>
      </ng-container>
    </div>

    <div
      class="overdue-segment relative h-1 flex-auto rounded-r-full"
      [ngClass]="{
        'is-active': isOverdue
      }">
      <strong
        *ngIf="isOverdue"
        class="text-red-90 absolute bottom-full block whitespace-nowrap pb-1.5 text-[9px] font-semibold leading-4">
        OVERDUE
      </strong>

      <strong
        *ngIf="!isOverdue"
        class="text-grey-40 absolute bottom-full block whitespace-nowrap pb-1.5 text-[9px] font-semibold leading-4">
        {{ secondaryMilestoneDate | date: 'MMM d' }}
      </strong>

      <span
        class="absolute left-0 top-full block pt-2 text-[11px] font-semibold leading-4"
        [ngClass]="{
          'text-red-90': isOverdue,
          'text-grey-50': !isOverdue
        }">
        $0
      </span>
    </div>
  </div>
</div>
