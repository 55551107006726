/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, fromEvent, map, take, tap, withLatestFrom } from 'rxjs';

import { DialogRef } from '@angular/cdk/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActionForm } from '@troyai/actions/data-access';
import { ActionsListComponent, OngoingActionsListComponent } from '@troyai/actions/ui';
import { UserRoles } from '@troyai/auth/data-access';
import { RolesDirective, UserRolesService } from '@troyai/auth/feature';
import { FeatureFlagDirective } from '@troyai/feature-flags';
import { ContentWrapperLayoutComponent, NavbarWrapperComponent } from '@troyai/layout';
import { GetPatientOngoingCareActions, PatientsState } from '@troyai/patients/data-access';
import { ModalService, SectionComponent, SkeletonLoaderComponent } from '@troyai/ui-kit';
import { ActionEmbedModalComponent } from '../../components/action-embed-modal/action-embed-modal.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NavbarWrapperComponent,
    ContentWrapperLayoutComponent,
    SectionComponent,
    ActionsListComponent,
    OngoingActionsListComponent,
    FeatureFlagDirective,
    SkeletonLoaderComponent,
    RolesDirective,
  ],
  templateUrl: './patient-actions-listing.component.html',
})
export class PatientActionsListingFeatureComponent implements OnInit {
  constructor(
    private store: Store,
    private modalService: ModalService,
    private userRolesService: UserRolesService
  ) {}

  private actionModalRef?: DialogRef<unknown, ActionEmbedModalComponent>;
  private destroyRef = inject(DestroyRef);

  selectedPatient$ = this.store.select(PatientsState.selectedPatient);
  patientActionsGroups$ = this.store.select(PatientsState.getPatientActions).pipe(
    map((actions) => {
      if (actions && actions.length) {
        const groupingKeys = [
          {
            group: 'New',
            keys: ['New'],
          },
          {
            group: 'In Progress',
            keys: ['In Review', 'Needs Resubmission', 'Approved For Payment'],
          },
          {
            group: 'Completed',
            keys: ['Paid', 'Expired'],
          },
        ];

        // Group actions by provided keys
        const groupedActions = actions.reduce((result: any, currentValue) => {
          for (const groupingKey of groupingKeys) {
            if (groupingKey.keys.includes(currentValue.status)) {
              if (!result[groupingKey.group]) {
                result[groupingKey.group] = [];
              }
              result[groupingKey.group].push(currentValue);
            }
          }

          return result;
        }, {});

        // Order groups by custom order
        const customOrdered = ['New', 'In Progress', 'Completed'].reduce((orderedObj: any, key) => {
          if (Object.prototype.hasOwnProperty.call(groupedActions, key)) {
            return {
              ...orderedObj,
              [key]: groupedActions[key],
            };
          }
          return orderedObj;
        }, {});

        // Return array of objects with status and actions for easy usage in the template
        return Object.keys(customOrdered).map((key) => {
          return {
            status: key,
            actions: customOrdered[key],
          };
        });
      } else {
        return [];
      }
    })
  );
  ongoingCareActions$ = this.store.select(PatientsState.ongoingCareActions);
  ongoingCareActionsLoadingStatus$ = this.store.select(
    PatientsState.ongoingCareActionsLoadingStatus
  );

  userRoles = UserRoles;

  triggerActionOpen(formSettings: ActionForm) {
    const url = formSettings.form_url;

    if (formSettings.form_display === 'modal') {
      this.actionModalRef = this.modalService.openDialog(ActionEmbedModalComponent, url, {
        disablePadding: true,
        preventClosePromptMessage:
          'Are you sure you want to close this action form? Any progress made so far will be lost.',
        preventClosePromptTitle: 'Close Action Form',
        offsetTop: 50,
        preventCloseOnPromptConfirm: () => {
          this.actionModalRef?.close();
        },
      });
    }

    if (formSettings.form_display === 'new_tab') {
      window.open(url, '_blank');
    }
  }

  ngOnInit(): void {
    this.selectedPatient$
      .pipe(
        filter((patient) => !!patient),
        withLatestFrom(this.userRolesService.hasAccess([UserRoles.CMInternal])),
        tap(([patient, hasAccess]) => {
          if (!patient || !hasAccess) return;

          this.store.dispatch(
            new GetPatientOngoingCareActions({
              memberGlobalId: patient.global_id,
            })
          );
        }),
        take(1)
      )
      .subscribe();

    fromEvent(window, 'message', { capture: true })
      .pipe(
        filter((event) => {
          const messageEvent = event as MessageEvent;
          return messageEvent.data === 'CLOSE_ACTION_FORM_MODAL';
        }),
        take(1),
        tap(() => {
          // Closing the modal will emit the actionComplete event
          this.actionModalRef?.close();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
