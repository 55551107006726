import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserRoles, UserState } from '@troyai/auth/data-access';
import { distinctUntilChanged, filter, map, skipWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  constructor(private store: Store) {}
  private userRoles$ = this.store.select(UserState.userRoles);

  hasAccess(requiredRoles: UserRoles[]) {
    return this.userRoles$.pipe(
      filter((roles) => !!roles),
      skipWhile((roles) => !roles),
      distinctUntilChanged(),
      map((userRoles: UserRoles[] | undefined) => {
        if (userRoles && userRoles.length) {
          for (const requiredRole of requiredRoles) {
            if (userRoles.includes(requiredRole)) {
              return true;
            }
          }
        }

        return false;
      })
    );
  }
}
