import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Action } from '@troyai/actions/data-access';
import { TabComponent, TabsComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-action-layout-regular',
  standalone: true,
  imports: [CommonModule, TabsComponent, TabComponent],
  templateUrl: './action-layout-regular.component.html',
})
export class ActionLayoutRegularComponent {
  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.mobileMode = !result.matches;
      });
  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() action?: Action;

  mobileMode: boolean | null = null;
}
