<div class="relative h-screen space-y-3">
  @if (medicationListDataSource.page$ | async; as medPage) {
    @if (medPage.totalElements > 0) {
      <t-expandable-card class="block">
        <div header class="flex items-center gap-x-3">
          <strong class="text-grey-100 text-xs font-semibold tracking-wider">MEDICATIONS</strong>
          <t-label size="tiny" color="error" paddingSize="small">
            {{ medPage.totalElements }}
          </t-label>
        </div>

        <div class="px-8 pb-6">
          <t-data-listing
            [items]="medPage.items"
            variant="table"
            verticalSpacingSize="small"
            class="border-grey-20 block border-t">
            <ng-template #rowTemplate let-item>
              <div class="flex items-center justify-between">
                <span class="text-grey-70 w-8/12 text-sm">{{ item.drug_name }}</span>
                <t-label size="tiny" color="neutral" paddingSize="small">
                  {{ item.disease }}
                </t-label>
              </div>
            </ng-template>
          </t-data-listing>
        </div>
      </t-expandable-card>
    }

    @if (medPage.totalElements > 0 && medPage.pageSize !== medPage.totalElements) {
      <div class="border-grey-20 absolute bottom-0 left-0 w-full border-t p-4">
        <t-paginator
          [collectionSize]="medPage.totalElements"
          [currentPage]="medPage.pageNumber"
          [pageSize]="pageSize"
          [pageNumbers]="false"
          (pageChanged)="medicationListDataSource.fetch($event.pageNumber)"
          [maxSize]="2" />
      </div>
    }
  }
</div>
