import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@troyai/ui-kit';
import { Store } from '@ngxs/store';
import { PharmacyContextState, SetGlobalPharmacy } from '@troyai/pharmacy-context/data-access';
import { UserPharmacy } from '@troyai/auth/data-access';

@Component({
  selector: 't-pharmacy-list',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './pharmacy-list.component.html',
})
export class PharmacyListComponent {
  constructor(private store: Store) {}

  @Input() showAllPharmaciesOption = true;

  // Used for selecting all pharmacies option
  allPharmaciesOption: UserPharmacy = {
    global_id: null,
    name: 'All pharmacies',
    npi: null,
  };

  selectedPharmacy$ = this.store.select(PharmacyContextState.selectedPharmacy);
  pharmaciesList$ = this.store.select(PharmacyContextState.pharmaciesList);
  pharmaciesListLoading$ = this.store.select(PharmacyContextState.pharmaciesListLoading);

  selectPharmacy(pharmacy: UserPharmacy) {
    this.store.dispatch(new SetGlobalPharmacy({ pharmacy }));
  }
}
