export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export type AvailableFeatureFlags =
  /*
   * Patient related features
   */
  | 'critical-comments'
  | 'case-notes'
  | 'enrollment-plan-details'
  | 'patient-medication-records'
  | 'patient-clinical-observations'
  | 'patient-encounters'
  | 'patient-diagnoses'
  | 'ongoing-care-actions'
  | 'hra-listing'
  | 'hra-listing-addendum'
  /*
   * Pharmacy related features
   */
  | 'plan-review-for-non-troy-members-actions'
  | 'activity-view'
  | 'moc-training-requirement'
  | 'dashboard-view'
  | 'adherence-view'
  | 'adherence-bonus'
  | 'patients-view'
  | 'actions-view'
  | 'prior-authorization-view'
  | 'np-appointments-view'
  | 'resources-view'
  | 'baa-onboarding-required'
  | 'financial-onboarding-required'
  | 'np-patients-view'
  | 'in-app-notifications'
  /*
   * General features
   */
  | 'guided-tour'
  | 'demo-mode';

export type FeatureFlags = Record<AvailableFeatureFlags, boolean>;

interface Auth0ConnectionData {
  domain: string;
  clientId: string;
  audience: string;
}

export class Environment {
  readonly production!: boolean;
  readonly enableDebugTools!: boolean;
  readonly logLevel!: LogLevel;
  readonly apiUrl!: string;
  readonly apiHost!: string;
  readonly auth0ConnectionData!: Auth0ConnectionData;
  readonly featureFlags!: FeatureFlags;
}
