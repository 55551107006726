import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { FeatureFlagsService } from '@troyai/feature-flags';

export function FeatureFlagsRedirectGuard(): CanActivateFn {
  return () => {
    const router = inject(Router);
    const featureFlagService = inject(FeatureFlagsService);

    if (!featureFlagService.isFeatureEnabled('dashboard-view')) {
      return router.parseUrl('/adherence');
    }

    return true;
  };
}
