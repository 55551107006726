import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  generateSortOptions,
  ListingDataSource,
  SortOptionValue,
} from '@troyai/data-source-listing';
import { DiagnosesState, Diagnosis } from '@troyai/portal/diagnoses/data-access';
import { splitDiagnosesByType } from '@troyai/portal/diagnoses/feature';
import { PopulateDataFromControlEnrichment } from '@troyai/portal/dynamic-forms/data-access';
import {
  ButtonComponent,
  DataListingComponent,
  ExpandableCardComponent,
  LabelComponent,
  PaginatorComponent,
} from '@troyai/ui-kit';
import { map } from 'rxjs';

@Component({
  selector: 't-inline-diagnoses-list',
  standalone: true,
  imports: [
    CommonModule,
    ExpandableCardComponent,
    LabelComponent,
    DataListingComponent,
    PaginatorComponent,
    ButtonComponent,
  ],
  templateUrl: './inline-diagnoses-list.component.html',
})
export class InlineDiagnosesListComponent implements OnInit {
  constructor(private store: Store) {}

  diagnosesList$ = this.store.select(DiagnosesState.diagnosesList).pipe(
    map((diagnoses) => {
      return splitDiagnosesByType(diagnoses);
    })
  );

  regularDiagnosesDataSource = new ListingDataSource<Diagnosis>();
  potentialDiagnosesDataSource = new ListingDataSource<Diagnosis>();

  pageSize = 10;
  diagnosesAvailableSortingOptions: SortOptionValue<Diagnosis>[] = generateSortOptions<Diagnosis>(
    [
      {
        label: 'Date',
        value: {
          keyName: 'added_at',
          order: 'desc',
        },
      },
    ],
    'added_at'
  );

  transferToFormField(diagnosis: Diagnosis) {
    this.store.dispatch(
      new PopulateDataFromControlEnrichment('192_activeDiagnoses', {
        Name: diagnosis.name,
        Code: diagnosis.code,
      })
    );
  }

  ngOnInit(): void {
    this.regularDiagnosesDataSource.init(
      this.diagnosesList$.pipe(map((items) => items.regularDiagnoses)),
      this.diagnosesAvailableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );

    this.potentialDiagnosesDataSource.init(
      this.diagnosesList$.pipe(map((items) => items.potentialDiagnoses)),
      this.diagnosesAvailableSortingOptions,
      [],
      '',
      {
        page: 1,
        size: this.pageSize,
      },
      {
        searchableProperties: [],
      }
    );
  }
}
