/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CheckBoxComponent,
  DropdownMenuItemComponent,
  IconsModule,
  LinkComponent,
  PopoverAnchoringService,
  RadioComponent,
} from '@troyai/ui-kit';
import { Subject, takeUntil, tap } from 'rxjs';
import { Filter } from '../../models/filters.model';
import { SortOptionValue } from '../../models/page.model';

@Component({
  selector: 't-data-source-options',
  standalone: true,
  imports: [
    CommonModule,
    LinkComponent,
    CheckBoxComponent,
    IconsModule,
    RadioComponent,
    DropdownMenuItemComponent,
  ],
  templateUrl: './data-source-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceOptionsComponent implements OnChanges, OnInit, OnDestroy {
  constructor(
    private popoverAnchoringService: PopoverAnchoringService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @Input() filters: Filter<any>[] = [];
  @Output() filtersChange = new EventEmitter<Filter<any>[]>();
  selectedFilterCategory?: Filter<any>;

  @Input() sortingOptions: SortOptionValue<any>[] = [];
  @Output() sortingChange = new EventEmitter<SortOptionValue<any>[]>();
  sortMode = false;
  activeSortOptionLabel?: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  selectFilterAndClose(filterId: string, filterValueId: string) {
    this.updateFilterSelection(filterId, filterValueId);
    this.popoverAnchoringService.closePopover();
  }

  toggleFilterOptionsSelectionMode(filter?: Filter<any>) {
    this.selectedFilterCategory = filter;
  }

  updateFilterSelection(filterId: string, filterValueId: string) {
    const newFilters = this.filters.map((filter) => {
      if (filter.config.id === filterId) {
        const activeFilters = filter.values.map((filterEntry: any) => {
          if (filterEntry.id === filterValueId) {
            return {
              id: filterEntry.id,
              isActive: !filterEntry.isActive,
            };
          }
          return filterEntry;
        });

        return {
          ...filter,
          values: activeFilters,
        };
      } else {
        return filter;
      }
    });

    this.selectedFilterCategory = newFilters.find(
      (newFilter) => newFilter.config.id === this.selectedFilterCategory?.config.id
    );
    this.filtersChange.emit(newFilters);
  }

  toggleSortSelectionMode() {
    this.sortMode = !this.sortMode;
  }

  selectSortAndClose(sort: SortOptionValue<any>) {
    this.updateSortSelection(sort);
    this.popoverAnchoringService.closePopover();
  }

  updateSortSelection(sort: SortOptionValue<any>) {
    const newSortSelection = this.sortingOptions.map((option) => {
      if (option.config.value.keyName === sort.config.value.keyName) {
        return {
          ...option,
          isActive: true,
        };
      }

      return {
        ...option,
        isActive: false,
      };
    });

    this.activeSortOptionLabel = sort.config.label;
    this.sortingChange.emit(newSortSelection);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sortingOptions']) {
      const activeSortOptionLabel = this.sortingOptions.find((option) => option.isActive);
      if (activeSortOptionLabel) {
        this.activeSortOptionLabel = activeSortOptionLabel.config.label;
      }
    }

    // Auto-select the first filter category if there is only one
    if (changes['filters']) {
      if (this.filters.length === 1) {
        this.toggleFilterOptionsSelectionMode(this.filters[0]);
      }
    }
  }

  ngOnInit() {
    this.popoverAnchoringService.popoverClosed
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.selectedFilterCategory = undefined;
          this.sortMode = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
