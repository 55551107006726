import { Injectable } from '@angular/core';
import { getTenantRef } from './tenant-ref.util';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  getCurrentTenantRef() {
    return getTenantRef();
  }
}
