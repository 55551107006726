import { CommonModule, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { ActionsState, GetActionFormSettings } from '@troyai/actions/data-access';
import { Environment } from '@troyai/envs';
import {
  Appointment,
  CareState,
  GetAppointmentsList,
  NavigateToCurrentWeek,
  NavigateToNextWeek,
  NavigateToPreviousWeek,
  RemoveInnerSlot,
  RemoveSlotsFromDay,
  RestoreInnerSlot,
  ToggleAppointmentsGroup,
  UnassignAppointmentForPatient,
} from '@troyai/hra/data-access';
import { PhonePipe, TimeIntervalPipe } from '@troyai/portal/common/pipes';
import {
  AccordionComponent,
  AccordionItemComponent,
  AccordionItemToggleEvent,
  ButtonComponent,
  DropdownButtonComponent,
  DropdownMenuComponent,
  DropdownMenuItemComponent,
  EmptyStateCardComponent,
  IconsModule,
  LabelComponent,
  LinkComponent,
  ModalModule,
  ModalService,
  PopoverModule,
  SectionComponent,
  SkeletonLoaderComponent,
  WeekNavigationTarget,
  WeekNavigatorComponent,
} from '@troyai/ui-kit';
import { endOfWeek, format, isValid, startOfWeek } from 'date-fns';
import { parse } from 'date-fns/parse';
import { take, withLatestFrom } from 'rxjs';
import { PatientsListSelectorModalComponent } from '../../components/patients-list-selector-modal/patients-list-selector-modal.component';
import { SyncCalendarModalComponent } from '../../components/sync-calendar-modal/sync-calendar-modal.component';

import { AssessmentDraftsListComponent } from '@troyai/hra/ui';
import { ContentWrapperLayoutComponent } from '@troyai/layout';
import { DynamicFormState, GetAllSubmissionDrafts } from '@troyai/portal/dynamic-forms/data-access';
import { ActionAppointmentPreviewModalComponent } from '../action-appointment-preview-modal/action-appointment-preview-modal.component';
import { PrintAppointmentsModalComponent } from '../print-appointments-modal/print-appointments-modal.component';

@Component({
  selector: 't-np-appointments-listing',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ContentWrapperLayoutComponent,
    SectionComponent,
    PopoverModule,
    IconsModule,
    DropdownButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    AccordionComponent,
    AccordionItemComponent,
    ButtonComponent,
    LinkComponent,
    ModalModule,
    PhonePipe,
    SkeletonLoaderComponent,
    WeekNavigatorComponent,
    EmptyStateCardComponent,
    TimeIntervalPipe,
    LabelComponent,
    AssessmentDraftsListComponent,
  ],
  templateUrl: './np-appointments-listing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NPAppointmentsListingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private modalService: ModalService,
    private env: Environment,
    private titlecase: TitleCasePipe
  ) {}

  private destroyRef = inject(DestroyRef);

  groupedAppointmentsListItems$ = this.store.select(CareState.groupedAppointmentsListItems);
  appointmentsListItemsLoading$ = this.store.select(CareState.appointmentsListItemsLoading);
  appointmentsPeriod$ = this.store.select(CareState.appointmentsPeriod);
  allSubmissionDrafts$ = this.store.select(DynamicFormState.allSubmissionDrafts);
  allSubmissionDraftsLoadingStatus$ = this.store.select(
    DynamicFormState.allSubmissionDraftsLoadingStatus
  );

  openScheduleModal(timeslot: Appointment) {
    this.modalService.openDialog(PatientsListSelectorModalComponent, timeslot, {
      title: 'Schedule Appointment',
      background: 'grey',
      allowOverflow: true,
    });
  }

  openPrintAppointmentsModal() {
    this.modalService.openDialog(
      PrintAppointmentsModalComponent,
      {},
      {
        title: 'Print All Appointments',
        background: 'grey',
        allowOverflow: true,
      }
    );
  }

  viewAppointmentDetails(timeslot: Appointment, day: string) {
    this.modalService.openDialog(
      ActionAppointmentPreviewModalComponent,
      {
        timeslot,
        day,
      },
      {
        title: `Schedule NP Health Assessment for ${this.titlecase.transform(
          timeslot.member_name
        )}`,
        background: 'grey',
      }
    );
  }

  unassignPatientAppointment(timeslotId: number) {
    this.store.dispatch(
      new UnassignAppointmentForPatient({
        timeslotId,
        // TODO: Clarify if there is a need to add dynamic reason selection
        reason: 'Patient Unassigned',
      })
    );
  }

  openSyncCalendarModal() {
    this.modalService.openDialog(SyncCalendarModalComponent, null, {
      title: 'Sync Calendar',
      background: 'grey',
    });
  }

  removeTimeslot(id: number, day: string) {
    this.store.dispatch(
      new RemoveInnerSlot({
        timeslotId: id,
        day,
      })
    );
  }

  restoreTimeslot(id: number, day: string) {
    this.store.dispatch(
      new RestoreInnerSlot({
        timeslotId: id,
        day,
      })
    );
  }

  handleToggleChange(event: AccordionItemToggleEvent) {
    this.store.dispatch(
      new ToggleAppointmentsGroup({
        idx: event.idx,
        expanded: event.expanded,
      })
    );
  }

  removeAvailableTimeslotsForGroup(groupKey: string) {
    this.store.dispatch(
      new RemoveSlotsFromDay({
        groupKey,
      })
    );
  }

  onWeekChange(target: WeekNavigationTarget) {
    switch (target) {
      case 'next':
        this.store.dispatch(new NavigateToNextWeek());
        break;
      case 'previous':
        this.store.dispatch(new NavigateToPreviousWeek());
        break;
      case 'current':
        this.store.dispatch(new NavigateToCurrentWeek());
        break;
    }
  }

  // TODO: Temporary solution until the form settings are included in the timeslot
  fetchAndOpenForm(actionGlobalId: number) {
    this.actions$.pipe(ofActionSuccessful(GetActionFormSettings), take(1)).subscribe(() => {
      const formSettings = this.store.selectSnapshot(ActionsState.selectedActionFormSettings);
      if (!formSettings) return;

      // TODO: Handle other types of actions (ex: modal)
      window.open(formSettings.form_url, '_blank');
    });

    this.store.dispatch(new GetActionFormSettings({ globalId: actionGlobalId }));
  }

  ngOnInit() {
    this.route.queryParamMap.pipe().subscribe((params: ParamMap) => {
      const startDateParam = params.get('startDate') as string;
      const endDateParam = params.get('endDate') as string;

      if (startDateParam && endDateParam) {
        const startDateFromParam = parse(startDateParam, 'yyyy-MM-dd', Date.now());
        const endDateFromParam = parse(endDateParam, 'yyyy-MM-dd', Date.now());

        if (isValid(startDateFromParam) && isValid(endDateFromParam)) {
          this.store.dispatch(
            new GetAppointmentsList({
              startDate: startDateFromParam,
              endDate: endDateFromParam,
            })
          );
        } else {
          // Use History API directly to prevent any navigation events
          window.history.replaceState({}, document.title, this.router.url.split('?')[0]);
        }
      } else {
        this.store.dispatch(
          new GetAppointmentsList({
            startDate: startOfWeek(Date.now(), {
              weekStartsOn: 1,
            }),
            endDate: endOfWeek(Date.now(), {
              weekStartsOn: 1,
            }),
          })
        );
      }
    });

    // Update router query params on every navigation action
    this.actions$
      .pipe(
        ofActionSuccessful(NavigateToNextWeek, NavigateToPreviousWeek, NavigateToCurrentWeek),
        withLatestFrom(this.appointmentsPeriod$)
      )
      .subscribe((result) => {
        const [, appointmentsPeriod] = result;
        this.router.navigate([], {
          queryParams: {
            startDate: format(appointmentsPeriod.startDate, 'yyyy-MM-dd'),
            endDate: format(appointmentsPeriod.endDate, 'yyyy-MM-dd'),
          },
        });
      });

    this.store.dispatch(new GetAllSubmissionDrafts());
  }
}
