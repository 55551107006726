import { format } from 'date-fns';

const medication = [
  {
    name: 'Brimonidine Tartrate Ophthalmic Solution 0.2 %',
    dosage: '1 drop per affected eye every 8 hours',
  },
  {
    name: 'oxyCODONE-Acetaminophen Oral Tablet 10-325 MG',
    dosage: '1 tablet every 4 hours as needed for pain',
  },
  {
    name: 'Lisinopril Oral Tablet 10 MG',
    dosage: '1 tablet daily',
  },
  {
    name: 'Atorvastatin Oral Tablet 20 MG',
    dosage: '1 tablet daily',
  },
  {
    name: 'Metformin Hydrochloride Oral Tablet 500 MG',
    dosage: '1 tablet twice daily',
  },
];

const lastNames = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Miller',
  'Davis',
  'Garcia',
  'Rodriguez',
  'Wilson',
];

const firstNames = [
  'Alex',
  'Jordan',
  'Taylor',
  'Morgan',
  'Casey',
  'Riley',
  'Jamie',
  'Robin',
  'Drew',
  'Cameron',
];

export const getRandomFirstName = () => {
  return firstNames[Math.floor(Math.random() * firstNames.length)];
};

export const getRandomLastName = () => {
  return lastNames[Math.floor(Math.random() * lastNames.length)];
};

export const getRandomDate = (startDate?: Date, endDate?: Date) => {
  const start = startDate || new Date(1950, 0, 1);
  const end = endDate || new Date(2000, 0, 1);
  return format(
    new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())),
    'yyyy-MM-dd'
  );
};

export const getRandomGender = () => {
  return Math.random() > 0.5 ? 'Male' : 'Female';
};

export const getRandomMemberId = () => {
  return Math.floor(Math.random() * 1000000).toString();
};

export const getRandomUSPhoneNumber = () => {
  return `(${Math.floor(Math.random() * 1000)
    .toString()
    .padStart(3, '0')}) ${Math.floor(Math.random() * 1000)
    .toString()
    .padStart(3, '0')}-${Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, '0')}`;
};

export const getRandomMedication = () => {
  return medication[Math.floor(Math.random() * medication.length)];
};

export const getRandomBoolean = () => {
  return Math.random() > 0.5;
};
