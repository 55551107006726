<ng-container *ngIf="selectedPharmacy$ | async as selectedPharmacy">
  <div
    class="relative rounded p-2 transition-colors"
    [ngClass]="{
      'hover:bg-grey-90 cursor-pointer': hasMultiplePharmacies,
      'bg-grey-90': pharmaciesListPopover.isOpen()
    }"
    [tPopoverAnchor]="pharmaciesListPopover"
    (click)="hasMultiplePharmacies ? pharmaciesListPopover.toggle() : null">
    <div class="space-y-2">
      <strong class="block text-xs font-semibold text-white">{{ selectedPharmacy.name }} </strong>
      <span class="text-grey-60 block text-xs" *ngIf="selectedPharmacy.npi; else noNPIAvailable">
        NPI: {{ selectedPharmacy.npi }}
      </span>
    </div>

    <div *ngIf="hasMultiplePharmacies" class="text-grey-50 absolute right-2 top-2">
      <t-icon key="more-vert" size="tiny" color="inherit"></t-icon>
    </div>
  </div>

  <t-popover
    class="hidden"
    verticalAlign="end"
    [horizontalAlign]="mobileMode ? 'center' : 'after'"
    #pharmaciesListPopover
    hasBackdrop>
    <div class="-translate-x-2 -translate-y-2">
      <t-pharmacy-list></t-pharmacy-list>
    </div>
  </t-popover>
</ng-container>

<ng-template #noNPIAvailable>
  <div class="text-grey-60 flex items-center gap-x-1">
    <span class="text-xs"> Data from all stores </span>
    <div class="group relative">
      <t-icon key="error" size="tiniest" color="inherit"></t-icon>
      <p
        class="shadow-small bg-grey-80 absolute -bottom-2 left-8 z-10 hidden w-[300px] max-w-xs px-4 py-3 text-xs text-white group-hover:block">
        Using "All pharmacies" view will gather data from all your stores. Change the view to a
        specific store for a more in depth review.
      </p>
    </div>
  </div>
</ng-template>
