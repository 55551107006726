import { offset } from '@floating-ui/dom';
import { GuidedTourService } from './guided-tour.service';
import Step from 'shepherd.js/src/types/step';

export const defaultTourSegments = {
  dashboard: false,
  patients: false,
  actions: false,
  'single-action': false,
  'single-patient': false,
  adherence: false,
};

export const generateGuidedTourDefaultConfigOptions = (guidedTourService: GuidedTourService) => {
  return {
    floatingUIOptions: {
      middleware: [offset(10)],
    },
    classes: 'guided-tour-component',
    scrollTo: false,
    highlightClass: 'guided-tour-highlight',
    cancelIcon: {
      enabled: true,
    },
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        action: () => {
          guidedTourService.back();
        },
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        action: () => {
          guidedTourService.next();
        },
      },
    ],
  };
};

export const generateLastSlideButtons = (guidedTourService: GuidedTourService) => {
  return [
    {
      classes: 'shepherd-button-primary',
      text: 'Back',
      action: () => {
        guidedTourService.back();
      },
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Done',
      action: () => {
        guidedTourService.complete();
      },
    },
  ];
};

export const generateFirstSlideButtons = (guidedTourService: GuidedTourService) => {
  return [
    {
      classes: 'shepherd-button-primary',
      text: 'Next',
      action: () => {
        guidedTourService.next();
      },
    },
  ];
};

export const generatePositionStrategy = (position: Step.PopperPlacement): Step.PopperPlacement => {
  const windowWidth = window.innerWidth;
  return windowWidth > 768 ? position : 'bottom';
};
