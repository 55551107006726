/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { formatUTCDate } from '@troyai/shared/utils';
import { formatDate } from 'date-fns';
import { IconsModule } from '../../icon/icon.module';
import { InputTextComponent } from '../input-text.component';

@Component({
  selector: 't-date-picker-input',
  standalone: true,
  imports: [CommonModule, FormsModule, IconsModule, InputTextComponent, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerInputComponent),
      multi: true,
    },
  ],
  templateUrl: './date-picker-input.component.html',
})
export class DatePickerInputComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder = 'Select date';
  @Input() dateFormat = 'dd/MM/yyyy';
  @Input() label = 'Date';
  @Input() disablePast = false;
  @Input() disableFuture = false;

  public dateControl = new FormControl<null | string>(null);
  public dateViewControl = new FormControl<null | string>(null);
  public todayDate = formatDate(new Date(), 'yyyy-MM-dd');

  writeValue(date?: string): void {
    if (!date) return;
    this.dateControl.setValue(date);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (_value: string | null): void => undefined;
  public registerOnChange(fn: (value: string | null) => void): void {
    this._onChange = fn;
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private _getValue(): string | null {
    try {
      if (this.dateControl.invalid || !this.dateControl.value) return null;
      const date = formatUTCDate(this.dateControl.value, this.dateFormat);
      return date;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
  }

  ngOnInit() {
    this.dateControl.valueChanges.subscribe(() => {
      const value = this._getValue();

      if (value) {
        this.dateViewControl.setValue(value);
        this._onChange(value);
      }
    });
  }
}
