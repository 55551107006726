import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureFlagDirective } from '@troyai/feature-flags';
import { MemberHra } from '@troyai/hra/data-access';
import { ButtonComponent, CardComponent, IconsModule, LabelComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-hra-list',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    LabelComponent,
    ButtonComponent,
    IconsModule,
    FeatureFlagDirective,
  ],
  templateUrl: './hra-list.component.html',
})
export class HraListComponent {
  @Input({ required: true }) hraItems: MemberHra[] = [];
  @Output() selectItemForPreview = new EventEmitter<MemberHra>();
  @Output() selectItemForAddendum = new EventEmitter<MemberHra>();

  triggerItemSelectionForPreview(hraItem: MemberHra) {
    this.selectItemForPreview.emit(hraItem);
  }

  triggerItemSelectionForAddendum(hraItem: MemberHra) {
    this.selectItemForAddendum.emit(hraItem);
  }
}
