export type NotificationTypes = 'action' | 'payment' | 'pa' | 'message';

export const notificationRouteByType = (type: NotificationTypes, meta?: Record<string, string>) => {
  if (!meta) {
    return null;
  }

  switch (type) {
    case 'message':
    case 'action':
    case 'payment':
      if (!meta['action_id'] || !meta['member_assigned_to']) {
        return '/actions';
      }
      return `/patients/${meta['member_assigned_to']}/actions/${meta['action_id']}`;

    case 'pa':
      return meta['member_id'] ? `/patients/${meta['member_id']}` : '/patients';

    default:
      return null;
  }
};
