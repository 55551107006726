import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { UserProfileStateModel } from './user-profile-state.model';
import { UserProfileApiService } from '../user-profile-api.service';
import { GetUserProfile, UpdateUserProfile } from './user-profile.actions';
import { switchMap, tap, timer } from 'rxjs';

const stateDefaults = {
  userProfileData: undefined,
  userProfileDataLoading: true,
};

@State<UserProfileStateModel>({
  name: 'userProfileState',
  defaults: stateDefaults,
})
@Injectable()
export class UserProfileState {
  constructor(private userProfileApiService: UserProfileApiService) {}

  @Selector()
  static getUserProfileData(state: UserProfileStateModel) {
    return state.userProfileData;
  }

  @Selector()
  static getUserProfileDataLoading(state: UserProfileStateModel) {
    return state.userProfileDataLoading;
  }

  @Action(GetUserProfile)
  getUserProfile({ setState, patchState }: StateContext<UserProfileStateModel>) {
    patchState({
      userProfileData: undefined,
      userProfileDataLoading: true,
    });
    return this.userProfileApiService.getProfile().pipe(
      tap((userProfileData) => {
        setState({
          userProfileData,
          userProfileDataLoading: false,
        });
      })
    );
  }

  @Action(UpdateUserProfile)
  updateUserProfile({ setState }: StateContext<UserProfileStateModel>, action: UpdateUserProfile) {
    setState({
      userProfileDataLoading: true,
    });
    return timer(750).pipe(
      tap(() =>
        setState({
          userProfileDataLoading: true,
        })
      ),
      switchMap(() => this.userProfileApiService.updateProfile(action.payload.data)),
      tap(() =>
        setState({
          userProfileDataLoading: false,
        })
      )
    );
  }
}
