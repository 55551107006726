import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_OPTIONS, CacheOptions, RestApiClientService } from '@troyai/rest-api-client';
import { PriorAuthorizationItem } from './models/prior-authorization-item.model';

@Injectable({
  providedIn: 'root',
})
export class PriorAuthorizationsApiService {
  constructor(private restApiService: RestApiClientService) {}

  // Count being 0 returns all results
  getPriorAuthorizations(count = 0, pharmacyId: number) {
    return this.restApiService.get<PriorAuthorizationItem[]>(
      `prior-authorizations?latest=${count}&pharmacy_id=${pharmacyId}`
    );
  }

  // Count being 0 returns all results
  getPriorAuthorizationByMemberId(globalId: number, count = 0, cacheOptions?: CacheOptions) {
    const context = new HttpContext();

    if (cacheOptions) {
      context.set(CACHE_OPTIONS, cacheOptions);
    }

    return this.restApiService.get<PriorAuthorizationItem[]>(
      `members/${globalId}/prior-authorizations?latest=${count}`,
      { context }
    );
  }
}
