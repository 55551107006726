import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import {
  CompleteOnboardingStep,
  InitiatePlaidLink,
  OnboardingState,
  OnboardingStep,
  PlaidItemSuccessResponse,
  SetOnboardingStepStatus,
  SubmitPlaidPublicToken,
} from '@troyai/onboarding/data-access';
import { ModalModule, ModalService } from '@troyai/ui-kit';
import { NgxPlaidLinkService, PlaidLinkHandler } from 'ngx-plaid-link';
import { combineLatest, filter, map, take, withLatestFrom } from 'rxjs';
import { BankAccountSelectorModalComponent } from '../bank-account-selector-modal/bank-account-selector-modal.component';
import { DetailsAgreementModalComponent } from '../details-agreement-modal/details-agreement-modal.component';
import { MocTrainingModalComponent } from '../moc-training-modal/moc-training-modal.component';
import { OnboardingItemComponent } from '../onboarding-item/onboarding-item.component';

/**
 * Component to display the onboarding steps from the state and trigger the appropriate actions when a step is clicked
 */
@Component({
  selector: 't-onboarding-steps',
  standalone: true,
  imports: [CommonModule, OnboardingItemComponent, ModalModule],
  templateUrl: './onboarding-steps.component.html',
})
export class OnboardingStepsComponent {
  constructor(
    private store: Store,
    private modalService: ModalService,
    private actions$: Actions,
    private plaidLinkService: NgxPlaidLinkService,
    private route: ActivatedRoute
  ) {}

  onboardingSteps$ = this.store.select(OnboardingState.onboardingSteps);
  currentActiveStep$ = this.store.select(OnboardingState.currentActiveStep);
  plaidLinkToken$ = this.store.select(OnboardingState.plaidLinkToken);

  onboardingData$ = combineLatest([this.onboardingSteps$, this.currentActiveStep$]).pipe(
    filter(([steps, currentActiveStep]) => !!steps.length && !!currentActiveStep),
    map(([steps, currentActiveStep]) => {
      return {
        steps,
        currentActiveStep,
      };
    })
  );

  private plaidLinkHandler = {} as PlaidLinkHandler;

  triggerOnboardingStep = (step: OnboardingStep) => {
    switch (step.id) {
      case 'details_and_agreement': {
        const modalRef = this.modalService.openDialog(DetailsAgreementModalComponent, null, {
          preventClosePromptMessage:
            'Are you sure you want to close this form? Any progress made so far will be lost.',
          preventClosePromptTitle: 'Close Form',
          preventCloseOnPromptConfirm: () => {
            modalRef.close();
          },
          disablePadding: true,
        });
        break;
      }

      case 'bank_account_connection': {
        this.actions$
          .pipe(
            ofActionSuccessful(InitiatePlaidLink),
            withLatestFrom(this.plaidLinkToken$),
            take(1)
          )
          .subscribe(([, plaidLinkToken]) => {
            if (!plaidLinkToken) return;

            this.plaidLinkService
              .createPlaid({
                token: plaidLinkToken,
                onExit: () => {
                  this.store.dispatch(
                    new SetOnboardingStepStatus('bank_account_connection', {
                      loading: false,
                    })
                  );
                },
                onSuccess: (token: string, metadata: PlaidItemSuccessResponse) => {
                  this.route.paramMap.pipe(take(1)).subscribe((params) => {
                    const onboardingSubject = params.get('onboardingSubject');
                    if (!onboardingSubject) return;
                    const pharmacyId = parseInt(onboardingSubject);
                    if (typeof pharmacyId !== 'number') return;

                    if (pharmacyId && metadata.accounts && metadata.accounts.length > 0) {
                      // If only one account is found, we can complete the step
                      if (metadata.accounts.length === 1) {
                        this.store.dispatch([
                          new SubmitPlaidPublicToken(token, Number(pharmacyId)),
                          new CompleteOnboardingStep(),
                        ]);
                        return;
                      }

                      // If multiple accounts resulted from the Plaid Link flow, we need to show the account selector modal
                      if (metadata.accounts.length > 1) {
                        this.modalService.openPromptDialog(
                          BankAccountSelectorModalComponent,
                          {
                            token,
                            pharmacyId,
                            accounts: metadata.accounts,
                          },
                          {
                            hideCloseButton: true,
                            disableClose: true,
                          }
                        );
                        return;
                      }
                    }
                  });
                },
              })
              .then((handler: PlaidLinkHandler) => {
                this.plaidLinkHandler = handler;
                this.plaidLinkHandler.open();
              });
          });

        this.route.paramMap.pipe(take(1)).subscribe((params) => {
          const onboardingSubject = params.get('onboardingSubject');
          if (!onboardingSubject) return;
          const pharmacyId = parseInt(onboardingSubject);
          if (typeof pharmacyId !== 'number') return;

          if (pharmacyId) {
            this.store.dispatch([
              new SetOnboardingStepStatus('bank_account_connection', {
                loading: true,
              }),
              new InitiatePlaidLink(Number(pharmacyId)),
            ]);
          }
        });
        break;
      }

      case 'model_of_care_training': {
        this.modalService.openDialog(MocTrainingModalComponent, null, {
          title: 'Model of Care Training',
          disablePadding: true,
        });
        break;
      }

      default:
        break;
    }
  };
}
