<div class="relative flex max-w-sm items-center justify-center py-8 lg:w-full">
  <div class="flex flex-col items-center justify-center gap-y-8">
    <p class="text-grey-80 block w-2/3 text-center text-base">
      {{ data.tourIntroText }}
    </p>
    <div class="flex justify-center space-x-2.5">
      <t-button (click)="startTour()" size="medium">TOUR</t-button>
      <t-button (click)="skipTour()" variant="secondary" size="medium">SKIP</t-button>
    </div>
  </div>
</div>
