/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsModule } from '../../icon/icon.module';
import { PopoverComponent } from '../../popover/popover.component';
import { PopoverModule } from '../../popover/popover.module';
import { OptionsButtonComponent } from './options-button/options-button.component';

@Component({
  selector: 't-search-input',
  standalone: true,
  imports: [CommonModule, FormsModule, IconsModule, OptionsButtonComponent, PopoverModule],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements ControlValueAccessor {
  constructor(private cdr: ChangeDetectorRef) {}

  @Input() hasOptions = false;
  @Input() optionsDropdown!: PopoverComponent;
  @Input() optionsMenuId = '';
  @Input() optionButtonText = 'Options';

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  isFocused = false;
  displayReset = false;
  input = '';
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = (newValue: any) => {
      this.handleResetButtonStatus(newValue);
      fn(newValue);
    };
  }
  registerOnTouched(fn: any): void {
    this.onTouch = (newValue: any) => {
      this.handleResetButtonStatus(newValue);
      fn(newValue);
    };
  }
  writeValue(input: string) {
    this.input = input;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = input || '';
    }
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  handleResetButtonStatus(input: any) {
    this.displayReset = !!(input && input.length > 0);
  }

  clearSearch() {
    this.onChange('');
    this.onTouch('');
    this.writeValue('');
    this.input = '';
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
  }
}
