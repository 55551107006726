<div
  class="flex gap-x-4"
  *ngIf="calendarAccessToken$ | async as calendarAccessToken; else noAccessTokenAvailable">
  <div class="flex-1">
    <t-input-text [readonly]="true" [formControl]="calendarAccessFormControl"></t-input-text>
  </div>

  <t-button size="large" (onClick)="copy()">COPY URL</t-button>
</div>

<ng-template #noAccessTokenAvailable> </ng-template>
