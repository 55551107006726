<div class="relative">
  <div
    [tPopoverAnchor]="optionsSelection"
    (click)="optionsSelection.toggle()"
    class="flex min-w-[100px] cursor-pointer justify-between rounded-lg border border-black border-opacity-10 px-2 py-1 transition-all hover:border-opacity-20">
    @if (currentSelection) {
      <strong class="text-grey-70 text-xs font-semibold uppercase tracking-wider">
        {{ currentSelection.label }}
      </strong>
    }

    <span
      class="text-grey-60"
      [ngClass]="{
        '-scale-100': !optionsSelection.isOpen
      }">
      <t-icon key="keyboard-arrow-down" size="tiny" color="inherit" />
    </span>
  </div>
</div>

<t-popover verticalAlign="below" horizontalAlign="end" #optionsSelection hasBackdrop>
  <ul
    class="divide-y-1 divide-grey-20 shadow-small text-grey-90 block w-full min-w-[100px] translate-y-1 bg-white">
    @for (item of options; track item.id) {
      <li
        (click)="optionsSelection.close(); selectItem(item)"
        class="hover:bg-grey-20 cursor-pointer p-2 text-xs">
        {{ item.label }}
      </li>
    }
  </ul>
</t-popover>
