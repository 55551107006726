import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LayoutService } from './layout.service';
import { Layouts } from './models/layouts.enum';

/**
 * Sets the page layout type through a resolver so that
 * before navigating to a component layout is available.
 */
export const setLayout = (inputLayout: Layouts): ResolveFn<void> => {
  return () => {
    inject(LayoutService).setLayout(inputLayout);
  };
};
