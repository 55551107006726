import { AfterViewInit, Component, DestroyRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedTourService } from '../../guided-tour.service';
import {
  generateFirstSlideButtons,
  generateGuidedTourDefaultConfigOptions,
  generatePositionStrategy,
} from '../../guided-tour.config';
import { Store } from '@ngxs/store';
import { ActionsState } from '@troyai/actions/data-access';
import { combineLatest, delay, filter, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TourStartingPromptComponent } from '../tour-starting-prompt/tour-starting-prompt.component';
import { ModalService } from '@troyai/ui-kit';

@Component({
  selector: 't-single-action-tour',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-action-tour.component.html',
})
export class SingleActionTourComponent implements AfterViewInit {
  constructor(
    private guidedTourService: GuidedTourService,
    private store: Store,
    private modalService: ModalService
  ) {}

  destroyRef = inject(DestroyRef);

  selectedAction$ = this.store
    .select(ActionsState.selectedAction)
    .pipe(filter((action) => !!action));

  ngAfterViewInit() {
    this.guidedTourService.defaultStepOptions = generateGuidedTourDefaultConfigOptions(
      this.guidedTourService
    );
    this.guidedTourService.tourName = 'single-action';
    this.guidedTourService.modal = true;
    this.guidedTourService.confirmCancel = false;
    this.guidedTourService.addSteps([
      {
        id: '1-action-cta',
        attachTo: {
          element: '#tour-action-cta',
          on: generatePositionStrategy('left'),
        },
        title: 'Actions',
        text: ['Your call to action.'],
        buttons: generateFirstSlideButtons(this.guidedTourService),
      },
    ]);

    combineLatest([this.selectedAction$, of(this.guidedTourService.tourName)])
      .pipe(
        delay(500),
        tap(([, tourName]) => {
          if (!this.guidedTourService.getLocalStorageCompletionStatusByName(tourName)) {
            this.modalService.openPromptDialog(
              TourStartingPromptComponent,
              {
                tourIntroText:
                  'This is the Action view, where you can submit the requested data and chat with the Troy.AI team.',
              },
              {
                hideCloseButton: true,
              }
            );
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
