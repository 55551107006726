<div class="relative">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="grid auto-rows-auto grid-cols-3 gap-8">
    <div class="col-span-2">
      <t-select
        label="Result of outreach"
        [loading]="(outreachAttemptsOutcomesLoading$ | async)?.loading || false"
        [items]="(outreachAttemptsOutcomes$ | async) || []"
        formControlName="outcome" />
      <t-field-error [controlName]="form.controls['outcome']" />
    </div>

    <div class="col-span-1">
      <t-input-text
        formControlName="phoneNumber"
        id="phoneNumber"
        label="Phone Number"
        icon="call" />
      <t-field-error [controlName]="form.controls['phoneNumber']" />
    </div>

    @if (displayReasonField) {
      <div class="col-span-3">
        <t-input-text label="Reason" formControlName="reason" id="reason" />
        <t-field-error [controlName]="form.controls['reason']" />
      </div>
    }

    @if (displayFollowUpCallField) {
      <div class="col-span-3">
        <t-date-picker-input
          label="Time of follow-up call"
          dateFormat="M/d/y"
          formControlName="followUpCallDate"
          id="followUpCallDate" />
      </div>
    }

    <div class="col-span-3">
      <t-button type="submit">Save</t-button>
    </div>
  </form>
</div>
