import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserState } from '@troyai/auth/data-access';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { UserRolesService } from '../services/user-roles.service';

/**
 * This guard is used to check if the user has the required role to access the route.
 *
 * The route must have the following data:
 *
 * @example
 * requiredRoles: UserRoles[]
 * rolesRoutesMapping: { [key in UserRoles]: string }
 *
 * If the user has any of the required roles, the guard will allow access to the route.
 * If the user does not have any of the required roles, the guard will redirect based on the rolesRoutesMapping, defaulting to '/forbidden'.
 */
@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(
    private userRolesService: UserRolesService,
    private router: Router,
    private store: Store
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.handleRoleGuard(next);
  }

  canActivateChild(next: ActivatedRouteSnapshot) {
    return this.canActivate(next);
  }

  private handleRoleGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
    const userRoles$ = this.store.select(UserState.userRoles);
    const rolesRoutesMapping = route.data['rolesRoutesMapping'];

    return this.userRolesService.hasAccess(route.data['requiredRoles']).pipe(
      withLatestFrom(userRoles$),
      map(([hasRequiredRole, userRoles]) => {
        if (!userRoles || !userRoles.length) {
          this.router.navigateByUrl('/forbidden');
          return false;
        }

        if (!hasRequiredRole) {
          const url = rolesRoutesMapping ? rolesRoutesMapping[userRoles[0]] : '/forbidden';
          this.router.navigateByUrl(url);
          return false;
        }

        return true;
      })
    );
  }
}
