import { Resource } from '@troyai/resources/data-access';

export type ActionFormDisplayType = 'modal' | 'inline' | 'new_tab';
export type ActionFormType = 'external_link' | 'native';

export interface FormSettings {
  form_type: ActionFormType;
  link_template: string;
  link_template_staging: string;
  form_display: ActionFormDisplayType;
}

export interface ActionMemberAssignment {
  full_name: string;
  dob: string;
  first_name: string;
  last_name: string;
  middle_name: string;
}

export interface RelatedActions {
  global_id: number;
  action_template_id: number;
  timestamp: string;
  status: string;
  notes: string | null;
  due_date: string;
  pharmacy_assigned_to: number | null;
  member_assigned_to: number;
  reference_id: string;
  name: string;
  fee: string;
  title: string;
  fee_schedule_image: string;
  action_bundle_id: number;
  action_bundle_name: string;
}

export interface Action {
  global_id: number;
  action_template_id: number;
  approved_for_payment_date?: string;
  timestamp: string;
  status: string;
  notes?: string;
  due_date: string;
  pharmacy_assigned_to?: number;
  member_assigned_to: number;
  reference_id: string;
  name: string;
  fee: string;
  fee_schedule: string;
  fee_schedule_image: string;
  start_date: string;
  end_date?: string;
  template_id: number;
  title: string;
  description: string;
  fee_schedule_id: number;
  created_on: string;
  disabled_on?: string;
  pharmacy_name: string;
  pharmacy_id: number;
  scheduling_enabled: boolean;
  scheduled_at?: string;
  first_scheduled_by: string;
  scheduled_by: string;
  message_count: number;
  member: ActionMemberAssignment;
  updated_at: string;
  appointment_addresses?: AppointmentAddress[];
  scheduled_address?: AppointmentAddress;
  completion_time_max: number;
  completion_time_min: number;
  unread_message_count: number;
  resources: ActionResource[];
  last_hra_completed_on: string | null;
  extra_data: ExtraActionData[];
  related_actions: RelatedActions[];
  latest_form_submission_id: number | null;

  // Synthetic properties, not present in the response
  member_name: string;
  formatted_dob?: string;
}

export interface ActionTemplate {
  action_template_id: number;
  tenant_id: number;
  fee_schedule_id: number;
  title: string;
  form_settings: ActionForm;
  reference_id: string;
  fee_schedule_image: string;
  description: string;
}

export interface ExtraActionDataBase {
  type: string;
}

export interface ExtraActionDataRecentMedicationList extends ExtraActionDataBase {
  type: 'recent_medication_list';
  data: RecentMedicationList[];
}

export interface ExtraActionDataHypertension extends ExtraActionDataBase {
  type: 'hypertension_history';
  data: HypertensionHistoryList[];
}

export interface RecentMedicationList {
  member_id: string;
  drug: string;
  pharmacy: string;
  service_date: string;
}

export interface HypertensionHistoryList {
  member_id: string;
  value: string;
  pharmacy: string;
  measure_date: string;
}

export type ExtraActionData = ExtraActionDataRecentMedicationList | ExtraActionDataHypertension;

export interface GetActionsQueryParams {
  filter: string;
}

export interface ComputedActionValues {
  formDisplayType: ActionFormDisplayType;
  formType: ActionFormType;
  formURL: string;
  hasCompletedState: boolean;
  nativeFormResources?: ActionNativeFormResources;
  hasDraft?: boolean;
}

export interface AppointmentAddress {
  address_city: string;
  address_county: string;
  address_hash: string;
  address_state: string;
  address_street: string;
  address_zip: string;
  at_pharmacy: boolean;
  scheduled_telehealth: boolean;
  np_id: number;
}

export interface ActionResource extends Resource {
  action_template_id: number;
  resource_id: number;
}

export enum ActionStatusEnum {
  NEW = 'New',
  NEEDS_RESUBMISSION = 'Needs Resubmission',
  SCHEDULED = 'Scheduled',
  APPROVED_FOR_PAYMENT = 'Approved For Payment',
  IN_REVIEW = 'In Review',
  DATA_REVIEW = 'Data Review',
  NEEDS_REVIEW = 'Needs Review',
  AWAITING_DATA_TO_CONFIRM_COMPLETION = 'Awaiting Data to Confirm Completion',
  COMPLETED_NOT_ELIGIBLE_FOR_PAYMENT = 'Completed, not eligible for payment',
  PAID = 'Paid',
  INVOICED = 'Invoiced',
  CANCELED = 'Canceled',
  INTERNALLY_COMPLETED = 'Internally Completed',
  EXPIRED = 'Expired',
  COMPLETED = 'Completed',
}

export interface CustomStatusMap {
  [key: string]: ActionStatusEnum;
}

export interface ActionNativeFormResources {
  source: string;
  id: string;
}

export interface ActionForm {
  form_type: ActionFormType;
  form_display: ActionFormDisplayType;
  form_url: string;
  native_form_resources?: ActionNativeFormResources;
}
