<div
  class="relative max-w-[240px] text-center"
  [ngClass]="{
    'space-y-3': orientation === 'vertical',
    'flex max-w-none items-center gap-x-2 space-y-0': orientation === 'horizontal'
  }">
  <p class="text-grey-100 block text-sm">
    {{ content }}
  </p>
  <t-label
    (click)="pharmaciesList.toggle()"
    [tPopoverAnchor]="pharmaciesList"
    class="text-amber-70 cursor-pointer"
    size="tiny"
    color="warning">
    <div class="flex items-center gap-x-2">
      <span>{{ label }}</span>
      <t-icon key="add" size="tiniest" color="inherit"></t-icon>
    </div>
  </t-label>

  <t-popover
    class="hidden"
    verticalAlign="above"
    horizontalAlign="start"
    #pharmaciesList
    hasBackdrop>
    <t-pharmacy-list [showAllPharmaciesOption]="false"></t-pharmacy-list>
  </t-popover>
</div>
