<div
  class="relative"
  [ngStyle]="{
    'width.px': size,
    'height.px': size
  }">
  <svg
    class="-rotate-90"
    [ngStyle]="{
      'width.px': size,
      'height.px': size
    }">
    <circle
      class="stroke-grey-20"
      [attr.cx]="center"
      [attr.cy]="center"
      fill="transparent"
      [attr.r]="radius"
      [attr.stroke-width]="trackWidth + 'px'" />
    <circle
      [attr.cx]="center"
      [attr.cy]="center"
      fill="transparent"
      [attr.r]="radius"
      stroke="inherit"
      [attr.stroke-width]="indicatorWidth + 'px'"
      [attr.stroke-dasharray]="dashArray"
      [attr.stroke-dashoffset]="dashOffset"
      stroke-linecap="butt" />
  </svg>
  <div
    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 space-y-1.5 text-center"
    [ngStyle]="{
      'maxWidth.px': size
    }">
    @if (label) {
      <span class="text-grey-70 block text-[10px] font-semibold">{{ label }}</span>
    }

    <span
      class="block text-sm font-semibold"
      [ngClass]="{
        'text-grey-100': !matchTextColor,
        inherit: matchTextColor
      }">
      {{ progress | percent: '0.0-1' }}
    </span>
  </div>
</div>
