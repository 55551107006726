<div class="relative">
  @if (displayAgreementEmbed) {
    <div class="relative pt-5">
      @if (onboardingAgreementData$ | async; as agreementData) {
        <object
          class="min-h-[calc(100vh-250px)] w-full"
          [data]="getSanitizedAgreementURL(agreementData.documentURL)"
          type="application/pdf">
          <p class="p-8">
            No PDF support detected. Please download the file from
            <a
              class="underline"
              [href]="getSanitizedAgreementURL(agreementData.documentURL)"
              target="_blank">
              here.
            </a>
          </p>
        </object>
        <div class="shadow-small w-full px-8 py-5">
          <t-button (click)="displayAgreementEmbed = false">I AGREE</t-button>
        </div>
      }
    </div>
  } @else {
    <div class="px-8 pt-5">
      <div class="bg-amber-20 flex items-center justify-between rounded-2xl p-4 pl-8">
        <p class="text-grey-70 text-sm">
          Please read the Agreement before filling out and signing the form.
        </p>
        <t-button variant="secondary" (click)="displayAgreementEmbed = true">
          VIEW AGREEMENT
        </t-button>
      </div>
    </div>

    <div class="px-8 py-6">
      <t-action-iframe-embed [formMinHeight]="600" [sourceURL]="(formUrl$ | async) || ''" />
    </div>
  }
</div>
