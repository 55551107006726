import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AgeByDobPipe, DateOfBirthPipe, GenderLetterPipe } from '@troyai/portal/common/pipes';

@Component({
  selector: 't-patient-meta',
  standalone: true,
  imports: [CommonModule, DateOfBirthPipe, AgeByDobPipe, GenderLetterPipe],
  templateUrl: './patient-meta.component.html',
  styleUrls: ['./patient-meta.component.css'],
})
export class PatientMetaComponent {
  @Input({ required: true }) metaData: {
    dateOfBirth: string;
    gender?: string;
    dateOfDeath?: string | null;
  } = {
    dateOfBirth: '',
  };
}
