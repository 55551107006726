import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed',
  standalone: true,
})
export class ToFixedPipe implements PipeTransform {
  transform(value: number, digits = 0, direction = 'up'): number {
    if (!Number.isInteger(digits) || digits < 0) {
      throw new Error('Digits must be a positive integer');
    }

    const roundFn = direction === 'up' ? Math.ceil : Math.floor;
    return roundFn(value * 10 ** digits) / 10 ** digits;
  }
}
