import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Action } from '@troyai/actions/data-access';
import { ExpirationDatePipe } from '@troyai/portal/common/pipes';
import { CardComponent, LabelComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-actions-list-minimal',
  standalone: true,
  imports: [CommonModule, CardComponent, RouterModule, LabelComponent, ExpirationDatePipe],
  templateUrl: './actions-list-minimal.component.html',
})
export class ActionsListMinimalComponent {
  @Input() actions: Action[] = [];
  @Input() patientId = 0;
}
