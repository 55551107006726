/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import {
  CustomDynamicFormControl,
  DynamicRenderedControls,
} from '@troyai/portal/dynamic-forms/data-access';
import { getDate, getMonth, getYear, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class FormRendererControlService {
  toFormControl(config: DynamicRenderedControls): CustomDynamicFormControl | null {
    // Some controls are just for presentation purposes only, being irrelevant for submitted data.
    if (config.type === 'control_head') {
      return null;
    }

    const validators = [];
    if (config.required) {
      validators.push(Validators.required);
    }

    if (config.type === 'control_dropdown') {
      const control = new CustomDynamicFormControl(
        { value: config.defaultValue || null, disabled: config.readonly },
        validators
      );

      control.controlType = 'control_dropdown';
      return control;
    }

    const control = new CustomDynamicFormControl(
      { value: config.defaultValue || '', disabled: config.readonly },
      validators
    );

    control.controlType = config.type;
    control.fieldLabel = config.label;
    return control;
  }

  formControlsToJotformSubmission(
    formControls: {
      [key: string]: AbstractControl<any, any>;
    },
    dynamicRenderedControls: DynamicRenderedControls[]
  ) {
    const excludedControlTypes = ['control_head'];
    const eligibleControls = dynamicRenderedControls.filter(
      (item) => !excludedControlTypes.includes(item.type)
    );

    const submittedData = eligibleControls
      .map((control) => {
        const controlFormValue = formControls[control.controlName];

        if (control.type === 'control_fullname') {
          return {
            key: control.controlName,
            value: {
              first: controlFormValue.value['first'],
              last: controlFormValue.value['last'],
            },
          };
        }

        if (control.type === 'control_address') {
          return {
            key: control.controlName,
            value: {
              addr_line1: controlFormValue.value['addr_line1'] || '',
              addr_line2: controlFormValue.value['addr_line2'] || '',
              city: controlFormValue.value['city'] || '',
              state: controlFormValue.value['state'] || '',
              postal: controlFormValue.value['postal'] || '',
            },
          };
        }

        if (control.type === 'control_checkbox') {
          if (controlFormValue.value && Array.isArray(controlFormValue.value)) {
            return {
              key: control.controlName,
              value: controlFormValue.value.reduce((acc, item, idx) => {
                acc[idx] = item;
                return acc;
              }, {}),
            };
          }

          return {
            key: control.controlName,
            value: '',
          };
        }

        if (control.type === 'control_datetime') {
          if (controlFormValue.value) {
            const parsedDate = parse(controlFormValue.value, 'yyyy-MM-dd', new Date());

            return {
              key: control.controlName,
              value: {
                day: String(getDate(parsedDate)),
                month: String(getMonth(parsedDate) + 1),
                year: String(getYear(parsedDate)),
              },
            };
          }
          return {
            key: control.controlName,
            value: '',
          };
        }

        if (control.type === 'control_widget') {
          const value =
            controlFormValue.value && controlFormValue.value.rows
              ? JSON.stringify(controlFormValue.value.rows)
              : '';

          return {
            key: control.controlName,
            value,
          };
        }

        if (control.type === 'control_matrix') {
          if (control.inputType === 'radio') {
            const value = control.rows.reduce(
              (acc, row, idx) => {
                if (controlFormValue.value[row]) {
                  acc[idx] = [controlFormValue.value[row]];
                }

                return acc;
              },
              {} as Record<string, string[]>
            );

            return {
              key: control.controlName,
              value: Object.keys(value).length > 0 ? value : '',
            };
          }

          return {
            key: control.controlName,
            value: controlFormValue.value,
          };
        }

        return {
          key: control.controlName,
          value: controlFormValue.value,
        };
      })
      .reduce(
        (acc, item) => {
          if (item.key) {
            acc[item.key] = item.value;
          }

          return acc;
        },
        {} as Record<string, any>
      );

    return submittedData;
  }
}
