import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { ButtonGroupToggleOption } from './button-group-toggle-options.model';

@Component({
  selector: 't-button-group-toggle',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './button-group-toggle.component.html',
})
export class ButtonGroupToggleComponent {
  @Input() options: ButtonGroupToggleOption[] = [];
  @Output() selectedOption = new EventEmitter<ButtonGroupToggleOption[]>();

  optionSelected(option: ButtonGroupToggleOption) {
    if (option.selected) {
      return;
    }

    const selectedOptions: ButtonGroupToggleOption[] = this.options.map((item) => {
      if (item.id === option.id) {
        return {
          ...item,
          selected: true,
        };
      }

      return {
        ...item,
        selected: false,
      };
    });

    this.selectedOption.emit(selectedOptions);
  }
}
