import { DataSource } from '@angular/cdk/collections';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, share, startWith, switchMap } from 'rxjs/operators';

import { indicate } from '@troyai/shared/utils';
import { Filter } from './models/filters.model';
import {
  DataSourceListingConfig,
  ListingDataSourceOptions,
  Page,
  Pagination,
  SortOptionValue,
} from './models/page.model';
import { DataSourceListingService } from './services/data-source-listing.service';

export interface SimpleDataSource<T> extends DataSource<T> {
  connect(): Observable<T[]>;
  disconnect(): void;
}

export class ListingDataSource<T> implements SimpleDataSource<T> {
  private pageNumber = new Subject<number>();
  private sort!: BehaviorSubject<SortOptionValue<T>[]>;
  private filters!: BehaviorSubject<Filter<T>[]>;
  private search!: BehaviorSubject<string>;
  private loading = new Subject<boolean>();

  public loading$ = this.loading.asObservable();
  public page$!: Observable<Page<T>>;

  private router?: Router;
  private dataSourceListingService: DataSourceListingService;
  private pageSize = 10;

  constructor(options: ListingDataSourceOptions = {}) {
    if (options.routeEvents) {
      this.router = inject(Router);
    }

    this.dataSourceListingService = new DataSourceListingService(this.router);
  }

  init(
    source$: Observable<T[]>,
    sort: SortOptionValue<T>[],
    filters: Filter<T>[],
    search: string,
    pagination: Pagination,
    config: DataSourceListingConfig<T>
  ) {
    this.sort = new BehaviorSubject<SortOptionValue<T>[]>(sort);
    this.filters = new BehaviorSubject<Filter<T>[]>(filters);
    this.search = new BehaviorSubject<string>(search);
    this.pageSize = pagination.size;

    const param$ = combineLatest([this.sort, this.filters, this.search, source$]);
    this.page$ = param$.pipe(
      switchMap(([sort, filters, search, source]) =>
        this.pageNumber.pipe(
          startWith(pagination.page),
          switchMap((page) =>
            this.dataSourceListingService
              .generateListingEntries(
                source,
                sort,
                filters,
                search,
                {
                  page,
                  size: this.pageSize,
                },
                config
              )
              .pipe(indicate(this.loading))
          )
        )
      ),
      share()
    );
  }

  sortBy(sort: SortOptionValue<T>[]): void {
    this.sort.next(sort);
    this.pageNumber.next(1);
  }

  filterBy(filters: Filter<T>[]): void {
    this.filters.next(filters);
    this.pageNumber.next(1);
  }

  searchBy(searchText: string): void {
    this.search.next(searchText);
    this.pageNumber.next(1);
  }

  fetch(page: number): void {
    this.pageNumber.next(page);
  }

  connect(): Observable<T[]> {
    return this.page$.pipe(map((page) => page.items));
  }

  disconnect(): void {
    return;
  }
}
