import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  Store,
  ofActionSuccessful,
} from '@ngxs/store';
import { EMPTY, catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { GetCurrentUserDetails, UserRoles, UserState } from '@troyai/auth/data-access';
import { UserRolesService } from '@troyai/auth/feature';
import { FeatureFlagsService } from '@troyai/feature-flags';
import { notificationRouteByType } from '@troyai/notifications/utils';
import { enrichNotificationPreview } from '../helpers/enrich-notification-preview.util';
import { Notification, NotificationPreview } from '../models/notification.interface';
import { NotificationsApiService } from '../notifications-api.service';
import { NotificationsGraphQLService } from '../notifications-graphql.service';
import { NotificationsLocalStorageService } from '../notifications-local-storage.service';
import { NotificationsWsService } from '../notifications-ws.service';
import { NotificationsStateModel } from './notifications-state.model';
import {
  GetNotificationsCount,
  GetNotificationsList,
  MarkAllNotificationsAsRead,
  MarkNotificationAsRead,
  NotificationReceived,
  NotificationsSetupReady,
  SetupGraphQLConnection,
  SetupNotificationsToken,
  SetupWebSocketConnection,
} from './notifications.actions';

@State<NotificationsStateModel>({
  name: 'notificationsState',
  defaults: {
    notifications: [],
    notificationsLoading: false,
    notificationsCount: 0,
    token: '',
    setupReady: false,
  },
})
@Injectable()
export class NotificationsState {
  constructor(
    private store: Store,
    private actions$: Actions,
    private notificationsApiService: NotificationsApiService,
    private notificationsLocalStorageService: NotificationsLocalStorageService,
    private notificationsGraphQLService: NotificationsGraphQLService,
    private notificationsWSService: NotificationsWsService,
    private router: Router,
    private userRolesService: UserRolesService,
    private featureFlagsService: FeatureFlagsService
  ) {
    this.listenToNotifications();
    this.actions$
      .pipe(
        ofActionSuccessful(GetCurrentUserDetails),
        mergeMap(() => {
          return this.userRolesService.hasAccess([UserRoles.PharmacyUser]);
        })
      )
      .subscribe((isPharmacyUser) => {
        if (isPharmacyUser && this.featureFlagsService.isFeatureEnabled('in-app-notifications')) {
          this.store.dispatch(new SetupNotificationsToken());
        }
      });

    this.actions$
      .pipe(
        ofActionSuccessful(
          SetupNotificationsToken,
          SetupGraphQLConnection,
          SetupWebSocketConnection
        )
      )
      .subscribe(() => {
        this.store.dispatch(new GetNotificationsCount());
        this.store.dispatch(new NotificationsSetupReady());
      });
  }

  @Selector()
  static token(state: NotificationsStateModel) {
    return state.token;
  }

  @Selector()
  static notificationsCount(state: NotificationsStateModel) {
    return state.notificationsCount;
  }

  @Selector()
  static notificationsList(state: NotificationsStateModel) {
    return state.notifications;
  }

  @Selector()
  static notificationsLoading(state: NotificationsStateModel) {
    return state.notificationsLoading;
  }

  @Selector()
  static isSetupReady(state: NotificationsStateModel) {
    return state.setupReady;
  }

  @Action(GetNotificationsList)
  getNotificationsList({ patchState }: StateContext<NotificationsStateModel>) {
    patchState({
      notificationsLoading: true,
    });

    const currentUserPharmaciesList = this.store.selectSnapshot(UserState.userPharmacies);

    return this.notificationsGraphQLService.getNotificationsList().pipe(
      map((response) => {
        const messages = response.messages.nodes;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodedMessageContents = messages.map((message: any) => {
          const parsedPreview: NotificationPreview = JSON.parse(atob(message.preview));
          const enrichedParsedPreview = enrichNotificationPreview(
            parsedPreview,
            currentUserPharmaciesList
          );

          return {
            ...message,
            preview: enrichedParsedPreview,
            routeTarget: notificationRouteByType(parsedPreview.type, parsedPreview.resource),
          };
        });

        return decodedMessageContents;
      }),
      tap((response) => {
        patchState({ notifications: response });
      }),
      tap(() => {
        patchState({
          notificationsLoading: false,
        });
      })
    );
  }

  @Action(SetupNotificationsToken)
  setupNotificationsToken({ patchState, dispatch }: StateContext<NotificationsStateModel>) {
    return this.notificationsLocalStorageService.localToken$().pipe(
      switchMap((token) => {
        if (token) {
          patchState({ token });
          return of(token);
        }

        return this.notificationsApiService.getToken().pipe(
          tap((response) => {
            this.notificationsLocalStorageService.setToken(response.token);
            patchState({ token: response.token });
          }),
          map((response) => response.token),
          catchError(() => EMPTY)
        );
      }),
      tap((token) => {
        if (token) {
          dispatch([new SetupGraphQLConnection(), new SetupWebSocketConnection()]);
        }
      })
    );
  }

  @Action(SetupGraphQLConnection)
  setupGraphQLConnection({ getState }: StateContext<NotificationsStateModel>) {
    const { token } = getState();
    return this.notificationsGraphQLService.setupClient(token);
  }

  @Action(SetupWebSocketConnection)
  setupWebSocketConnection({ getState }: StateContext<NotificationsStateModel>) {
    const { token } = getState();
    const currentUserEmailHash = this.store.selectSnapshot(UserState.userEmailHash);

    this.notificationsWSService.connect(token);
    return this.notificationsLocalStorageService.localToken$().pipe(
      tap(() => {
        this.notificationsWSService.sendMessage({
          action: 'subscribe',
          data: {
            channel: currentUserEmailHash,
            event: '*',
            version: 4,
          },
        });
      })
    );
  }

  @Action(NotificationsSetupReady)
  markSetupAsReady({ patchState }: StateContext<NotificationsStateModel>) {
    return patchState({ setupReady: true });
  }

  listenToNotifications() {
    this.notificationsWSService.messages$
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap({
          error: (error) => console.log('Error:', error),
          complete: () => console.log('Connection Closed'),
        })
      )
      .subscribe((data) => {
        this.store.dispatch(
          new NotificationReceived({
            notificationData: data,
          })
        );
      });
  }

  @Action(NotificationReceived)
  notificationReceived(
    { patchState, getState }: StateContext<NotificationsStateModel>,
    action: NotificationReceived
  ) {
    const currentNotificationCount = getState().notificationsCount;
    const currentNotificationsList = getState().notifications;
    const currentUserPharmaciesList = this.store.selectSnapshot(UserState.userPharmacies);

    if (action.payload.notificationData.type === 'message') {
      patchState({
        notificationsCount: currentNotificationCount + 1,
      });

      if (this.router.url === '/notifications') {
        const notificationPreview: NotificationPreview = JSON.parse(
          atob(action.payload.notificationData.preview)
        );

        const enrichedNotificationPreview = enrichNotificationPreview(
          notificationPreview,
          currentUserPharmaciesList
        );

        const newNotification: Notification = {
          created: new Date().toISOString(),
          messageId: action.payload.notificationData.id,
          preview: enrichedNotificationPreview,
          read: null,
          routeTarget: notificationRouteByType(
            notificationPreview.type,
            notificationPreview.resource
          ),
        };
        patchState({
          notifications: [newNotification, ...currentNotificationsList],
        });
      }
    }
    return;
  }

  @Action(GetNotificationsCount)
  getNotificationsCount({ patchState }: StateContext<NotificationsStateModel>) {
    return this.notificationsGraphQLService.getNotificationsCount().pipe(
      tap((response) => {
        patchState({ notificationsCount: response.count });
      })
    );
  }

  @Action(MarkAllNotificationsAsRead)
  markAllAsRead({ patchState, getState }: StateContext<NotificationsStateModel>) {
    const currentNotifications = getState().notifications;
    return this.notificationsGraphQLService.markAllAsRead().pipe(
      tap(() => {
        patchState({
          notificationsCount: 0,
          notifications: currentNotifications.map((notification) => {
            return {
              ...notification,
              read: new Date().toISOString(),
            };
          }),
        });
      })
    );
  }

  @Action(MarkNotificationAsRead)
  markNotificationAsRead(
    { patchState, getState }: StateContext<NotificationsStateModel>,
    action: MarkNotificationAsRead
  ) {
    const currentNotificationCount = getState().notificationsCount;
    return this.notificationsGraphQLService.markNotificationAsRead(action.payload.id).pipe(
      tap(() => {
        patchState({ notificationsCount: Math.max(currentNotificationCount - 1, 0) });
      })
    );
  }
}
