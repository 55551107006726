import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@troyai/ui-kit';

type Variants = 'success' | 'warning' | 'danger';

@Component({
  selector: 't-action-status-card',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './action-status-card.component.html',
})
export class ActionStatusCardComponent {
  @Input() variant: Variants = 'success';
  @Input() titleText: string | null = 'Completed';
}
