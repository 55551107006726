import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadedFile } from '@troyai/patients/data-access';
import { LinkComponent } from '@troyai/ui-kit';
import { PatientFileCardComponent } from '../patient-file-card/patient-file-card.component';

@Component({
  selector: 't-patient-files-list',
  standalone: true,
  imports: [CommonModule, PatientFileCardComponent, LinkComponent],
  templateUrl: './patient-files-list.component.html',
})
export class PatientFilesListComponent {
  @Input() files: UploadedFile[] = [];
  @Input() newUploadedFiles: UploadedFile[] = [];
  @Output() fileDeleted = new EventEmitter<UploadedFile>();
  @Output() previewFile = new EventEmitter<UploadedFile>();
  @Output() downloadFile = new EventEmitter<UploadedFile>();

  deleteFile(file: UploadedFile) {
    this.fileDeleted.emit(file);
  }

  preview(file: UploadedFile) {
    this.previewFile.emit(file);
  }

  download(file: UploadedFile) {
    this.downloadFile.emit(file);
  }
}
