import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { TokenResponse } from './models/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getToken() {
    return this.restApiService.get<TokenResponse>(`notifications/auth`);
  }
}
