import { MedicationListItem } from '../models/medication.interface';

export class GetPatientMedicationsList {
  static readonly type = '[Medication] Get patient medication list';
  constructor(public memberGlobalId: number) {}
}

export class UpdateMedicationStatus {
  static readonly type = '[Medication] Update medication status';
  constructor(
    public payload: {
      memberGlobalId: number;
      medicationId: number;
      note: string;
      active: boolean;
    },
    public persist = true
  ) {}
}

export class UpdateMedicationNote {
  static readonly type = '[Medication] Update medication note';
  constructor(
    public payload: {
      memberGlobalId: number;
      medicationId: number;
      medicationListItem: Partial<MedicationListItem>;
    }
  ) {}
}

export class UpdateMedicationEditModeStatus {
  static readonly type = '[Medication] Update medication edit mode status';
  constructor(
    public medicationId: number,
    public editModeStatus: boolean
  ) {}
}
