<t-nav-item
  class="block"
  icon="help"
  variant="tertiary"
  size="small"
  (click)="helpAreaPopover.toggle()"
  [tPopoverAnchor]="helpAreaPopover">
  Help
</t-nav-item>

<t-popover
  class="hidden"
  [verticalAlign]="mobileMode ? 'above' : 'center'"
  [horizontalAlign]="mobileMode ? 'center' : 'after'"
  [autoFocus]="false"
  #helpAreaPopover
  hasBackdrop>
  <div class="shadow-large block w-[300px] max-w-xs rounded bg-white p-5">
    <div class="flex flex-col items-center justify-center gap-y-2 pb-8 pt-5">
      <img class="block h-12 w-12" src="assets/media-call-center.svg" alt="Help center" />
      <span class="text-grey-70 block text-xs">We're here to help</span>
    </div>

    <div class="space-y-2">
      <div class="bg-grey-20 text-grey-70 flex items-center justify-between rounded-lg p-4 text-xs">
        <span>EMAIL</span>
        <a class="underline" href="mailto:help@troy.ai">help&#64;troy.ai</a>
      </div>

      <div class="bg-grey-20 divide-grey-60 divide-y divide-opacity-10 rounded-lg p-4">
        <div class="text-grey-70 flex items-center justify-between pb-3 text-xs">
          <span>PHONE</span>
          <a class="underline" href="tel:910-460-0553">910 460-0553</a>
        </div>

        <ul class="text-grey-70 space-y-1 pt-3 text-xs opacity-50">
          <li>Dylan Harless, PharmD</li>
        </ul>
      </div>
    </div>

    <ng-container *ngIf="showTourStartButton">
      <div class="bg-grey-60 my-5 h-px bg-opacity-10"></div>

      <t-button
        (click)="startCurrentViewTourSegment(); helpAreaPopover.close()"
        size="medium"
        variant="secondary"
        [isFullWidth]="true"
        icon="ads-click">
        Show Guided Tour
      </t-button>
    </ng-container>
  </div>
</t-popover>
