import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, filter, fromEvent, take, takeUntil, tap } from 'rxjs';

import { Store } from '@ngxs/store';
import { Action, ActionsState, ComputedActionValues } from '@troyai/actions/data-access';
import { ActionEstimatedCompletionTimeComponent } from '@troyai/actions/ui';
import { CustomMediumDatePipe } from '@troyai/portal/common/pipes';
import { TrackDirective } from '@troyai/tracking';
import {
  ButtonComponent,
  CardComponent,
  IconsModule,
  LabelComponent,
  ModalModule,
  ModalService,
} from '@troyai/ui-kit';
import { ActionEmbedModalComponent } from '../action-embed-modal/action-embed-modal.component';
import { ActionIframeEmbedComponent } from '../action-iframe-embed/action-iframe-embed.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HraFormModalComponent } from '@troyai/hra/feature';

@Component({
  selector: 't-action-cta-card',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    ButtonComponent,
    ModalModule,
    ActionIframeEmbedComponent,
    LabelComponent,
    CardComponent,
    CustomMediumDatePipe,
    ActionEstimatedCompletionTimeComponent,
    TrackDirective,
  ],
  templateUrl: './action-cta-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCtaCardComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private actionModalRef?: DialogRef<unknown, any>;

  selectedActionFormSettingsLoading$ = this.store.select(
    ActionsState.selectedActionFormSettingsLoading
  );
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() action!: Action;
  @Input() description = '';
  @Input() computedActionValues!: ComputedActionValues;

  @Output() actionComplete = new EventEmitter();

  openActionModal() {
    if (this.computedActionValues.formType === 'external_link') {
      this.actionModalRef = this.modalService.openDialog(
        ActionEmbedModalComponent,
        this.computedActionValues.formURL,
        {
          disablePadding: true,
          preventClosePromptMessage:
            'Are you sure you want to close this action form? Any progress made so far will be lost.',
          preventClosePromptTitle: 'Close Action Form',
          offsetTop: 50,
          preventCloseOnPromptConfirm: () => {
            this.actionModalRef?.close();
          },
        }
      );
    }

    if (this.computedActionValues.formType === 'native') {
      this.actionModalRef = this.modalService.openDialog(
        HraFormModalComponent,
        this.computedActionValues.nativeFormResources,
        {
          title: 'HRA Form',
          isFullscreen: true,
          offsetTop: 1,
          preventCloseOnPromptConfirm: () => {
            this.actionModalRef?.close();
          },
          preventClosePromptMessage:
            'Are you sure you want to close this action form? Any unsaved changes will be lost.',
          preventClosePromptTitle: 'Close Action Form',
        }
      );
    }

    // Either on manually closing the modal, or by finishing the form, emit the actionComplete event
    this.actionModalRef?.closed.pipe(take(1)).subscribe(() => {
      this.actionComplete.emit();
    });
  }

  openNewTab() {
    window.open(this.computedActionValues.formURL, '_blank');
  }

  ngOnInit(): void {
    fromEvent(window, 'message', { capture: true })
      .pipe(
        filter((event) => {
          const messageEvent = event as MessageEvent;
          return messageEvent.data === 'CLOSE_ACTION_FORM_MODAL';
        }),
        take(1),
        tap(() => {
          // Closing the modal will emit the actionComplete event
          this.actionModalRef?.close();

          // If the form is embedded inline, emit the actionComplete event
          if (this.computedActionValues.formDisplayType === 'inline') {
            this.actionComplete.emit();
          }
          this.computedActionValues.hasCompletedState = true;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    window.removeEventListener(
      'message',
      () => {
        return false;
      },
      false
    );
  }
}
