import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { DateDistancePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 't-widget-updated-status',
  standalone: true,
  imports: [CommonModule, ButtonComponent, DateDistancePipe, RouterModule],
  templateUrl: './widget-updated-status.component.html',
})
export class WidgetUpdatedStatusComponent {
  @Input() updateText = 'Last Update';
  @Input() updateTime: string | null = '';
  @Input() href = '';
  @Input() params: Params = {};
  @Input() buttonText = 'VIEW ALL';
}
