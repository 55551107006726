import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHistoryService } from '@troyai/portal/common/navigation';
import { ButtonComponent, CardComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-navbar-wrapper',
  standalone: true,
  imports: [CommonModule, CardComponent, ButtonComponent],
  templateUrl: './navbar-wrapper.component.html',
})
export class NavbarWrapperComponent {
  constructor(
    private routerHistoryService: RouterHistoryService,
    private router: Router
  ) {}
  @Input() fallbackRoute?: string;

  previousRoute = this.routerHistoryService.previousRoutePath.value;

  handleBackNavigation() {
    const targetPath = this.previousRoute ?? this.fallbackRoute ?? '/';
    this.router.navigateByUrl(targetPath);
  }
}
