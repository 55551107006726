import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '@troyai/actions/data-access';
import { UserRoles } from '@troyai/auth/data-access';
import { RolesDirective } from '@troyai/auth/feature';
import { AddressPipe, CustomMediumDatePipe } from '@troyai/portal/common/pipes';
import { ButtonComponent, IconsModule, LabelComponent } from '@troyai/ui-kit';

@Component({
  selector: 't-action-appointment-preview',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    CustomMediumDatePipe,
    ButtonComponent,
    AddressPipe,
    LabelComponent,
    RolesDirective,
  ],
  templateUrl: './action-appointment-preview.component.html',
})
export class ActionAppointmentPreviewComponent {
  @Input({ required: true }) action!: Action;
  @Output() canceledAppointment = new EventEmitter<void>();

  userRoles = UserRoles;

  cancelAppointment() {
    this.canceledAppointment.emit();
  }
}
